/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ConfigurationState {
  openModalEditPermissionsRoot: boolean;
  rootPermissions: Object;
  configurationAlert: Object;
}

const initialState: ConfigurationState = {
  openModalEditPermissionsRoot: false,
  rootPermissions: {},
  configurationAlert: {},
};

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    updateOpenModalEditPermissionsRoot: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.openModalEditPermissionsRoot = action.payload;
    },
    updateRootPermissions: (state, action: PayloadAction<Object>) => {
      state.rootPermissions = action.payload;
    },
    updateConfigurationAlert: (state, action: PayloadAction<Object>) => {
      state.configurationAlert = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateOpenModalEditPermissionsRoot,
  updateRootPermissions,
  updateConfigurationAlert,
} = configurationSlice.actions;

export default configurationSlice.reducer;
