/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Alert, Button, Spin, Table, Dropdown, Space, Menu, Empty } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsis,
  faTrash,
  faPenToSquare,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
  updateVendor,
  updateOpenDrawerVendor,
  updateShowVendorTable,
  updateOpenFormVendor,
  updateOpenFormVendorAction,
  updateOpenModalDeleteVendor,
} from "../../../redux/tools/toolsSlice";
import { useLazyGetVendorsQuery } from "../../../redux/configurationFiles/configurationFilesAPI";
// COMPONENTS
import VendorsHeader from "./VendorsHeader";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../../utils/utils";

const { Column } = Table;

function VendorsTable() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  // eslint-disable-next-line no-unused-vars
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [vendorsCatalog, setVendorsCatalog] = useState<any>([]);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  const [
    triggerGetVendors,
    {
      data: dataVendors,
      isLoading: isLoadingVendors,
      isFetching: isFetchingVendors,
    },
  ] = useLazyGetVendorsQuery();

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { permissions } = useSelector((state: any) => state.user);
  const { vendorRefreshTable, showVendorTable } = useSelector(
    (state: any) => state.tool
  );

  const manageActions = () => {
    const BLANK = {
      label: (
        <div className="generalStyles__tableActionBox">
          <span>
            <FontAwesomeIcon
              className="generalStyles__error generalStyles__mrFix"
              icon={faBan}
            />
            {t("general.noActions")}
          </span>
        </div>
      ),
      key: "0",
    };

    const EDIT = validatePermission("permission-not-defined", permissions)
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenModalEdit();
              }}
              onKeyDown={() => {
                handleOpenModalEdit();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faPenToSquare}
                />
                {t("organizationManagement.edit")}
              </span>
            </div>
          ),
          key: "2",
        }
      : null;

    const DELETE = validatePermission("permission-not-defined", permissions)
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenModalDelete();
              }}
              onKeyDown={() => {
                handleOpenModalDelete();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faTrash}
                />
                {t("organizationManagement.delete")}
              </span>
            </div>
          ),
          key: "3",
        }
      : null;

    // RETURN OBJECT WITH VALID ACTIONS
    if (EDIT || DELETE) {
      return [EDIT, DELETE];
    }
    return [BLANK];
  };

  const menu = <Menu items={manageActions()} />;

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onClickShowForm = () => {
    setIsLoading(true);
    dispatch(updateShowVendorTable(true));
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const handleOpenModalEdit = () => {
    dispatch(updateVendor(selectedItem));
    dispatch(updateOpenFormVendor(true));
    dispatch(updateOpenFormVendorAction("update"));
  };

  const handleOpenModalDelete = () => {
    dispatch(updateVendor(selectedItem));
    dispatch(updateOpenModalDeleteVendor(true));
  };

  const onCellClick = (record: any) => {
    if (record) {
      dispatch(updateVendor(record));
      dispatch(updateOpenDrawerVendor(true));
    }
  };

  const getInitialData = async () => {
    const token = await GETJwtToken();
    triggerGetVendors({
      token,
    });
  };

  const refreshTable = async () => {
    getInitialData();
  };
  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    getInitialData();
  }, [showVendorTable]);

  useEffect(() => {
    if (vendorRefreshTable) {
      refreshTable();
    }
  }, [vendorRefreshTable]);

  useEffect(() => {
    if (dataVendors && dataVendors.data) {
      setVendorsCatalog(dataVendors.data);
    }
  }, [dataVendors]);

  // ************************************************ */
  // COMPONENT ************************************** */

  return (
    <div>
      {/** ----------------------------------------------------- */}
      {/** SHOW ALERT */}
      {!showVendorTable && (
        <div className="mb-4">
          <Alert
            message={t("configuration.vendor.warning")}
            description={t("configuration.vendor.warningDescription")}
            type="warning"
            showIcon
            closable
          />
          <Button
            className="buttonStyle__2 configuration__editRootPermisionsButton"
            onClick={onClickShowForm}
          >
            {t("configuration.vendor.showRootConfiguration")}
          </Button>
        </div>
      )}
      {/** ----------------------------------------------------- */}
      {/** VENDOR FORM */}
      {showVendorTable && (
        <Spin spinning={isLoading || isLoadingVendors}>
          {validatePermission("permission-not-defined", permissions) ? (
            <>
              <VendorsHeader />
              <Table
                rowClassName={(record, index) => getTableRowClass(index, theme)}
                dataSource={vendorsCatalog}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "25", "50", "100"],
                }}
                size="small"
                className="mt-3"
                loading={isFetchingVendors}
              >
                {/** NAME */}
                <Column
                  title="Name"
                  dataIndex="name"
                  key="name"
                  className=""
                  onCell={(record) => ({
                    onClick: () => {
                      onCellClick(record);
                    },
                  })}
                  render={(text) => (
                    <div className="generalStyles__info">{text || "--"}</div>
                  )}
                />
                {/** ACTIONS */}
                <Column
                  title={t("edgeManagement.banks.actions")}
                  key="action"
                  render={(_: any, record: any) => (
                    <Dropdown overlay={menu} trigger={["click"]} className="">
                      <Space
                        className="generalStyles__hoverCursor"
                        onClick={() => {
                          setSelectedItem(record);
                        }}
                      >
                        <FontAwesomeIcon icon={faEllipsis} />
                      </Space>
                    </Dropdown>
                  )}
                />
              </Table>
            </>
          ) : (
            <>
              <div className="generalStyles__noAccessToListTable">
                <Empty
                  description={t(
                    "organizationManagement.listNotAvailableOrganizations"
                  )}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              </div>
            </>
          )}
        </Spin>
      )}
    </div>
  );
}

export default VendorsTable;
