import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import {
  Drawer,
  Button,
  Input,
  Spin,
  Divider,
  InputNumber,
  Switch,
} from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faCircleCheck,
  faBuildingShield,
} from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetConfigurationFilesQuery } from "../../../redux/configurationFiles/configurationFilesAPI";
import { usePutBankMutation } from "../../../redux/banks/bankAPI";
import {
  updateOpenModalEditBank,
  updateBankAlert,
} from "../../../redux/banks/bankSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

function BanksEditForm() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [isLoading, setIsLoading] = useState(false);
  const [masterID, setMasterID] = useState("");
  const [numberOfLifts, setNumberOfLifts] = useState(1);
  const [numberOfStops, setNumberOfStops] = useState(2);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [board, setBoard] = useState(0);
  const [active, setActive] = useState(true);

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { bank, openModalEditBank } = useSelector((state: any) => state.bank);
  const { theme } = useSelector((state: any) => state.home);
  const { userLogged } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    putBank,
    {
      isSuccess: isSuccessPutBank,
      isError: isErrorPutBank,
      error: errorPutBank,
      isLoading: isLoadingPutBank,
      reset: resetPutBank,
    },
  ] = usePutBankMutation();
  const [triggerGetConfigurationFilesTable, { data: configurationFiles }] =
    useLazyGetConfigurationFilesQuery();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onFill = () => {
    setNumberOfLifts(bank.number_of_lifts);
    setNumberOfStops(bank.stops);
    setName(bank.name);
    setType(bank.type);
    setBoard(bank.board);
    setActive(bank.active);
  };

  const onChangeName = (newValue: string) => {
    setName(newValue);
  };

  const onChangeBoard = (newValue: number) => {
    setBoard(newValue);
  };

  const handleCloseModal = () => {
    dispatch(updateOpenModalEditBank(false));
  };

  const onChangeActive = (newValue: boolean) => {
    setActive(newValue);
  };

  const onClickSave = async () => {
    const token = await GETJwtToken();
    const BODY = {
      token,
      id: bank.id,
      body: {
        name,
        board,
        active,
        // Old data
        server_id: bank.server_id,
        number_of_lifts: bank.number_of_lifts,
        floor_count: bank.floor_count,
        floor_below_lobby: bank.floor_below_lobby,
        columns: bank.columns,
        stops: bank.stops,
        logical_port: bank.logical_port,
        rear_doors: bank.rear_doors,
        configuration_id: bank.configuration_id,
        port_id: bank.port_id,
        type: bank.type,
      },
    };
    putBank(BODY);
  };

  const onClose = () => {
    dispatch(updateOpenModalEditBank(false));
  };

  const getConfigurationFiles = async (orgId: string) => {
    const token = await GETJwtToken();
    triggerGetConfigurationFilesTable({
      token,
      orgId,
      page: 1,
      limit: 999,
    });
  };

  const filterConfigurationFiles = () => {
    const newObj: any = [];
    if (configurationFiles && configurationFiles.data) {
      configurationFiles.data.map((item: any) => {
        if (
          numberOfLifts <= item.lifts &&
          numberOfStops <= item.stops &&
          item.lift_type === type
        ) {
          newObj.push(item);
        }
        return true;
      });
    }
  };
  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (userLogged && userLogged.org && userLogged.org.id) {
      setMasterID(userLogged.org.id);
    }
  }, [userLogged]);

  useEffect(() => {
    if (openModalEditBank) {
      resetPutBank();
      getConfigurationFiles(masterID);
      if (bank) {
        onFill();
        setType(bank.type);
      }
    }
  }, [openModalEditBank]);

  useEffect(() => {
    if (isSuccessPutBank) {
      setTimeout(() => {
        dispatch(
          updateBankAlert({
            title: t("general.success"),
            description: t("edgeManagement.banks.bankEdited"),
            status: "success",
          })
        );
      }, 150);
      dispatch(updateOpenModalEditBank(false));
    }
    if (isErrorPutBank) {
      setTimeout(() => {
        dispatch(
          updateBankAlert({
            title: t("general.error"),
            description: errorPutBank,
            status: "error",
          })
        );
      }, 150);
      dispatch(updateOpenModalEditBank(false));
    }
  }, [isSuccessPutBank, isErrorPutBank]);

  useEffect(() => {
    filterConfigurationFiles();
  }, [configurationFiles]);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 400);
    filterConfigurationFiles();
  }, [numberOfLifts, numberOfStops]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClose}
      closable={false}
      visible={openModalEditBank}
    >
      <Spin spinning={isLoadingPutBank || isLoading} tip={t("general.loading")}>
        {/** ---------------------------------------------------- */}
        {/** BANK INFORMATION */}
        <div>
          <Divider orientation="left" className="generalStyles__drawerDivider">
            <h5>
              <FontAwesomeIcon
                icon={faBuildingShield}
                className="generalStyles__info generalStyles__mrFix"
              />
              {t("edgeManagement.banks.editBank")}
            </h5>
          </Divider>
        </div>
        <div className="container">
          <div
            className={
              theme === "dark"
                ? "row drawer__box__dark"
                : "row drawer__box__light"
            }
          >
            {/** NAME */}
            <div className="col-md-12">
              <div className="generalStyles__flex">
                <div className="generalStyles__inputFlexName">
                  <span className="generalStyles__inputFlexRequired">*</span>
                  <span className="drawer__text">
                    {t("edgeManagement.banks.bankName")}
                  </span>
                </div>
                <div className="generalStyles__width100">
                  <Input
                    className=""
                    size="small"
                    value={name}
                    onChange={(value) => {
                      onChangeName(value.target.value);
                    }}
                  />
                </div>
                <div className="generalStyles__infoTooltipIconBlank" />
              </div>
            </div>
            {/** LIFT TYPE */}
            <div className="col-md-12 mt-3">
              <div className="generalStyles__flex">
                <div className="generalStyles__inputFlexName">
                  <span className="generalStyles__inputFlexRequired">*</span>
                  <span>{t("edgeManagement.lifts.type")}</span>
                </div>
                <div className="generalStyles__width100">
                  <Input value="ELEVATOR" disabled />
                </div>
                <div className="generalStyles__infoTooltipIconBlank" />
              </div>
            </div>
            {/** BOARD */}
            <div className="col-md-12 mt-3">
              <div className="generalStyles__flex">
                <div className="generalStyles__inputFlexName">
                  <span>{t("configuration.configurationFiles.board")}</span>
                </div>
                <div className="generalStyles__width100">
                  <InputNumber
                    className="generalStyles__inputNumberFull"
                    precision={0}
                    value={board}
                    onChange={onChangeBoard}
                    max={99999}
                    size="small"
                  />
                </div>
                <div className="generalStyles__infoTooltipIconBlank" />{" "}
              </div>
            </div>
            {/** BANK ACTIVE */}
            <div className="col-md-12 mt-3">
              <div className="generalStyles__flex">
                <div className="generalStyles__inputFlexName">
                  <span>{t("general.active")}</span>
                </div>
                <div className="generalStyles__width100">
                  <Switch
                    onChange={onChangeActive}
                    checked={active}
                    checkedChildren={<FontAwesomeIcon icon={faCircleCheck} />}
                    unCheckedChildren={
                      <FontAwesomeIcon icon={faCircleExclamation} />
                    }
                  />
                </div>
                <div className="generalStyles__infoTooltipIconBlank" />
              </div>
            </div>
          </div>
          <br />
        </div>
        {/** ---------------------------------------------------- */}
        <div className="mt-4">
          <div>
            <Button onClick={handleCloseModal} className="buttonStyle__3">
              {t("organizationManagement.close")}
            </Button>
            <Button
              onClick={onClickSave}
              disabled={isLoadingPutBank || name === ""}
              className="buttonStyle__3"
              type="primary"
            >
              {t("general.save")}
            </Button>
          </div>
        </div>
      </Spin>
    </Drawer>
  );
}

export default BanksEditForm;
