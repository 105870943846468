import React from "react";
// REACT DETECT OFFLINE
import { Offline, Online } from "react-detect-offline";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import RootPermissionsJsonViewer from "./RootPermissionsJsonViewer";
import RootPermissionsEditModal from "./RootPermissionsEditModal";
// NO INTERNET CONNECTION
import NoInternetConnection from "../../unauthorized/NoInternetConnection";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

const RootPermissionsPage = () => {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const { permissions } = useSelector((state: any) => state.user);
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      {validatePermission("root-permissions", permissions) ? (
        <div
          className={
            theme === "light"
              ? "layout__contentPage__light"
              : "layout__contentPage__dark"
          }
        >
          <Online>
            <RootPermissionsJsonViewer />
            <RootPermissionsEditModal />
          </Online>
          <Offline>
            <NoInternetConnection />
          </Offline>
        </div>
      ) : (
        <UnauthorizedPage />
      )}
    </div>
  );
};

export default RootPermissionsPage;
