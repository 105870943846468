import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { DatePicker, Button, Select, Tooltip } from "antd";
// MOMENT DATE FORMAT
import moment from "moment";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsRotate,
  faDownload,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  updateOpenAlertDownloadReport,
  updateReportOffsetRefreshTable,
  updateReportOffsetFilters,
} from "../../../redux/reports/reportsSlice";
import { useLazyGetAllOffsetsTypesQuery } from "../../../redux/configurationFiles/configurationFilesAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import OrganizationCascaderSelect from "../../organizationManagement/organizations/OrganizationCascaderSelect";

function ReportOffsetsHeader() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const { RangePicker } = DatePicker;
  const [t] = useTranslation("global");
  const dispatch = useDispatch();
  const { Option } = Select;

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [offsetTypeList, setOffsetTypeList] = useState([]);
  const [defaultStartDate, setDefaultStartDate] = useState("");
  const [defaultEndDate, setDefaultEndDate] = useState("");
  const [datePickerValue, setDatePickerValue] = useState<any>([]);
  const [offsetType, setOffsetType] = useState("");
  const [cascaderSelectData, setCascaderSelectData] = useState<any>([]);
  const [cascaderTypeLevel, setCascaderTypeLevel] = useState(4);
  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { reportOffsetFilters } = useSelector((state: any) => state.report);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [triggerGetOffsetsType, { data: liftOffsetsType }] =
    useLazyGetAllOffsetsTypesQuery();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const getDaysInMonth = (year: any, month: any) =>
    new Date(year, month, 0).getDate();

  const getDefaultDatePicker = () => {
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1; // 👈️ months are 0-based
    const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);
    const startDate = `${currentYear}-${
      currentMonth < 10 ? "0" : ""
    }${currentMonth}-01`;
    const endDate = `${currentYear}-${
      currentMonth < 10 ? "0" : ""
    }${currentMonth}-${daysInCurrentMonth}`;
    setDefaultStartDate(startDate);
    setDefaultEndDate(endDate);
    setDatePickerValue([startDate, endDate]);
    // Save initial default date in filter object
    const copy = structuredClone(reportOffsetFilters);
    copy.startDate = startDate;
    copy.endDate = endDate;
    dispatch(updateReportOffsetFilters(copy));
  };

  const initData = async () => {
    const token = await GETJwtToken();
    triggerGetOffsetsType({ token });
    getDefaultDatePicker();
  };

  const onClickDownloadReport = () => {
    dispatch(updateOpenAlertDownloadReport(true));
  };

  const onClickReloadPage = () => {
    dispatch(updateReportOffsetRefreshTable(true));
    setTimeout(() => {
      dispatch(updateReportOffsetRefreshTable(false));
    }, 1000);
  };

  const filterDatePicker = (date: any, dateString: [string, string]): void => {
    if (dateString && date && dateString.length > 0) {
      const copy = structuredClone(reportOffsetFilters);
      copy.startDate = dateString[0];
      copy.endDate = dateString[1];
      setDatePickerValue([dateString[0], dateString[1]]);
      dispatch(updateReportOffsetFilters(copy));
    } else {
      const copy = structuredClone(reportOffsetFilters);
      copy.startDate = "";
      copy.endDate = "";
      setDatePickerValue([defaultStartDate, defaultEndDate]);
      dispatch(updateReportOffsetFilters(copy));
    }
  };

  const filterOffsetType = (value: string) => {
    // Save filters
    const copy = structuredClone(reportOffsetFilters);
    copy.offsetTypeId = value;
    setOffsetType(value);
    dispatch(updateReportOffsetFilters(copy));
  };

  const onClickClearFilters = () => {
    setDatePickerValue([defaultStartDate, defaultEndDate]);
    setOffsetType("");
    dispatch(
      updateReportOffsetFilters({
        startDate: "",
        endDate: "",
        filterTypeLevel: 0,
        filterTypeArr: [],
        offsetTypeId: "",
      })
    );
  };

  const disableSearchAndRefreshButton = () => {
    let disableButton = false;
    if (
      (reportOffsetFilters && reportOffsetFilters.filterTypeLevel === 0) ||
      reportOffsetFilters.filterTypeArr.length === 0 ||
      reportOffsetFilters.endDate === "" ||
      reportOffsetFilters.startDate === ""
    ) {
      disableButton = true;
    }
    return disableButton;
  };

  const onChangeCascaderSelect = () => {
    const copy = structuredClone(reportOffsetFilters);
    copy.filterTypeArr = cascaderSelectData;
    dispatch(updateReportOffsetFilters(copy));
  };

  const onChangeCascaderTypeLevel = () => {
    const copy = structuredClone(reportOffsetFilters);
    copy.filterTypeLevel = cascaderTypeLevel;
    copy.filterTypeArr = [];
    dispatch(updateReportOffsetFilters(copy));
  };
  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (liftOffsetsType && liftOffsetsType.data) {
      setOffsetTypeList(liftOffsetsType.data);
    }
  }, [liftOffsetsType]);

  useEffect(() => {
    if (cascaderSelectData && cascaderSelectData.length > 0) {
      onChangeCascaderSelect();
    }
  }, [cascaderSelectData]);

  useEffect(() => {
    onChangeCascaderTypeLevel();
  }, [cascaderTypeLevel]);
  // ************************************************ */
  // COMPONENT ************************************** */

  return (
    <div className="generalStyles__spaceBetween">
      <div className="generalStyles__flex">
        {/** ******************************************* */}
        {/** FILTER DATE AND TIME */}
        <div className="generalStyles__mlFix">
          <RangePicker
            onChange={filterDatePicker}
            style={{ width: "250px" }}
            value={[moment(datePickerValue[0]), moment(datePickerValue[1])]}
          />
        </div>
        {/** ******************************************* */}
        {/** CASCADER SELECT */}
        <OrganizationCascaderSelect
          setCascaderSelectData={setCascaderSelectData}
          setCascaderTypeLevel={setCascaderTypeLevel}
        />
        {/** ******************************************* */}
        {/** SELECT OFF TYPE OFFSETS */}
        <div className="generalStyles__mlFix">
          <Select
            showSearch
            optionFilterProp="children"
            placeholder="Offset Type"
            style={{ width: "150px" }}
            allowClear
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            value={offsetType}
            className="generalStyles__width100"
            onChange={filterOffsetType}
          >
            {offsetTypeList.map((item: any) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </div>
        {/** ******************************************* */}
        {/** SEARCH */}
        <div className="generalStyles__mlFix">
          <Tooltip
            placement="topLeft"
            title={
              disableSearchAndRefreshButton()
                ? t("report.requiredSelectFiler")
                : t("general.search")
            }
          >
            <Button
              className={
                disableSearchAndRefreshButton()
                  ? "buttonStyle__inactive__1__dark"
                  : "buttonStyle__6"
              }
              icon={<FontAwesomeIcon icon={faSearch} />}
              onClick={() => {
                onClickReloadPage();
              }}
              disabled={disableSearchAndRefreshButton()}
            />
          </Tooltip>
        </div>
        {/** ******************************************* */}
        {/** REFRESH */}
        <div className="generalStyles__mlFix">
          <Tooltip
            placement="topLeft"
            title={
              disableSearchAndRefreshButton()
                ? t("report.requiredSelectFiler")
                : t("organizationManagement.refreshTable")
            }
          >
            <Button
              className={
                disableSearchAndRefreshButton()
                  ? "buttonStyle__inactive__1__dark"
                  : "buttonStyle__6"
              }
              icon={<FontAwesomeIcon icon={faArrowsRotate} />}
              onClick={() => {
                onClickReloadPage();
              }}
              disabled={disableSearchAndRefreshButton()}
            />
          </Tooltip>
        </div>
        {/** ******************************************* */}
        {/** CLEAR FILTERS */}
        <div className="generalStyles__mlFix">
          <Tooltip placement="topLeft" title={t("general.clearFilters")}>
            <Button
              className="buttonStyle__2"
              icon={<FontAwesomeIcon icon={faTrash} />}
              onClick={onClickClearFilters}
            />
          </Tooltip>
        </div>
        {/** ******************************************* */}
        {/** DOWNLOAD */}
        <div className="generalStyles__mlFix">
          <Tooltip
            placement="topLeft"
            title={`${t("general.download")} ${t("general.report")}`}
          >
            <Button
              className="buttonStyle__2"
              icon={<FontAwesomeIcon icon={faDownload} />}
              onClick={onClickDownloadReport}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default ReportOffsetsHeader;
