import React from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Divider } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingShield } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerBankCheckOffsets } from "../../../redux/banks/bankSlice";
// COMPONENTS

function BanksCheckOffsets() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { openDrawerBankCheckOffsets } = useSelector(
    (state: any) => state.bank
  );
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */

  const onClose = () => {
    dispatch(updateOpenDrawerBankCheckOffsets(false));
  };

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="45%"
      placement="right"
      onClose={onClose}
      closable={false}
      visible={openDrawerBankCheckOffsets}
    >
      <div>
        <br />
        {/** ************************************************* */}
        {/** CHECK OFFSETS */}
        <div>
          <Divider orientation="left" className="generalStyles__drawerDivider">
            <h5>
              <FontAwesomeIcon
                icon={faBuildingShield}
                className="generalStyles__info generalStyles__mrFix"
              />
              {t("configuration.configurationFiles.offsets")}
            </h5>
          </Divider>
        </div>
        <div
          className={
            theme === "dark" ? " drawer__box__dark" : " drawer__box__light"
          }
        >
          Check offsets
        </div>
      </div>
    </Drawer>
  );
}

export default BanksCheckOffsets;
