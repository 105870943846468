import React, { useEffect } from "react";
// REACT DETECT OFFLINE
import { Offline, Online } from "react-detect-offline";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateConfigurationFileBreadcrumb } from "../../../redux/configurationFiles/configurationFilesSlice";
// COMPONENTS
import ConfigurationFilesHeader from "./ConfigurationFilesHeader";
import ConfigurationFilesTable from "./ConfigurationFilesTable";
import ConfigurationFilesDrawer from "./ConfigurationFilesDrawer";
import ConfigurationFilesDeleteModal from "./ConfigurationFilesDeleteModal";
import ConfigurationFilesCreateForm from "./ConfigurationFilesCreateForm";
import ConfigurationFilesDataBlockForm from "./ConfigurationFilesDataBlockForm";
// NO INTERNET CONNECTION
import NoInternetConnection from "../../unauthorized/NoInternetConnection";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

const ConfigurationFilesPage = () => {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch();
  const { permissions } = useSelector((state: any) => state.user);
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    dispatch(updateConfigurationFileBreadcrumb([]));
  }, []);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      {validatePermission("configuration-manager", permissions) ? (
        <div>
          {/** HEADER AND STEPER */}
          <div
            className={
              theme === "light"
                ? "layout__contentPage__light"
                : "layout__contentPage__dark"
            }
          >
            <Online>
              <ConfigurationFilesDrawer />
              <ConfigurationFilesCreateForm />
              <ConfigurationFilesDeleteModal />
              <ConfigurationFilesHeader />
              <ConfigurationFilesTable />
              <ConfigurationFilesDataBlockForm />
            </Online>
            <Offline>
              <NoInternetConnection />
            </Offline>
          </div>
        </div>
      ) : (
        <UnauthorizedPage />
      )}
    </div>
  );
};

export default ConfigurationFilesPage;
