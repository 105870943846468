/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthenticationState {
    openModalTotpToken: boolean;
}

const initialState: AuthenticationState = {
    openModalTotpToken: false
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    udpateOpenModalTotpToken: (state, action: PayloadAction<boolean>) => {
      state.openModalTotpToken = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
    udpateOpenModalTotpToken
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
