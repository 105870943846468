import React from "react";
// ANT DESIGN COMPONENTS
import { Divider } from "antd";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";

function DrawerServerInformation({ data }: any) {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const { theme } = useSelector((state: any) => state.home);
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      <br />
      {/** ************************************************* */}
      {/** SERVER INFORMATION */}
      <div>
        <Divider orientation="left" className="generalStyles__drawerDivider">
          <h5>
            <FontAwesomeIcon
              icon={faServer}
              className="generalStyles__info generalStyles__mrFix"
            />
            {t("edgeManagement.servers.serverInformation")}
          </h5>
        </Divider>
      </div>
      <div
        className={
          theme === "dark" ? " drawer__box__dark" : " drawer__box__light"
        }
      >
        {/* ID */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              ID:
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">{data && data.id ? data.id : "--"}</p>
          </div>
        </div>
        {/* NAME */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("edgeManagement.servers.serverName")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {data && data.name ? data.name : "--"}
            </p>
          </div>
        </div>
        {/* IP */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("edgeManagement.servers.ip")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">{data && data.ip ? data.ip : "--"}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DrawerServerInformation;
