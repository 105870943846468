/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// INTERFACE
import {
  OrganizationState,
  OrganizationInterface,
} from '../../interfaces/Organizations.interface';

const initialState: OrganizationState = {
  organization: {
    address: '',
    city: '',
    country: '',
    id: '',
    name: '',
    parent_id: '',
    phone: '',
    phone_code: '',
    state: '',
    type: '',
    updated_at: '',
    parent_name: '',
  },
  openModalCreateNewOrganization: false,
  openModalCreateSubOrganization: false,
  openModalEditOrganization: false,
  openModalDeleteOrganization: false,
  openDrawerOrganization: false,
  organizationAlert: {},
  organizationTableParams: {
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ["10", "20", "50", "100"],
    },
  },
  organizationRefreshTable: false,
  organizationList: [],
  organizationAndChilds: {
    data: {},
    childs: [],
  },
  organizationBreadcrumb: [],
  organizationUpdateBreadcrumb: {
    update: false,
    id: '',
    name: '',
  },
  organizationMonitoringViewData: [],
  organizationSelectMultipleData: []
};

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    updateOrganization: (
      state,
      action: PayloadAction<OrganizationInterface>
    ) => {
      state.organization = action.payload;
    },
    updateOpenModalCreateNewOrganization: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.openModalCreateNewOrganization = action.payload;
    },
    updateOpenModalCreateSubOrganization: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.openModalCreateSubOrganization = action.payload;
    },
    updateOpenModalEditOrganization: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.openModalEditOrganization = action.payload;
    },
    updateOpenModalDeleteOrganization: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.openModalDeleteOrganization = action.payload;
    },
    updateOpenDrawerOrganization: (state, action: PayloadAction<boolean>) => {
      state.openDrawerOrganization = action.payload;
    },
    updateOrganizationAlert: (state, action: PayloadAction<Object>) => {
      state.organizationAlert = action.payload;
    },
    updateOrganizationTableParams: (state, action: PayloadAction<Object>) => {
      state.organizationTableParams = action.payload;
    },
    updateOrganizationRefreshTable: (state, action: PayloadAction<boolean>) => {
      state.organizationRefreshTable = action.payload;
    },
    updateOrganizationList: (state, action: PayloadAction<any>) => {
      state.organizationList = action.payload;
    },
    updateOrganizationAndChilds: (state, action: PayloadAction<Object>) => {
      state.organizationAndChilds = action.payload;
    },
    updateOrganizationBreadcrumb: (state, action: PayloadAction<any>) => {
      state.organizationBreadcrumb = action.payload;
    },
    updateOrganizationUpdateBreadcrumb: (
      state,
      action: PayloadAction<Object>
    ) => {
      state.organizationUpdateBreadcrumb = action.payload;
    },
    updateOrganizationMonitoringViewData: (state, action: PayloadAction<Object>) => {
      state.organizationMonitoringViewData = action.payload;
    },
    updateOrganizationSelectMultipleData: (state, action: PayloadAction<any>) => {
      state.organizationSelectMultipleData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateOrganization,
  updateOpenModalCreateNewOrganization,
  updateOpenModalCreateSubOrganization,
  updateOpenModalEditOrganization,
  updateOpenModalDeleteOrganization,
  updateOpenDrawerOrganization,
  updateOrganizationAlert,
  updateOrganizationTableParams,
  updateOrganizationRefreshTable,
  updateOrganizationList,
  updateOrganizationAndChilds,
  updateOrganizationBreadcrumb,
  updateOrganizationUpdateBreadcrumb,
  updateOrganizationMonitoringViewData,
  updateOrganizationSelectMultipleData
} = organizationSlice.actions;

export default organizationSlice.reducer;
