import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import {
  Spin,
  Divider,
  Drawer,
  Input,
  Button,
  Select,
  InputNumber,
  Switch,
  Tooltip,
  Empty,
  Alert,
} from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuildingShield,
  faServer,
  faElevator,
  faCircleCheck,
  faCircleExclamation,
  faGear,
  faCircleInfo,
  faPlug,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetConfigurationFilesQuery } from "../../../redux/configurationFiles/configurationFilesAPI";
import {
  updateOpenModalCreateNewBank,
  updateBankAlert,
} from "../../../redux/banks/bankSlice";
import { usePostBankMutation } from "../../../redux/banks/bankAPI";
import { useLazyGetServersByOrganizationQuery } from "../../../redux/servers/serverAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import OrganizationSelectOrganizationSubOrganization from "../../organizationManagement/organizations/OrganizationSelectOrganizationSubOrganization";
// STYLES

const { Option } = Select;

function BanksCreateForm() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [masterID, setMasterID] = useState("");
  const [listServers, setListServers] = useState<any[]>([]);
  const [numberOfLifts, setNumberOfLifts] = useState(1);
  const [numberOfFloorsBelowLobby, setNumberOfFloorsBelowLobby] = useState(0);
  const [numberOfColumns, setNumberOfColumns] = useState(1);
  const [numberOfStops, setNumberOfStops] = useState(2);
  const [maxNumberOfStops, setMaxNumberOfStops] = useState(0);
  const [organizationsAndChildsIsLoading, setOrganizationsAndChildsIsLoading] =
    useState(false);
  const [active, setActive] = useState(true);
  const [name, setName] = useState("");
  const [type, setType] = useState("ELEVATOR");
  const [rearDoors, setRearDoors] = useState(true);
  const [board, setBoard] = useState(0);
  const [logicalPort, setLogicalPort] = useState(0);
  const [server, setServer] = useState("");
  const [configurationSelected, setConfigurationSelected] = useState("");
  const [listConfigurationFiles, setListConfigurationFiles] = useState<any>([]);
  const [channels, setChannels] = useState<any>([]);
  const [channel, setChannel] = useState("");
  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { openModalCreateNewBank } = useSelector((state: any) => state.bank);
  const { userLogged } = useSelector((state: any) => state.home);
  const { theme } = useSelector((state: any) => state.home);
  const { organizationAndChilds } = useSelector(
    (state: any) => state.organization
  );
  const {
    idServerSelected,
    nameServerSelected,
    server: serverFromServerTable,
  } = useSelector((state: any) => state.server);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    postBank,
    {
      isSuccess: isSuccessPostBank,
      isLoading: isLoadingPostBank,
      isError: isErrorPostBank,
      error: errorPostBank,
      reset: resetPostBank,
    },
  ] = usePostBankMutation();
  const [
    triggerGetServersByOrganization,
    { data: dataGetServers, isLoading: isLoadingGetServers },
  ] = useLazyGetServersByOrganizationQuery();
  const [triggerGetConfigurationFilesTable, { data: configurationFiles }] =
    useLazyGetConfigurationFilesQuery();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const cleanData = () => {
    setListServers([]);
    setNumberOfLifts(1);
    setNumberOfColumns(1);
    setNumberOfStops(2);
    setLogicalPort(0);
    setRearDoors(true);
    setType("ELEVATOR");
    setName("");
    setActive(true);
    setBoard(0);
    setNumberOfFloorsBelowLobby(0);
    setConfigurationSelected("");
    setChannel("");
  };

  const onChangeName = (newValue: string) => {
    setName(newValue);
  };

  const onChangeNumberOfLifts = (value: number) => {
    setNumberOfLifts(value);
  };

  const onChangeNumberOfStops = (value: number) => {
    setNumberOfStops(value);
    setNumberOfFloorsBelowLobby(0);
  };

  const onChangeMaxNumberOfStops = (value: number) => {
    setMaxNumberOfStops(value);
  };

  const onChangeType = (newValue: string) => {
    setType(newValue);
  };

  const onChangeConfiguration = (newValue: string) => {
    setConfigurationSelected(newValue);
  };

  const onChangeActive = (newValue: boolean) => {
    setActive(newValue);
  };

  const onChangeBoard = (newValue: number) => {
    setBoard(newValue);
  };

  const onChangeServer = (newValue: string) => {
    setServer(newValue);
    const index = listServers.findIndex(
      (object: any) => object.id === newValue
    );
    setChannels(listServers[index].channels);
  };

  const onChangeChannel = (newValue: string) => {
    setChannel(newValue);
  };

  const onClickSave = async () => {
    const token = await GETJwtToken();
    const BODY = {
      token,
      body: {
        active,
        lifts: [],
        name,
        server_id: server,
        number_of_lifts: numberOfLifts,
        type,
        floor_count: numberOfStops,
        floor_below_lobby: numberOfFloorsBelowLobby,
        columns: numberOfColumns,
        stops: numberOfStops,
        board,
        logical_port: logicalPort,
        rear_doors: rearDoors,
        configuration_id: configurationSelected,
        offsets: [],
        floor_names: [],
        direct_link: "",
        channel_id: channel,
        configurations: [configurationSelected],
      },
    };
    postBank(BODY);
  };

  const onClickClose = () => {
    cleanData();
    dispatch(updateOpenModalCreateNewBank(false));
  };

  const getConfigurationFiles = async (orgId: string) => {
    const token = await GETJwtToken();
    triggerGetConfigurationFilesTable({
      token,
      orgId,
      page: 1,
      limit: 999,
    });
  };

  const launchGetServers = async (value: string) => {
    const token = await GETJwtToken();
    let orgId = userLogged.org_id;
    if (value !== "") {
      orgId = value;
    }
    triggerGetServersByOrganization({ token, orgId });
  };

  const filterConfigurationFiles = () => {
    const newObj: any = [];
    if (configurationFiles && configurationFiles.data) {
      configurationFiles.data.map((item: any) => {
        if (
          numberOfLifts <= item.lifts &&
          numberOfStops <= item.stops &&
          item.lift_type === type
        ) {
          newObj.push(item);
        }
        return true;
      });
    }
    setListConfigurationFiles(newObj);
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (openModalCreateNewBank) {
      cleanData();
      resetPostBank();
      getConfigurationFiles(masterID);
    }
  }, [openModalCreateNewBank]);

  useEffect(() => {
    if (isSuccessPostBank) {
      setTimeout(() => {
        dispatch(
          updateBankAlert({
            title: t("general.success"),
            description: t("edgeManagement.banks.bankCreated"),
            status: "success",
          })
        );
      }, 150);
      onClickClose();
    }
    if (isErrorPostBank) {
      setTimeout(() => {
        dispatch(
          updateBankAlert({
            title: t("general.error"),
            description: errorPostBank,
            status: "error",
          })
        );
      }, 150);
      onClickClose();
    }
  }, [isSuccessPostBank, isErrorPostBank]);

  useEffect(() => {
    if (userLogged && userLogged.org && userLogged.org.id) {
      setMasterID(userLogged.org.id);
    }
  }, [userLogged]);

  useEffect(() => {
    if (
      dataGetServers &&
      dataGetServers.data &&
      dataGetServers.data.length > 0
    ) {
      setListServers(dataGetServers.data);
    } else {
      setListServers([]);
      if (idServerSelected === "") {
        setServer("");
      }
    }
  }, [dataGetServers]);

  useEffect(() => {
    if (
      organizationAndChilds &&
      organizationAndChilds.data &&
      organizationAndChilds.data.idOrganizationSelected
    ) {
      setListServers([]);
      launchGetServers(organizationAndChilds.data.idOrganizationSelected);
    }
  }, [organizationAndChilds]);

  useEffect(() => {
    if (idServerSelected && idServerSelected !== "") {
      setServer(idServerSelected);
      if (
        serverFromServerTable &&
        serverFromServerTable.channels &&
        serverFromServerTable.channels.length > 0
      ) {
        setChannels(serverFromServerTable.channels);
      }
    }
  }, [idServerSelected]);

  useEffect(() => {
    filterConfigurationFiles();
  }, [configurationFiles]);

  useEffect(() => {
    filterConfigurationFiles();
  }, [numberOfLifts, numberOfStops]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClickClose}
      closable={false}
      visible={openModalCreateNewBank}
    >
      <Spin
        spinning={
          isLoadingPostBank ||
          isLoadingGetServers ||
          organizationsAndChildsIsLoading
        }
        tip={t("general.loading")}
      >
        {/** ---------------------------------------------------- */}
        {/** BANK INFORMATION */}
        <div>
          <Divider orientation="left" className="generalStyles__drawerDivider">
            <h5>
              <FontAwesomeIcon
                icon={faBuildingShield}
                className="generalStyles__info generalStyles__mrFix"
              />
              {t("edgeManagement.banks.createNewBank")}
            </h5>
          </Divider>
        </div>
        <div className="container">
          <div
            className={
              theme === "dark"
                ? "row drawer__box__dark"
                : "row drawer__box__light"
            }
          >
            {/** NAME */}
            <div className="col-md-12">
              <div className="generalStyles__flex">
                <div className="generalStyles__inputFlexName">
                  <span className="generalStyles__inputFlexRequired">*</span>
                  <span className="drawer__text">
                    {t("edgeManagement.banks.bankName")}
                  </span>
                </div>
                <div className="generalStyles__width100">
                  <Input
                    className=""
                    size="small"
                    onChange={(value) => {
                      onChangeName(value.target.value);
                    }}
                    value={name}
                  />
                </div>
                <div className="generalStyles__infoTooltipIconBlank" />
              </div>
            </div>
            {/** LIFT TYPE */}
            <div className="col-md-12 mt-3">
              <div className="generalStyles__flex">
                <div className="generalStyles__inputFlexName">
                  <span className="generalStyles__inputFlexRequired">*</span>
                  <span>{t("edgeManagement.lifts.type")}</span>
                </div>
                <div className="generalStyles__width100">
                  <Select
                    value={type}
                    onChange={onChangeType}
                    className="generalStyles__width100"
                    size="small"
                  >
                    {["ELEVATOR", "ESCALATOR", "WALKINGBAND"].map(
                      (item: any) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      )
                    )}
                  </Select>
                </div>
                <div className="generalStyles__infoTooltipIconBlank" />
              </div>
            </div>
            {/** BOARD */}
            <div className="col-md-12 mt-3">
              <div className="generalStyles__flex">
                <div className="generalStyles__inputFlexName">
                  <span>{t("configuration.configurationFiles.board")}</span>
                </div>
                <div className="generalStyles__width100">
                  <InputNumber
                    className="generalStyles__inputNumberFull"
                    precision={0}
                    value={board}
                    onChange={onChangeBoard}
                    max={99999}
                    size="small"
                  />
                </div>
                <div className="generalStyles__infoTooltipIconBlank" />
              </div>
            </div>
            {/** BANK ACTIVE */}
            <div className="col-md-12 mt-3">
              <div className="generalStyles__flex">
                <div className="generalStyles__inputFlexName">
                  <span>{t("general.active")}</span>
                </div>
                <div className="generalStyles__width100">
                  <Switch
                    onChange={onChangeActive}
                    checked={active}
                    checkedChildren={<FontAwesomeIcon icon={faCircleCheck} />}
                    unCheckedChildren={
                      <FontAwesomeIcon icon={faCircleExclamation} />
                    }
                  />
                </div>
                <div className="generalStyles__infoTooltipIconBlank" />
              </div>
            </div>
          </div>
          {/** ---------------------------------------------------- */}
          {/** CONFIGURATION TYPE WALKING BAND */}
          {type === "WALKINGBAND" && (
            <>
              <br />
              <Divider
                orientation="left"
                className="generalStyles__drawerDivider"
              >
                <h5>
                  <FontAwesomeIcon
                    icon={faElevator}
                    className="generalStyles__info generalStyles__mrFix"
                  />
                  {t("edgeManagement.banks.walikingbandConfiguration")}
                </h5>
              </Divider>
              <div
                className={
                  theme === "dark"
                    ? "row drawer__box__dark"
                    : "row drawer__box__light"
                }
              >
                <Empty
                  imageStyle={{ height: 60 }}
                  description={<span>{t("general.noData")}</span>}
                />
              </div>
            </>
          )}
          {/** ---------------------------------------------------- */}
          {/** CONFIGURATION TYPE ESCALETOR */}
          {type === "ESCALATOR" && (
            <>
              <br />
              <Divider
                orientation="left"
                className="generalStyles__drawerDivider"
              >
                <h5>
                  <FontAwesomeIcon
                    icon={faElevator}
                    className="generalStyles__info generalStyles__mrFix"
                  />
                  {t("edgeManagement.banks.escalatorConfiguration")}
                </h5>
              </Divider>
              <div
                className={
                  theme === "dark"
                    ? "row drawer__box__dark"
                    : "row drawer__box__light"
                }
              >
                <Empty
                  imageStyle={{ height: 60 }}
                  description={<span>{t("general.noData")}</span>}
                />
              </div>
            </>
          )}
          {/** ---------------------------------------------------- */}
          {/** ASSIGN TO CONFIGURATION */}
          <br />
          <Divider orientation="left" className="generalStyles__drawerDivider">
            <h5>
              <FontAwesomeIcon
                icon={faGear}
                className="generalStyles__info generalStyles__mrFix"
              />
              {t("configuration.configurationFiles.configurationFiles")}
            </h5>
          </Divider>
          <div
            className={
              theme === "dark"
                ? "row drawer__box__dark"
                : "row drawer__box__light"
            }
          >
            {/** ---------------------------------------------------- */}
            {/** CONFIGURATION */}
            <div className="col-md-12">
              <div className="generalStyles__flex">
                <div className="generalStyles__inputFlexName">
                  <span className="generalStyles__inputFlexRequired">*</span>
                  <span>
                    {t(
                      "configuration.configurationFiles.configurationSuggested"
                    )}
                  </span>
                </div>
                <div className="generalStyles__width100">
                  <Select
                    value={configurationSelected}
                    onChange={(e: any) => {
                      onChangeConfiguration(e);
                      const index = listConfigurationFiles.findIndex(
                        (item: any) => item.id === e
                      );
                      onChangeNumberOfLifts(
                        listConfigurationFiles[index].lifts
                      );
                      onChangeMaxNumberOfStops(
                        listConfigurationFiles[index].stops
                      );
                    }}
                    className="generalStyles__width100"
                    size="small"
                  >
                    {listConfigurationFiles.map((item: any) => (
                      <Option key={item.id} value={item.id}>
                        {item.name} - Lifts:
                        <span className="generalStyles__info">
                          {" "}
                          {item.lifts}
                        </span>
                        {"  "} Stops:
                        <span className="generalStyles__info">
                          {" "}
                          {item.stops}
                        </span>
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="generalStyles__infoTooltipIconBlank" />
              </div>
            </div>
            {/** STOPS */}
            <div className="col-md-12 mt-3">
              <div className="generalStyles__flex">
                <div className="generalStyles__inputFlexName">
                  <span>
                    Total {t("configuration.configurationFiles.stops")}
                  </span>
                </div>
                <div className="generalStyles__width100">
                  <InputNumber
                    className="generalStyles__inputNumberFull"
                    precision={0}
                    value={numberOfStops}
                    onChange={onChangeNumberOfStops}
                    max={maxNumberOfStops}
                    size="small"
                    disabled={configurationSelected === ""}
                  />
                </div>
                <div>
                  <Tooltip
                    title={`${t(
                      "tooltip.maxStopsAllowed"
                    )} ${maxNumberOfStops}`}
                    placement="top"
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="generalStyles__infoTooltipIcon"
                    />
                  </Tooltip>
                </div>{" "}
              </div>
            </div>
            {/** STOPS BELOW LOBBY */}
            <div className="col-md-12 mt-3">
              <div className="generalStyles__flex">
                <div className="generalStyles__inputFlexName">
                  <span>
                    {t("configuration.configurationFiles.floorsBelowLobby")}
                  </span>
                </div>
                <div className="generalStyles__width100">
                  <InputNumber
                    className="generalStyles__inputNumberFull"
                    precision={0}
                    value={numberOfFloorsBelowLobby}
                    onChange={setNumberOfFloorsBelowLobby}
                    max={numberOfStops - 1}
                    size="small"
                    disabled={configurationSelected === ""}
                  />
                </div>
                <div>
                  <Tooltip
                    title={`${t("tooltip.maxStopsAllowedBelowLobby")} ${
                      numberOfStops - 1
                    }`}
                    placement="top"
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="generalStyles__infoTooltipIcon"
                    />
                  </Tooltip>
                </div>{" "}
              </div>
            </div>
          </div>
          {idServerSelected === "" ? (
            <>
              {/** ---------------------------------------------------- */}
              {/** ASSIGN TO ORGANIZATION -- */}
              <OrganizationSelectOrganizationSubOrganization
                setOrganizationsAndChildsIsLoading={
                  setOrganizationsAndChildsIsLoading
                }
              />
              <br />
              {/** ---------------------------------------------------- */}
              {/** SERVERS -- */}
              <Divider
                orientation="left"
                className="generalStyles__drawerDivider"
              >
                <h5>
                  <FontAwesomeIcon
                    icon={faServer}
                    className="generalStyles__info generalStyles__mrFix"
                  />
                  {t("edgeManagement.servers.servers")}
                </h5>
              </Divider>
              <div
                className={
                  theme === "dark"
                    ? "row drawer__box__dark"
                    : "row drawer__box__light"
                }
              >
                <div className="col-md-12">
                  <div className="generalStyles__flex">
                    <div className="generalStyles__inputFlexName">
                      <span className="generalStyles__inputFlexRequired">
                        *
                      </span>
                      <span className="drawer__text">
                        {t("edgeManagement.servers.servers")}
                      </span>
                    </div>
                    <div className="generalStyles__width100">
                      <Select
                        className="generalStyles__width100"
                        disabled={listServers.length === 0}
                        showSearch
                        optionFilterProp="children"
                        size="small"
                        filterOption={(input: any, option: any) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={server}
                        onChange={onChangeServer}
                      >
                        {listServers.map((st) => (
                          <Option key={st.id} value={st.id}>
                            {st.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    {listServers && listServers.length === 0 && (
                      <div>
                        <Tooltip
                          title={t(
                            "edgeManagement.servers.noServersFoundDescription"
                          )}
                          placement="top"
                        >
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            className="generalStyles__infoTooltipIcon"
                          />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {/** ---------------------------------------------------- */}
              {/** SERVERS -- */}
              <br />
              <Divider
                orientation="left"
                className="generalStyles__drawerDivider"
              >
                <h5>
                  <FontAwesomeIcon
                    icon={faServer}
                    className="generalStyles__info generalStyles__mrFix"
                  />
                  {t("edgeManagement.servers.server")}
                </h5>
              </Divider>
              <div
                className={
                  theme === "dark"
                    ? "row drawer__box__dark"
                    : "row drawer__box__light"
                }
              >
                {/** SERVER AUTO SELECTED */}
                <div className="col-md-12">
                  <div className="generalStyles__flex">
                    <div className="generalStyles__inputFlexName">
                      <span className="generalStyles__inputFlexRequired">
                        *
                      </span>
                      <span className="drawer__text">
                        {t("edgeManagement.servers.server")}
                      </span>
                    </div>
                    <div className="generalStyles__width100">
                      <Input
                        className=""
                        size="small"
                        disabled
                        value={nameServerSelected}
                      />
                    </div>
                    <div>
                      <Tooltip
                        title={`${t(
                          "tooltip.assignBankToServer1"
                        )} ${nameServerSelected} ${t(
                          "tooltip.assignBankToServer2"
                        )}`}
                        placement="top"
                      >
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          className="generalStyles__infoTooltipIcon"
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <br />
          {/** ---------------------------------------------------- */}
          {/** CHANNELS -- */}
          <Divider orientation="left" className="generalStyles__drawerDivider">
            <h5>
              <FontAwesomeIcon
                icon={faPlug}
                className="generalStyles__info generalStyles__mrFix"
              />
              {t("edgeManagement.servers.channels")}
            </h5>
          </Divider>
          <div
            className={
              theme === "dark"
                ? "row drawer__box__dark"
                : "row drawer__box__light"
            }
          >
            {channels.length > 0 ? (
              <div className="col-md-12">
                <div className="generalStyles__flex">
                  <div className="generalStyles__inputFlexName">
                    <span className="generalStyles__inputFlexRequired">*</span>
                    <span className="drawer__text">
                      {t("edgeManagement.servers.channels")}
                    </span>
                  </div>
                  <div className="generalStyles__width100">
                    <Select
                      className="generalStyles__width100"
                      disabled={channels.length === 0}
                      showSearch
                      optionFilterProp="children"
                      size="small"
                      filterOption={(input: any, option: any) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      value={channel}
                      onChange={onChangeChannel}
                    >
                      {channels.map((st: any) => (
                        <Option key={st.id} value={st.id}>
                          {st.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            ) : (
              <Alert
                message={t("edgeManagement.banks.noChannelsAssigned")}
                type="warning"
                showIcon
                closable
              />
            )}
          </div>
        </div>
        {/** CLOSE AND SUBMIT BUTTONS */}
        <div className="mt-4">
          <div>
            <Button
              type="default"
              onClick={onClickClose}
              className="buttonStyle__3"
            >
              {t("organizationManagement.close")}
            </Button>
            <Button
              onClick={onClickSave}
              disabled={
                isLoadingPostBank ||
                name === "" ||
                configurationSelected === "" ||
                server === "" ||
                channel === ""
              }
              className="buttonStyle__3"
              type="primary"
            >
              {t("general.save")}
            </Button>
          </div>
        </div>
      </Spin>
    </Drawer>
  );
}

export default BanksCreateForm;
