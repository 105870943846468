import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Spin, Divider, Drawer, Button, Select, Tooltip, Input } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faElevator,
  faBuildingShield,
  faServer,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  updateOpenModalCreateNewLift,
  updateLiftAlert,
} from "../../../redux/lifts/liftSlice";
import { updateBankBreadcrumb } from "../../../redux/banks/bankSlice";
import { usePostLiftMutation } from "../../../redux/lifts/liftAPI";
import { useLazyGetBanksByServerQuery } from "../../../redux/banks/bankAPI";
import { useLazyGetServersByOrganizationQuery } from "../../../redux/servers/serverAPI";
import { updateServerBreadcrumb } from "../../../redux/servers/serverSlice";

// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import OrganizationSelectOrganizationSubOrganization from "../../organizationManagement/organizations/OrganizationSelectOrganizationSubOrganization";
// INTERFACE
import {
  ServerInterface,
  BankInterface,
  LiftPositionInterface,
} from "../../../interfaces/EdgeManagement.interface";

const { Option } = Select;

function LiftsCreateForm() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [name, setName] = useState("");
  const [listServers, setListServers] = useState<ServerInterface[]>([]);
  const [listBanks, setListBanks] = useState<BankInterface[]>([]);
  const [listLiftPosition, setListLiftPosition] = useState<
    LiftPositionInterface[]
  >([]);
  const [listLiftPositionStatic, setListLiftPositionStatic] = useState<
    LiftPositionInterface[]
  >([]);
  const [liftPosition, setLiftPosition] = useState(0);
  const [type, setType] = useState("ELEVATOR");
  const [idBankSelected, setIdBankSelected] = useState("");
  const [idServerSelected, setIdServerSelected] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);
  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { openModalCreateNewLift } = useSelector((state: any) => state.lift);
  const { userLogged } = useSelector((state: any) => state.home);
  const { theme } = useSelector((state: any) => state.home);
  const [organizationsAndChildsIsLoading, setOrganizationsAndChildsIsLoading] =
    useState(false);
  const { organizationAndChilds } = useSelector(
    (state: any) => state.organization
  );
  const {
    idBankSelected: idBankSelectedSlice,
    nameBankSelected,
    bank,
  } = useSelector((state: any) => state.bank);
  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    postLift,
    {
      isSuccess: isSuccessPostLift,
      isLoading: isLoadingPostLift,
      isError: isErrorPostLift,
      error: errorPostLift,
      reset: resetPostLift,
    },
  ] = usePostLiftMutation();
  const [
    triggerGetBanksByOrganization,
    { data: dataGetBanks, isLoading: isLoadingGetBanks },
  ] = useLazyGetBanksByServerQuery();
  const [
    triggerGetServersByOrganization,
    { data: dataGetServers, isLoading: isLoadingGetServers },
  ] = useLazyGetServersByOrganizationQuery();
  const { serverBreadcrumb } = useSelector((state: any) => state.server);

  // ************************************************ */
  // FUNCTIONS ************************************** */

  const onClose = () => {
    dispatch(updateOpenModalCreateNewLift(false));
    setName("");
    setListServers([]);
    setListBanks([]);
    setListLiftPosition([]);
    setListLiftPositionStatic([]);
    setLiftPosition(0);
    setType("ELEVATOR");
    setIdBankSelected("");
    setIdServerSelected("");
    setFormIsValid(false);
  };

  const onChangeType = (newValue: string) => {
    setType(newValue);
  };

  const onChangeName = (newValue: string) => {
    setName(newValue);
  };

  const onChangeLiftPosition = (newValue: number) => {
    setLiftPosition(newValue);
  };

  const onFinish = async () => {
    const token = await GETJwtToken();
    const BODY = {
      token,
      body: {
        bank_id:
          idBankSelectedSlice === "" ? idBankSelected : idBankSelectedSlice,
        offsets: [],
        type,
        name,
        position: liftPosition,
      },
    };
    postLift(BODY);
  };

  const handleCloseModal = () => {
    onClose();
    dispatch(updateOpenModalCreateNewLift(false));
  };

  const launchGetServers = async (value: string) => {
    const token = await GETJwtToken();
    let orgId = userLogged.org_id;
    if (value !== "") {
      orgId = value;
    }
    triggerGetServersByOrganization({ token, orgId });
  };

  const launchGetBanks = async (value: string) => {
    const token = await GETJwtToken();
    let orgId = userLogged.org_id;
    if (value !== "") {
      orgId = value;
    }
    triggerGetBanksByOrganization({ token, orgId, page: 1, limit: 9999 });
  };

  const buildArrayLiftsPosition = (bankId: string) => {
    const index = listBanks.findIndex(
      (item: BankInterface) => item.id === bankId
    );
    const newArr: LiftPositionInterface[] = [];
    let numberOfLifts: number = 0;
    if (listBanks[index].number_of_lifts) {
      numberOfLifts = listBanks[index].number_of_lifts;
    }
    // Create new array off lifts position
    for (let x = 0; x < numberOfLifts; x += 1) {
      let reserved = false;
      // Validate if position is reserved
      if (
        listBanks &&
        listBanks[index] &&
        listBanks[index].lifts &&
        listBanks[index].lifts.length > 0
      ) {
        for (let y = 0; y < listBanks[index].lifts.length; y += 1) {
          if (listBanks[index].lifts[y].position === x + 1) {
            reserved = true;
          }
        }
      }
      // Push new item in array
      newArr.push({
        name: `Position No ${x + 1}`,
        id: x + 1,
        reserved,
        liftId: "",
        isValid: true,
      });
    }
    setListLiftPosition(newArr);
  };

  const buildArrayLiftsPositionStatic = () => {
    const newArr: LiftPositionInterface[] = [];
    let numberOfLifts: number = 0;
    if (bank.number_of_lifts) {
      numberOfLifts = bank.number_of_lifts;
    }
    // Create new array off lifts position
    for (let x = 0; x < numberOfLifts; x += 1) {
      let reserved = false;
      // Validate if position is reserved
      if (bank.lifts && bank.lifts.length > 0) {
        for (let y = 0; y < bank.lifts.length; y += 1) {
          if (bank.lifts[y].position === x + 1) {
            reserved = true;
          }
        }
      }
      // Push new item in array
      newArr.push({
        name: `Position No ${x + 1}`,
        id: x + 1,
        reserved,
        liftId: "",
        isValid: true,
      });
    }
    setListLiftPositionStatic(newArr);
  };

  const onChangeBank = (bankId: string) => {
    setIdBankSelected(bankId);
    buildArrayLiftsPosition(bankId);
  };

  const onChangeServer = (serverId: string) => {
    setIdServerSelected(serverId);
    launchGetBanks(serverId);
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (openModalCreateNewLift) {
      resetPostLift();
    }
  }, [openModalCreateNewLift]);

  useEffect(() => {
    if (isSuccessPostLift) {
      setTimeout(() => {
        dispatch(
          updateLiftAlert({
            title: t("general.success"),
            description: t("edgeManagement.lifts.liftCreated"),
            status: "success",
          })
        );
      }, 150);
      dispatch(updateServerBreadcrumb([serverBreadcrumb[0]]));
      dispatch(updateBankBreadcrumb([]));
      onClose();
    }
    if (isErrorPostLift) {
      setTimeout(() => {
        dispatch(
          updateLiftAlert({
            title: t("general.error"),
            description: errorPostLift,
            status: "error",
          })
        );
      }, 150);
      dispatch(updateServerBreadcrumb([serverBreadcrumb[0]]));
      dispatch(updateBankBreadcrumb([]));
      onClose();
    }
  }, [isSuccessPostLift, isErrorPostLift]);

  useEffect(() => {
    if (dataGetBanks && dataGetBanks.data) {
      setListBanks(dataGetBanks.data);
    }
  }, [dataGetBanks]);

  useEffect(() => {
    if (
      organizationAndChilds &&
      organizationAndChilds.data &&
      organizationAndChilds.data.idOrganizationSelected
    ) {
      setListServers([]);
      setListBanks([]);
      launchGetServers(organizationAndChilds.data.idOrganizationSelected);
    }
  }, [organizationAndChilds]);

  useEffect(() => {
    if (dataGetServers && dataGetServers.data) {
      setListServers(dataGetServers.data);
    }
  }, [dataGetServers]);

  useEffect(() => {
    if (
      name !== "" &&
      type !== "" &&
      liftPosition > 0 &&
      (idBankSelected !== "" || idBankSelectedSlice !== "")
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [name, type, idBankSelected, idBankSelectedSlice, liftPosition]);

  useEffect(() => {
    if (bank && bank.number_of_lifts && openModalCreateNewLift) {
      buildArrayLiftsPositionStatic();
    }
  }, [nameBankSelected, bank, openModalCreateNewLift]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClose}
      closable={false}
      visible={openModalCreateNewLift}
    >
      <Spin
        spinning={
          isLoadingPostLift ||
          isLoadingGetBanks ||
          organizationsAndChildsIsLoading ||
          isLoadingGetServers
        }
        tip={t("general.loading")}
      >
        <div>
          <Divider orientation="left" className="generalStyles__drawerDivider">
            <h5>
              <FontAwesomeIcon
                icon={faElevator}
                className="generalStyles__info generalStyles__mrFix"
              />
              {t("edgeManagement.lifts.createNewLift")}
            </h5>
          </Divider>
        </div>
        <div className="container">
          <div
            className={
              theme === "dark"
                ? "row drawer__box__dark"
                : "row drawer__box__light"
            }
          >
            {/** NAME */}
            <div className="col-md-12 ">
              <div className="generalStyles__flex">
                <div className="generalStyles__inputFlexName">
                  <span className="generalStyles__inputFlexRequired">*</span>
                  <span className="drawer__text">
                    {t("configuration.configurationFiles.name")}
                  </span>
                </div>
                <div className="generalStyles__width100">
                  <Input
                    className="generalStyles__inputNumberFull"
                    size="small"
                    value={name}
                    onChange={(e) => {
                      onChangeName(e.target.value);
                    }}
                  />
                </div>
                <div className="generalStyles__infoTooltipIconBlank" />
              </div>
            </div>
            {/** TYPE */}
            <div className="col-md-12 mt-3">
              <div className="generalStyles__flex">
                <div className="generalStyles__inputFlexName">
                  <span className="generalStyles__inputFlexRequired">*</span>
                  <span className="drawer__text">
                    {t("edgeManagement.lifts.type")}
                  </span>
                </div>
                <div className="generalStyles__width100">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    className="generalStyles__width100"
                    allowClear
                    size="small"
                    value={type}
                    onChange={onChangeType}
                  >
                    {["ELEVATOR", "ESCALATOR", "WALKINGBAND"].map(
                      (item: string) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      )
                    )}
                  </Select>
                </div>
                <div className="generalStyles__infoTooltipIconBlank" />
              </div>
            </div>
          </div>
          {idBankSelectedSlice === "" ? (
            <>
              {/** ---------------------------------------------------- */}
              {/** GET ORGANIZATION AND CHILDS ----------- */}
              <OrganizationSelectOrganizationSubOrganization
                setOrganizationsAndChildsIsLoading={
                  setOrganizationsAndChildsIsLoading
                }
              />
              {/** SERVER */}
              <Divider
                orientation="left"
                className="generalStyles__drawerDivider"
              >
                <h5>
                  <FontAwesomeIcon
                    icon={faServer}
                    className="generalStyles__info generalStyles__mrFix"
                  />
                  {t("edgeManagement.servers.selectServer")}
                </h5>
              </Divider>
              <div
                className={
                  theme === "dark"
                    ? "row drawer__box__dark"
                    : "row drawer__box__light"
                }
              >
                <div className="col-md-12">
                  <div className="generalStyles__flex">
                    <div className="generalStyles__inputFlexName">
                      <span className="generalStyles__inputFlexRequired">
                        *
                      </span>
                      <span className="drawer__text">
                        {t("edgeManagement.servers.servers")}
                      </span>
                    </div>
                    <div className="generalStyles__width100">
                      <Select
                        className="generalStyles__width100"
                        onChange={onChangeServer}
                        disabled={listServers.length === 0}
                        showSearch
                        size="small"
                        value={idServerSelected}
                        optionFilterProp="children"
                        filterOption={(input: string, option: any) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {listServers.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    {listServers.length === 0 ? (
                      <div>
                        <Tooltip
                          title={`${t(
                            "edgeManagement.servers.noServersFoundDescription"
                          )}`}
                          placement="top"
                        >
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            className="generalStyles__infoTooltipIcon"
                          />
                        </Tooltip>
                      </div>
                    ) : (
                      <div className="generalStyles__infoTooltipIconBlank" />
                    )}
                  </div>
                </div>
              </div>
              <br />
              {/** BANKS */}
              <Divider
                orientation="left"
                className="generalStyles__drawerDivider"
              >
                <h5>
                  <FontAwesomeIcon
                    icon={faBuildingShield}
                    className="generalStyles__info generalStyles__mrFix"
                  />
                  {t("edgeManagement.banks.assignToBank")}
                </h5>
              </Divider>
              <div
                className={
                  theme === "dark"
                    ? "row drawer__box__dark"
                    : "row drawer__box__light"
                }
              >
                <div className="col-md-12">
                  <div className="generalStyles__flex">
                    <div className="generalStyles__inputFlexName">
                      <span className="generalStyles__inputFlexRequired">
                        *
                      </span>
                      <span className="drawer__text">
                        {t("edgeManagement.banks.banks")}
                      </span>
                    </div>
                    <div className="generalStyles__width100">
                      <Select
                        className="generalStyles__width100"
                        onChange={onChangeBank}
                        disabled={listBanks.length === 0}
                        value={idBankSelected}
                        showSearch
                        size="small"
                        optionFilterProp="children"
                        filterOption={(input: string, option: any) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {listBanks.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    {listBanks.length === 0 ? (
                      <div>
                        <Tooltip
                          title={`${t(
                            "edgeManagement.banks.noBanksFoundDescription"
                          )}`}
                          placement="top"
                        >
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            className="generalStyles__infoTooltipIcon"
                          />
                        </Tooltip>
                      </div>
                    ) : (
                      <div className="generalStyles__infoTooltipIconBlank" />
                    )}
                  </div>
                </div>
                {/** LIFT POSITION -- */}
                <div className="col-md-12 mt-3">
                  <div className="generalStyles__flex">
                    <div className="generalStyles__inputFlexName">
                      <span className="generalStyles__inputFlexRequired">
                        *
                      </span>
                      <span className="drawer__text">
                        {t("edgeManagement.banks.liftPosition")}
                      </span>
                    </div>
                    <div className="generalStyles__width100">
                      <Select
                        className="generalStyles__width100"
                        onChange={onChangeLiftPosition}
                        disabled={listLiftPosition.length === 0}
                        showSearch
                        size="small"
                        value={liftPosition === 0 ? null : liftPosition}
                        optionFilterProp="children"
                      >
                        {listLiftPosition.map((item) => (
                          <Option
                            key={item.id}
                            value={item.id}
                            disabled={item.reserved}
                          >
                            {item.reserved ? (
                              <Tooltip
                                title="Position Already Reserved"
                                placement="left"
                              >
                                {item.name}
                              </Tooltip>
                            ) : (
                              <>{item.name}</>
                            )}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className="generalStyles__infoTooltipIconBlank" />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {/** BANKS -- */}
              <br />
              <Divider
                orientation="left"
                className="generalStyles__drawerDivider"
              >
                <h5>
                  <FontAwesomeIcon
                    icon={faBuildingShield}
                    className="generalStyles__info generalStyles__mrFix"
                  />
                  {t("edgeManagement.banks.bank")}
                </h5>
              </Divider>
              <div
                className={
                  theme === "dark"
                    ? "row drawer__box__dark"
                    : "row drawer__box__light"
                }
              >
                {/** BANK AUTO SELECTED */}
                <div className="col-md-12">
                  <div className="generalStyles__flex">
                    <div className="generalStyles__inputFlexName">
                      <span className="generalStyles__inputFlexRequired">
                        *
                      </span>
                      <span className="drawer__text">
                        {t("edgeManagement.banks.bank")}
                      </span>
                    </div>
                    <div className="generalStyles__width100">
                      <Input
                        className=""
                        size="small"
                        disabled
                        value={nameBankSelected}
                      />
                    </div>
                    <div>
                      <Tooltip
                        title={`${t(
                          "tooltip.assignLiftToBank1"
                        )} ${nameBankSelected} ${t(
                          "tooltip.assignLiftToBank2"
                        )}`}
                        placement="top"
                      >
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          className="generalStyles__infoTooltipIcon"
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
                {/** LIFT POSITION -- */}
                <div className="col-md-12 mt-3">
                  <div className="generalStyles__flex">
                    <div className="generalStyles__inputFlexName">
                      <span className="generalStyles__inputFlexRequired">
                        *
                      </span>
                      <span className="drawer__text">
                        {t("edgeManagement.banks.liftPosition")}
                      </span>
                    </div>
                    <div className="generalStyles__width100">
                      <Select
                        className="generalStyles__width100"
                        onChange={onChangeLiftPosition}
                        disabled={listLiftPositionStatic.length === 0}
                        showSearch
                        size="small"
                        value={liftPosition === 0 ? null : liftPosition}
                        optionFilterProp="children"
                      >
                        {listLiftPositionStatic.map((item) => (
                          <Option
                            key={item.id}
                            value={item.id}
                            disabled={item.reserved}
                          >
                            {item.reserved ? (
                              <Tooltip
                                title="Position Reserved"
                                placement="left"
                              >
                                {item.name}
                              </Tooltip>
                            ) : (
                              <>{item.name}</>
                            )}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className="generalStyles__infoTooltipIconBlank" />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {/** ---------------------------------------------------- */}
        <div className="mt-4">
          <div>
            <Button
              type="default"
              onClick={handleCloseModal}
              className="buttonStyle__3"
            >
              {t("organizationManagement.close")}
            </Button>
            <Button
              onClick={onFinish}
              disabled={isLoadingPostLift || !formIsValid}
              className="buttonStyle__3"
              type="primary"
            >
              {t("general.save")}
            </Button>
          </div>
        </div>
      </Spin>
    </Drawer>
  );
}

export default LiftsCreateForm;
