import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Divider, Drawer, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserShield,
  faBuildingCircleCheck,
  faUserLock,
  faCheckCircle,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerRole } from "../../../redux/roles/roleSlice";
// COMPONENTS
import RolesStaticRolesTree from "./RolesStaticRolesTree";
import DrawerRoleInformation from "../../components/DrawerRoleInformation";
import DrawerOrganizationInformation from "../../components/DrawerOrganizationInformation";

library.add(
  faUserShield,
  faBuildingCircleCheck,
  faUserLock,
  faCheckCircle,
  faBan
);

function RolesDrawer() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch();
  const { role, openDrawerRole } = useSelector((state: any) => state.role);
  const [data, setData] = useState<any>({});
  const [t] = useTranslation("global");
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onClickClose = () => {
    dispatch(updateOpenDrawerRole(false));
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (openDrawerRole) {
      if (role) {
        setData(role);
      }
    }
  }, [openDrawerRole]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClickClose}
      closable={false}
      visible={openDrawerRole}
    >
      {data && (
        <div>
          {/** ************************************************* */}
          {/** ROLE INFORMATION */}
          <DrawerRoleInformation data={data || {}} />
          {/** ************************************************* */}
          {/** ORGANIZATION INFORMATION */}
          <DrawerOrganizationInformation data={data.orgs || {}} />
          {/** ************************************************* */}
          {/** ROLES STATIC TREE */}
          <br />
          <div>
            <Divider
              orientation="left"
              className="generalStyles__drawerDivider"
            >
              <h5>
                <FontAwesomeIcon
                  icon={faUserLock}
                  className="generalStyles__info generalStyles__mrFix"
                />
                {t("organizationManagement.permissions")}
              </h5>
            </Divider>
          </div>
          <div
            className={
              theme === "dark" ? " drawer__box__dark" : " drawer__box__light"
            }
          >
            <div>
              <RolesStaticRolesTree roleData={role} />
            </div>
          </div>
        </div>
      )}
      {/** CLOSE AND SUBMIT BUTTONS */}
      <div className="mt-4 generalStyles__width100">
        <div>
          <Button
            type="default"
            onClick={onClickClose}
            className="buttonStyle__5"
          >
            {t("organizationManagement.close")}
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

export default RolesDrawer;
