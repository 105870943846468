import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Table, Switch, InputNumber, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateConfigurationFileAlert } from "../../../redux/configurationFiles/configurationFilesSlice";
import { usePutOffsetsBulkMutation } from "../../../redux/configurationFiles/configurationFilesAPI";
// STYLES
// UTILS
import {
  buildSelectedItemsOffsetsTable,
  buildDataOffsetsTable,
  getTableRowClass,
  getOffsetsCountByConfiguration,
} from "../../../utils/utils";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

const { Column } = Table;

function OffsetsTable() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const { theme } = useSelector((state: any) => state.home);
  const dispatch = useDispatch();
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [isLoading, setIsLoading] = useState(false);
  const [rangeTableColumns, setRangeTableColumns] = useState<any>([]);
  const [rangeTableData, setRangeTableData] = useState<any>([]);
  const [fullTableData, setFullTableData] = useState<any>([]);
  const [fullTableColumns, setFullTableColumns] = useState<any>([]);
  const [fullTable, setFullTable] = useState(false);
  const [editMode, setEditMode] = useState(false);
  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { configurationFile } = useSelector(
    (state: any) => state.configurationFile
  );
  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    putOffsetsBulk,
    { isSuccess, isError, error, isLoading: isLoadingPutOffsetsBulk, reset },
  ] = usePutOffsetsBulkMutation();
  // ************************************************ */
  // FUNCTIONS ************************************** */
  const showLoading = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const buildRangeTableData = () => {
    let newArr: any = [];
    let selectedItems: any = [];
    // Check if component to create is form configuration or table view
    selectedItems = buildSelectedItemsOffsetsTable(
      configurationFile.data_block.offsets
    );
    // Build data to create offsets table
    newArr = buildDataOffsetsTable(configurationFile.data_block.offsets);
    setRangeTableColumns(selectedItems);
    newArr.sort(
      (a: any, b: any) => parseFloat(a.liftnumber) - parseFloat(b.liftnumber)
    );
    setRangeTableData(newArr);
  };

  const buildFullTableData = (data_: any) => {
    // Build data to create offsets table
    let newArr: any = [];
    if (data_.length > 0) {
      newArr = data_;
    } else {
      newArr = buildDataOffsetsTable(configurationFile.data_block.offsets);
    }
    newArr.sort(
      (a: any, b: any) => parseFloat(a.liftnumber) - parseFloat(b.liftnumber)
    );
    setFullTableColumns(newArr);
    const newData: any = [];
    if (newArr && newArr.length > 0) {
      for (let x = 0; x < newArr[0].statusArr.length; x += 1) {
        const myArray = newArr[0].statusArr[x].name.split("-");
        const newObj: any = {
          id: newArr[0].statusArr[x].name,
          name: myArray[0],
          byte: myArray[1],
          byteCount: newArr[0].statusArr[x].totalItems,
          offsetTypePosition: newArr[0].statusArr[x].offsetTypePosition,
        };
        for (let y = 0; y < newArr.length; y += 1) {
          newObj[`${newArr[y].liftName}${newArr[y].statusArr[x].name}`] =
            newArr[y].statusArr[x].offset;
        }
        newData.push(newObj);
      }
    }
    newData.sort(
      (a: any, b: any) =>
        parseFloat(a.offsetTypePosition) - parseFloat(b.offsetTypePosition)
    );
    setFullTableData(newData);
  };

  const onClickAutoFillOffsets = (rangeTableData_: any) => {
    const clone = structuredClone(rangeTableData_);
    for (let x = 0; x < clone.length; x += 1) {
      let counter = 0;
      for (let y = 0; y < clone[x].statusArr.length; y += 1) {
        if (clone[x].statusArr[y].keyIndex === 0) {
          counter = clone[x].statusArr[y].offset;
        } else if (clone[x].statusArr[y].keyIndex !== 0) {
          if (counter > 0) counter += 1;
        }
        const newOffset = counter === 0 ? 0 : counter;
        clone[x].statusArr[y].offset = newOffset;
      }
    }
    buildFullTableData(clone);
  };

  const onChangeOffsetFirstValue = (
    newValue: number,
    offset: string,
    liftName: string
  ) => {
    const clone = structuredClone(rangeTableData);
    if (clone && clone.length > 0) {
      for (let x = 0; x < clone.length; x += 1) {
        // Change specific name
        for (let y = 0; y < clone[x].statusArr.length; y += 1) {
          // Save new offset value
          if (
            clone[x].liftName === liftName &&
            clone[x].statusArr[y].key === offset
          ) {
            clone[x].statusArr[y].offset = newValue;
          }
          // Check if offset is valid
          if (
            clone[x].statusArr[y].offset === 0 ||
            clone[x].statusArr[y].offset === null
          ) {
            clone[x].statusArr[y].isValid = false;
          } else {
            clone[x].statusArr[y].isValid = true;
          }
        }
      }
      setRangeTableData(clone);
      onClickAutoFillOffsets(clone);
    }
  };

  const onChangeSwitchFullTable = (newValue: boolean) => {
    setFullTable(newValue);
    showLoading();
  };

  const onChangeSwitchEditMode = (newValue: boolean) => {
    setEditMode(newValue);
    setFullTable(false);
    showLoading();
  };

  const onClickSave = async () => {
    if (
      configurationFile &&
      configurationFile.data_block &&
      configurationFile.data_block.offsets
    ) {
      const copy = structuredClone(configurationFile.data_block.offsets);
      rangeTableData.map((item: any) => {
        if (item && item.statusArr) {
          item.statusArr.map((item2: any) => {
            if (item2 && item2.keyIndex === 0) {
              // *-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
              // update offset value
              copy.map((offset: any, copyIndex: number) => {
                const myArray = item2.name.split("-");
                if (
                  item2.keyIndex === 0 &&
                  item.liftnumber === offset.position &&
                  myArray[0] === offset.offset_type.name
                ) {
                  copy[copyIndex].initial_value = item2.offset;
                  copy[copyIndex].last_value =
                    item2.offset + item2.byteCount - 1;
                }
                return true;
              });
            }
            return true;
          });
        }
        return true;
      });
      // Save bulk offsets
      const token = await GETJwtToken();
      const BODY = {
        token,
        body: { offsets: copy },
      };
      putOffsetsBulk(BODY);
    }
  };
  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (
      configurationFile &&
      configurationFile.data_block &&
      configurationFile.data_block.offsets
    ) {
      showLoading();
      buildRangeTableData();
      buildFullTableData([]);
      reset();
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        dispatch(
          updateConfigurationFileAlert({
            title: t("general.success"),
            description: t(
              "configuration.offsetTypeConfiguration.offsetsUpdated"
            ),
            status: "success",
          })
        );
      }, 150);
    }
    if (isError) {
      setTimeout(() => {
        dispatch(
          updateConfigurationFileAlert({
            title: t("general.error"),
            description: error,
            status: "error",
          })
        );
      }, 150);
    }
  }, [isSuccess, isError]);
  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      {/** SWITCH TABLE */}
      <div
        className="generalStyles__pageHeader"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>
          <div className="generalStyles__flex">
            <div className="generalStyles__mrFix25">
              <span className="configuration__configurationNameTitle">
                {configurationFile && configurationFile.name
                  ? configurationFile.name
                  : "--"}
              </span>
            </div>
          </div>
        </div>
        <div className="generalStyles__flex">
          <div>
            <Switch
              checked={fullTable}
              onChange={(e) => {
                onChangeSwitchFullTable(e);
              }}
              checkedChildren="Range Table"
              unCheckedChildren="Full Table"
              style={{ marginRight: "20px", width: "110px" }}
            />
          </div>
          <div>
            <Switch
              checked={editMode}
              onChange={(e) => {
                onChangeSwitchEditMode(e);
              }}
              style={{ width: "110px" }}
              checkedChildren="Edit Offsets"
              unCheckedChildren="Offsets"
            />
          </div>
          <div>
            <Button
              className="buttonStyle__2 generalStyles__mlFix"
              style={{ marginTop: "-5px" }}
              onClick={onClickSave}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <div className="mt-3">
        {fullTable ? (
          <>
            <div>
              {/** ---------------------------------------------------- */}
              {/** FULL TABLE */}
              {fullTableData && fullTableData.length > 0 && (
                <Table
                  rowClassName={(record, index) =>
                    getTableRowClass(index, theme)
                  }
                  dataSource={fullTableData}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "50", "100"],
                  }}
                  loading={isLoading || isLoadingPutOffsetsBulk}
                  size="small"
                  rowKey={(record) => record.id}
                  scroll={{ x: "100%" }}
                >
                  {/** NAME COLUMN */}
                  <Column
                    title="Offset"
                    dataIndex="name"
                    key="name"
                    className="generalStyles__tableHeaderLink"
                    fixed
                    width="150px"
                    render={(text) => (
                      <div>
                        <div className="configuration__columnEllipsis">
                          <span>{text}</span>
                        </div>
                      </div>
                    )}
                  />
                  {/** BYTE NUMBER */}
                  <Column
                    title="Byte"
                    dataIndex="byte"
                    key="byte"
                    width="100px"
                    render={(text, record: any) => (
                      <div>
                        <div className="">
                          <span>
                            {text} /{" "}
                            {record.byteCount ||
                              getOffsetsCountByConfiguration(
                                record.name,
                                configurationFile.data_block.offsets
                              )}
                          </span>
                        </div>
                      </div>
                    )}
                  />
                  {/** DINAMIC COLUMN */}
                  {fullTableColumns.length > 0 && (
                    <>
                      {fullTableColumns.map((item: any) => (
                        <Column
                          title={item.liftName}
                          dataIndex={item.liftName}
                          key={item.key}
                          width="125px"
                          className="configuration__columnEllipsis"
                          render={(text, record: any) => (
                            <div>
                              <div className="generalStyles__mlFix">
                                {record[`${item.liftName}${record.id}`]}
                              </div>
                            </div>
                          )}
                        />
                      ))}
                    </>
                  )}
                </Table>
              )}
            </div>
          </>
        ) : (
          <>
            <div>
              {/** ---------------------------------------------------- */}
              {/** RANGE TABLE */}
              {rangeTableData && rangeTableData.length > 0 && (
                <Table
                  rowClassName={(record, index) =>
                    getTableRowClass(index, theme)
                  }
                  dataSource={rangeTableData}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "50", "100"],
                  }}
                  scroll={{ x: "100%" }}
                  loading={isLoading || isLoadingPutOffsetsBulk}
                  size="small"
                  rowKey={(record) => record.key}
                >
                  {/** NAME COLUMN */}
                  <Column
                    title="Lift"
                    dataIndex="liftName"
                    key="liftName"
                    fixed
                    className="generalStyles__tableHeaderLink "
                    width="150px"
                    render={(text) => (
                      <div className="">
                        <span>{text}</span>
                      </div>
                    )}
                  />
                  {/** DINAMIC COLUMN */}
                  {rangeTableColumns.length > 0 && (
                    <>
                      {rangeTableColumns.map((item: any) => (
                        <Column
                          title={item.name}
                          dataIndex={item.name}
                          key={item.key}
                          className="configuration__columnEllipsis"
                          render={(text, record: any) => (
                            <>
                              {record.statusArr.map(
                                (item2: any, index2: number) => (
                                  <div key={index2}>
                                    {`${item.name}-0` === item2.key && (
                                      <>
                                        <InputNumber
                                          status={
                                            item2.isValid ? "" : "warning"
                                          }
                                          bordered={!item2.isValid}
                                          style={{ width: "100%" }}
                                          min={0}
                                          disabled={!editMode}
                                          precision={0}
                                          value={item2.offset}
                                          onChange={(e: number) => {
                                            onChangeOffsetFirstValue(
                                              e,
                                              item2.key,
                                              record.liftName
                                            );
                                          }}
                                        />
                                      </>
                                    )}
                                  </div>
                                )
                              )}
                            </>
                          )}
                        />
                      ))}
                    </>
                  )}
                </Table>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default OffsetsTable;
