/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ReportState {
  openModalDownloadReport: boolean;
  reportOffsetTableParams: Object;
  reportOffsetRefreshTable: boolean;
  reportOffsetFilters: Object;
}

const initialState: ReportState = {
  openModalDownloadReport: false,
  reportOffsetTableParams: {
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ["10", "20", "50", "100"],
    },
  },
  reportOffsetRefreshTable: false,
  reportOffsetFilters: {
    startDate: "",
    endDate: "",
    filterTypeLevel: 2,
    filterTypeArr: [],
    offsetTypeId: ""
  }
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    updateOpenAlertDownloadReport: (state, action: PayloadAction<boolean>) => {
      state.openModalDownloadReport = action.payload;
    },
    updateReportOffsetTableParams: (state, action: PayloadAction<Object>) => {
      state.reportOffsetTableParams = action.payload;
    },
    updateReportOffsetRefreshTable: (state, action: PayloadAction<boolean>) => {
      state.reportOffsetRefreshTable = action.payload;
    },
    updateReportOffsetFilters: (state, action: PayloadAction<Object>) => {
      state.reportOffsetFilters = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
    updateOpenAlertDownloadReport,
    updateReportOffsetTableParams,
    updateReportOffsetRefreshTable,
    updateReportOffsetFilters
} = reportSlice.actions;

export default reportSlice.reducer;
