/* eslint-disable arrow-body-style */
import React from "react";
// ANT DESIGN COMPONENTS
// REDUX
// COMPONENTS
import MonitoringGrid from "./MonitoringGrid";
import MonitoringHeader from "./MonitoringHeader2";
import BanksDrawer from "../../edgeManagement/banks/BanksDrawer";

const MonitoringPage = () => {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      <MonitoringHeader />
      <MonitoringGrid />
      <BanksDrawer />
    </div>
  );
};

export default MonitoringPage;
