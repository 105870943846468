import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Spin, Table, Empty, Input, Button, Select } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsUpDown, faSearch } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateReportOffsetTableParams } from "../../../redux/reports/reportsSlice";
import { useLazyGetOffsetReportQuery } from "../../../redux/reports/reportsAPI";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import {
  getTableRowClass,
  generateExcelAndDownload,
} from "../../../utils/utils";
// COMPONENTS
import ReportsAlertDownloadModal from "../ReportsAlertDownloadModal";

const { Column } = Table;

function ReportOffsetsTable() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const [t] = useTranslation("global");
  const dispatch = useDispatch();

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [data, setData] = useState<any>([]);
  const [listColumns, setListColumns] = useState<any>([]);
  const [selectedColumns, setSelectColumns] = useState([]);
  const [downloadReport, setDownloadReport] = useState(false);
  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { theme } = useSelector((state: any) => state.home);
  const { permissions } = useSelector((state: any) => state.user);
  const {
    reportOffsetTableParams,
    reportOffsetRefreshTable,
    reportOffsetFilters,
  } = useSelector((state: any) => state.report);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    triggerGetReportOffsets,
    {
      data: dataReportOffset,
      isLoading: isLoadingReportOffset,
      isError: isErrorReportOffset,
      isFetching: isFetchingReportOffset,
    },
  ] = useLazyGetOffsetReportQuery();

  // ************************************************ */
  // FUNCTIONS ************************************** */

  /** Take all the values that comes from offset report API call and create
  a list with all the available columns */
  const buildListOfColumnsArray = () => {
    const newArr: any = [];
    Object.keys(data[0]).forEach((key: any) => {
      if (key !== "offset") {
        newArr.push({
          label: key,
          value: key,
        });
      }
      return true;
    });
    setListColumns(newArr);
  };

  /**  Update selected columns object when select is updated */
  const onChangeSelectedColumns = (newSelectedItemsObj: any) => {
    setSelectColumns(newSelectedItemsObj);
  };

  /** Remove columns that are not selected */
  const removeUnselectedColumns = () => {
    const newArr: any = [];
    data.map((item: any) => {
      const newObjet: any = {};
      // Save default column offset
      newObjet.offset = item.offset;
      // Remove columns from json
      selectedColumns.map((item2: any) => {
        newObjet[item2] = item[item2];
        return true;
      });
      newArr.push(newObjet);
      return true;
    });
    return newArr;
  };

  const getSearchAndFilters = () => {
    let searchAndFilterString = "";
    // FILTER START DATE
    if (reportOffsetFilters && reportOffsetFilters.startDate !== "") {
      searchAndFilterString = searchAndFilterString.concat(
        "&start_date=",
        reportOffsetFilters.startDate
      );
    }
    // FILTER END DATE
    if (reportOffsetFilters && reportOffsetFilters.endDate !== "") {
      searchAndFilterString = searchAndFilterString.concat(
        "&end_date=",
        reportOffsetFilters.endDate
      );
    }
    // FILTER OFFSET TYPE
    if (reportOffsetFilters && reportOffsetFilters.offsetTypeId !== "") {
      searchAndFilterString = searchAndFilterString.concat(
        "&offset_id=",
        reportOffsetFilters.offsetTypeId
      );
    }
    // FILTER TYPE
    if (reportOffsetFilters && reportOffsetFilters.filterTypeLevel > 0) {
      // Get offsets by ORGANIZATION
      if (
        reportOffsetFilters.filterTypeLevel === 1 &&
        reportOffsetFilters.filterTypeArr &&
        reportOffsetFilters.filterTypeArr[0]
      ) {
        searchAndFilterString = searchAndFilterString.concat(
          "&filter_target=organization_id&filter=",
          reportOffsetFilters.filterTypeArr[0].value
        );
      }
      // Get offsets by SERVER
      else if (
        reportOffsetFilters.filterTypeLevel === 2 &&
        reportOffsetFilters.filterTypeArr &&
        reportOffsetFilters.filterTypeArr[1]
      ) {
        searchAndFilterString = searchAndFilterString.concat(
          "&filter_target=server_id&filter=",
          reportOffsetFilters.filterTypeArr[1].value
        );
      }
      // Get offsets by PORT
      else if (
        reportOffsetFilters.filterTypeLevel === 3 &&
        reportOffsetFilters.filterTypeArr &&
        reportOffsetFilters.filterTypeArr[2]
      ) {
        searchAndFilterString = searchAndFilterString.concat(
          "&filter_target=port_id&filter=",
          reportOffsetFilters.filterTypeArr[2].value
        );
      }
      // Get offsets by BANK
      else if (
        reportOffsetFilters.filterTypeLevel === 4 &&
        reportOffsetFilters.filterTypeArr &&
        reportOffsetFilters.filterTypeArr[3]
      ) {
        searchAndFilterString = searchAndFilterString.concat(
          "&filter_target=bank_id&filter=",
          reportOffsetFilters.filterTypeArr[3].value
        );
      }
    }
    //
    if (searchAndFilterString.length > 3) {
      return searchAndFilterString;
    }
    return "";
  };

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: any
  ) => {
    dispatch(
      updateReportOffsetTableParams({
        pagination,
        filters,
        ...sorter,
      })
    );
    //
    const token = await GETJwtToken();
    triggerGetReportOffsets({
      page: pagination.current,
      limit: pagination.pageSize,
      token,
    });
  };

  const refreshTable = async () => {
    const searchAndFilterString = getSearchAndFilters();
    dispatch(
      updateReportOffsetTableParams({
        pagination: {
          current: 1,
          pageSize: 10,
          total: reportOffsetTableParams.pagination.total,
        },
      })
    );
    //
    const token = await GETJwtToken();
    triggerGetReportOffsets({
      page: 1,
      limit: 10,
      token,
      searchAndFilterString,
    });
  };
  // ************************************************* */
  // USE EFFECT ************************************** */

  useEffect(() => {
    if (
      dataReportOffset &&
      dataReportOffset.data &&
      dataReportOffset.data.length > 0
    ) {
      dispatch(
        updateReportOffsetTableParams({
          ...reportOffsetTableParams,
          pagination: {
            ...reportOffsetTableParams.pagination,
            total: dataReportOffset.total,
          },
        })
      );
      setData(dataReportOffset.data);
    }
  }, [dataReportOffset]);

  useEffect(() => {
    if (data && data.length > 0) {
      buildListOfColumnsArray();
    }
  }, [data]);

  useEffect(() => {
    if (downloadReport) {
      const finalExcelJson = removeUnselectedColumns();
      generateExcelAndDownload(finalExcelJson);
      setTimeout(() => {
        setDownloadReport(false);
      }, 1000);
    }
  }, [downloadReport]);

  useEffect(() => {
    if (isErrorReportOffset) {
      setData([]);
    }
  }, [isErrorReportOffset]);

  useEffect(() => {
    if (reportOffsetRefreshTable) {
      refreshTable();
    }
  }, [reportOffsetRefreshTable]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Spin spinning={isLoadingReportOffset || isFetchingReportOffset}>
      {validatePermission("permission-not-defined", permissions) ? (
        <>
          {/** ******************************************* */}
          {/** DINAMIC COLUMNS TO DISPLAY */}
          <div className="generalStyles__mlFix mt-3">
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select"
              onChange={onChangeSelectedColumns}
              options={listColumns}
            />
          </div>
          {/** ******************************************* */}
          {/** TABLE */}
          <Table
            rowClassName={(record, index) => getTableRowClass(index, theme)}
            dataSource={data}
            pagination={{
              defaultPageSize: 8,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50", "100"],
            }}
            size="small"
            className="mt-3"
            onChange={handleTableChange}
          >
            {/** OFFSET */}
            <Column
              title="Offset"
              dataIndex="offset"
              key="offset"
              className=""
              filterIcon={<FontAwesomeIcon icon={faSearch} />}
              filterDropdown={() => (
                <div className="generalStyles__searchDropdown">
                  <Input
                    style={{ marginBottom: 8, display: "block" }}
                    placeholder="offset"
                  />
                  <div className="generalStyles__spaceBetween">
                    <div />
                    <div>
                      <Button type="primary" size="small" style={{ width: 90 }}>
                        Search
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              render={(text) => (
                <div className="generalStyles__info">{text}</div>
              )}
            />
            {/** DINAMIC COLUMNS */}
            {selectedColumns.map((column: any) => (
              <Column
                title={column}
                dataIndex={column}
                key={column}
                className=""
                filterIcon={<FontAwesomeIcon icon={faArrowsUpDown} />}
                filterDropdown={() => {}}
                render={(text) => <div className="">{text}</div>}
              />
            ))}
          </Table>
        </>
      ) : (
        <>
          <div className="generalStyles__noAccessToListTable">
            <Empty
              description={t(
                "organizationManagement.listNotAvailableOrganizations"
              )}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        </>
      )}
      {/** ******************************************* */}
      {/** ALERT DOWNLOAD REPORT */}
      <ReportsAlertDownloadModal setDownloadReport={setDownloadReport} />
    </Spin>
  );
}

export default ReportOffsetsTable;
