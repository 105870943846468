import React, { useState } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Button, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// COPY TO CLIBBOARD
import { CopyToClipboard } from "react-copy-to-clipboard";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faKey } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenModalSecretClient } from "../../../redux/servers/serverSlice";

function ServerModalSecretClient({ secretClientToken }: any) {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  // eslint-disable-next-line no-unused-vars
  const [copied, setCopied] = useState(false);

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { openModalSecretClient } = useSelector((state: any) => state.server);
  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleCloseModal = () => {
    dispatch(updateOpenModalSecretClient(false));
  };

  const onCopy = () => {
    setCopied(true);
  };
  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Modal
      onOk={handleCloseModal}
      closable={false}
      onCancel={handleCloseModal}
      visible={openModalSecretClient}
      footer={null}
    >
      <div>
        <div className="text-center">
          <FontAwesomeIcon
            icon={faKey}
            className="generalStyles__warning generalStyles__deleteItemIcon"
          />
          <p className="generalStyles__info">
            {t("edgeManagement.servers.secretClient")}
          </p>
          <div>
            <CopyToClipboard onCopy={onCopy} text={secretClientToken}>
              <Tooltip title={t("general.copyToClipboard")}>
                <Button
                  className="buttonStyle__4"
                  icon={
                    <FontAwesomeIcon
                      icon={faCopy}
                      className="generalStyles__warning generalStyles__mrFix"
                    />
                  }
                >
                  {secretClientToken}
                </Button>
              </Tooltip>
            </CopyToClipboard>
          </div>
          <div className="generalStyles__flexEnd mt-4">
            <div>
              <Button onClick={handleCloseModal} className="buttonStyle__4">
                {t("general.close")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ServerModalSecretClient;
