import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import {
  Spin,
  Divider,
  Drawer,
  Input,
  InputNumber,
  Alert,
  Button,
  Select,
  Tooltip,
} from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faServer,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  updateOpenDrawerEditChannel,
  updateServerAlert,
  updateChannel,
  updateOpenModalDeleteChannel,
} from "../../../redux/servers/serverSlice";
import {
  usePutChannelMutation,
  usePostChannelMutation,
} from "../../../redux/servers/serverAPI";
// COMPONENTS
import ServersDeleteChannelModal from "./ServersDeleteChannelModal";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

const { Option } = Select;

function ServerCreateFormChannels() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [channels, setChannels] = useState<any>([]);
  const [originalChannels, setOriginalChannels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [nameTCP, setNameTCP] = useState("");
  const [nameSerial, setNameSerial] = useState("/dev/ttyS");
  const [index, setIndex] = useState("");
  const [baudRate, setBaudRate] = useState(1);
  const [type, setType] = useState("SERIAL");
  const [ip, setIp] = useState("");
  const [ipIsValid, setIpIsValid] = useState(false);
  const [number, setNumber] = useState(1);
  const [alertNoChanges, setAlertNoChanges] = useState(false);
  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { openDrawerEditChannel, server } = useSelector(
    (state: any) => state.server
  );
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    putChannel,
    { isSuccess, isLoading: isLoadingPut, isError, error, reset },
  ] = usePutChannelMutation();

  const [
    postChannel,
    {
      isSuccess: isSuccessPost,
      isLoading: isLoadingPost,
      isError: isErrorPost,
      error: errorPost,
      reset: resetPost,
    },
  ] = usePostChannelMutation();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const showIsLoading = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };

  const onClickClose = () => {
    setChannels([]);
    dispatch(updateOpenDrawerEditChannel(false));
    setOriginalChannels([]);
    setNameSerial("/dev/ttyS");
    setNameTCP("");
    setIndex("");
    setBaudRate(1);
    setType("SERIAL");
    setAlertNoChanges(false);
    setIp("");
    setNumber(0);
  };

  const onClickSave = async () => {
    showIsLoading();
    let formChange = false;
    const token = await GETJwtToken();
    for (let x = 0; x < channels.length; x += 1) {
      if (JSON.stringify(channels[x]) !== JSON.stringify(originalChannels[x])) {
        formChange = true;
        // This channel has changed, PUT
        const BODY = {
          token,
          id: channels[x].id,
          body: {
            active: true,
            banks: [],
            banks2delete: [],
            index: channels[x].type === "TCP" ? "" : channels[x].index,
            name: channels[x].name,
            server_id: server.id,
            type: channels[x].type,
            baud_rate:
              channels[x].type === "SERIAL" ? channels[x].baud_rate : 1,
            ip: channels[x].type === "SERIAL" ? "0.0.0.0" : channels[x].ip,
            port_number:
              channels[x].type === "SERIAL" ? 0 : channels[x].port_number,
          },
        };
        putChannel(BODY);
      }
    }
    if (!formChange) {
      setAlertNoChanges(true);
      setTimeout(() => {
        setAlertNoChanges(false);
      }, 2500);
    } else {
      setAlertNoChanges(false);
    }
  };

  const onClickCreateChannel = async () => {
    const token = await GETJwtToken();
    const BODY = {
      token,
      body: {
        active: true,
        banks: [],
        banks2delete: [],
        index: type === "TCP" ? "" : index,
        name: type === "SERIAL" ? nameSerial : nameTCP,
        server_id: server.id,
        type,
        baud_rate: baudRate,
        ip: type === "SERIAL" ? "0.0.0.0" : ip,
        port_number: type === "SERIAL" ? 0 : number,
      },
    };
    postChannel(BODY);
  };

  const onChangeEditName = (newValue: string, item: any) => {
    const clone = structuredClone(channels);
    for (let x = 0; x < clone.length; x += 1) {
      if (clone[x].id === item.id) {
        clone[x].name = newValue;
      }
    }
    setChannels(clone);
  };

  const onChangeEditIndex = (newValue: string, item: any) => {
    const clone = structuredClone(channels);
    for (let x = 0; x < clone.length; x += 1) {
      if (clone[x].id === item.id) {
        clone[x].index = newValue;
      }
    }
    setChannels(clone);
  };

  const onChangeEditBaudRate = (newValue: number, item: any) => {
    const clone = structuredClone(channels);
    for (let x = 0; x < clone.length; x += 1) {
      if (clone[x].id === item.id) {
        clone[x].baud_rate = newValue;
      }
    }
    setChannels(clone);
  };

  const onChangeEditType = (newValue: string, item: any) => {
    const clone = structuredClone(channels);
    for (let x = 0; x < clone.length; x += 1) {
      if (clone[x].id === item.id) {
        clone[x].type = newValue;
      }
    }
    setChannels(clone);
  };

  const onChangeEditIp = (newValue: string, item: any) => {
    const clone = structuredClone(channels);
    for (let x = 0; x < clone.length; x += 1) {
      if (clone[x].id === item.id) {
        clone[x].ip = newValue;
      }
    }
    setChannels(clone);
  };

  const onChangeEditNumber = (newValue: number, item: any) => {
    const clone = structuredClone(channels);
    for (let x = 0; x < clone.length; x += 1) {
      if (clone[x].id === item.id) {
        clone[x].port_number = newValue;
      }
    }
    setChannels(clone);
  };

  const onClickDeleteChannel = (item: any) => {
    dispatch(updateChannel(item));
    dispatch(updateOpenModalDeleteChannel(true));
  };

  const onChangeNameTCP = (newValue: string) => {
    setNameTCP(newValue);
  };

  const onChangeNameSerial = (newValue: string) => {
    setNameSerial(newValue);
  };

  const onChangeIndex = (newValue: string) => {
    setIndex(newValue);
    setNameSerial(`/dev/ttyS${newValue}`);
  };

  const onChangeBaudRate = (newValue: number) => {
    setBaudRate(newValue);
  };

  const onChangeType = (newValue: string) => {
    setType(newValue);
  };

  const onChangeIp = (newValue: string) => {
    setIp(newValue);
    if (
      /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}(\/\d+)?$/.test(
        newValue
      )
    ) {
      setIpIsValid(true);
    } else {
      setIpIsValid(false);
    }
  };

  const onChangeNumber = (newValue: number) => {
    setNumber(newValue);
  };

  const validateAsignedIpEdit = (value: string) => {
    if (
      /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}(\/\d+)?$/.test(
        value
      )
    ) {
      return "";
    }
    return "error";
  };
  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    showIsLoading();
    if (openDrawerEditChannel) {
      reset();
      resetPost();
    }
  }, [openDrawerEditChannel]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        dispatch(
          updateServerAlert({
            title: t("general.success"),
            description: t("edgeManagement.servers.channelEdited"),
            status: "success",
          })
        );
      }, 150);
      onClickClose();
    }
    if (isError) {
      setTimeout(() => {
        dispatch(
          updateServerAlert({
            title: t("general.error"),
            description: error,
            status: "error",
          })
        );
      }, 150);
      onClickClose();
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (isSuccessPost) {
      setTimeout(() => {
        dispatch(
          updateServerAlert({
            title: t("general.success"),
            description: t("edgeManagement.servers.channelCreated"),
            status: "success",
          })
        );
      }, 150);
      onClickClose();
    }
    if (isErrorPost) {
      setTimeout(() => {
        dispatch(
          updateServerAlert({
            title: t("general.error"),
            description: errorPost,
            status: "error",
          })
        );
      }, 150);
      onClickClose();
    }
  }, [isSuccessPost, isErrorPost]);

  useEffect(() => {
    if (openDrawerEditChannel) {
      if (server && server.channels && server.channels.length > 0) {
        setChannels(server.channels);
        setOriginalChannels(server.channels);
      }
    } else {
      setChannels([]);
      setOriginalChannels([]);
    }
  }, [openDrawerEditChannel]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClickClose}
      closable={false}
      visible={openDrawerEditChannel}
    >
      <Spin
        spinning={isLoading || isLoadingPut || isLoadingPost}
        tip={t("general.loading")}
      >
        {/** ************************************************* */}
        {/** SERVER INFORMATION */}
        <div>
          <Divider orientation="left" className="generalStyles__drawerDivider">
            <h5>
              <FontAwesomeIcon
                icon={faServer}
                className="generalStyles__info generalStyles__mrFix"
              />
              {t("edgeManagement.servers.serverInformation")}
            </h5>
          </Divider>
        </div>
        <div
          className={
            theme === "dark" ? " drawer__box__dark" : " drawer__box__light"
          }
        >
          {/* ID */}
          <div className="generalStyles__flex">
            <div>
              <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
                ID:
              </p>
            </div>
            <div className="drawer__textContainer">
              <p className="drawer__text">{server.id ? server.id : "--"}</p>
            </div>
          </div>
          {/* NAME */}
          <div className="generalStyles__flex">
            <div>
              <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
                {t("edgeManagement.servers.serverName")}
              </p>
            </div>
            <div className="drawer__textContainer">
              <p className="drawer__text">{server.name ? server.name : "--"}</p>
            </div>
          </div>
          {/* IP */}
          <div className="generalStyles__flex">
            <div>
              <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
                {t("edgeManagement.servers.ip")}
              </p>
            </div>
            <div className="drawer__textContainer">
              <p className="drawer__text">{server.ip}</p>
            </div>
          </div>
        </div>
        <br />
        {/** ************************************************* */}
        {/** EDIT AND DELETE CHANNELS */}
        <div>
          <Divider orientation="left" className="generalStyles__drawerDivider">
            <h5>
              <FontAwesomeIcon
                icon={faServer}
                className="generalStyles__info generalStyles__mrFix"
              />
              {t("edgeManagement.servers.channelsAssigned")}
            </h5>
          </Divider>
        </div>
        {alertNoChanges && (
          <Alert
            message={t("general.noChangesDetected")}
            type="info"
            showIcon
            className="mb-3"
          />
        )}
        {channels.length > 0 ? (
          <>
            {channels.map((item: any) => (
              <div
                key={item.id}
                className={
                  theme === "dark"
                    ? " drawer__box__dark mt-3"
                    : " drawer__box__light mt-3"
                }
              >
                {/* ID */}
                <div className="col-md-12">
                  <div className="generalStyles__flex">
                    <div className="generalStyles__inputFlexName">
                      <span className="drawer__text">ID</span>
                    </div>
                    <div className="generalStyles__width100">
                      <Input
                        className="generalStyles__input"
                        disabled
                        value={item.id}
                        size="small"
                      />
                    </div>
                    <div className="generalStyles__infoTooltipIconBlank" />
                  </div>
                </div>
                {item.type === "SERIAL" && (
                  <>
                    {/** ---------------------------------------------------- */}
                    {/** INDEX  */}
                    <div className="col-md-12 mt-3">
                      <div className="generalStyles__flex">
                        <div className="generalStyles__inputFlexName">
                          <span className="generalStyles__inputFlexRequired">
                            *
                          </span>
                          <span className="drawer__text">
                            {t("edgeManagement.servers.index")}
                          </span>
                        </div>
                        <div className="generalStyles__width100">
                          <Input
                            className="generalStyles__input"
                            onChange={(
                              event: React.FormEvent<HTMLInputElement>
                            ) => {
                              onChangeEditIndex(
                                event.currentTarget.value,
                                item
                              );
                            }}
                            value={item.index}
                            size="small"
                          />
                        </div>
                        <div className="generalStyles__infoTooltipIconBlank" />
                      </div>
                    </div>
                  </>
                )}
                {/** ---------------------------------------------------- */}
                {/** NAME  */}
                <div className="col-md-12 mt-3">
                  <div className="generalStyles__flex">
                    <div className="generalStyles__inputFlexName">
                      <span className="generalStyles__inputFlexRequired">
                        *
                      </span>
                      <span className="drawer__text">{t("general.name")}</span>
                    </div>
                    <div className="generalStyles__width100">
                      <Input
                        className="generalStyles__input"
                        onChange={(
                          event: React.FormEvent<HTMLInputElement>
                        ) => {
                          onChangeEditName(event.currentTarget.value, item);
                        }}
                        value={item.name}
                        size="small"
                      />
                    </div>
                    <div className="generalStyles__infoTooltipIconBlank" />
                  </div>
                </div>
                {/** ---------------------------------------------------- */}
                {/** BAUD RATE  */}
                {item.type === "SERIAL" && (
                  <div className="col-md-12 mt-3">
                    <div className="generalStyles__flex">
                      <div className="generalStyles__inputFlexName">
                        <span className="generalStyles__inputFlexRequired">
                          *
                        </span>
                        <span className="drawer__text">
                          {t("edgeManagement.servers.baudRate")}
                        </span>
                      </div>
                      <div className="generalStyles__width100">
                        <InputNumber
                          className="generalStyles__input"
                          onChange={(e: number) => {
                            onChangeEditBaudRate(e, item);
                          }}
                          min={1}
                          value={item.baud_rate}
                          size="small"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <div className="generalStyles__infoTooltipIconBlank" />
                    </div>
                  </div>
                )}
                {item.type === "TCP" && (
                  <>
                    {/** CHANNEL  IP  */}
                    <div className="col-md-12 mt-3">
                      <div className="generalStyles__flex">
                        <div className="generalStyles__inputFlexName">
                          <span className="generalStyles__inputFlexRequired">
                            *
                          </span>
                          <span className="drawer__text">
                            {t("edgeManagement.servers.channelIp")}
                          </span>
                        </div>
                        <div className="generalStyles__width100">
                          <Input
                            className="generalStyles__input"
                            onChange={(
                              event: React.FormEvent<HTMLInputElement>
                            ) => {
                              onChangeEditIp(event.currentTarget.value, item);
                            }}
                            value={item.ip}
                            size="small"
                            status={validateAsignedIpEdit(item.ip)}
                          />
                        </div>
                        <div>
                          <Tooltip
                            title={t("tooltip.ipFormatValid")}
                            placement="left"
                          >
                            <FontAwesomeIcon
                              icon={faCircleInfo}
                              className="generalStyles__infoTooltipIcon"
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    {/** CHANNEL NUMBER  */}
                    <div className="col-md-12 mt-3">
                      <div className="generalStyles__flex">
                        <div className="generalStyles__inputFlexName">
                          <span className="generalStyles__inputFlexRequired">
                            *
                          </span>
                          <span className="drawer__text">
                            {t("edgeManagement.servers.channelNumber")}
                          </span>
                        </div>
                        <div className="generalStyles__width100">
                          <InputNumber
                            className="generalStyles__input"
                            size="small"
                            onChange={(e: number) => {
                              onChangeEditNumber(e, item);
                            }}
                            value={item.port_number}
                            style={{ width: "100%" }}
                            min={1}
                          />
                        </div>
                        <div className="generalStyles__infoTooltipIconBlank" />
                      </div>
                    </div>
                  </>
                )}
                {/** ---------------------------------------------------- */}
                {/** TYPE  */}
                <div className="col-md-12 mt-3">
                  <div className="generalStyles__flex">
                    <div className="generalStyles__inputFlexName">
                      <span className="generalStyles__inputFlexRequired">
                        *
                      </span>
                      <span>{t("edgeManagement.lifts.type")}</span>
                    </div>
                    <div className="generalStyles__width100">
                      <Select
                        value={item.type}
                        onChange={(e) => {
                          onChangeEditType(e, item);
                        }}
                        className="generalStyles__width100"
                        size="small"
                      >
                        {["SERIAL", "TCP"].map((item2: string) => (
                          <Option key={item2} value={item2}>
                            {item2}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className="generalStyles__infoTooltipIconBlank" />
                  </div>
                </div>
                {/** DELETE  */}
                <div className="mt-3 generalStyles__flexEnd">
                  <div>
                    <Tooltip
                      placement="left"
                      title={`${t("edgeManagement.servers.deleteChannel")} - ${
                        item.name
                      }`}
                    >
                      <Button
                        onClick={() => {
                          onClickDeleteChannel(item);
                        }}
                        style={{ borderStyle: "none" }}
                        icon={<FontAwesomeIcon icon={faTrash} />}
                        shape="circle"
                        className="buttonStyle__2"
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            <div
              className={
                theme === "dark" ? " drawer__box__dark" : " drawer__box__light"
              }
            >
              <Alert
                message={t("edgeManagement.servers.noChannelsFound")}
                type="warning"
                showIcon
              />
            </div>
          </>
        )}
        {/** ---------------------------------------------------- */}
        {/** SAVE EDIT channels BUTTONS */}
        {!isLoading && channels && channels.length > 0 && (
          <>
            <div className="mt-4  generalStyles__width100">
              <div className="generalStyles__flexEnd">
                <Button
                  onClick={onClickSave}
                  className="buttonStyle__3"
                  type="primary"
                >
                  {t("general.save")}
                </Button>
              </div>
            </div>
          </>
        )}
        <br />
        <div>
          <Divider orientation="left" className="generalStyles__drawerDivider">
            <h5>
              <FontAwesomeIcon
                icon={faServer}
                className="generalStyles__info generalStyles__mrFix"
              />
              {t("edgeManagement.servers.createChannel")}
            </h5>
          </Divider>
        </div>
        <div className="container">
          <div
            className={
              theme === "dark"
                ? "row drawer__box__dark"
                : "row drawer__box__light"
            }
          >
            {/** ---------------------------------------------------- */}
            {/** NAME SERIAL  */}
            {type === "SERIAL" && (
              <>
                {/** ---------------------------------------------------- */}
                {/** INDEX  */}
                <div className="col-md-12">
                  <div className="generalStyles__flex">
                    <div className="generalStyles__inputFlexName">
                      <span className="generalStyles__inputFlexRequired">
                        *
                      </span>
                      <span className="drawer__text">
                        {t("edgeManagement.servers.index")}
                      </span>
                    </div>
                    <div className="generalStyles__width100">
                      <Input
                        className="generalStyles__input"
                        onChange={(
                          event: React.FormEvent<HTMLInputElement>
                        ) => {
                          onChangeIndex(event.currentTarget.value);
                        }}
                        value={index}
                        size="small"
                      />
                    </div>
                    <div className="generalStyles__infoTooltipIconBlank" />
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="generalStyles__flex">
                    <div className="generalStyles__inputFlexName">
                      <span className="generalStyles__inputFlexRequired">
                        *
                      </span>
                      <span className="drawer__text">{t("general.name")}</span>
                    </div>
                    <div className="generalStyles__width100">
                      <Input
                        className="generalStyles__input"
                        onChange={(
                          event: React.FormEvent<HTMLInputElement>
                        ) => {
                          onChangeNameSerial(event.currentTarget.value);
                        }}
                        value={nameSerial}
                        size="small"
                      />
                    </div>
                    <div className="generalStyles__infoTooltipIconBlank" />
                  </div>
                </div>
              </>
            )}
            {/** ---------------------------------------------------- */}
            {type === "TCP" && (
              <>
                {/** NAME TCP  */}
                <div className="col-md-12">
                  <div className="generalStyles__flex">
                    <div className="generalStyles__inputFlexName">
                      <span className="generalStyles__inputFlexRequired">
                        *
                      </span>
                      <span className="drawer__text">{t("general.name")}</span>
                    </div>
                    <div className="generalStyles__width100">
                      <Input
                        className="generalStyles__input"
                        onChange={(
                          event: React.FormEvent<HTMLInputElement>
                        ) => {
                          onChangeNameTCP(event.currentTarget.value);
                        }}
                        value={nameTCP}
                        size="small"
                      />
                    </div>
                    <div className="generalStyles__infoTooltipIconBlank" />
                  </div>
                </div>
                {/** CHANNEL  IP  */}
                <div className="col-md-12 mt-3">
                  <div className="generalStyles__flex">
                    <div className="generalStyles__inputFlexName">
                      <span className="generalStyles__inputFlexRequired">
                        *
                      </span>
                      <span className="drawer__text">
                        {t("edgeManagement.servers.channelIp")}
                      </span>
                    </div>
                    <div className="generalStyles__width100">
                      <Input
                        className="generalStyles__input"
                        onChange={(
                          newValue: React.FormEvent<HTMLInputElement>
                        ) => {
                          onChangeIp(newValue.currentTarget.value);
                        }}
                        value={ip}
                        size="small"
                        status={!ipIsValid && ip !== "" ? "error" : ""}
                      />
                    </div>
                    <div>
                      <Tooltip
                        title={t("tooltip.ipFormatValid")}
                        placement="left"
                      >
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          className="generalStyles__infoTooltipIcon"
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
                {/** CHANNEL NUMBER  */}
                <div className="col-md-12 mt-3">
                  <div className="generalStyles__flex">
                    <div className="generalStyles__inputFlexName">
                      <span className="generalStyles__inputFlexRequired">
                        *
                      </span>
                      <span className="drawer__text">
                        {t("edgeManagement.servers.channelNumber")}
                      </span>
                    </div>
                    <div className="generalStyles__width100">
                      <InputNumber
                        className="generalStyles__input"
                        size="small"
                        onChange={(e: number) => {
                          onChangeNumber(e);
                        }}
                        value={number}
                        style={{ width: "100%" }}
                        min={1}
                      />
                    </div>
                    <div className="generalStyles__infoTooltipIconBlank" />
                  </div>
                </div>
              </>
            )}
            {/** ---------------------------------------------------- */}
            {/** BAUD RATE  */}
            {type === "SERIAL" && (
              <div className="col-md-12 mt-3">
                <div className="generalStyles__flex">
                  <div className="generalStyles__inputFlexName">
                    <span className="generalStyles__inputFlexRequired">*</span>
                    <span className="drawer__text">
                      {t("edgeManagement.servers.baudRate")}
                    </span>
                  </div>
                  <div className="generalStyles__width100">
                    <InputNumber
                      className="generalStyles__input"
                      onChange={(e: number) => {
                        onChangeBaudRate(e);
                      }}
                      value={baudRate}
                      size="small"
                      style={{ width: "100%" }}
                      min={1}
                    />
                  </div>
                  <div className="generalStyles__infoTooltipIconBlank" />
                </div>
              </div>
            )}
            {/** ---------------------------------------------------- */}
            {/** TYPE  */}
            <div className="col-md-12 mt-3">
              <div className="generalStyles__flex">
                <div className="generalStyles__inputFlexName">
                  <span className="generalStyles__inputFlexRequired">*</span>
                  <span>{t("edgeManagement.lifts.type")}</span>
                </div>
                <div className="generalStyles__width100">
                  <Select
                    value={type}
                    onChange={onChangeType}
                    className="generalStyles__width100"
                    size="small"
                  >
                    {["SERIAL", "TCP"].map((item: any) => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="generalStyles__infoTooltipIconBlank" />
              </div>
            </div>
          </div>
          <br />
        </div>
        {/** ---------------------------------------------------- */}
        {/** CLOSE AND SAVE BUTTONS */}
        {!isLoading && (
          <>
            <div className="mt-4 generalStyles__width100">
              <div>
                <Button
                  type="default"
                  onClick={onClickClose}
                  className="buttonStyle__3"
                >
                  {t("edgeManagement.close")}
                </Button>
                <Button
                  onClick={onClickCreateChannel}
                  className="buttonStyle__3"
                  type="primary"
                >
                  {t("edgeManagement.servers.addChannel")}
                </Button>
              </div>
            </div>
          </>
        )}
      </Spin>
      {/** ---------------------------------------------------- */}
      {/** DELETE MODAL COMPONENT */}
      <ServersDeleteChannelModal />
    </Drawer>
  );
}

export default ServerCreateFormChannels;
