// ************************************************* */
// FUNCTIONS *************************************** */
const validatePermission = (permission: string, permissions: any) => {
  let permissionValid = false;
  // -*-*-*-*-*-*-*-*
  if(permission === 'permission-not-defined'){
    permissionValid = true;
  }
  // -*-*-*-*-*-*-*-*
  if (permissions && permissions.pages) {
    // VALIDATE PAGES PERMISSIONS
    permissions.pages.map((page: any) => {
      if (page.tree_key === permission && page.active) {
        permissionValid = true;
      }
      if (page.sub_pages) {
        // VALIDATE SUB PAGES PERMISSIONS
        page.sub_pages.map((subPage: any) => {
          if (subPage.tree_key === permission && subPage.active) {
            permissionValid = true;
          }
          if (subPage.actions) {
            // VALIDATE ACTIONS PERMISSIONS
            subPage.actions.map((action: any) => {
              if (action.tree_key === permission && action.active) {
                permissionValid = true;
              }
              return true;
            });
          }
          return true;
        });
      }
      return true;
    });
  }
  return permissionValid;
};

export default validatePermission;
