import React from 'react'
import { useNavigate } from 'react-router-dom'
// ANT DESIGN COMPONENTS
import { Button } from 'antd'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser } from '@fortawesome/free-solid-svg-icons'
// REDUX
import { useDispatch } from 'react-redux'
import { updateBreadcrumbTitle } from '../../redux/home/homeSlice'
// STYLES
import notFoundImage from '../../assets/error/404.png'

library.add(faUser)

function NotFoundPage() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch()
  const [t] = useTranslation('global')
  const navigate = useNavigate()

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const routeChange = () => {
    localStorage.setItem('headerTitle', 'dashboard')
    dispatch(updateBreadcrumbTitle('dashboard'))
    navigate('/dashboard')
  }
  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      <div className="generalStyles__flexCenter mb-5">
        <img src={notFoundImage} alt="" width="300" />
      </div>
      <div className="generalStyles__flexCenter mb-3">
        <Button onClick={routeChange} type="primary">
          {t('general.backToDashboard')}
        </Button>
      </div>
    </div>
  )
}

export default NotFoundPage
