import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Button, Input, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  useBlockUserMutation,
  useUnBlockUserMutation,
} from "../../../redux/users/userAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import {
  updateOpenModalBlockUnBlockUser,
  updateUserAlert,
} from "../../../redux/users/userSlice";
// UTILS
import { styleDeleteButton } from "../../../utils/utils";

library.add(faTrash, faTriangleExclamation);

function UsersBlockUnBlockModal() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch();
  const { user, openModalBlockUnBlockUser } = useSelector(
    (state: any) => state.user
  );
  const [
    disableButtonConfirmBlockUnBlockUser,
    setDisableButtonConfirmBlockUnBlockUser,
  ] = useState(true);
  const [confirmUserName, setConfirmUserName] = useState("");
  const { theme } = useSelector((state: any) => state.home);
  const [t] = useTranslation("global");

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    triggerBlockUser,
    {
      isLoading: isLoadingBlockUser,
      isSuccess: isSuccessBlockUser,
      isError: isErrorBlockUser,
      error: errorBlockUser,
      reset: resetBlockUser,
    },
  ] = useBlockUserMutation();
  const [
    triggerUnBlockUser,
    {
      isLoading: isLoadingUnBlockUser,
      isSuccess: isSuccessUnBlockUser,
      isError: isErrorUnBlockUser,
      error: errorUnblockUser,
      reset: resetUnBlockUser,
    },
  ] = useUnBlockUserMutation();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleOk = () => {
    dispatch(updateOpenModalBlockUnBlockUser(false));
  };

  const handleCancel = () => {
    dispatch(updateOpenModalBlockUnBlockUser(false));
  };

  const handleCloseModal = () => {
    dispatch(updateOpenModalBlockUnBlockUser(false));
  };

  const handleLockUnlockItem = async () => {
    const token = await GETJwtToken();
    if (user && user.status === "ACTIVE") {
      triggerBlockUser({ id: user.id, token });
    } else {
      triggerUnBlockUser({ id: user.id, token });
    }
  };

  const handleSubmitLockUnlockItem = async (event: any) => {
    if (event.key === "Enter") {
      handleLockUnlockItem();
    }
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (openModalBlockUnBlockUser) {
      setDisableButtonConfirmBlockUnBlockUser(true);
      setConfirmUserName("");
      resetBlockUser();
      resetUnBlockUser();
    }
  }, [openModalBlockUnBlockUser]);

  useEffect(() => {
    setDisableButtonConfirmBlockUnBlockUser(true);
    let firstName = "";
    let middleName = "";
    let lastName = "";
    if (user.first_name) {
      firstName = user.first_name;
    }
    if (user.middle_name) {
      middleName = ` ${user.middle_name}`;
    }
    if (user.last_name) {
      lastName = ` ${user.last_name}`;
    }
    const userName = firstName + middleName + lastName;
    //
    if (
      confirmUserName &&
      confirmUserName.toLowerCase() === userName.toLowerCase()
    ) {
      setDisableButtonConfirmBlockUnBlockUser(false);
    } else {
      setDisableButtonConfirmBlockUnBlockUser(true);
    }
  }, [confirmUserName]);

  useEffect(() => {
    if (isSuccessBlockUser || isSuccessUnBlockUser) {
      setTimeout(() => {
        dispatch(
          updateUserAlert({
            title: t("general.success"),
            description:
              user && user.status === "ACTIVE"
                ? t("userManagement.userLocked")
                : t("userManagement.userUnlocked"),
            status: "success",
          })
        );
      }, 150);
      dispatch(updateOpenModalBlockUnBlockUser(false));
    }
    if (isErrorBlockUser) {
      setTimeout(() => {
        dispatch(
          updateUserAlert({
            title: t("general.error"),
            description: errorBlockUser,
            status: "error",
          })
        );
      }, 150);
      dispatch(updateOpenModalBlockUnBlockUser(false));
    }
    if (isErrorUnBlockUser) {
      setTimeout(() => {
        dispatch(
          updateUserAlert({
            title: "Error",
            description: errorUnblockUser,
            status: "error",
          })
        );
      }, 150);
      dispatch(updateOpenModalBlockUnBlockUser(false));
    }
  }, [
    isSuccessBlockUser,
    isSuccessUnBlockUser,
    isErrorBlockUser,
    isErrorUnBlockUser,
  ]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Modal
      onOk={handleOk}
      closable={false}
      onCancel={handleCancel}
      visible={openModalBlockUnBlockUser}
      footer={null}
    >
      <Spin spinning={isLoadingBlockUser || isLoadingUnBlockUser}>
        <div>
          <div className="text-center">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="generalStyles__warning generalStyles__deleteItemIcon"
            />
            <p className="mt-3 drawer__text">
              {t("userManagement.lockUnlockDescription.text1")}
              <b className="generalStyles__warning">
                {user && user.status && user.status === "ACTIVE" ? (
                  <> {t("userManagement.lockUnlockDescription.text2")}</>
                ) : (
                  <>{t("userManagement.lockUnlockDescription.text3")}</>
                )}
              </b>
              {t("userManagement.lockUnlockDescription.text4")}
              <b className="generalStyles__warning">
                {user.first_name} {user.middle_name} {user.last_name}{" "}
              </b>
              {t("userManagement.lockUnlockDescription.text5")}
            </p>
            <Input
              placeholder={t("userManagement.userName")}
              onChange={(e) => {
                setConfirmUserName(e.target.value);
              }}
              value={confirmUserName}
              onKeyDown={handleSubmitLockUnlockItem}
              status="warning"
            />
          </div>
          <div className="generalStyles__flexEnd mt-4">
            <div>
              <Button
                htmlType="submit"
                className={styleDeleteButton(
                  disableButtonConfirmBlockUnBlockUser,
                  theme
                )}
                onClick={handleLockUnlockItem}
                disabled={disableButtonConfirmBlockUnBlockUser}
              >
                {user && user.status === "ACTIVE" ? (
                  <>{t("organizationManagement.lockUnlockDescription.text2")}</>
                ) : (
                  <>{t("organizationManagement.lockUnlockDescription.text3")}</>
                )}{" "}
              </Button>
              <Button onClick={handleCloseModal} className="buttonStyle__4">
                {t("userManagement.close")}
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
}

export default UsersBlockUnBlockModal;
