/* eslint-disable no-use-before-define */
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Table, Alert, Menu, Dropdown, Space, Empty, Tag } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsis,
  faTrash,
  faPenToSquare,
  faBan,
  faFile,
  faCube,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateBreadcrumbTitle } from "../../../redux/home/homeSlice";
import {
  useLazyGetConfigurationFilesQuery,
  useLazyGetAllOffsetsTypesQuery,
} from "../../../redux/configurationFiles/configurationFilesAPI";
import {
  updateOpenModalDeleteConfigurationFile,
  updateOpenModalEditConfigurationFile,
  updateOpenDrawerConfigurationFile,
  updateConfigurationFile,
  updateConfigurationFileTableParams,
  updateOpenDrawerBankFiles,
  updateConfigurationFileBreadcrumb,
  updateOffsetTypeName,
  updateOffsetTypeTagColor,
  updateOpenDrawerCreateDataBlock,
} from "../../../redux/configurationFiles/configurationFilesSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import validatePermission from "../../../utils/validatePermissions";
// UTILS
import {
  getOffsetsCountByConfiguration,
  getTableRowClass,
} from "../../../utils/utils";

const ConfigurationFilesTable = () => {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch();
  const [configurationFiles, setConfigurationFiles] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [idOrganizationSelected, setIdOrganizationSelected] = useState("");
  const [t] = useTranslation("global");
  const { permissions } = useSelector((state: any) => state.user);
  const { userLogged } = useSelector((state: any) => state.home);
  const { configurationFileTableParams, configurationFileRefreshTable } =
    useSelector((state: any) => state.configurationFile);
  const [offsetTypeList, setOffsetsTypeList] = useState<any>([]);
  const { theme } = useSelector((state: any) => state.home);
  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    triggerGetConfigurationFilesTable,
    { data, isLoading, isError, isFetching },
  ] = useLazyGetConfigurationFilesQuery();
  const [
    triggerGetOffsetsTypes,
    { data: liftOffsetsType, isLoading: isLoadingGetOffsetTypes },
  ] = useLazyGetAllOffsetsTypesQuery();
  // ************************************************ */
  // TABLE ACTIONS ************************************ */

  const { Column } = Table;

  const manageActions = () => {
    const BLANK = {
      label: (
        <div className="generalStyles__tableActionBox">
          <span>
            <FontAwesomeIcon
              className="generalStyles__error generalStyles__mrFix"
              icon={faBan}
            />
            {t("general.noActions")}
          </span>
        </div>
      ),
      key: "0",
    };

    const EDIT = validatePermission("configuration-managerupdate", permissions)
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenModalEdit();
              }}
              onKeyDown={() => {
                handleOpenModalEdit();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faPenToSquare}
                />
                {t("organizationManagement.edit")}
              </span>
            </div>
          ),
          key: "1",
        }
      : null;

    const DELETE = validatePermission(
      "configuration-managerdelete",
      permissions
    )
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenModalDelete();
              }}
              onKeyDown={() => {
                handleOpenModalDelete();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faTrash}
                />
                {t("organizationManagement.delete")}
              </span>
            </div>
          ),
          key: "4",
        }
      : null;

    const BANK_FILES = validatePermission("permission-not-defined", permissions)
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenDrawerBankFiles();
              }}
              onKeyDown={() => {
                handleOpenDrawerBankFiles();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faFile}
                />
                {t("configuration.configurationFiles.bankFiles")}
              </span>
            </div>
          ),
          key: "2",
        }
      : null;

    const CREATE_DATA_BLOCK = validatePermission(
      "permission-not-defined",
      permissions
    )
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenDrawerCreateDataBlock();
              }}
              onKeyDown={() => {
                handleOpenDrawerCreateDataBlock();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faCube}
                />
                {t("configuration.dataBlock.newDataBlock")}
              </span>
            </div>
          ),
          key: "3",
        }
      : null;

    // RETURN OBJECT WITH VALID ACTIONS
    if (EDIT || DELETE || BANK_FILES || CREATE_DATA_BLOCK) {
      return [EDIT, DELETE, BANK_FILES, CREATE_DATA_BLOCK];
    }
    return [BLANK];
  };

  const menu = <Menu items={manageActions()} />;

  // ************************************************ */
  // FUNCTIONS ************************************** */

  const handleOpenModalEdit = () => {
    dispatch(updateConfigurationFile(selectedItem));
    dispatch(updateOpenModalEditConfigurationFile(true));
  };

  const handleOpenDrawerCreateDataBlock = () => {
    dispatch(updateConfigurationFile(selectedItem));
    dispatch(updateOpenDrawerCreateDataBlock(true));
  };

  const handleOpenModalDelete = () => {
    dispatch(updateConfigurationFile(selectedItem));
    dispatch(updateOpenModalDeleteConfigurationFile(true));
  };

  const handleOpenDrawerBankFiles = () => {
    dispatch(updateConfigurationFile(selectedItem));
    dispatch(updateOpenDrawerBankFiles(true));
  };

  const handleGoToOffsetsTable = (selectedItem_: any) => {
    dispatch(updateConfigurationFile(selectedItem_));
    dispatch(updateConfigurationFileBreadcrumb(["Offsets"]));
    localStorage.setItem("headerTitle", "offsets");
    dispatch(updateBreadcrumbTitle("offsets"));
  };

  const handleGoToDefinitions = (
    record: any,
    offsetType: string,
    offsetTypeTagColor: string,
    title: string
  ) => {
    dispatch(updateConfigurationFile(record));
    dispatch(updateOffsetTypeName(offsetType));
    dispatch(updateOffsetTypeTagColor(offsetTypeTagColor));
    dispatch(
      updateConfigurationFileBreadcrumb([
        selectedItem.name,
        `${offsetType} - Definitions`,
      ])
    );
    localStorage.setItem("headerTitle", title);
    dispatch(updateBreadcrumbTitle(title));
  };

  const onCellClick = (record: any) => {
    if (record) {
      dispatch(updateConfigurationFile(record));
      dispatch(updateOpenDrawerConfigurationFile(true));
    }
  };

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: any
  ) => {
    dispatch(
      updateConfigurationFileTableParams({
        pagination,
        filters,
        ...sorter,
      })
    );
    //
    const token = await GETJwtToken();
    triggerGetConfigurationFilesTable({
      page: pagination.current,
      limit: pagination.pageSize,
      token,
      orgId: idOrganizationSelected,
    });
  };

  const refreshTable = async (orgId: string) => {
    dispatch(
      updateConfigurationFileTableParams({
        pagination: {
          current: 1,
          pageSize: 10,
          total: configurationFileTableParams.pagination.total,
        },
        filters: {},
        sorter: {},
      })
    );
    //
    const token = await GETJwtToken();
    await triggerGetConfigurationFilesTable({
      page: 1,
      limit: 10,
      token,
      orgId,
    }).unwrap();
  };

  const getInitialData = async () => {
    const token = await GETJwtToken();
    triggerGetConfigurationFilesTable({
      page: configurationFileTableParams.pagination.current,
      limit: configurationFileTableParams.pagination.pageSize,
      token,
    });
  };

  const getOffsetsList = async () => {
    const token = await GETJwtToken();
    triggerGetOffsetsTypes({ token });
  };

  const buildConfigurationFilesData = (data_: any) => {
    const newArr: any = [];
    data_.map((item: any) => {
      const copy = structuredClone(item);
      if (copy && copy.data_blocks && copy.data_blocks.length > 0) {
        copy.data_blocks.map((item2: any, index: number) => {
          const copy2 = structuredClone(copy);
          copy2.rowSpan = index === 0 ? copy.data_blocks.length : 0;
          copy2.data_block = item2;
          newArr.push(copy2);
          return true;
        });
      } else {
        copy.data_block = [];
        copy.rowSpan = 1;
        newArr.push(copy);
      }
      return true;
    });
    setConfigurationFiles(newArr);
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (userLogged && userLogged.org) {
      setIdOrganizationSelected(userLogged.org.id);
      getInitialData();
      getOffsetsList();
    }
  }, []);

  useEffect(() => {
    if (data && data.data && data.data.length > 0) {
      dispatch(
        updateConfigurationFileTableParams({
          ...configurationFileTableParams,
          pagination: {
            ...configurationFileTableParams.pagination,
            total: data.total,
          },
        })
      );
      buildConfigurationFilesData(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      setConfigurationFiles([]);
    }
  }, [isError]);

  useEffect(() => {
    if (configurationFileRefreshTable) {
      refreshTable(idOrganizationSelected);
    }
  }, [configurationFileRefreshTable]);

  useEffect(() => {
    if (liftOffsetsType && liftOffsetsType.data) {
      setOffsetsTypeList(liftOffsetsType.data);
    }
  }, [liftOffsetsType]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div className="mt-3">
      {validatePermission("configuration-managerread", permissions) ? (
        <>
          {!isError ? (
            <Table
              rowClassName={(record, index) => getTableRowClass(index, theme)}
              className="customTable"
              dataSource={configurationFiles}
              loading={isLoading || isFetching || isLoadingGetOffsetTypes}
              rowKey={(record) => record.id}
              size="small"
              pagination={configurationFileTableParams.pagination}
              onChange={handleTableChange}
              scroll={{ x: 900 }}
            >
              {/** NAME */}
              <Column
                onCell={(record: any) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                  rowSpan: record.rowSpan,
                })}
                title={t("configuration.configurationFiles.name")}
                dataIndex="name"
                key="name"
                className="generalStyles__tableHeaderLink generalStyles__ellipsis__250px"
                render={(text) => (
                  <>
                    <div className="">{text || "--"}</div>
                  </>
                )}
              />
              {/** TYPE */}
              <Column
                onCell={(record: any) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                  rowSpan: record.rowSpan,
                })}
                title={t("edgeManagement.lifts.type")}
                dataIndex="lift_type"
                key="lift_type"
                width="150px"
                className=""
                filterSearch
                render={(text) => (
                  <>
                    <div className="">
                      {text === "ELEVATOR" && (
                        <Tag color="blue" className="drawer__typeTag">
                          {text}
                        </Tag>
                      )}
                      {text === "ESCALATOR" && (
                        <Tag color="geekblue" className="drawer__typeTag">
                          {text}
                        </Tag>
                      )}
                      {text === "WALKINGBAND" && (
                        <Tag color="cyan" className="drawer__typeTag">
                          {text}
                        </Tag>
                      )}
                    </div>
                  </>
                )}
              />
              {/** LIFTS */}
              <Column
                onCell={(record: any) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                  rowSpan: record.rowSpan,
                })}
                title={t("configuration.configurationFiles.lifts")}
                dataIndex="lifts"
                key="lifts"
                width="100px"
                filterSearch
                render={(text) => (
                  <>
                    <div className="">{text || 0}</div>
                  </>
                )}
              />
              {/** FLOORS */}
              <Column
                onCell={(record: any) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                  rowSpan: record.rowSpan,
                })}
                title={t("configuration.configurationFiles.floors")}
                dataIndex="stops"
                key="stops"
                width="100px"
                filterSearch
                render={(text, record: any) => (
                  <>
                    <div className="">{record.stops || 0}</div>
                  </>
                )}
              />
              {/** VENDOR */}
              <Column
                onCell={(record: any) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                  rowSpan: record.rowSpan,
                })}
                title={t("configuration.vendor.vendor")}
                dataIndex="vendor"
                key="vendor"
                width="100px"
                filterSearch
                render={(text, record: any) => (
                  <>
                    <div className="">
                      {record &&
                      record.data_block &&
                      record.data_block.vendor &&
                      record.data_block.vendor.name
                        ? record.data_block.vendor.name
                        : "--"}
                    </div>
                  </>
                )}
              />
              {/** DATA BLOCKS */}
              <Column
                title={t("configuration.dataBlock.dataBlock")}
                filterSearch
                width="150px"
                render={(text, record: any) => (
                  <>
                    <Link to="offsets">
                      {record &&
                      record.data_block &&
                      record.data_block.data_block_type &&
                      record.data_block.data_block_type.name ? (
                        <Tag
                          color="green"
                          style={{
                            cursor: "pointer",
                            width: "100px",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            handleGoToOffsetsTable(record);
                          }}
                        >
                          {record.data_block.data_block_type.name}
                        </Tag>
                      ) : (
                        <Tag
                          color="red"
                          style={{
                            cursor: "pointer",
                            width: "100px",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            handleGoToOffsetsTable(record);
                          }}
                        >
                          N/A
                        </Tag>
                      )}
                    </Link>
                  </>
                )}
              />
              {/** DEFINITIONS */}
              <Column
                title="Definitions"
                dataIndex="definitions"
                key="definitions"
                filterSearch
                render={(text, record: any) => (
                  <div
                    className="generalStyles__flex"
                    style={{ minWidth: "350px", flexWrap: "wrap" }}
                  >
                    {offsetTypeList.map((item: any, index: number) => (
                      <div key={index} className="mt-1">
                        {getOffsetsCountByConfiguration(
                          item.name,
                          record.data_block.offsets
                        ) > 0 && (
                          <div>
                            <Link to="definitions">
                              <Tag
                                color={item.color}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleGoToDefinitions(
                                    record,
                                    item.name,
                                    item.color,
                                    `definitions`
                                  );
                                }}
                                key={index}
                              >
                                <span>{item.name}</span>
                              </Tag>
                            </Link>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              />
              {/** ACTIONS */}
              <Column
                title={t("edgeManagement.banks.actions")}
                key="action"
                width="100px"
                onCell={(record: any) => ({
                  rowSpan: record.rowSpan,
                })}
                render={(_: any, record: any) => (
                  <Dropdown overlay={menu} trigger={["click"]} className="">
                    <Space
                      className="generalStyles__hoverCursor"
                      onClick={() => {
                        setSelectedItem(record);
                      }}
                    >
                      <FontAwesomeIcon icon={faEllipsis} />
                    </Space>
                  </Dropdown>
                )}
              />
            </Table>
          ) : (
            <Alert
              message={t("organizationManagement.errorDescription.error")}
              description={t("organizationManagement.errorDescription.text1")}
              type="error"
              showIcon
              className="mt-3"
            />
          )}
        </>
      ) : (
        <>
          <div className="generalStyles__noAccessToListTable">
            <Empty
              description={t("edgeManagement.banks.listNotAvailable")}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ConfigurationFilesTable;
