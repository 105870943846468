import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

const maxRetries = 2;

const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE,
  }),
  { maxRetries }
);

export const monitoringAPI = createApi({
  reducerPath: 'monitoringAPI',
  baseQuery,
  keepUnusedDataFor: 0,
  tagTypes: ['tagGetOrganizationCascader'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    //* *********************************************************** */
    //* GET ALL ORGANIZATIONS AND ITS CASCADER DATA */
    getOrganizationsCascader: builder.query({
      query: (body: any) => ({
        url: `/monitor-view/`,
        headers: { authorization: body.token },
      }),
      providesTags: ['tagGetOrganizationCascader'],
    })
    //* *********************************************************** */
  }),
});

export const {
	useLazyGetOrganizationsCascaderQuery
} = monitoringAPI;
