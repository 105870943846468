import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

const maxRetries = 2;

const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE,
  }),
  { maxRetries }
);

export const liftAPI = createApi({
  reducerPath: 'liftAPI',
  baseQuery,
  keepUnusedDataFor: 0,
  tagTypes: ['tagGetLifts', 'tagPostLift', 'tagPutLift', 'tagDeleteLift'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    //* *********************************************************** */
    //* GET ALL LIFTS */
    getLifts: builder.query({
      query: (body: any) => ({
        url: `/lifts/?page=${body.page}&limit=${body.limit}`,
        headers: { authorization: body.token },
      }),
      providesTags: ['tagGetLifts'],
    }),
    //* *********************************************************** */
    //* GET LIFTS BY BANK */
    getLiftsByBank: builder.query({
      query: (body: any) => ({
        url: `/lifts/?filter_target=bank_id&filter=${body.bankId}`,
        headers: { authorization: body.token },
      }),
      providesTags: ['tagGetLifts'],
    }),
    //* *********************************************************** */
    //* CREATE LIFT */
    postLift: builder.mutation({
      query: (body: any) => ({
        url: '/lifts/',
        method: 'post',
        body: body.body,
        headers: { authorization: body.token },
      }),
      invalidatesTags: ['tagGetLifts'],
    }),
    //* *********************************************************** */
    //* EDIT LIFT */
    putLift: builder.mutation({
      query: (body: any) => ({
        url: `/lifts/${body.id}`,
        method: 'put',
        body: body.body,
        headers: { authorization: body.token },
      }),
      invalidatesTags: ['tagGetLifts'],
    }),
    //* *********************************************************** */
    //* DELETE LIFT */
    deleteLift: builder.mutation({
      query: (body: any) => ({
        url: `/lifts/${body.id}`,
        method: 'delete',
        headers: { authorization: body.token },
      }),
      invalidatesTags: ['tagGetLifts'],
    }),
    //* *********************************************************** */
  }),
});

export const {
  useLazyGetLiftsQuery,
  useLazyGetLiftsByBankQuery,
  usePostLiftMutation,
  usePutLiftMutation,
  useDeleteLiftMutation,
} = liftAPI;
