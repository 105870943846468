/* eslint-disable no-nested-ternary */
import { Route, Routes, Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// ANT DESIGN COMPONENTS
import { Layout, Menu, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoon,
  faSun,
  faBuilding,
  faBuildingUser,
  faUsers,
  faUser,
  faUserShield,
  faShieldHalved,
  faTools,
  faGears,
  faServer,
  faBuildingShield,
  faElevator,
  faFileCircleCheck,
  faChartGantt,
  faFileArchive,
} from "@fortawesome/free-solid-svg-icons";
// SWITCH THEME
import { useThemeSwitcher } from "react-css-theme-switcher";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
  updateBreadcrumbTitle,
  updateUserLogged,
  updateTheme,
} from "../../redux/home/homeSlice";
import { updatePermissions } from "../../redux/users/userSlice";
import { useLazyGetCurrentLoggedUserQuery } from "../../redux/users/userAPI";
// COMPONENTS
import LayoutBreadcrumb from "./LayoutBreadcrumb";
import UsersPage from "../userManagement/users/UsersPage";
import OrganizationsPage from "../organizationManagement/organizations/OrganizationsPage";
import RolesPage from "../organizationManagement/roles/RolesPage";
import RootPermissionsPage from "../tools/rootPermissions/RootPermissionsPage";
import OffsetTypePage from "../tools/offsetType/OffsetTypePage";
import YourProfileDrawer from "./YourProfileDrawer";
import GlobalAlert from "./GlobalAlert";
import MonitoringPage from "../monitoringManagement/monitoring/MonitoringPage";
import NotFoundPage from "../unauthorized/NotFoundPage";
import BanksPage from "../edgeManagement/banks/BanksPage";
import ServersPage from "../edgeManagement/servers/ServersPage";
import LiftsPage from "../edgeManagement/lifts/LiftsPage";
import ConfigurationFiles from "../configurationManagement/configurationFiles/ConfigurationFilesPage";
import DefinitionsPage from "../configurationManagement/definitions/DefinitionsPage";
import OffsetsPage from "../configurationManagement/offsets/OffsetsPage";
import SpecialSwitchesPage from "../tools/specialSwitches/SpecialSwitchesPage";
import SpecialSwitchesCatalogPage from "../tools/specialSwitchesCatalog/SpecialSwitchesCatalogPage";
import DataBlocksPage from "../tools/dataBlocks/DataBlocksPage";
import ReportOffsetsPage from "../reports/offsets/ReportOffsetsPage";
import VendorsPage from "../tools/vendors/VendorsPage";
// AUTHORIZATION
import GETJwtToken from "../../redux/authentication/authentication";
import validatePermission from "../../utils/validatePermissions";
// STYLES
import "../../styles/generalStyles.scss";
import logoBlack from "../../assets/logo/black-bg-lnlogo.png";
import logoWhite from "../../assets/logo/white-bg-lnlogo.png";
import mobileLogo from "../../assets/logo/lnLogo.png";

const { Header, Content, Footer, Sider } = Layout;
library.add(
  faMoon,
  faSun,
  faBuilding,
  faUsers,
  faUser,
  faBuildingUser,
  faUserShield
);

const App = () => {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [t] = useTranslation("global");
  const { theme } = useSelector((state: any) => state.home);
  const { permissions } = useSelector((state: any) => state.user);
  const [current, setCurrent] = useState<any>("");
  const [openKeys, setOpenKeys] = useState([""]);
  const { switcher, themes } = useThemeSwitcher();
  const [isLoading, setIsLoading] = useState(false);
  const [localShowFullView, setLocalShowFullView] = useState(false);
  const rootSubmenuKeys = [
    "users",
    "monitoring",
    "roles",
    "organizations",
    "servers",
    "banks",
    "lifts",
    "root-permissions",
    "offset-type-configuration",
    "configuration-catalog",
    "configuration-catalog/definitions",
    "configuration-catalog/offsets",
    "reports/report-offsets",
    "tools/data-blocks",
    "tools/root-permissions",
    "tools/offset-type-configuration",
    "tools/special-switches-configuration",
    "tools/special-switches-catalog-configuration",
    "tools/vendors",
  ];
  const { showFullView } = useSelector((state: any) => state.monitoring);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [triggerGetCurrentLoggedUserQuery] = useLazyGetCurrentLoggedUserQuery();
  // ************************************************* */
  // FUNCTIONS *************************************** */
  const showIsLoading = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };

  const handleChangeHeaderTitle = (title: string) => {
    localStorage.setItem("headerTitle", title);
    dispatch(updateBreadcrumbTitle(title));
  };

  const getCurrentLoggedUser = async () => {
    const token = await GETJwtToken();
    const response = await triggerGetCurrentLoggedUserQuery({ token }).unwrap();
    if (response && response.data && response.data.user) {
      dispatch(updateUserLogged(response.data.user));
      if (response.data.user.role && response.data.user.role.menu) {
        dispatch(updatePermissions(response.data.user.role.menu));
      }
    }
  };

  const onChangeTab: any = (e: any) => {
    if (e.key) {
      setCurrent(e.key.toString());
    }
  };
  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const getTheme = () => {
    setIsLoading(true);
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme:dark)").matches
    ) {
      setTimeout(() => {
        switcher({ theme: themes.dark });
        localStorage.setItem("theme", "dark");
        dispatch(updateTheme("dark"));
        setIsLoading(false);
      }, 1000);
    } else {
      setTimeout(() => {
        switcher({ theme: themes.light });
        localStorage.setItem("theme", "light");
        dispatch(updateTheme("light"));
      }, 1000);
    }
  };

  const updateSideBarTab = (current_: string, key_: string) => {
    handleChangeHeaderTitle(current_);
    setCurrent(current_);
    setOpenKeys([key_]);
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    getTheme();
    getCurrentLoggedUser();
    const headerTitle = localStorage.getItem("headerTitle");
    setCurrent(headerTitle);
    if (headerTitle === "users") {
      setOpenKeys(["userManagement"]);
    } else if (headerTitle === "roles" || headerTitle === "organizations") {
      setOpenKeys(["organizationManagement"]);
    } else if (
      headerTitle === "servers" ||
      headerTitle === "banks" ||
      headerTitle === "lifts" ||
      headerTitle === "configurationCatalog"
    ) {
      setOpenKeys(["edgeManagement"]);
    } else if (
      headerTitle === "rootPermissions" ||
      headerTitle === "offsetTypeConfiguration" ||
      headerTitle === "specialSwitches" ||
      headerTitle === "specialSwitchesCatalog" ||
      headerTitle === "dataBlocks" ||
      headerTitle === "vendors"
    ) {
      setOpenKeys(["tools"]);
    } else if (headerTitle === "reportOffsets") {
      setOpenKeys(["reports"]);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("headerTitle") === "monitoring") {
      setCurrent("monitoring");
    }
  }, [localStorage.getItem("headerTitle")]);

  useEffect(() => {
    if (location && location.pathname) {
      switch (location.pathname) {
        case "/monitoring":
          updateSideBarTab("monitoring", "monitoring");
          break;
        case "/organization-management/roles":
          updateSideBarTab("roles", "organizationManagement");
          break;
        case "/organization-management/organizations":
          updateSideBarTab("organizations", "organizationManagement");
          break;
        case "/users-management/users":
          updateSideBarTab("users", "userManagement");
          break;
        case "/edge-management/servers":
          updateSideBarTab("servers", "edgeManagement");
          break;
        case "/edge-management/banks":
          updateSideBarTab("banks", "edgeManagement");
          break;
        case "/edge-management/lifts":
          updateSideBarTab("lifts", "edgeManagement");
          break;
        case "/edge-management/configuration-catalog":
          updateSideBarTab("configurationCatalog", "edgeManagement");
          break;
        case "/tools/root-permissions":
          updateSideBarTab("rootPermissions", "tools");
          break;
        case "/tools/offset-type-configuration":
          updateSideBarTab("offsetTypeConfiguration", "tools");
          break;
        case "/tools/special-switches-configuration":
          updateSideBarTab("specialSwitchesConfiguration", "tools");
          break;
        case "/tools/special-switches-catalog-configuration":
          updateSideBarTab("specialSwitchesCatalogConfiguration", "tools");
          break;
        case "/tools/data-blocks-configuration":
          updateSideBarTab("dataBlocksConfiguration", "tools");
          break;
        case "/tools/vendors":
          updateSideBarTab("vendorsConfiguration", "tools");
          break;
        case "/reports/report-offsets":
          updateSideBarTab("reportOffsets", "reports");
          break;
        default:
          break;
      }
    }
  }, [location]);

  useEffect(() => {
    showIsLoading();
    setTimeout(() => {
      setLocalShowFullView(showFullView);
    }, 100);
  }, [showFullView]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Spin spinning={isLoading}>
      {!isLoading && (
        <Layout className="generalStyles__layoutHeight">
          {/** ******************************************************* */}
          {/** SIDE BAR */}
          {((!localShowFullView &&
            localStorage.getItem("headerTitle") === "monitoring") ||
            localStorage.getItem("headerTitle") !== "monitoring") && (
            <Sider
              collapsible
              collapsed={collapsed || isMobile}
              onCollapse={(value) => setCollapsed(value)}
              width="290"
            >
              <div className="logo">
                <img
                  src={
                    isMobile
                      ? mobileLogo
                      : theme === "light"
                      ? logoWhite
                      : logoBlack
                  }
                  alt=""
                  className={
                    isMobile
                      ? "layout__sideBarMobileLogo"
                      : "layout__sideBarLogo"
                  }
                />
              </div>
              <Menu
                defaultSelectedKeys={[current]}
                defaultOpenKeys={openKeys}
                selectedKeys={[current]}
                openKeys={openKeys}
                mode="inline"
                onClick={onChangeTab}
                onOpenChange={onOpenChange}
              >
                {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
                {/** MONITORING *********** */}
                <Menu.Item
                  key="monitoring"
                  icon={
                    <FontAwesomeIcon
                      className="generalStyles__info"
                      icon={faChartGantt}
                    />
                  }
                >
                  <Link
                    onClick={() => {
                      handleChangeHeaderTitle("monitoring");
                    }}
                    to="/monitoring"
                  >
                    <span>{t("sideBar.monitoring")}</span>
                  </Link>
                </Menu.Item>
                {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
                {/** ORGANIZATION MANAGEMENT *********** */}
                {validatePermission("organization-management", permissions) && (
                  <Menu.SubMenu
                    title="Organization Management"
                    key="organizationManagement"
                    icon={
                      <FontAwesomeIcon
                        className="generalStyles__info"
                        icon={faBuildingUser}
                      />
                    }
                  >
                    {/** ORGANIZATIONS *********** */}
                    {validatePermission("organizations", permissions) && (
                      <Menu.Item
                        key="organizations"
                        icon={
                          <FontAwesomeIcon
                            className="generalStyles__info"
                            icon={faBuilding}
                          />
                        }
                      >
                        <Link
                          onClick={() => {
                            handleChangeHeaderTitle("organizations");
                          }}
                          to="/organization-management/organizations"
                        >
                          <span>{t("sideBar.organizations")}</span>
                        </Link>
                      </Menu.Item>
                    )}
                    {/** ROLES AND PERMISSIONS *********** */}
                    {validatePermission(
                      "roles-and-permissions",
                      permissions
                    ) && (
                      <Menu.Item
                        key="roles"
                        icon={
                          <FontAwesomeIcon
                            className="generalStyles__info"
                            icon={faUserShield}
                          />
                        }
                      >
                        <Link
                          onClick={() => {
                            handleChangeHeaderTitle("roles");
                          }}
                          to="/organization-management/roles"
                        >
                          <span>{t("sideBar.roles")}</span>
                        </Link>
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                )}
                {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
                {/** USER MANAGEMENT *********** */}
                {validatePermission("user-management", permissions) && (
                  <Menu.SubMenu
                    title="User Management"
                    key="userManagement"
                    icon={
                      <FontAwesomeIcon
                        className="generalStyles__info"
                        icon={faUser}
                      />
                    }
                  >
                    {/** USERS *********** */}
                    {validatePermission("users", permissions) && (
                      <Menu.Item
                        key="users"
                        icon={
                          <FontAwesomeIcon
                            className="generalStyles__info"
                            icon={faUsers}
                          />
                        }
                      >
                        <Link
                          onClick={() => {
                            handleChangeHeaderTitle("users");
                          }}
                          to="/users-management/users"
                        >
                          <span>{t("sideBar.users")}</span>
                        </Link>
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                )}
                {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
                {/** EDGE MANAGEMENT *********** */}
                {validatePermission("edge-management", permissions) && (
                  <Menu.SubMenu
                    title="Edge Management"
                    key="edgeManagement"
                    icon={
                      <FontAwesomeIcon
                        className="generalStyles__info"
                        icon={faGears}
                      />
                    }
                  >
                    {/** SERVERS *********** */}
                    {validatePermission("server-manager", permissions) && (
                      <Menu.Item
                        key="servers"
                        icon={
                          <FontAwesomeIcon
                            className="generalStyles__info"
                            icon={faServer}
                          />
                        }
                      >
                        <Link
                          onClick={() => {
                            handleChangeHeaderTitle("servers");
                          }}
                          to="/edge-management/servers"
                        >
                          <span>{t("sideBar.servers")}</span>
                        </Link>
                      </Menu.Item>
                    )}
                    {/** BANKS *********** */}
                    {validatePermission("bank-manager", permissions) && (
                      <Menu.Item
                        key="banks"
                        icon={
                          <FontAwesomeIcon
                            className="generalStyles__info"
                            icon={faBuildingShield}
                          />
                        }
                      >
                        <Link
                          onClick={() => {
                            handleChangeHeaderTitle("banks");
                          }}
                          to="/edge-management/banks"
                        >
                          <span>{t("sideBar.banks")}</span>
                        </Link>
                      </Menu.Item>
                    )}
                    {/** LIFTS *********** */}
                    {validatePermission("lift-manager", permissions) && (
                      <Menu.Item
                        key="lifts"
                        icon={
                          <FontAwesomeIcon
                            className="generalStyles__info"
                            icon={faElevator}
                          />
                        }
                      >
                        <Link
                          onClick={() => {
                            handleChangeHeaderTitle("lifts");
                          }}
                          to="/edge-management/lifts"
                        >
                          <span>{t("sideBar.lifts")}</span>
                        </Link>
                      </Menu.Item>
                    )}
                    {/** CONFIGURATION CATALOG *********** */}
                    {validatePermission(
                      "configuration-manager",
                      permissions
                    ) && (
                      <Menu.Item
                        key="configurationCatalog"
                        icon={
                          <FontAwesomeIcon
                            className="generalStyles__info"
                            icon={faFileCircleCheck}
                          />
                        }
                      >
                        <Link
                          onClick={() => {
                            handleChangeHeaderTitle("configurationCatalog");
                          }}
                          to="/edge-management/configuration-catalog"
                        >
                          <span>{t("sideBar.configurationCatalog")}</span>
                        </Link>
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                )}
                {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
                {/** REPORTS *********** */}
                {validatePermission("permission-not-defined", permissions) && (
                  <Menu.SubMenu
                    title="Reports"
                    key="reports"
                    icon={
                      <FontAwesomeIcon
                        className="generalStyles__info"
                        icon={faFileCircleCheck}
                      />
                    }
                  >
                    {validatePermission(
                      "permission-not-defined",
                      permissions
                    ) && (
                      <>
                        {/** REPORT OFFSETS *********** */}
                        <Menu.Item
                          key="reportOffsets"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faFileArchive}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle("reportOffsets");
                            }}
                            to="/reports/report-offsets"
                          >
                            <span>{t("sideBar.offsets")}</span>
                          </Link>
                        </Menu.Item>
                      </>
                    )}
                  </Menu.SubMenu>
                )}
                {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
                {/** TOOLS *********** */}
                {validatePermission("tools", permissions) && (
                  <Menu.SubMenu
                    title="Tools"
                    key="tools"
                    icon={
                      <FontAwesomeIcon
                        className="generalStyles__info"
                        icon={faTools}
                      />
                    }
                  >
                    {validatePermission("root-permissions", permissions) && (
                      <>
                        {/** ROOT PERMISSIONS *********** */}
                        <Menu.Item
                          key="rootPermissions"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faShieldHalved}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle("rootPermissions");
                            }}
                            to="/tools/root-permissions"
                          >
                            <span>{t("sideBar.rootPermissions")}</span>
                          </Link>
                        </Menu.Item>
                      </>
                    )}
                    {validatePermission(
                      "permission-not-defined",
                      permissions
                    ) && (
                      <>
                        {/** OFFSET TYPE CONFIGURATION *********** */}
                        <Menu.Item
                          key="offsetTypeConfiguration"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faShieldHalved}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "offsetTypeConfiguration"
                              );
                            }}
                            to="/tools/offset-type-configuration"
                          >
                            <span>{t("sideBar.offsetTypeConfiguration")}</span>
                          </Link>
                        </Menu.Item>
                      </>
                    )}
                    {validatePermission(
                      "permission-not-defined",
                      permissions
                    ) && (
                      <>
                        {/** SPECIAL SWITCHES CONFIGURATION *********** */}
                        <Menu.Item
                          key="specialSwitchesConfiguration"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faShieldHalved}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "specialSwitchesConfiguration"
                              );
                            }}
                            to="/tools/special-switches-configuration"
                          >
                            <span>
                              {t("sideBar.specialSwitchesConfiguration")}
                            </span>
                          </Link>
                        </Menu.Item>
                      </>
                    )}
                    {validatePermission(
                      "permission-not-defined",
                      permissions
                    ) && (
                      <>
                        {/** SPECIAL SWITCHES CATALOG CONFIGURATION *********** */}
                        <Menu.Item
                          key="specialSwitchesCatalogConfiguration"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faShieldHalved}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "specialSwitchesCatalogConfiguration"
                              );
                            }}
                            to="/tools/special-switches-catalog-configuration"
                          >
                            <span>
                              {t("sideBar.specialSwitchesCatalogConfiguration")}
                            </span>
                          </Link>
                        </Menu.Item>
                      </>
                    )}
                    {validatePermission(
                      "permission-not-defined",
                      permissions
                    ) && (
                      <>
                        {/** DATA BLOCKS CONFIGURATION *********** */}
                        <Menu.Item
                          key="dataBlocksConfiguration"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faShieldHalved}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle(
                                "dataBlocksConfiguration"
                              );
                            }}
                            to="/tools/data-blocks-configuration"
                          >
                            <span>{t("sideBar.dataBlocks")}</span>
                          </Link>
                        </Menu.Item>
                      </>
                    )}
                    {validatePermission(
                      "permission-not-defined",
                      permissions
                    ) && (
                      <>
                        {/** VENDORS CONFIGURATION *********** */}
                        <Menu.Item
                          key="vendorsConfiguration"
                          icon={
                            <FontAwesomeIcon
                              className="generalStyles__info"
                              icon={faShieldHalved}
                            />
                          }
                        >
                          <Link
                            onClick={() => {
                              handleChangeHeaderTitle("vendorsConfiguration");
                            }}
                            to="/tools/vendors"
                          >
                            <span>{t("sideBar.vendors")}</span>
                          </Link>
                        </Menu.Item>
                      </>
                    )}
                  </Menu.SubMenu>
                )}
              </Menu>
            </Sider>
          )}
          {/** ******************************************************* */}
          {/** LAYOUT */}
          <Layout
            style={{
              overflowX: "auto",
            }}
          >
            {/** ******************************************************* */}
            {/** HEADER */}
            {((!localShowFullView &&
              localStorage.getItem("headerTitle") === "monitoring") ||
              localStorage.getItem("headerTitle") !== "monitoring") && (
              <Header className="generalStyles__header">
                <div className="generalStyles__spaceBetween">
                  <div />
                  <div>
                    <YourProfileDrawer />
                  </div>
                </div>
              </Header>
            )}

            {/** ******************************************************* */}
            {/** CONTENT */}
            <Content>
              <LayoutBreadcrumb />
              <div
                className={
                  isMobile ? "layout__contentPageMobile" : "layout__contentPage"
                }
              >
                <Routes>
                  <Route path="*" element={<NotFoundPage />} />
                  <Route path="" element={<MonitoringPage />} />
                  <Route path="monitoring" element={<MonitoringPage />} />
                  <Route path="users" element={<UsersPage />} />
                  <Route path="organizations" element={<OrganizationsPage />} />
                  <Route path="roles" element={<RolesPage />} />
                  <Route
                    path="root-permissions"
                    element={<RootPermissionsPage />}
                  />
                  <Route
                    path="offset-type-configuration"
                    element={<OffsetTypePage />}
                  />
                  <Route
                    path="special-switches-configuration"
                    element={<SpecialSwitchesPage />}
                  />
                  <Route
                    path="special-switches-catalog-configuration"
                    element={<SpecialSwitchesCatalogPage />}
                  />
                  <Route
                    path="data-blocks-configuration"
                    element={<DataBlocksPage />}
                  />
                  <Route path="vendors" element={<VendorsPage />} />
                  <Route
                    path="configuration-catalog"
                    element={<ConfigurationFiles />}
                  />
                  <Route
                    path="configuration-catalog/definitions"
                    element={<DefinitionsPage />}
                  />
                  <Route
                    path="configuration-catalog/offsets"
                    element={<OffsetsPage />}
                  />
                  <Route path="banks" element={<BanksPage />} />
                  <Route path="servers" element={<ServersPage />} />
                  <Route path="lifts" element={<LiftsPage />} />
                  <Route
                    path="report-offsets"
                    element={<ReportOffsetsPage />}
                  />
                </Routes>
                <GlobalAlert />
              </div>
            </Content>
            {/** ******************************************************* */}
            {/** FOOTER */}
            <Footer className="layout__footerText">
              <span
                className={
                  isMobile
                    ? "layout__footerTextMobile"
                    : "layout__footerTextDesktop"
                }
              >
                © {new Date().getFullYear()} powered by - LiftNet
              </span>
            </Footer>
          </Layout>
        </Layout>
      )}
      {isLoading && <div style={{ height: "50vh" }} />}
    </Spin>
  );
};

export default App;
