/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface HomeState {
  userInformation: Object;
  userLogged: Object;
  breadcrumb: Object;
  theme: string;
  language: string;
  breadcrumbTitle: string;
  globalAlert: Object;
}

const initialState: HomeState = {
  userInformation: {},
  userLogged: {},
  breadcrumb: {},
  theme: 'light',
  language: 'en',
  breadcrumbTitle: '',
  globalAlert: {},
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    updateUserInformation: (state, action: PayloadAction<Object>) => {
      state.userInformation = action.payload;
    },
    updateUserLogged: (state, action: PayloadAction<Object>) => {
      state.userLogged = action.payload;
    },
    updateBreadcrumb: (state, action: PayloadAction<Object>) => {
      state.breadcrumb = action.payload;
    },
    updateTheme: (state, action: PayloadAction<string>) => {
      state.theme = action.payload;
    },
    updateLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    updateBreadcrumbTitle: (state, action: PayloadAction<string>) => {
      state.breadcrumbTitle = action.payload;
    },
    updateGlobalAlert: (state, action: PayloadAction<Object>) => {
      state.globalAlert = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateUserInformation,
  updateUserLogged,
  updateBreadcrumb,
  updateTheme,
  updateLanguage,
  updateBreadcrumbTitle,
  updateGlobalAlert, 
} = homeSlice.actions;

export default homeSlice.reducer;
