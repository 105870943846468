import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import {
  Drawer,
  Divider,
  Switch,
  Input,
  Button,
  Empty,
  Spin,
  Table,
} from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faElevator,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { usePutBankMutation } from "../../../redux/banks/bankAPI";
import {
  updateOpenDrawerBankFloorNames,
  updateBank,
  updateBankAlert,
} from "../../../redux/banks/bankSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../../utils/utils";
// COMPONENTS
import DrawerBankInformation from "../../components/DrawerBankInformation";

const { Column, ColumnGroup } = Table;

function BanksFloorNames() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [floorNamesObj, setFloorNamesObj] = useState<any>([]);
  const [liftsObj, setLiftsOjb] = useState<any>([]);

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { openDrawerBankFloorNames, bank } = useSelector(
    (state: any) => state.bank
  );
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    putBank,
    { isSuccess, isError, error, isLoading, reset: resetPutBank },
  ] = usePutBankMutation();
  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onClose = () => {
    dispatch(updateOpenDrawerBankFloorNames(false));
  };

  const cleanData = () => {
    setFloorNamesObj([]);
  };

  const onChangeInputFloorName = (newValue: string, index: number) => {
    const newObj = [...floorNamesObj];
    newObj[index].name = newValue;
    setFloorNamesObj(newObj);
  };

  const onChangeDoor = (
    item: any,
    newValue: boolean,
    index: number,
    doorType: string
  ) => {
    const copy: any = structuredClone(liftsObj);
    const index2 = copy[index].lift_floors.findIndex(
      (x: any) => x.bank_floor_id === item.id
    );
    copy[index].lift_floors[index2][doorType] = newValue;
    setLiftsOjb(copy);
  };

  const doorCheckedValue = (item: any, recordId: any, doorType: string) => {
    const index = item.lift_floors.findIndex(
      (x: any) => x.bank_floor_id === recordId
    );
    return item.lift_floors[index][doorType];
  };

  const handleCloseDrawer = () => {
    dispatch(updateBank({}));
    dispatch(updateOpenDrawerBankFloorNames(false));
  };

  const onClickSave = async () => {
    const token = await GETJwtToken();
    const body = {
      name: bank.name,
      server_id: bank.server_id,
      number_of_lifts: bank.number_of_lifts,
      floor_count: bank.floor_count,
      floor_below_lobby: bank.floor_below_lobby,
      columns: bank.columns,
      stops: bank.stops,
      board: bank.board,
      logical_port: bank.logical_port,
      rear_doors: bank.rear_doors,
      configuration_id: bank.configuration_id,
      type: bank.type,
      active: bank.active,
      bank_floors: floorNamesObj,
      lift_floors: liftsObj,
      port_id: bank.port_id,
    };
    //
    const BODY = {
      id: bank.id,
      body,
      token,
    };
    putBank(BODY);
  };

  const buildBankFloorNamesObject = (
    totalFloors: number,
    floorsBelowLobby: number,
    data_: any
  ) => {
    const newObj = [];
    const newData = [...data_];
    // Sort floor data
    newData.sort(
      (a: any, b: any) => Number(a.floor_index) - Number(b.floor_index)
    );
    //
    if (totalFloors > 0) {
      for (let x = totalFloors; x > 0; x -= 1) {
        // Save previous floor names
        let name = "";
        name = newData[x - 1].name;
        // Set lobby name
        if (newData[x - 1].name === "" && x - floorsBelowLobby === 1) {
          name = "Lobby";
        }
        newObj.push({
          id: newData[x - 1].id,
          name,
          floorNo: x,
          key: newData[x - 1].floor_no,
          floorBelowLobby: x <= floorsBelowLobby,
        });
      }
      setFloorNamesObj(newObj);
    }
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    cleanData();
    if (openDrawerBankFloorNames) {
      resetPutBank();
      if (bank && bank.bank_floors && bank.bank_floors.length > 0) {
        buildBankFloorNamesObject(
          bank.floor_count,
          bank.floor_below_lobby,
          bank.bank_floors
        );
      }
      if (bank && bank.lifts && bank.lifts.length > 0) {
        setLiftsOjb(bank.lifts);
      }
    }
  }, [openDrawerBankFloorNames]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        dispatch(
          updateBankAlert({
            title: t("general.success"),
            description: t("edgeManagement.banks.floorNamesUpdated"),
            status: "success",
          })
        );
      }, 150);
      dispatch(updateOpenDrawerBankFloorNames(false));
    }
    if (isError) {
      setTimeout(() => {
        dispatch(
          updateBankAlert({
            title: t("general.error"),
            description: error,
            status: "error",
          })
        );
      }, 150);
      dispatch(updateOpenDrawerBankFloorNames(false));
    }
  }, [isSuccess, isError]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClose}
      closable={false}
      visible={openDrawerBankFloorNames}
    >
      <Spin spinning={isLoading} tip={t("general.loading")}>
        <div>
          {/** ************************************************* */}
          {/** BANK INFORMATION */}
          <DrawerBankInformation data={bank} />
          {/** ************************************************* */}
          {/** FLOORS */}
          <br />
          <div>
            <Divider
              orientation="left"
              className="generalStyles__drawerDivider"
            >
              <h5>
                <FontAwesomeIcon
                  icon={faElevator}
                  className="generalStyles__info generalStyles__mrFix"
                />
                {t("edgeManagement.banks.floorNames")}
              </h5>
            </Divider>
          </div>

          {floorNamesObj &&
          floorNamesObj.length > 0 &&
          bank &&
          bank.lifts &&
          bank.lifts.length > 0 ? (
            <>
              <Table
                rowClassName={(record, index) => getTableRowClass(index, theme)}
                className="customTable"
                dataSource={floorNamesObj}
                rowKey={(record) => record.id}
                pagination={{
                  defaultPageSize: 9999,
                  showSizeChanger: false,
                }}
                size="small"
                scroll={{ x: "900" }}
              >
                <ColumnGroup>
                  {/** FLOOR NO */}
                  <Column
                    title="No"
                    dataIndex="key"
                    key="key"
                    className=""
                    filterSearch
                    width="50px"
                    render={(text, record: any) => (
                      <div
                        className="generalStyles__flex"
                        style={{ width: "50px" }}
                      >
                        <div>
                          {record.name.toLowerCase() === "lobby" && (
                            <FontAwesomeIcon
                              icon={faStar}
                              className="generalStyles__warningTooltipIcon"
                              style={{ paddingTop: "10px" }}
                            />
                          )}
                          {record.floorBelowLobby && (
                            <FontAwesomeIcon
                              icon={faArrowDown}
                              className="generalStyles__warningTooltipIcon"
                              style={{ paddingTop: "10px" }}
                            />
                          )}
                          {!record.floorBelowLobby &&
                            record.name.toLowerCase() !== "lobby" && (
                              <FontAwesomeIcon
                                icon={faArrowUp}
                                className="generalStyles__infoTooltipIcon"
                                style={{ paddingTop: "10px" }}
                              />
                            )}
                        </div>
                        <div className="edgeManagement__floorNameTootle">
                          {text || "--"}
                        </div>
                      </div>
                    )}
                  />
                  {/** NAME */}
                  <Column
                    title={t("general.name")}
                    dataIndex="name"
                    key="name"
                    className="generalStyles__tableHeaderLink"
                    filterSearch
                    width="50px"
                    render={(text, record: any, indexName: number) => (
                      <>
                        <div className="edgeManagement__floorNameName">
                          <Input
                            placeholder={t("edgeManagement.banks.floorName")}
                            value={text}
                            onChange={(e) => {
                              onChangeInputFloorName(e.target.value, indexName);
                            }}
                            bordered={false}
                          />
                        </div>
                      </>
                    )}
                  />
                </ColumnGroup>
                {/** LIFTS */}
                {liftsObj.map((item: any, indexLift: number) => (
                  <ColumnGroup title={item.name} key={indexLift}>
                    <Column
                      title="F"
                      align="center"
                      filterSearch
                      render={(text, record: any) => (
                        <>
                          <Switch
                            className="edgeManagement__floorNameTootle"
                            checked={doorCheckedValue(
                              item,
                              record.id,
                              "front_door"
                            )}
                            onChange={(e) => {
                              onChangeDoor(record, e, indexLift, "front_door");
                            }}
                          />
                        </>
                      )}
                    />
                    <Column
                      title="R"
                      align="center"
                      filterSearch
                      render={(text, record: any) => (
                        <>
                          <Switch
                            className="edgeManagement__floorNameTootle"
                            checked={doorCheckedValue(
                              item,
                              record.id,
                              "rear_door"
                            )}
                            onChange={(e) => {
                              onChangeDoor(record, e, indexLift, "rear_door");
                            }}
                          />
                        </>
                      )}
                    />
                  </ColumnGroup>
                ))}
              </Table>
            </>
          ) : (
            <div
              className={
                theme === "dark"
                  ? " drawer__box__dark mb-3"
                  : " drawer__box__light mb-3"
              }
            >
              <Empty
                description={
                  <span>{t("edgeManagement.banks.floorNamesNoValid")}</span>
                }
              />
            </div>
          )}
          {/** CLOSE AND SUBMIT BUTTONS */}
          <div className="mt-4">
            <div>
              <Button
                type="default"
                onClick={handleCloseDrawer}
                className="buttonStyle__3"
              >
                {t("organizationManagement.close")}
              </Button>
              <Button
                onClick={onClickSave}
                className="buttonStyle__3"
                type="primary"
              >
                {t("general.save")}
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Drawer>
  );
}

export default BanksFloorNames;
