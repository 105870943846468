/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LiftState {
  lift: Object;
  openModalCreateNewLift: boolean;
  openModalEditLift: boolean;
  openModalDeleteLift: boolean;
  openModalActivateDeactivate: boolean;
  openDrawerLift: boolean;
  openDrawerLiftConfiguration: boolean;
  liftAlert: Object;
  liftTableParams: Object;
  liftRefreshTable: boolean;
  liftList: Array<any>;
  liftBreadcrumb: Array<any>;
  liftUpdateBreadcrumb: Object;
}

const initialState: LiftState = {
  lift: {},
  openModalCreateNewLift: false,
  openModalEditLift: false,
  openModalDeleteLift: false,
  openModalActivateDeactivate: false,
  openDrawerLift: false,
    openDrawerLiftConfiguration: false,
  liftAlert: {},
  liftTableParams: {
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ["10", "20", "50", "100"],
    },
  },
  liftRefreshTable: false,
  liftList: [],
  liftBreadcrumb: [],
  liftUpdateBreadcrumb: {
    update: false,
    id: '',
    name: '',
  },
};

export const liftSlice = createSlice({
  name: 'lift',
  initialState,
  reducers: {
    updateLift: (state, action: PayloadAction<Object>) => {
      state.lift = action.payload;
    },
    updateOpenModalCreateNewLift: (state, action: PayloadAction<boolean>) => {
      state.openModalCreateNewLift = action.payload;
    },
    updateOpenModalEditLift: (state, action: PayloadAction<boolean>) => {
      state.openModalEditLift = action.payload;
    },
    updateOpenModalDeleteLift: (state, action: PayloadAction<boolean>) => {
      state.openModalDeleteLift = action.payload;
    },
    updateOpenModalActivateDeactivate: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.openModalActivateDeactivate = action.payload;
    },
    updateOpenDrawerLift: (state, action: PayloadAction<boolean>) => {
      state.openDrawerLift = action.payload;
    },
    updateOpenDrawerLiftConfiguration: (state, action: PayloadAction<boolean>) => {
      state.openDrawerLiftConfiguration = action.payload;
    },
    updateLiftAlert: (state, action: PayloadAction<Object>) => {
      state.liftAlert = action.payload;
    },
    updateLiftTableParams: (state, action: PayloadAction<Object>) => {
      state.liftTableParams = action.payload;
    },
    updateLiftRefreshTable: (state, action: PayloadAction<boolean>) => {
      state.liftRefreshTable = action.payload;
    },
    updateLiftList: (state, action: PayloadAction<any>) => {
      state.liftList = action.payload;
    },
    updateLiftBreadcrumb: (state, action: PayloadAction<any>) => {
      state.liftBreadcrumb = action.payload;
    },
    updateLiftUpdateBreadcrumb: (state, action: PayloadAction<Object>) => {
      state.liftUpdateBreadcrumb = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateLift,
  updateOpenModalCreateNewLift,
  updateOpenModalEditLift,
  updateOpenModalDeleteLift,
  updateOpenModalActivateDeactivate,
  updateOpenDrawerLift,
  updateLiftAlert,
  updateLiftTableParams,
  updateLiftRefreshTable,
  updateLiftList,
  updateLiftBreadcrumb,
  updateLiftUpdateBreadcrumb,
  updateOpenDrawerLiftConfiguration
} = liftSlice.actions;

export default liftSlice.reducer;
