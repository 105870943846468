/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BankState {
  bank: Object;
  idBankSelected: String;
  offsetsTable: Array<any>;
  generateOffsetsConfirm: boolean;
  openModalGenerateOffsets: boolean;
  nameBankSelected: String;
  openModalCreateNewBank: boolean;
  openModalEditBank: boolean;
  openModalDeleteBank: boolean;
  openModalOffsetsTable: boolean;
  openModalActivateDeactivate: boolean;
  openDrawerLiftsPosition: boolean,
  openDrawerBank: boolean;
  openDrawerBankFloorNames: boolean;
  openDrawerBankCheckOffsets: boolean;
  bankAlert: Object;
  bankTableParams: Object;
  bankRefreshTable: boolean;
  bankLiftsRefreshTable: boolean;
  bankList: Array<any>;
  bankBreadcrumb: Array<any>;
  bankUpdateBreadcrumb: Object;
}

const initialState: BankState = {
  bank: {},
  idBankSelected: '',
  offsetsTable: [],
  generateOffsetsConfirm: false,
  openModalGenerateOffsets: false,
  nameBankSelected: '',
  openModalCreateNewBank: false,
  openModalEditBank: false,
  openModalDeleteBank: false,
  openModalOffsetsTable: false,
  openModalActivateDeactivate: false,
  openDrawerLiftsPosition: false,
  openDrawerBank: false,
  openDrawerBankFloorNames: false,
  openDrawerBankCheckOffsets: false,
  bankAlert: {},
  bankTableParams: {
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ["10", "20", "50", "100"],
    },
  },
  bankRefreshTable: false,
  bankLiftsRefreshTable: false,
  bankList: [],
  bankBreadcrumb: [],
  bankUpdateBreadcrumb: {
    update: false,
    id: '',
    name: '',
  },
};

export const bankSlice = createSlice({
  name: 'bank',
  initialState,
  reducers: {
    updateBank: (state, action: PayloadAction<Object>) => {
      state.bank = action.payload;
    },
    updateIdBankSelected: (state, action: PayloadAction<String>) => {
      state.idBankSelected = action.payload;
    },
    updateOffsetsTable: (state, action: PayloadAction<any>) => {
      state.offsetsTable = action.payload;
    },
    updateGenerateOffsetsConfirm: (state, action: PayloadAction<boolean>) => {
      state.generateOffsetsConfirm = action.payload;
    },
    updateOpenModalGenerateOffsets: (state, action: PayloadAction<boolean>) => {
      state.openModalGenerateOffsets = action.payload;
    },
    updateNameBankSelected: (state, action: PayloadAction<String>) => {
      state.nameBankSelected = action.payload;
    },
    updateOpenModalCreateNewBank: (state, action: PayloadAction<boolean>) => {
      state.openModalCreateNewBank = action.payload;
    },
    updateOpenModalEditBank: (state, action: PayloadAction<boolean>) => {
      state.openModalEditBank = action.payload;
    },
    updateOpenModalDeleteBank: (state, action: PayloadAction<boolean>) => {
      state.openModalDeleteBank = action.payload;
    },
    updateOpenModalOffsetsTable: (state, action: PayloadAction<boolean>) => {
      state.openModalOffsetsTable = action.payload;
    },
    updateOpenModalActivateDeactivate: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.openModalActivateDeactivate = action.payload;
    },
    updateOpenDrawerLiftsPosition: (state, action: PayloadAction<boolean>) => {
      state.openDrawerLiftsPosition = action.payload;
    },
    updateOpenDrawerBank: (state, action: PayloadAction<boolean>) => {
      state.openDrawerBank = action.payload;
    },
    updateOpenDrawerBankFloorNames: (state, action: PayloadAction<boolean>) => {
      state.openDrawerBankFloorNames = action.payload;
    },
    updateOpenDrawerBankCheckOffsets: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.openDrawerBankCheckOffsets = action.payload;
    },
    updateBankAlert: (state, action: PayloadAction<Object>) => {
      state.bankAlert = action.payload;
    },
    updateBankTableParams: (state, action: PayloadAction<Object>) => {
      state.bankTableParams = action.payload;
    },
    updateBankRefreshTable: (state, action: PayloadAction<boolean>) => {
      state.bankRefreshTable = action.payload;
    },
    updateBankLiftsRefreshTable: (state, action: PayloadAction<boolean>) => {
      state.bankLiftsRefreshTable = action.payload;
    },
    updateBankList: (state, action: PayloadAction<any>) => {
      state.bankList = action.payload;
    },
    updateBankBreadcrumb: (state, action: PayloadAction<any>) => {
      state.bankBreadcrumb = action.payload;
    },
    updateBankUpdateBreadcrumb: (state, action: PayloadAction<Object>) => {
      state.bankUpdateBreadcrumb = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateBank,
  updateIdBankSelected,
  updateOffsetsTable,
  updateGenerateOffsetsConfirm,
  updateOpenModalGenerateOffsets,
  updateNameBankSelected,
  updateOpenModalCreateNewBank,
  updateOpenModalEditBank,
  updateOpenModalDeleteBank,
  updateOpenModalOffsetsTable,
  updateOpenModalActivateDeactivate,
  updateOpenDrawerLiftsPosition,
  updateOpenDrawerBank,
  updateOpenDrawerBankFloorNames,
  updateOpenDrawerBankCheckOffsets,
  updateBankAlert,
  updateBankTableParams,
  updateBankRefreshTable,
  updateBankLiftsRefreshTable,
  updateBankList,
  updateBankBreadcrumb,
  updateBankUpdateBreadcrumb,
} = bankSlice.actions;

export default bankSlice.reducer;
