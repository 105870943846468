import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Button, Form, Input, Spin, Divider } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuildingCircleCheck,
  faUserShield,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { usePutRoleMutation } from "../../../redux/roles/roleAPI";
import {
  updateOpenModalEditRole,
  updateRoleAlert,
} from "../../../redux/roles/roleSlice";
// COMPONENTS
import RolesDinamicRolesTree from "./RolesDinamicRolesTree";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

library.add(faBuildingCircleCheck, faUserShield, faUserLock);

function RolesEditRoleForm() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch();
  const { role, openModalEditRole } = useSelector((state: any) => state.role);
  const [t] = useTranslation("global");
  const [menu, setMenu] = useState<any>({});
  const [form] = Form.useForm();
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    putRole,
    {
      isSuccess: isSuccessPutRole,
      isError: isErrorPutRole,
      error: errorPutRole,
      isLoading: isLoadingPutRole,
      reset: resetPutRole,
    },
  ] = usePutRoleMutation();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onReset = () => {
    form.resetFields();
  };

  const handleCloseModal = () => {
    dispatch(updateOpenModalEditRole(false));
  };

  const onFinish = async (values: any) => {
    const token = await GETJwtToken();
    const BODY = {
      id: role.id,
      body: {
        active: role.active,
        menu: JSON.stringify(menu),
        name: values.name,
      },
      token,
    };
    putRole(BODY);
  };

  const onClose = () => {
    onReset();
    dispatch(updateOpenModalEditRole(false));
  };

  const onFill = () => {
    if (role) {
      form.setFieldsValue({
        name: role.name || "",
      });
      if (role.menu) {
        setMenu(role.menu);
      }
    }
  };

  // ************************************************* */
  // USE EFFECT ************************************** */

  useEffect(() => {
    if (openModalEditRole) {
      onFill();
      resetPutRole();
    }
  }, [openModalEditRole]);

  useEffect(() => {
    if (isSuccessPutRole) {
      setTimeout(() => {
        dispatch(
          updateRoleAlert({
            title: t("general.success"),
            description: t("organizationManagement.roles.roleUpdated"),
            status: "success",
          })
        );
      }, 150);
      dispatch(updateOpenModalEditRole(false));
    }
    if (isErrorPutRole) {
      setTimeout(() => {
        dispatch(
          updateRoleAlert({
            title: t("general.error"),
            description: errorPutRole,
            status: "error",
          })
        );
      }, 150);
      dispatch(updateOpenModalEditRole(false));
    }
  }, [isSuccessPutRole, isErrorPutRole]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClose}
      closable={false}
      visible={openModalEditRole}
    >
      <Spin spinning={isLoadingPutRole} tip={t("general.loading")}>
        <div>
          <Divider orientation="left" className="generalStyles__drawerDivider">
            <h5>
              <FontAwesomeIcon
                icon={faUserShield}
                className="generalStyles__info generalStyles__mrFix"
              />
              {t("organizationManagement.editRole")}
            </h5>
          </Divider>
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <div className="container">
            <div
              className={
                theme === "dark"
                  ? "row drawer__box__dark"
                  : "row drawer__box__light"
              }
            >
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** NAME */}
                <div className="col-md-12 mt-3">
                  <div className="generalStyles__flex">
                    <div className="generalStyles__inputFlexName">
                      <span className="generalStyles__inputFlexRequired">
                        *
                      </span>
                      <span> {t("organizationManagement.roleName")}</span>
                    </div>
                    <div className="generalStyles__width100">
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: t(
                              "organizationManagement.formRequiredMessage.roleName"
                            ),
                          },
                          {
                            max: 50,
                            message: t(
                              "organizationManagement.formRequiredMessage.roleNameMax"
                            ),
                          },
                        ]}
                      >
                        <Input className="generalStyles__input" size="small" />
                      </Form.Item>
                    </div>
                    <div>
                      <div className="generalStyles__infoTooltipIconBlank" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/** ---------------------------------------------------- */}
            {/** ASSIGN TO ORGANIZATION */}
            {false && (
              <>
                <br />
                <Divider
                  orientation="left"
                  className="generalStyles__drawerDivider"
                >
                  <h5>
                    <FontAwesomeIcon
                      icon={faBuildingCircleCheck}
                      className="generalStyles__info generalStyles__mrFix"
                    />
                    {t("organizationManagement.assignToOrganization")}
                  </h5>
                </Divider>
                <div
                  className={
                    theme === "dark"
                      ? "row drawer__box__dark"
                      : "row drawer__box__light"
                  }
                >
                  <div className="col-12">
                    <Form.Item
                      name="organization"
                      label={
                        <>
                          <span className="drawer__text">
                            {t("organizationManagement.assignToOrganization")}
                          </span>
                        </>
                      }
                      rules={[
                        {
                          // required: true,
                          message: t(
                            "organizationManagement.formRequiredMessage.type"
                          ),
                        },
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </div>
                </div>
              </>
            )}
            {/** ************************************************* */}
            {/** ************************************************* */}
            <div>
              <Divider
                orientation="left"
                className="generalStyles__drawerDivider"
              >
                <h5>
                  <FontAwesomeIcon
                    icon={faUserLock}
                    className="generalStyles__info generalStyles__mrFix"
                  />
                  {t("organizationManagement.permissions")}
                </h5>
              </Divider>
            </div>
            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div
              className={
                theme === "dark" ? " drawer__box__dark" : " drawer__box__light"
              }
            >
              <div>
                <RolesDinamicRolesTree setMenu={setMenu} roleData={role} />
              </div>
            </div>
          </div>
          {/** ---------------------------------------------------- */}
          <div className="mt-4">
            <div>
              <Button onClick={handleCloseModal} className="buttonStyle__3">
                {t("general.close")}
              </Button>
              <Button
                htmlType="submit"
                disabled={isLoadingPutRole}
                className="buttonStyle__3"
                type="primary"
              >
                {t("general.save")}
              </Button>
            </div>
          </div>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default RolesEditRoleForm;
