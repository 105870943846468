import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Divider, Drawer, Button, Input, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  updateOpenFormSpecialSwitchCatalog,
  updateToolAlert,
} from "../../../redux/tools/toolsSlice";
import {
  usePostSpecialSwitchCatalogMutation,
  usePutSpecialSwitchCatalogMutation,
} from "../../../redux/configurationFiles/configurationFilesAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

function SpecialSwitchesCatalogForm() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [name, setName] = useState<any>("");
  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const {
    specialSwitchCatalog,
    openFormSpecialSwitchCatalog,
    openFormSpecialSwitchCatalogAction,
  } = useSelector((state: any) => state.tool);
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    postSpecialSwitchCatalog,
    {
      isSuccess: isSuccessPost,
      isLoading: isLoadingPost,
      isError: isErrorPost,
      error: errorPost,
      reset: resetPost,
    },
  ] = usePostSpecialSwitchCatalogMutation();
  const [
    putSpecialSwitchCatalog,
    {
      isSuccess: isSuccessPut,
      isLoading: isLoadingPut,
      isError: isErrorPut,
      error: errorPut,
      reset: resetPut,
    },
  ] = usePutSpecialSwitchCatalogMutation();
  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onFill = () => {
    setName(specialSwitchCatalog.name);
  };

  const onClickClose = () => {
    dispatch(updateOpenFormSpecialSwitchCatalog(false));
  };

  const onChangeName = (newValue: string) => {
    setName(newValue);
  };

  const onClickSave = async () => {
    const token = await GETJwtToken();
    if (openFormSpecialSwitchCatalogAction === "create") {
      const BODY = {
        token,
        body: {
          name,
        },
      };
      postSpecialSwitchCatalog(BODY);
    } else if (openFormSpecialSwitchCatalogAction === "update") {
      const BODY = {
        token,
        id: specialSwitchCatalog.id,
        body: {
          name,
        },
      };
      putSpecialSwitchCatalog(BODY);
    }
  };
  // ************************************************* */
  // USE EFFECT ************************************** */

  useEffect(() => {
    if (openFormSpecialSwitchCatalog) {
      resetPost();
      resetPut();
      if (openFormSpecialSwitchCatalogAction === "update") {
        onFill();
      } else {
        setName("");
      }
    }
  }, [openFormSpecialSwitchCatalog]);

  useEffect(() => {
    if (isSuccessPost) {
      setTimeout(() => {
        dispatch(
          updateToolAlert({
            title: t("general.success"),
            description: t(
              "configuration.specialSwitchesConfiguration.specialSwitchCatalogCreated"
            ),
            status: "success",
          })
        );
      }, 150);
      onClickClose();
    }
    if (isErrorPost) {
      setTimeout(() => {
        dispatch(
          updateToolAlert({
            title: t("general.error"),
            description: errorPost,
            status: "error",
          })
        );
      }, 150);
      onClickClose();
    }
  }, [isSuccessPost, isErrorPost]);

  useEffect(() => {
    if (isSuccessPut) {
      setTimeout(() => {
        dispatch(
          updateToolAlert({
            title: t("general.success"),
            description: t(
              "configuration.specialSwitchesConfiguration.specialSwitchCatalogUpdated"
            ),
            status: "success",
          })
        );
      }, 150);
      onClickClose();
    }
    if (isErrorPut) {
      setTimeout(() => {
        dispatch(
          updateToolAlert({
            title: t("general.error"),
            description: errorPut,
            status: "error",
          })
        );
      }, 150);
      onClickClose();
    }
  }, [isSuccessPut, isErrorPut]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width={isMobile ? "95%" : "35%"}
      placement="right"
      onClose={onClickClose}
      closable={false}
      visible={openFormSpecialSwitchCatalog}
    >
      <Spin spinning={isLoadingPost || isLoadingPut} tip={t("general.loading")}>
        {/** ************************************************* */}
        {/** SPECIAL SWITCH FORM */}
        <div>
          <Divider orientation="left" className="generalStyles__drawerDivider">
            <h5>
              <FontAwesomeIcon
                icon={faGear}
                className="generalStyles__info generalStyles__mrFix"
              />
              {openFormSpecialSwitchCatalogAction === "create"
                ? t(
                    "configuration.specialSwitchesConfiguration.createNewCatalog"
                  )
                : t("configuration.specialSwitchesConfiguration.updateCatalog")}
            </h5>
          </Divider>
        </div>
        <div
          className={
            theme === "dark" ? " drawer__box__dark" : " drawer__box__light"
          }
        >
          {/** NAME */}
          <div className="col-md-12">
            <div className="generalStyles__flex">
              <div className="generalStyles__inputFlexName">
                <span className="generalStyles__inputFlexRequired">*</span>
                <span className="drawer__text">{t("general.name")}</span>
              </div>
              <div className="generalStyles__width100">
                <Input
                  className=""
                  size="small"
                  value={name}
                  onChange={(e) => {
                    onChangeName(e.currentTarget.value);
                  }}
                />
              </div>
              <div className="generalStyles__infoTooltipIconBlank" />
            </div>
          </div>
        </div>
        <br />
        {/** ************************************************* */}
        {/** CLOSE AND SUBMIT BUTTONS */}
        <div className="mt-4 generalStyles__width100">
          <div>
            <div>
              <Button onClick={onClickClose} className="buttonStyle__3">
                {t("organizationManagement.close")}
              </Button>
              <Button
                onClick={onClickSave}
                className="buttonStyle__3"
                type="primary"
                disabled={name === "" || name === undefined}
              >
                {t("general.save")}
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Drawer>
  );
}

export default SpecialSwitchesCatalogForm;
