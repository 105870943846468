import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Divider, Drawer, Tag, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faBuildingLock,
  faCheckCircle,
  faCircleExclamation,
  faBan,
  faBuildingCircleCheck,
  faUserShield,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerUser } from "../../../redux/users/userSlice";

library.add(
  faUser,
  faBuildingCircleCheck,
  faBuildingLock,
  faCheckCircle,
  faCircleExclamation,
  faBan,
  faUserShield,
  faUserLock
);

function UserDrawer() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch();
  const { user, openDrawerUser } = useSelector((state: any) => state.user);
  const [data, setData] = useState<any>({});
  const [t] = useTranslation("global");
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onClickClose = () => {
    dispatch(updateOpenDrawerUser(false));
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (user) {
      setData(user);
    }
  }, [user]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClickClose}
      closable={false}
      visible={openDrawerUser}
    >
      {data && (
        <div>
          {/** ************************************************* */}
          <div>
            <Divider
              orientation="left"
              className="generalStyles__drawerDivider"
            >
              <h5>
                <FontAwesomeIcon
                  icon={faUser}
                  className="generalStyles__info generalStyles__mrFix"
                />
                {t("userManagement.userInformation")}
              </h5>
            </Divider>
          </div>
          <div
            className={
              theme === "dark" ? " drawer__box__dark" : " drawer__box__light"
            }
          >
            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("userManagement.userId")}:
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">{data.id ? data.id : "--"}</p>
              </div>
            </div>
            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("userManagement.firstName")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data.first_name ? data.first_name : "--"}
                </p>
              </div>
            </div>
            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("userManagement.middleName")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data.middle_name ? data.middle_name : "--"}
                </p>
              </div>
            </div>
            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("userManagement.lastName")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data.last_name ? data.last_name : "--"}
                </p>
              </div>
            </div>
            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("userManagement.email")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">{data.email ? data.email : "--"}</p>
              </div>
            </div>

            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("userManagement.address")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data.contact_info &&
                  data.contact_info[0] &&
                  data.contact_info[0].address
                    ? data.contact_info[0].address
                    : "--"}
                </p>
              </div>
            </div>

            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("userManagement.phone")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data.contact_info &&
                  data.contact_info[0] &&
                  data.contact_info[0].phone
                    ? data.contact_info[0].phone
                    : "--"}
                </p>
              </div>
            </div>

            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("userManagement.dialCode")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data.contact_info &&
                  data.contact_info[0] &&
                  data.contact_info[0].phone_code
                    ? data.contact_info[0].phone_code
                    : "--"}
                </p>
              </div>
            </div>
            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("userManagement.status")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data.status ? (
                    <>
                      {data.status === "ACTIVE" && (
                        <Tag
                          color="success"
                          icon={
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="generalStyles__mrFix"
                            />
                          }
                          className="drawer__tag"
                        >
                          {t("userManagement.active")}
                        </Tag>
                      )}
                      {data.status === "NEW" && (
                        <Tag
                          color="warning"
                          icon={
                            <FontAwesomeIcon
                              icon={faCircleExclamation}
                              className="generalStyles__mrFix"
                            />
                          }
                          className="drawer__tag"
                        >
                          {t("userManagement.newUser")}
                        </Tag>
                      )}
                      {data.status === "BLOCKED" && (
                        <Tag
                          color="error"
                          icon={
                            <FontAwesomeIcon
                              icon={faBan}
                              className="generalStyles__mrFix"
                            />
                          }
                          className="drawer__tag"
                        >
                          {t("userManagement.lock")}
                        </Tag>
                      )}
                    </>
                  ) : (
                    "--"
                  )}
                </p>
              </div>
            </div>
          </div>
          <br />
          {/** ************************************************* */}
          {/** ************************************************* */}
          <div>
            <Divider
              orientation="left"
              className="generalStyles__drawerDivider"
            >
              <h5>
                <FontAwesomeIcon
                  icon={faBuildingCircleCheck}
                  className="generalStyles__info generalStyles__mrFix"
                />
                {t("organizationManagement.organizationInformation")}
              </h5>
            </Divider>
          </div>
          <div
            className={
              theme === "dark" ? " drawer__box__dark" : " drawer__box__light"
            }
          >
            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("organizationManagement.organizationId")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data.org && data.org.id ? data.org.id : "--"}
                </p>
              </div>
            </div>
            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("organizationManagement.name")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data.org && data.org.name ? data.org.name : "--"}
                </p>
              </div>
            </div>
            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("organizationManagement.type")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data.org && data.org.type ? (
                    <>
                      {data.org.type === "ORG"
                        ? "ORGANIZATION"
                        : "SUB ORGANIZATION"}
                    </>
                  ) : (
                    "--"
                  )}
                </p>
              </div>
            </div>
            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("organizationManagement.address")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data.org && data.org.address ? data.org.address : "--"}
                </p>
              </div>
            </div>
            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("organizationManagement.country")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data.org && data.org.country ? data.org.country : "--"}
                </p>
              </div>
            </div>
            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("organizationManagement.state")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data.org && data.org.state ? data.org.state : "--"}
                </p>
              </div>
            </div>
            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("organizationManagement.city")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data.org && data.org.city ? data.org.city : "--"}
                </p>
              </div>
            </div>
            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("organizationManagement.phoneNumber")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data.org && data.org.phone ? data.org.phone : "--"}
                </p>
              </div>
            </div>
            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("organizationManagement.dialCode")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data.org && data.org.phone_code ? data.org.phone_code : "--"}
                </p>
              </div>
            </div>
          </div>
          <br />
          {/** ************************************************* */}
          {/** ************************************************* */}
          <div>
            <Divider
              orientation="left"
              className="generalStyles__drawerDivider"
            >
              <h5>
                <FontAwesomeIcon
                  icon={faUserShield}
                  className="generalStyles__info generalStyles__mrFix"
                />
                {t("userManagement.roleInformation")}
              </h5>
            </Divider>
          </div>
          <div
            className={
              theme === "dark" ? " drawer__box__dark" : " drawer__box__light"
            }
          >
            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("userManagement.roleId")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data.role && data.role.id ? data.role.id : "--"}
                </p>
              </div>
            </div>
            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {" "}
                  {t("userManagement.name")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data.role && data.role.name ? data.role.name : "--"}
                </p>
              </div>
            </div>
            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {" "}
                  {t("userManagement.status")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data.role && data.role.active ? (
                    <Tag
                      color="success"
                      icon={
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="generalStyles__mrFix"
                        />
                      }
                      className="drawer__tag"
                    >
                      Active
                    </Tag>
                  ) : (
                    <Tag
                      color="error"
                      icon={
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="generalStyles__mrFix"
                        />
                      }
                      className="drawer__tag"
                    >
                      Locked
                    </Tag>
                  )}{" "}
                </p>
              </div>
            </div>
          </div>
          <br />
          {/** ************************************************* */}
          {/** ************************************************* */}
          <div>
            <Divider
              orientation="left"
              className="generalStyles__drawerDivider"
            >
              <h5>
                <FontAwesomeIcon
                  icon={faUserLock}
                  className="generalStyles__info generalStyles__mrFix"
                />
                {t("organizationManagement.permissions")}
              </h5>
            </Divider>
          </div>
          <div
            className={
              theme === "dark" ? " drawer__box__dark" : " drawer__box__light"
            }
          >
            {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
            <div className="generalStyles__flex">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  --
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">--</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {/** CLOSE AND SUBMIT BUTTONS */}
      <div className="mt-4 generalStyles__width100">
        <div>
          <Button
            type="default"
            onClick={onClickClose}
            className="buttonStyle__5"
          >
            {t("organizationManagement.close")}
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

export default UserDrawer;
