import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Divider, Drawer, Button, Spin, Select } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCube } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  updateOpenDrawerCreateDataBlock,
  updateConfigurationFileAlert,
} from "../../../redux/configurationFiles/configurationFilesSlice";
import {
  usePostDataBlockCompleteMutation,
  useLazyGetDataBlockTypesQuery,
} from "../../../redux/configurationFiles/configurationFilesAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import ConfigurationFilesSelectOffsets from "./ConfigurationFilesSelectOffsets";

function ConfigurationFilesDataBlockForm() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const { Option } = Select;

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [dataBlock, setDataBlock] = useState("");
  const [dataBlocksCatalog, setDataBlocksCatalog] = useState<any>([]);
  const [offsetsData, setOffsetsData] = useState([]);

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { configurationFile, openDrawerCreateDataBlock } = useSelector(
    (state: any) => state.configurationFile
  );
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    postDataBlock,
    {
      isSuccess: isSuccessPost,
      isLoading: isLoadingPost,
      isError: isErrorPost,
      error: errorPost,
      reset: resetPost,
    },
  ] = usePostDataBlockCompleteMutation();

  const [
    triggerGetDataBlocks,
    { data: dataDataBlocks, isLoading: isLoadingDataBlocks },
  ] = useLazyGetDataBlockTypesQuery();
  // ************************************************ */
  // FUNCTIONS ************************************** */

  const onClickClose = () => {
    dispatch(updateOpenDrawerCreateDataBlock(false));
  };

  const onClickSave = async () => {
    const token = await GETJwtToken();
    const BODY = {
      token,
      body: offsetsData,
    };
    postDataBlock(BODY);
  };

  const onChangeDataBlock = (newValue: string) => {
    setDataBlock(newValue);
  };

  const getInitialData = async () => {
    const token = await GETJwtToken();
    triggerGetDataBlocks({
      token,
    });
  };

  const onChangeOffsets = (data: any) => {
    if (data && data.dataBlock) {
      const copy = structuredClone(data.data);
      const blockData: any = {
        banks: [],
        configuration_id: configurationFile.id,
        data_block_type_id: dataBlock,
        definitions: [],
        offsets: [],
      };
      for (
        let indexData = 0;
        indexData < configurationFile.lifts;
        indexData += 1
      ) {
        // eslint-disable-next-line no-loop-func
        copy.map((blockOffset: any, indexBlockOffset: number) => {
          blockData.offsets.push({
            byte_count: blockOffset[`total${blockOffset.name}`],
            data_block_id: dataBlock,
            definitions: [],
            definitions2delete: [],
            exceptions: "",
            initial_value: 0,
            last_value: 0,
            type: blockOffset.name,
            name: `Car ${indexBlockOffset + 1}`,
            offset_type_id: blockOffset.offsetTypeId,
            position: indexData + 1,
          });
          return true;
        });
      }
      // Build offsets per data block
      setOffsetsData(blockData);
    }
  };
  // ************************************************* */
  // USE EFFECT ************************************** */

  useEffect(() => {
    if (openDrawerCreateDataBlock) {
      resetPost();
      getInitialData();
    }
  }, [openDrawerCreateDataBlock]);

  useEffect(() => {
    if (isSuccessPost) {
      setTimeout(() => {
        dispatch(
          updateConfigurationFileAlert({
            title: t("general.success"),
            description: t("configuration.dataBlock.dataBlockCreated"),
            status: "success",
          })
        );
      }, 150);
      onClickClose();
    }
    if (isErrorPost) {
      setTimeout(() => {
        dispatch(
          updateConfigurationFileAlert({
            title: t("general.error"),
            description: errorPost,
            status: "error",
          })
        );
      }, 150);
      onClickClose();
    }
  }, [isSuccessPost, isErrorPost]);

  useEffect(() => {
    if (dataDataBlocks && dataDataBlocks.data) {
      setDataBlocksCatalog(dataDataBlocks.data);
    }
  }, [dataDataBlocks]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width={isMobile ? "95%" : "35%"}
      placement="right"
      onClose={onClickClose}
      closable={false}
      visible={openDrawerCreateDataBlock}
    >
      <Spin
        spinning={isLoadingPost || isLoadingDataBlocks}
        tip={t("general.loading")}
      >
        {/** ************************************************* */}
        {/** DATA BLOCK FORM */}
        <div>
          <Divider orientation="left" className="generalStyles__drawerDivider">
            <h5>
              <FontAwesomeIcon
                icon={faCube}
                className="generalStyles__info generalStyles__mrFix"
              />
              {t("configuration.dataBlock.createNewDataBlock")}
            </h5>
          </Divider>
        </div>
        <div
          className={
            theme === "dark" ? " drawer__box__dark" : " drawer__box__light"
          }
        >
          {/** DATA BLOCK */}
          <div className="col-md-12 mt-3">
            <div className="generalStyles__flex">
              <div className="generalStyles__inputFlexName">
                <span className="generalStyles__inputFlexRequired">*</span>
                <span>{t("configuration.dataBlock.dataBlock")}</span>
              </div>
              <div className="generalStyles__width100">
                <Select
                  value={dataBlock}
                  onChange={onChangeDataBlock}
                  className="generalStyles__width100"
                  size="small"
                >
                  {dataBlocksCatalog.map((item: any) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="generalStyles__infoTooltipIconBlank" />
            </div>
          </div>
        </div>
        <div>
          {dataBlock && (
            <ConfigurationFilesSelectOffsets
              dataBlock="Offsets"
              onChangeOffsets={onChangeOffsets}
            />
          )}
        </div>
        <br />
        {/** ************************************************* */}
        {/** CLOSE AND SUBMIT BUTTONS */}
        <div className="mt-4 generalStyles__width100">
          <div>
            <div>
              <Button onClick={onClickClose} className="buttonStyle__3">
                {t("organizationManagement.close")}
              </Button>
              <Button
                onClick={onClickSave}
                className="buttonStyle__3"
                type="primary"
              >
                {t("general.save")}
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Drawer>
  );
}

export default ConfigurationFilesDataBlockForm;
