/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import {
  Table,
  Alert,
  Menu,
  Dropdown,
  Space,
  Tag,
  Empty,
  Input,
  Button,
  Checkbox,
  Select,
} from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsis,
  faTrash,
  faLock,
  faUnlock,
  faCheckCircle,
  faBan,
  faEdit,
  faSearch,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetOrganizationsQuery } from "../../../redux/organizations/organizationAPI";
import { useLazyGetRolesQuery } from "../../../redux/roles/roleAPI";
import {
  updateOpenModalDeleteRole,
  updateOpenModalEditRole,
  updateOpenModalBlockUnlockRole,
  updateOpenDrawerRole,
  updateRole,
  updateRoleTableParams,
} from "../../../redux/roles/roleSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import validatePermission from "../../../utils/validatePermissions";
// UTILS
import { getTableRowClass } from "../../../utils/utils";

const { Option } = Select;

const RolesTable = () => {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch();
  const [roles, setRoles] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [t] = useTranslation("global");
  const { permissions } = useSelector((state: any) => state.user);
  const { roleTableParams, roleRefreshTable, cleanFilters } = useSelector(
    (state: any) => state.role
  );
  const { Column } = Table;
  const { theme } = useSelector((state: any) => state.home);

  const [filterName, setFilterName] = useState("");
  const [filterOrganizationsList, setFilterOrganizationsList] = useState<any>(
    []
  );
  const [filterOrganizationName, setFilterOrganizationName] = useState("");
  const [filterStatusActive, setFilterStatusActive] = useState(false);
  const [filterStatusInactive, setFilterStatusInactive] = useState(false);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // ROW ACTIONS ************************************ */

  const manageActions = () => {
    const BLANK = {
      label: (
        <div className="generalStyles__tableActionBox">
          <span>
            <FontAwesomeIcon
              className="generalStyles__error generalStyles__mrFix"
              icon={faBan}
            />
            {t("general.noActions")}
          </span>
        </div>
      ),
      key: "1",
    };

    const EDIT = validatePermission("roles-and-permissionsupdate", permissions)
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenModalEdit();
              }}
              onKeyDown={() => {
                handleOpenModalEdit();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faEdit}
                />
                {t("organizationManagement.edit")}
              </span>
            </div>
          ),
          key: "1",
        }
      : null;

    const LOCK_UNLOCK = validatePermission(
      "roles-and-permissionslock-unlock",
      permissions
    )
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenModalLockUnlock();
              }}
              onKeyDown={() => {
                handleOpenModalLockUnlock();
              }}
            >
              {selectedItem.active ? (
                <span>
                  <FontAwesomeIcon
                    className="generalStyles__info generalStyles__mrFix"
                    icon={faLock}
                  />
                  {t("organizationManagement.block")}
                </span>
              ) : (
                <span>
                  <FontAwesomeIcon
                    className="generalStyles__info generalStyles__mrFix"
                    icon={faUnlock}
                  />
                  {t("organizationManagement.unlock")}
                </span>
              )}
            </div>
          ),
          key: "2",
        }
      : null;

    const DELETE = validatePermission(
      "roles-and-permissionsdelete",
      permissions
    )
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenModalDelete();
              }}
              onKeyDown={() => {
                handleOpenModalDelete();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faTrash}
                />
                {t("organizationManagement.delete")}
              </span>
            </div>
          ),
          key: "3",
        }
      : null;

    // RETURN OBJECT WITH VALID ACTIONS
    if (selectedItem) {
      if (
        selectedItem &&
        selectedItem.name &&
        selectedItem.name !== process.env.REACT_APP_ROLE_MASTER_NAME
      ) {
        if (EDIT || DELETE || LOCK_UNLOCK) {
          return [EDIT, DELETE, LOCK_UNLOCK];
        }
      }
    }
    return [BLANK];
  };

  const menu = <Menu items={manageActions()} />;

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [triggerGetRolesTable, { data, isLoading, isError, isFetching }] =
    useLazyGetRolesQuery();

  const [triggerGetOrganizations, { data: dataOrganizations }] =
    useLazyGetOrganizationsQuery();
  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleOpenModalDelete = () => {
    dispatch(updateRole(selectedItem));
    dispatch(updateOpenModalDeleteRole(true));
  };

  const handleOpenModalEdit = () => {
    dispatch(updateRole(selectedItem));
    dispatch(updateOpenModalEditRole(true));
  };

  const handleOpenModalLockUnlock = () => {
    dispatch(updateRole(selectedItem));
    dispatch(updateOpenModalBlockUnlockRole(true));
  };

  const onCellClick = (record: any) => {
    if (record) {
      dispatch(updateRole(record));
      dispatch(updateOpenDrawerRole(true));
    }
  };

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: any
  ) => {
    dispatch(
      updateRoleTableParams({
        pagination,
        filters,
        ...sorter,
      })
    );
    //
    const token = await GETJwtToken();
    triggerGetRolesTable({
      page: pagination.current,
      limit: pagination.pageSize,
      token,
    });
  };

  const getSearchAndFilters = () => {
    let searchAndFilterString = "";
    // NAME
    if (filterName !== "") {
      searchAndFilterString = searchAndFilterString.concat(
        "&filter_target=name&filter=",
        filterName
      );
    }
    // ORGANIZATION
    if (filterOrganizationName !== "") {
      searchAndFilterString = searchAndFilterString.concat(
        "&filter_target=org_id&filter=",
        filterOrganizationName
      );
    }
    // STATUS ACTIVE
    if (filterStatusActive && !filterStatusInactive) {
      searchAndFilterString = searchAndFilterString.concat(
        "&filter_target=active&filter=true"
      );
    }
    // STATUS INACTIVE
    if (!filterStatusActive && filterStatusInactive) {
      searchAndFilterString = searchAndFilterString.concat(
        "&filter_target=active&filter=false"
      );
    }
    // STATUS
    if (searchAndFilterString.length > 3) {
      return searchAndFilterString;
    }
    return "";
  };

  const refreshTable = async () => {
    const searchAndFilterString = getSearchAndFilters();
    dispatch(
      updateRoleTableParams({
        pagination: {
          current: 1,
          pageSize: 10,
          total: roleTableParams.pagination.total,
        },
        filters: {},
        sorter: {},
      })
    );
    //
    const token = await GETJwtToken();
    triggerGetRolesTable({
      page: 1,
      limit: 10,
      token,
      searchAndFilterString,
    });
  };

  const onClickCleanFilters = async () => {
    setFilterName("");
    setFilterOrganizationName("");
    setFilterStatusInactive(false);
    setFilterStatusActive(false);
    dispatch(
      updateRoleTableParams({
        pagination: {
          current: 1,
          pageSize: 10,
          total: roleTableParams.pagination.total,
        },
        filters: {},
        sorter: {},
      })
    );
    //
    const token = await GETJwtToken();
    triggerGetRolesTable({
      page: 1,
      limit: 10,
      token,
    });
  };

  const getInitialData = async () => {
    const token = await GETJwtToken();
    triggerGetRolesTable({
      page: roleTableParams.pagination.current,
      limit: roleTableParams.pagination.pageSize,
      token,
    });
    triggerGetOrganizations({
      token,
    });
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (data && data.data && data.data.length > 0) {
      dispatch(
        updateRoleTableParams({
          ...roleTableParams,
          pagination: {
            ...roleTableParams.pagination,
            total: data.total,
          },
        })
      );
      setRoles(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      setRoles([]);
    }
  }, [isError]);

  useEffect(() => {
    if (roleRefreshTable) {
      refreshTable();
    }
  }, [roleRefreshTable]);

  useEffect(() => {
    if (cleanFilters) {
      onClickCleanFilters();
    }
  }, [cleanFilters]);

  useEffect(() => {
    if (dataOrganizations && dataOrganizations.data) {
      setFilterOrganizationsList(dataOrganizations.data);
    }
  }, [dataOrganizations]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div className="mt-3">
      {!isError ? (
        <>
          {validatePermission("roles-and-permissionsread", permissions) ? (
            <Table
              rowClassName={(record, index) => getTableRowClass(index, theme)}
              className="customTable"
              dataSource={roles}
              loading={isLoading || isFetching}
              rowKey={(record) => record.id}
              size="small"
              pagination={roleTableParams.pagination}
              onChange={handleTableChange}
              scroll={{ x: 900 }}
            >
              {/** NAME */}
              <Column
                onCell={(record) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                })}
                title={t("general.name")}
                dataIndex="name"
                key="name"
                className=""
                width="200px"
                filterIcon={<FontAwesomeIcon icon={faSearch} />}
                filterDropdown={() => (
                  <div className="generalStyles__searchDropdown">
                    <Input
                      style={{ marginBottom: 8, display: "block" }}
                      placeholder={t("general.name")}
                      onChange={(e) => {
                        setFilterName(e.target.value);
                      }}
                      value={filterName}
                    />
                    <div className="generalStyles__spaceBetween">
                      <div />
                      <div>
                        <Button
                          type="primary"
                          size="small"
                          style={{ width: 90 }}
                          onClick={() => {
                            refreshTable();
                          }}
                        >
                          Search
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                render={(text) => (
                  <>
                    <div className="generalStyles__ellipsis__200px">{text}</div>
                  </>
                )}
              />
              {/** ORGANIZATION */}
              <Column
                onCell={(record) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                })}
                title={t("organizationManagement.organization")}
                dataIndex="organization"
                key="organization"
                className=""
                width="200px"
                filterIcon={<FontAwesomeIcon icon={faFilter} />}
                filterDropdown={() => (
                  <div className="generalStyles__searchDropdown">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      size="small"
                      allowClear
                      style={{ width: 250 }}
                      filterOption={(input: any, option: any) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(value) => {
                        setFilterOrganizationName(value);
                      }}
                      placeholder="Organization"
                    >
                      {filterOrganizationsList.map((item: any) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                    <div className="generalStyles__flexEnd mt-3">
                      <div>
                        <Button
                          type="primary"
                          size="small"
                          style={{ width: 90 }}
                          onClick={() => {
                            refreshTable();
                          }}
                        >
                          Filter
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                render={(text, record: any) => (
                  <>
                    <div className="">
                      {record && record.orgs && record.orgs.name
                        ? record.orgs.name
                        : ""}
                    </div>
                  </>
                )}
              />
              {/** STATUS */}
              <Column
                onCell={(record) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                })}
                title={t("organizationManagement.status")}
                dataIndex="status"
                key="status"
                className=""
                width="200px"
                filterIcon={<FontAwesomeIcon icon={faFilter} />}
                filterDropdown={() => (
                  <div className="generalStyles__searchDropdown">
                    <Checkbox
                      onChange={(e: any) => {
                        setFilterStatusActive(e.target.checked);
                      }}
                    >
                      {t("general.active")}
                    </Checkbox>
                    <br />
                    <Checkbox
                      onChange={(e: any) => {
                        setFilterStatusInactive(e.target.checked);
                      }}
                    >
                      {t("general.inactive")}
                    </Checkbox>
                    <div className="generalStyles__flexEnd mt-3">
                      <div>
                        <Button
                          type="primary"
                          size="small"
                          style={{ width: 90 }}
                          onClick={() => {
                            refreshTable();
                          }}
                        >
                          Filter
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                render={(text, record: any) => (
                  <>
                    <div className="generalStyles__ellipsis__200px">
                      {record.active ? (
                        <Tag
                          color="success"
                          icon={
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="generalStyles__mrFix"
                            />
                          }
                          className="drawer__tag"
                        >
                          {t("organizationManagement.active")}
                        </Tag>
                      ) : (
                        <Tag
                          color="error"
                          icon={
                            <FontAwesomeIcon
                              icon={faBan}
                              className="generalStyles__mrFix"
                            />
                          }
                          className="drawer__tag"
                        >
                          {t("organizationManagement.block")}
                        </Tag>
                      )}
                    </div>
                  </>
                )}
              />
              {/** ACTIONS */}
              <Column
                title={t("organizationManagement.actions")}
                key="action"
                width="50px"
                render={(_: any, record: any) => (
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <Space
                      className="generalStyles__hoverCursor"
                      onClick={() => {
                        setSelectedItem(record);
                      }}
                    >
                      <FontAwesomeIcon icon={faEllipsis} />
                    </Space>
                  </Dropdown>
                )}
              />
            </Table>
          ) : (
            <>
              <div className="generalStyles__noAccessToListTable">
                <Empty
                  description={t(
                    "organizationManagement.listNotAvailableRoles"
                  )}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              </div>
            </>
          )}
        </>
      ) : (
        <Alert
          message={t("organizationManagement.errorDescription.error")}
          description={t("organizationManagement.errorDescription.text1")}
          type="error"
          showIcon
          className="mt-3"
        />
      )}
    </div>
  );
};

export default RolesTable;
