import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import {
  Form,
  Spin,
  Divider,
  Drawer,
  Input,
  Button,
  Select,
  InputNumber,
  Tooltip,
} from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faBuildingCircleCheck,
  faUserShield,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  updateOpenModalCreateNewUser,
  updateUserAlert,
} from "../../../redux/users/userSlice";
import { usePostUserMutation } from "../../../redux/users/userAPI";
import { useLazyGetOrganizationAndChildsQuery } from "../../../redux/organizations/organizationAPI";
import { useLazyGetRolesByOrganizationQuery } from "../../../redux/roles/roleAPI";
import { updateOrganizationAndChilds } from "../../../redux/organizations/organizationSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import OrganizationSelectOrganizationSubOrganization from "../../organizationManagement/organizations/OrganizationSelectOrganizationSubOrganization";

library.add(faUser, faBuildingCircleCheck);
const { Option } = Select;

function UsersCreateUserForm() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch();
  const { openModalCreateNewUser } = useSelector((state: any) => state.user);
  const { organizationAndChilds } = useSelector(
    (state: any) => state.organization
  );
  const [organizationsAndChildsIsLoading, setOrganizationsAndChildsIsLoading] =
    useState(false);
  const { userLogged } = useSelector((state: any) => state.home);
  const [form] = Form.useForm();
  const [masterID, setMasterID] = useState("");
  const [listRoles, setListRoles] = useState<any[]>([]);
  const [t] = useTranslation("global");
  const { theme } = useSelector((state: any) => state.home);
  const [
    isLoadingGetOrganizationAndChilds,
    setIsLoadingGetOrganizationAndChilds,
  ] = useState(false);
  const [
    postUser,
    {
      isSuccess: isSuccessPostUser,
      isLoading: isLoadingPostUser,
      isError: isErrorPostUser,
      error: errorPostUser,
      reset: resetPostUser,
    },
  ] = usePostUserMutation();
  const [
    triggerGetOrganizationAndChilds,
    {
      isError: isErrorGetOrganizationAndChilds,
      error: errorGetOrganizationAndChilds,
    },
  ] = useLazyGetOrganizationAndChildsQuery();
  const [
    triggerGetRoles,
    { data: dataGetRoles, isLoading: isLoadingGetRolesByOrganization },
  ] = useLazyGetRolesByOrganizationQuery();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const cleanOrganizationsList = () => {
    dispatch(
      updateOrganizationAndChilds({
        childs: [],
      })
    );
  };

  const onClose = () => {
    cleanOrganizationsList();
    dispatch(updateOpenModalCreateNewUser(false));
  };

  const onFinish = async (values: any) => {
    const token = await GETJwtToken();
    const BODY = {
      token,
      body: {
        contact_info: [
          {
            address: values.address,
            phone: values.phone.toString(),
            phone_code: values.dialCode.toString(),
            priority: 0,
          },
        ],
        email: values.email,
        first_name: values.firstName,
        middle_name: values.middleName,
        last_name: values.lastName,
        org_id:
          organizationAndChilds &&
          organizationAndChilds.data &&
          organizationAndChilds.data.idOrganizationSelected
            ? organizationAndChilds.data.idOrganizationSelected
            : masterID,
        role_id: values.role,
      },
    };
    postUser(BODY);
  };

  const handleCloseModal = () => {
    cleanOrganizationsList();
    dispatch(updateOpenModalCreateNewUser(false));
  };

  const getOrganizationAndChilds = async (orgId: string) => {
    const token = await GETJwtToken();
    setIsLoadingGetOrganizationAndChilds(true);
    const data = await triggerGetOrganizationAndChilds({
      orgId,
      token,
    }).unwrap();
    setIsLoadingGetOrganizationAndChilds(false);
    if (
      data &&
      data.data &&
      data.data.children &&
      data.data.children.length > 0
    ) {
      const BODY = {
        childs: [
          ...organizationAndChilds.childs,
          {
            level: organizationAndChilds.childs.length + 1,
            data: data.data.children,
            orgSelected: orgId,
          },
        ],
      };
      dispatch(updateOrganizationAndChilds(BODY));
    }
  };

  const launchGetRoles = async (value: string) => {
    const token = await GETJwtToken();
    let orgId = userLogged.org_id;
    if (value !== "") {
      orgId = value;
    }
    triggerGetRoles({ token, orgId });
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (openModalCreateNewUser) {
      resetPostUser();
      form.resetFields();
      cleanOrganizationsList();
      getOrganizationAndChilds(masterID);
      launchGetRoles("");
    }
  }, [openModalCreateNewUser]);

  useEffect(() => {
    if (isSuccessPostUser) {
      setTimeout(() => {
        dispatch(
          updateUserAlert({
            title: "Success",
            description: "User created successfully",
            status: "success",
          })
        );
      }, 150);
      cleanOrganizationsList();
      dispatch(updateOpenModalCreateNewUser(false));
    }
    if (isErrorPostUser) {
      setTimeout(() => {
        dispatch(
          updateUserAlert({
            title: "Error",
            description: errorPostUser,
            status: "error",
          })
        );
      }, 150);
      cleanOrganizationsList();
      dispatch(updateOpenModalCreateNewUser(false));
    }
  }, [isSuccessPostUser, isErrorPostUser]);

  useEffect(() => {
    if (isErrorGetOrganizationAndChilds) {
      setTimeout(() => {
        dispatch(
          updateUserAlert({
            title: "Error",
            description: errorGetOrganizationAndChilds,
            status: "error",
          })
        );
      }, 150);
      cleanOrganizationsList();
      dispatch(updateOpenModalCreateNewUser(false));
    }
  }, [isErrorGetOrganizationAndChilds]);

  useEffect(() => {
    if (dataGetRoles && dataGetRoles.data) {
      setListRoles(dataGetRoles.data);
    }
  }, [dataGetRoles]);

  useEffect(() => {
    if (userLogged && userLogged.org && userLogged.org.id) {
      setMasterID(userLogged.org.id);
    }
  }, [userLogged]);

  useEffect(() => {
    if (
      organizationAndChilds &&
      organizationAndChilds.data &&
      organizationAndChilds.data.idOrganizationSelected
    ) {
      launchGetRoles(organizationAndChilds.data.idOrganizationSelected);
    }
  }, [organizationAndChilds]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClose}
      closable={false}
      visible={openModalCreateNewUser}
    >
      <Spin
        spinning={
          isLoadingPostUser ||
          isLoadingGetOrganizationAndChilds ||
          isLoadingGetRolesByOrganization ||
          organizationsAndChildsIsLoading
        }
        tip={t("general.loading")}
      >
        <div>
          <Divider orientation="left" className="generalStyles__drawerDivider">
            <h5>
              <FontAwesomeIcon
                icon={faUser}
                className="generalStyles__info generalStyles__mrFix"
              />
              {t("userManagement.createNewUser")}
            </h5>
          </Divider>
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <div className="container">
            <div
              className={
                theme === "dark"
                  ? "row drawer__box__dark"
                  : "row drawer__box__light"
              }
            >
              {/** ---------------------------------------------------- */}
              {/** EMAIL */}
              <div className="col-md-12">
                <div className="generalStyles__flex">
                  <div className="generalStyles__inputFlexName">
                    <span className="generalStyles__inputFlexRequired">*</span>
                    <span className="drawer__text">
                      {t("userManagement.email")}
                    </span>
                  </div>
                  <div className="generalStyles__width100">
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "userManagement.formRequiredMessage.email"
                          ),
                        },
                      ]}
                    >
                      <Input className="generalStyles__input" size="small" />
                    </Form.Item>
                  </div>
                  <div className="generalStyles__infoTooltipIconBlank" />
                </div>
              </div>
              {/** ---------------------------------------------------- */}
              {/** FIRTS NAME */}
              <div className="col-md-12">
                <div className="generalStyles__flex">
                  <div className="generalStyles__inputFlexName">
                    <span className="generalStyles__inputFlexRequired">*</span>
                    <span className="drawer__text">
                      {t("userManagement.firstName")}
                    </span>
                  </div>
                  <div className="generalStyles__width100">
                    <Form.Item
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "userManagement.formRequiredMessage.firstName"
                          ),
                        },
                        {
                          max: 25,
                          message: t(
                            "userManagement.formRequiredMessage.firstNameMax"
                          ),
                        },
                      ]}
                    >
                      <Input className="generalStyles__input" size="small" />
                    </Form.Item>
                  </div>
                  <div className="generalStyles__infoTooltipIconBlank" />
                </div>
              </div>
              {/** ---------------------------------------------------- */}
              {/** MIDDLE NAME */}
              <div className="col-md-12">
                <div className="generalStyles__flex">
                  <div className="generalStyles__inputFlexName">
                    <span className="drawer__text">
                      {t("userManagement.middleName")}
                    </span>
                  </div>
                  <div className="generalStyles__width100">
                    <Form.Item
                      name="middleName"
                      rules={[
                        {
                          max: 25,
                          message: t(
                            "userManagement.formRequiredMessage.middleNameMax"
                          ),
                        },
                      ]}
                    >
                      <Input className="generalStyles__input" size="small" />
                    </Form.Item>
                  </div>
                  <div className="generalStyles__infoTooltipIconBlank" />
                </div>
              </div>
              {/** ---------------------------------------------------- */}
              {/** LAST NAME */}
              <div className="col-md-12">
                <div className="generalStyles__flex">
                  <div className="generalStyles__inputFlexName">
                    <span className="generalStyles__inputFlexRequired">*</span>
                    <span className="drawer__text">
                      {t("userManagement.lastName")}
                    </span>
                  </div>
                  <div className="generalStyles__width100">
                    <Form.Item
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "userManagement.formRequiredMessage.lastName"
                          ),
                        },
                        {
                          max: 25,
                          message: t(
                            "userManagement.formRequiredMessage.lastNameMax"
                          ),
                        },
                      ]}
                    >
                      <Input className="generalStyles__input" size="small" />
                    </Form.Item>
                  </div>
                  <div className="generalStyles__infoTooltipIconBlank" />
                </div>
              </div>
              {/** ---------------------------------------------------- */}
              {/** ADDRESS */}
              <div className="col-md-12">
                <div className="generalStyles__flex">
                  <div className="generalStyles__inputFlexName">
                    <span className="generalStyles__inputFlexRequired">*</span>
                    <span className="drawer__text">
                      {t("userManagement.address")}
                    </span>
                  </div>
                  <div className="generalStyles__width100">
                    <Form.Item
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "userManagement.formRequiredMessage.address"
                          ),
                        },
                        {
                          max: 100,
                          message: t(
                            "userManagement.formRequiredMessage.addressMax"
                          ),
                        },
                      ]}
                    >
                      <Input className="generalStyles__input" size="small" />
                    </Form.Item>
                  </div>
                  <div className="generalStyles__infoTooltipIconBlank" />
                </div>
              </div>
              {/** ---------------------------------------------------- */}
              {/** PHONE */}
              <div className="col-md-12">
                <div className="generalStyles__flex">
                  <div className="generalStyles__inputFlexName">
                    <span className="generalStyles__inputFlexRequired">*</span>
                    <span className="drawer__text">
                      {t("organizationManagement.phoneNumber")}
                    </span>
                  </div>
                  <div className="generalStyles__width100">
                    <Form.Item
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "organizationManagement.formRequiredMessage.phone"
                          ),
                          type: "number",
                        },
                        {
                          type: "number",
                          max: 999999999,
                          message: t(
                            "userManagement.formRequiredMessage.phoneMax"
                          ),
                        },
                      ]}
                    >
                      <InputNumber
                        size="small"
                        addonBefore={
                          <Form.Item
                            name="dialCode"
                            rules={[
                              {
                                required: true,
                                message: t(
                                  "userManagement.formRequiredMessage.dialCode"
                                ),
                              },
                              {
                                type: "number",
                                max: 99999,
                                message: t(
                                  "userManagement.formRequiredMessage.dialCodeMax"
                                ),
                              },
                            ]}
                            noStyle
                          >
                            <InputNumber
                              prefix="+"
                              className="generalStyles__inputNumber"
                              size="small"
                            />
                          </Form.Item>
                        }
                        className="generalStyles__width100"
                      />
                    </Form.Item>
                  </div>
                  <div className="generalStyles__infoTooltipIconBlank" />
                </div>
              </div>
            </div>
            {/** ---------------------------------------------------- */}
            {/** GET ORGANIZATION AND CHILDS ----------- */}
            <OrganizationSelectOrganizationSubOrganization
              setOrganizationsAndChildsIsLoading={
                setOrganizationsAndChildsIsLoading
              }
            />
            <br />
            <Divider
              orientation="left"
              className="generalStyles__drawerDivider"
            >
              <h5>
                <FontAwesomeIcon
                  icon={faUserShield}
                  className="generalStyles__info generalStyles__mrFix"
                />
                {t("userManagement.role")}
              </h5>
            </Divider>
            <div
              className={
                theme === "dark"
                  ? "row drawer__box__dark"
                  : "row drawer__box__light"
              }
            >
              {/** ---------------------------------------------------- */}
              {/** ROLE */}
              <div className="col-md-12">
                <div className="generalStyles__flex">
                  <div className="generalStyles__inputFlexName">
                    <span className="generalStyles__inputFlexRequired">*</span>
                    <span className="drawer__text">
                      {t("userManagement.role")}
                    </span>
                  </div>
                  <div className="generalStyles__width100">
                    <Form.Item
                      name="role"
                      rules={[
                        {
                          required: true,
                          message: t("userManagement.formRequiredMessage.role"),
                        },
                      ]}
                    >
                      <Select
                        disabled={listRoles.length === 0}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        size="small"
                      >
                        {listRoles.map((st) => (
                          <Option key={st.id} value={st.id}>
                            {st.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div>
                    <Tooltip title={t("tooltip.userRole")} placement="top">
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        className="generalStyles__infoTooltipIcon"
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/** ---------------------------------------------------- */}
          <div className="mt-4">
            <div>
              <Button
                type="default"
                onClick={handleCloseModal}
                className="buttonStyle__3"
              >
                {t("general.close")}
              </Button>
              <Button
                htmlType="submit"
                disabled={isLoadingPostUser}
                className="buttonStyle__3"
                type="primary"
              >
                {t("general.save")}
              </Button>
            </div>
          </div>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default UsersCreateUserForm;
