import React, { useEffect } from "react";
// REACT DETECT OFFLINE
import { Offline, Online } from "react-detect-offline";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
  updateServerBreadcrumb,
  updateIdServerSelected,
  updateNameServerSelected,
} from "../../../redux/servers/serverSlice";
import {
  updateIdBankSelected,
  updateNameBankSelected,
  updateBankBreadcrumb,
} from "../../../redux/banks/bankSlice";
// COMPONENTS
import LiftsHeader from "./LiftsHeader";
import LiftsTable from "./LiftsTable";
import LiftsCreateForm from "./LiftsCreateForm";
import LiftsDrawer from "./LiftsDrawer";
import LiftsDeleteModal from "./LiftsDeleteModal";
import LiftsEditForm from "./LiftsEditForm";
import LiftsActivateDeactivate from "./LiftsActivateDeactivate";
import LiftsConfiguration from "./LifstConfiguration";
// NO INTERNET CONNECTION
import NoInternetConnection from "../../unauthorized/NoInternetConnection";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

const LiftsPage = () => {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { permissions } = useSelector((state: any) => state.user);
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    dispatch(updateServerBreadcrumb([]));
    dispatch(updateBankBreadcrumb([]));
    dispatch(updateIdServerSelected(""));
    dispatch(updateIdBankSelected(""));
    dispatch(updateNameBankSelected(""));
    dispatch(updateNameServerSelected(""));
  }, []);
  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      {validatePermission("bank-manager", permissions) ? (
        <div>
          <div
            className={
              theme === "light"
                ? "layout__contentPage__light"
                : "layout__contentPage__dark"
            }
          >
            <Online>
              <LiftsHeader />
              <LiftsTable renderComponent="liftsPage" />
              <LiftsCreateForm />
              <LiftsDrawer />
              <LiftsDeleteModal />
              <LiftsEditForm />
              <LiftsActivateDeactivate />
              <LiftsConfiguration />
            </Online>
            <Offline>
              <NoInternetConnection />
            </Offline>
          </div>
        </div>
      ) : (
        <UnauthorizedPage />
      )}
    </div>
  );
};

export default LiftsPage;
