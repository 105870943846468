import React, { useEffect, useState } from 'react'
// ANT DESIGN COMPONENTS
import { Modal, Button, Input } from 'antd'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons'
// REDUX
import { useDispatch, useSelector } from 'react-redux'
import { updateOpenModalActivateDeactivate } from '../../../redux/lifts/liftSlice'
// UTILS
import { styleDeleteButton } from '../../../utils/utils'

library.add(faTrash, faTriangleExclamation)

function LiftsActivateDeactivate() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch()
  const [t] = useTranslation('global')

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [disableButtonConfirmDelete, setDisableButtonConfirmDelete] = useState(
    true,
  )
  const [confirmLiftName, setConfirmLiftName] = useState('')

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { lift, openModalActivateDeactivate } = useSelector(
    (state: any) => state.lift,
  )
  const { theme } = useSelector((state: any) => state.home)

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleCloseModal = () => {
    dispatch(updateOpenModalActivateDeactivate(false))
  }

  // ************************************************* */
  // USE EFFECT ************************************** */

  useEffect(() => {
    setDisableButtonConfirmDelete(true)
    if (
      confirmLiftName &&
      lift &&
      lift.name &&
      confirmLiftName.toLowerCase() === lift.name.toLowerCase()
    ) {
      setDisableButtonConfirmDelete(false)
    } else {
      setDisableButtonConfirmDelete(true)
    }
  }, [confirmLiftName])

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Modal
      onOk={handleCloseModal}
      closable={false}
      onCancel={handleCloseModal}
      visible={openModalActivateDeactivate}
      footer={null}
    >
      <div>
        <div className="text-center">
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="generalStyles__warning generalStyles__deleteItemIcon"
          />
          <p className="mt-3 drawer__text">
            {t('edgeManagement.banks.offsetsConfigurationDescription1')}
            <b className="generalStyles__warning">
              {t('general.activate')}{' '}
            </b>{' '}
            {t('edgeManagement.lifts.theLift')}{' '}
            <b className="generalStyles__warning">
              {lift && lift.id ? lift.id : ''}{' '}
            </b>
            {t('edgeManagement.lifts.confirmLiftId')}
          </p>
          <Input
            placeholder="ID"
            onChange={(e) => {
              setConfirmLiftName(e.target.value)
            }}
            value={confirmLiftName}
            status="warning"
          />
        </div>
        <div className="generalStyles__flexEnd mt-4">
          <div>
            <Button
              htmlType="submit"
              className={styleDeleteButton(disableButtonConfirmDelete, theme)}
              disabled={disableButtonConfirmDelete}
            >
              {t('general.activate')}
            </Button>
            <Button onClick={handleCloseModal} className="buttonStyle__4">
              {t('organizationManagement.close')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default LiftsActivateDeactivate
