/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ToolState {
  offsetType: Object;
  openDrawerOffsetType: boolean;
  openFormOffsetType: boolean;
  showOffsetTypeTable: boolean;
  offsetTypeRefreshTable: boolean;
  openFormOffsetTypeAction: string;
  openModalDeleteOffsetType: boolean;
  toolAlert: Object;
  specialSwitch: Object;
  openDrawerSpecialSwitch: boolean;
  openFormSpecialSwitch: boolean;
  showSpecialSwitchTable: boolean;
  specialSwitchRefreshTable: boolean;
  openFormSpecialSwitchAction: string;
  openModalDeleteSpecialSwitch: boolean;
  specialSwitchCatalog: Object;
  openDrawerSpecialSwitchCatalog: boolean;
  openFormSpecialSwitchCatalog: boolean;
  showSpecialSwitchCatalogTable: boolean;
  specialSwitchCatalogRefreshTable: boolean;
  openFormSpecialSwitchCatalogAction: string;
  openModalDeleteSpecialSwitchCatalog: boolean;
  dataBlock: Object;
  openDrawerDataBlock: boolean;
  openFormDataBlock: boolean;
  showDataBlockTable: boolean;
  dataBlockRefreshTable: boolean;
  openFormDataBlockAction: string;
  openModalDeleteDataBlock: boolean;
  vendor: Object;
  openDrawerVendor: boolean;
  openFormVendor: boolean;
  showVendorTable: boolean;
  vendorRefreshTable: boolean;
  openFormVendorAction: string;
  openModalDeleteVendor: boolean;
}

const initialState: ToolState = {
  offsetType: {},
	openDrawerOffsetType: false,
  openFormOffsetType: false,
  showOffsetTypeTable: false,
  offsetTypeRefreshTable: false,
  openFormOffsetTypeAction: "create",
  openModalDeleteOffsetType: false,
  specialSwitch: {},
  toolAlert: {},
  openDrawerSpecialSwitch: false,
  openFormSpecialSwitch: false,
  showSpecialSwitchTable: false,
  specialSwitchRefreshTable: false,
  openFormSpecialSwitchAction: "create",
  openModalDeleteSpecialSwitch: false,
  specialSwitchCatalog: {},
  openDrawerSpecialSwitchCatalog: false,
  openFormSpecialSwitchCatalog: false,
  showSpecialSwitchCatalogTable: false,
  specialSwitchCatalogRefreshTable: false,
  openFormSpecialSwitchCatalogAction: "create",
  openModalDeleteSpecialSwitchCatalog: false,
  dataBlock: {},
  openDrawerDataBlock: false,
  openFormDataBlock: false,
  showDataBlockTable: false,
  dataBlockRefreshTable: false,
  openFormDataBlockAction: "create",
  openModalDeleteDataBlock: false,
  vendor: {},
  openDrawerVendor: false,
  openFormVendor: false,
  showVendorTable: false,
  vendorRefreshTable: false,
  openFormVendorAction: "create",
  openModalDeleteVendor: false
};

export const toolSlice = createSlice({
  name: 'tool',
  initialState,
  reducers: {
    updateOffsetType: (state, action: PayloadAction<Object>) => {
      state.offsetType = action.payload;
    },
    updateOpenDrawerOffsetType: (state, action: PayloadAction<boolean>) => {
      state.openDrawerOffsetType = action.payload;
    },
    updateOpenFormOffsetType: (state, action: PayloadAction<boolean>) => {
      state.openFormOffsetType = action.payload;
    },
    updateOpenFormOffsetTypeAction: (state, action: PayloadAction<string>) => {
      state.openFormOffsetTypeAction = action.payload;
    },
    updateOffsetTypeRefreshTable: (state, action: PayloadAction<boolean>) => {
      state.offsetTypeRefreshTable = action.payload;
    },
    updateOpenModalDeleteOffsetType: (state, action: PayloadAction<boolean>) => {
      state.openModalDeleteOffsetType = action.payload;
    },
    updateShowOffsetTypeForm: (state, action: PayloadAction<boolean>) => {
      state.showOffsetTypeTable = action.payload;
    },
    updateToolAlert: (state, action: PayloadAction<Object>) => {
      state.toolAlert = action.payload;
    },
    updateSpecialSwitch: (state, action: PayloadAction<Object>) => {
      state.specialSwitch = action.payload;
    },
    updateOpenDrawerSpecialSwitch: (state, action: PayloadAction<boolean>) => {
      state.openDrawerSpecialSwitch = action.payload;
    },
    updateOpenFormSpecialSwitch: (state, action: PayloadAction<boolean>) => {
      state.openFormSpecialSwitch = action.payload;
    },
    updateOpenFormSpecialSwitchAction: (state, action: PayloadAction<string>) => {
      state.openFormSpecialSwitchAction = action.payload;
    },
    updateSpecialSwitchRefreshTable: (state, action: PayloadAction<boolean>) => {
      state.specialSwitchRefreshTable = action.payload;
    },
    updateOpenModalDeleteSpecialSwitch: (state, action: PayloadAction<boolean>) => {
      state.openModalDeleteSpecialSwitch = action.payload;
    },
    updateShowSpecialSwitchForm: (state, action: PayloadAction<boolean>) => {
      state.showSpecialSwitchTable = action.payload;
    },
    updateSpecialSwitchCatalog: (state, action: PayloadAction<Object>) => {
      state.specialSwitchCatalog = action.payload;
    },
    updateOpenDrawerSpecialSwitchCatalog: (state, action: PayloadAction<boolean>) => {
      state.openDrawerSpecialSwitchCatalog = action.payload;
    },
    updateOpenFormSpecialSwitchCatalog: (state, action: PayloadAction<boolean>) => {
      state.openFormSpecialSwitchCatalog = action.payload;
    },
    updateOpenFormSpecialSwitchCatalogAction: (state, action: PayloadAction<string>) => {
      state.openFormSpecialSwitchCatalogAction = action.payload;
    },
    updateSpecialSwitchCatalogRefreshTable: (state, action: PayloadAction<boolean>) => {
      state.specialSwitchCatalogRefreshTable = action.payload;
    },
    updateOpenModalDeleteSpecialSwitchCatalog: (state, action: PayloadAction<boolean>) => {
      state.openModalDeleteSpecialSwitchCatalog = action.payload;
    },
    updateShowSpecialSwitchCatalogForm: (state, action: PayloadAction<boolean>) => {
      state.showSpecialSwitchCatalogTable = action.payload;
    },
    updateDataBlock: (state, action: PayloadAction<Object>) => {
      state.dataBlock = action.payload;
    },
    updateOpenDrawerDataBlock: (state, action: PayloadAction<boolean>) => {
      state.openDrawerDataBlock = action.payload;
    },
    updateOpenFormDataBlock: (state, action: PayloadAction<boolean>) => {
      state.openFormDataBlock = action.payload;
    },
    updateShowDataBlockTable: (state, action: PayloadAction<boolean>) => {
      state.showDataBlockTable = action.payload;
    },
    updateDataBlockRefreshTable: (state, action: PayloadAction<boolean>) => {
      state.dataBlockRefreshTable = action.payload;
    },
    updateOpenFormDataBlockAction: (state, action: PayloadAction<string>) => {
      state.openFormDataBlockAction = action.payload;
    },
    updateOpenModalDeleteDataBlock: (state, action: PayloadAction<boolean>) => {
      state.openModalDeleteDataBlock = action.payload;
    },
    updateVendor: (state, action: PayloadAction<Object>) => {
      state.vendor = action.payload;
    },
    updateOpenDrawerVendor: (state, action: PayloadAction<boolean>) => {
      state.openDrawerVendor = action.payload;
    },
    updateOpenFormVendor: (state, action: PayloadAction<boolean>) => {
      state.openFormVendor = action.payload;
    },
    updateShowVendorTable: (state, action: PayloadAction<boolean>) => {
      state.showVendorTable = action.payload;
    },
    updateVendorRefreshTable: (state, action: PayloadAction<boolean>) => {
      state.vendorRefreshTable = action.payload;
    },
    updateOpenFormVendorAction: (state, action: PayloadAction<string>) => {
      state.openFormVendorAction = action.payload;
    },
    updateOpenModalDeleteVendor: (state, action: PayloadAction<boolean>) => {
      state.openModalDeleteVendor = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateOffsetType,
  updateOpenDrawerOffsetType,
  updateOpenFormOffsetType,
  updateOffsetTypeRefreshTable,
  updateOpenFormOffsetTypeAction,
  updateOpenModalDeleteOffsetType,
  updateShowOffsetTypeForm,
  updateToolAlert,
  updateSpecialSwitch,
  updateOpenDrawerSpecialSwitch,
  updateOpenFormSpecialSwitch,
  updateOpenFormSpecialSwitchAction,
  updateSpecialSwitchRefreshTable,
  updateOpenModalDeleteSpecialSwitch,
  updateShowSpecialSwitchForm,
  updateSpecialSwitchCatalog,
  updateOpenDrawerSpecialSwitchCatalog,
  updateOpenFormSpecialSwitchCatalog, 
  updateOpenFormSpecialSwitchCatalogAction,
  updateSpecialSwitchCatalogRefreshTable,
  updateOpenModalDeleteSpecialSwitchCatalog, 
  updateShowSpecialSwitchCatalogForm,
  updateDataBlock,
  updateOpenDrawerDataBlock,
  updateOpenFormDataBlock,
  updateShowDataBlockTable,
  updateDataBlockRefreshTable,
  updateOpenFormDataBlockAction,
  updateOpenModalDeleteDataBlock, 
  updateVendor,
  updateOpenDrawerVendor,
  updateOpenFormVendor,
  updateShowVendorTable,
  updateVendorRefreshTable,
  updateOpenFormVendorAction, 
  updateOpenModalDeleteVendor
} = toolSlice.actions;

export default toolSlice.reducer;
