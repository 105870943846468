import React from 'react'
// REDUX
import { useSelector } from 'react-redux'
// REACT DETECT OFFLINE
import { Offline, Online } from 'react-detect-offline'
// COMPONENTS
import UsersTable from './UsersTable'
import UsersHeader from './UsersHeader'
import UsersCreateUserForm from './UsersCreateUserForm'
import UsersDrawer from './UsersDrawer'
import UsersEditUserForm from './UsersEditUserForm'
import UsersDeleteUserModal from './UsersDeleteUserModal'
import UsersResetPasswordModal from './UsersResetPasswordModal'
import UsersBlockUnBlockModal from './UsersBlockUnBlockModal'
// NO INTERNET CONNECTION
import NoInternetConnection from '../../unauthorized/NoInternetConnection'
// AUTHORIZATION
import validatePermission from '../../../utils/validatePermissions'
import UnauthorizedPage from '../../unauthorized/UnauthorizedPage'

// eslint-disable-next-line arrow-body-style
const UserPage = () => {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const { permissions } = useSelector((state: any) => state.user)
  const { theme } = useSelector((state: any) => state.home)

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      {validatePermission('users', permissions) ? (
        <div
          className={
            theme === 'light'
              ? 'layout__contentPage__light'
              : 'layout__contentPage__dark'
          }
        >
          <Online>
            <UsersHeader />
            <UsersTable />
            <UsersCreateUserForm />
            <UsersDrawer />
            <UsersEditUserForm />
            <UsersDeleteUserModal />
            <UsersResetPasswordModal />
            <UsersBlockUnBlockModal />
          </Online>
          <Offline>
            <NoInternetConnection />
          </Offline>
        </div>
      ) : (
        <UnauthorizedPage />
      )}
    </div>
  )
}

export default UserPage
