import React from 'react'
// REDUX
import { useSelector } from 'react-redux'
// REACT DETECT OFFLINE
import { Offline, Online } from 'react-detect-offline'
// COMPONENTS
import OrganizationTable from './OrganizationsTable'
import OrganizationsHeader from './OrganizationsHeader'
import OrganizationsCreateOrganizationForm from './OrganizationsCreateOrganizationForm'
import OrganizationsCreateSubOrganizationForm from './OrganizationsCreateSubOrganizationForm'
import OrganizationsEditOrganizationForm from './OrganizationsEditOrganizationForm'
import OrganizationsDeleteOrganizationModal from './OrganizationsDeleteOrganizationModal'
import OrganizationDrawer from './OrganizationDrawer'
// NO INTERNET CONNECTION
import NoInternetConnection from '../../unauthorized/NoInternetConnection'
// AUTHORIZATION
import validatePermission from '../../../utils/validatePermissions'
import UnauthorizedPage from '../../unauthorized/UnauthorizedPage'

const OrganizationPage = () => {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const { permissions } = useSelector((state: any) => state.user)
  const { theme } = useSelector((state: any) => state.home)

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      {validatePermission('organizations', permissions) ? (
        <div
          className={
            theme === 'light'
              ? 'layout__contentPage__light'
              : 'layout__contentPage__dark'
          }
        >
          <Online>
            <OrganizationsHeader />
            <OrganizationTable />
            <OrganizationsCreateOrganizationForm />
            <OrganizationsCreateSubOrganizationForm />
            <OrganizationsEditOrganizationForm />
            <OrganizationsDeleteOrganizationModal />
            <OrganizationDrawer />
          </Online>
          <Offline>
            <NoInternetConnection />
          </Offline>
        </div>
      ) : (
        <UnauthorizedPage />
      )}
    </div>
  )
}

export default OrganizationPage
