// REDUX
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
// REDUCERS / SLICES
import homeReducer from '../redux/home/homeSlice';
import loginReducer from '../redux/login/loginSlice';
import organizationReducer from '../redux/organizations/organizationSlice';
import userReducer from '../redux/users/userSlice';
import roleReduer from '../redux/roles/roleSlice';
import configurationReducer from '../redux/configuration/configurationSlice';
import serverReducer from '../redux/servers/serverSlice';
import bankReducer from '../redux/banks/bankSlice';
import liftReducer from '../redux/lifts/liftSlice';
import configurationFileReducer from '../redux/configurationFiles/configurationFilesSlice';
import monitoringReducer from '../redux/monitoring/monitoringSlice';
import toolReducer from '../redux/tools/toolsSlice'
import authenticationReducer from "../redux/authentication/authenticationSlice"
import reportReducer from "../redux/reports/reportsSlice"
// API
import { countriesStatesCitiesAPI } from '../redux/countriesStatesCitiesAPI';
import { organizationAPI } from '../redux/organizations/organizationAPI';
import { userAPI } from '../redux/users/userAPI';
import { roleAPI } from '../redux/roles/roleAPI';
import { serverAPI } from '../redux/servers/serverAPI';
import { bankAPI } from '../redux/banks/bankAPI';
import { liftAPI } from '../redux/lifts/liftAPI';
import { configurationFileAPI } from '../redux/configurationFiles/configurationFilesAPI';
import { monitoringAPI } from "../redux/monitoring/monitoringAPI"
import { reportAPI} from "../redux/reports/reportsAPI"

export const store = configureStore({
  reducer: {
    home: homeReducer,
    login: loginReducer,
    organization: organizationReducer,
    user: userReducer,
    role: roleReduer,
    configuration: configurationReducer,
    server: serverReducer,
    bank: bankReducer,
    lift: liftReducer,
    configurationFile: configurationFileReducer,
    monitoring: monitoringReducer,
    tool: toolReducer,
    authentication: authenticationReducer,
    report: reportReducer,
    // API REDUCERS
    [organizationAPI.reducerPath]: organizationAPI.reducer,
    [userAPI.reducerPath]: userAPI.reducer,
    [countriesStatesCitiesAPI.reducerPath]: countriesStatesCitiesAPI.reducer,
    [roleAPI.reducerPath]: roleAPI.reducer,
    [serverAPI.reducerPath]: serverAPI.reducer,
    [bankAPI.reducerPath]: bankAPI.reducer,
    [liftAPI.reducerPath]: liftAPI.reducer,
    [configurationFileAPI.reducerPath]: configurationFileAPI.reducer,
    [monitoringAPI.reducerPath]: monitoringAPI.reducer,
    [reportAPI.reducerPath]: reportAPI.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling
  // and other useful features off rtk-query
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      organizationAPI.middleware,
      userAPI.middleware,
      roleAPI.middleware,
      serverAPI.middleware,
      bankAPI.middleware,
      liftAPI.middleware,
      configurationFileAPI.middleware,
      monitoringAPI.middleware,
      reportAPI.middleware,
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = store.getState
// eslint-disable-next-line no-undef
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
