import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Divider, Drawer, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingLock } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerOrganization } from "../../../redux/organizations/organizationSlice";
// INTERFACE
import { OrganizationInterface } from "../../../interfaces/Organizations.interface";
import { RootState } from "../../../app/store";
// COMPONENTS
import DrawerOrganizationInformation from "../../components/DrawerOrganizationInformation";

function OrganizationDrawer() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [data, setData] = useState<OrganizationInterface>();

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { organization, openDrawerOrganization } = useSelector(
    (state: RootState) => state.organization
  );

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onClickClose = () => {
    dispatch(updateOpenDrawerOrganization(false));
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (organization) {
      setData(organization);
    }
  }, [organization]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClickClose}
      closable={false}
      visible={openDrawerOrganization}
    >
      {data && (
        <div>
          {/** ************************************************* */}
          {/** ORGANIZATION INFORMATION  */}
          <DrawerOrganizationInformation data={data || {}} />
          {/** ************************************************* */}
          {/** PARENT ORGANIZATION  */}
          <br />
          <div>
            <Divider
              orientation="left"
              className="generalStyles__drawerDivider"
            >
              <h5>
                <FontAwesomeIcon
                  icon={faBuildingLock}
                  className="generalStyles__info generalStyles__mrFix"
                />
                {t("organizationManagement.organizationParent")}
              </h5>
            </Divider>
          </div>
          <div
            className={
              theme === "dark" ? "drawer__box__dark" : "drawer__box__light"
            }
          >
            {/* PARETN ID */}
            <div className="generalStyles__flex drawer__container">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("organizationManagement.parentId")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data.parent_id ? data.parent_id : "--"}
                </p>
              </div>
            </div>
            {/* PARENT NAME */}
            <div className="generalStyles__flex drawer__container">
              <div>
                <p
                  className={isMobile ? "drawer__titleMobile" : "drawer__title"}
                >
                  {t("organizationManagement.name")}
                </p>
              </div>
              <div className="drawer__textContainer">
                <p className="drawer__text">
                  {data.parent_name ? data.parent_name : "--"}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {/** CLOSE AND SUBMIT BUTTONS */}
      <div className="mt-4 generalStyles__width100">
        <div>
          <Button
            type="default"
            onClick={onClickClose}
            className="buttonStyle__5"
          >
            {t("organizationManagement.close")}
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

export default OrganizationDrawer;
