import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Spin, Input, Button } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// AMPLIFY
import { Auth } from "aws-amplify";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { udpateOpenModalTotpToken } from "../../redux/authentication/authenticationSlice";
// UTILS
import { styleDeleteButton } from "../../utils/utils";

function AuthenticationTOTP({ setTotpTokenIsValid, setTotp }: any) {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const { theme } = useSelector((state: any) => state.home);
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [isLoading, setIsLoading] = useState(false);
  const [totpToken, setTotpToken] = useState("");
  const [tokenIsValid, setTokenIsValid] = useState(true);
  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { openModalTotpToken } = useSelector(
    (state: any) => state.authentication
  );

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onClickCancel = () => {
    dispatch(udpateOpenModalTotpToken(false));
  };

  const handleTokeIsNotValid = () => {
    setTotpTokenIsValid(false);
    setTotp("");
    setIsLoading(false);
    setTokenIsValid(false);
  };

  const handleTokenIsValid = () => {
    setTotpTokenIsValid(true);
    setTotp(totpToken);
    setIsLoading(false);
    setTokenIsValid(true);
    onClickCancel();
  };

  const handleSubmitConfirmTOTP = async () => {
    try {
      setIsLoading(true);
      const newUser = await Auth.currentAuthenticatedUser();
      const response = await Auth.verifyTotpToken(
        newUser, // Return object from Auth.signIn()
        totpToken // Confirmation code
      );
      setIsLoading(false);
      setTotpTokenIsValid(true);
      setTotp(totpToken);
      if (response) {
        handleTokenIsValid();
      } else {
        handleTokeIsNotValid();
      }
    } catch (error) {
      handleTokeIsNotValid();
    }
  };
  // ************************************************* */
  // USE EFFECT ************************************** */

  useEffect(() => {
    if (totpToken.length === 0) {
      setTokenIsValid(true);
    }
  }, [totpToken]);

  useEffect(() => {
    if (openModalTotpToken) {
      setIsLoading(false);
      setTotpToken("");
      setTokenIsValid(true);
    }
  }, [openModalTotpToken]);
  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Modal
      closable={false}
      onCancel={onClickCancel}
      visible={openModalTotpToken}
      footer={null}
    >
      <Spin spinning={isLoading}>
        <div>
          <div className="text-center">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="generalStyles__warning generalStyles__deleteItemIcon"
            />
            <p className="mt-3 drawer__text">
              {t("monitoring.validateTotpTokenDescription.description1")}
              <b className="generalStyles__warning">TOTP Token</b>.
              {t("monitoring.validateTotpTokenDescription.description2")}
              <b className="generalStyles__warning">Authy</b>
              {t("monitoring.validateTotpTokenDescription.description3")}
              <b className="generalStyles__warning">Google Authenticator</b>.
            </p>
            <Input
              placeholder="TOTP Token"
              onChange={(e) => {
                setTotpToken(e.target.value);
              }}
              value={totpToken}
              status={tokenIsValid ? "warning" : "error"}
            />
            {totpToken.length > 0 && !tokenIsValid && (
              <p
                style={{
                  textAlign: "left",
                  fontSize: "12px",
                  marginTop: "5px",
                  marginLeft: "5px",
                }}
                className="generalStyles__error"
              >
                {t("monitoring.codeMismatch")}
              </p>
            )}
          </div>
          <div className="generalStyles__flexEnd mt-4">
            <div>
              <Button
                htmlType="submit"
                className={styleDeleteButton(totpToken.length !== 6, theme)}
                onClick={handleSubmitConfirmTOTP}
                disabled={totpToken.length !== 6}
              >
                {t("monitoring.validateTotpToken")}
              </Button>
              <Button onClick={onClickCancel} className="buttonStyle__4">
                {t("general.close")}
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
}

export default AuthenticationTOTP;
