import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Cascader, Select } from "antd";
// REDUX
import { useDispatch } from "react-redux";
import { useLazyGetOrganizationsCascaderQuery } from "../../../redux/monitoring/monitoringAPI";
import { updateOrganizationMonitoringViewData } from "../../../redux/organizations/organizationSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

function OrganizationCascaderSelect({
  setCascaderSelectData,
  setCascaderTypeLevel,
}: any) {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const { Option } = Select;
  const dispatch = useDispatch();

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [cascaderData, setCascaderData] = useState([]);
  const [cascaderValue, setCascaderValue] = useState<any>([]);
  const [cascaderIdSelected, setCascaderIdSelected] = useState<any>([]);
  const [filterType, setFilterType] = useState(2);

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [triggerGetOrganizationsCascader, { data }] =
    useLazyGetOrganizationsCascaderQuery();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const initData = async () => {
    const token = await GETJwtToken();
    triggerGetOrganizationsCascader({ token });
  };

  const buildCascaderArray = (filterTypeLevel: number) => {
    if (data && data.organizations && data.organizations.length > 0) {
      const newArr: any = [];
      // ***************************************
      // BUILD ORGANIZATIONS CASCADER
      data.organizations.map((org: any) => {
        const orgCascader: any = {
          value: org.id,
          label: org.name,
          children: [],
        };
        // ***************************************
        // BUILD SERVER CASCADER
        if (
          org &&
          org.servers &&
          org.servers.length > 0 &&
          filterTypeLevel >= 2
        ) {
          org.servers.map((serv: any) => {
            const servCascader: any = {
              value: serv.id,
              label: serv.name,
              children: [],
            };
            // ***************************************
            // BUILD CHANNELS CASCADER
            if (
              serv &&
              serv.channels &&
              serv.channels.length > 0 &&
              filterTypeLevel >= 3
            ) {
              serv.channels.map((channel: any) => {
                const channelCascader: any = {
                  value: channel.id,
                  label: channel.name,
                  children: [],
                };
                // ***************************************
                // BUILD BANKS CASCADER
                if (
                  channel &&
                  channel.banks &&
                  channel.banks.length > 0 &&
                  filterTypeLevel >= 4
                ) {
                  channel.banks.map((bank: any) => {
                    const bankCascader: any = {
                      value: bank.id,
                      label: bank.name,
                      children: [],
                    };
                    return channelCascader.children.push(bankCascader);
                  });
                }
                return servCascader.children.push(channelCascader);
              });
            }
            return orgCascader.children.push(servCascader);
          });
        }
        return newArr.push(orgCascader);
      });
      setCascaderData(newArr);
    }
  };

  const onChangeSelect = (value: number) => {
    if (value) {
      // Clean Data
      setCascaderData([]);
      setCascaderValue([]);
      // Build again cascader data
      buildCascaderArray(value);
      setFilterType(value);
    }
  };

  const onChangeCascader = (_: any, selectedOptions: any) => {
    const newData: any = [];
    const newDataFilter: any = [];
    // Cascader value
    if (selectedOptions && selectedOptions.length > 0) {
      selectedOptions.map((item: any) => newData.push(item.label));
      selectedOptions.map((item: any) => newDataFilter.push(item));
    }
    // Filter object value
    setCascaderValue(newData);
    setCascaderIdSelected(newDataFilter);
  };
  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (data) {
      buildCascaderArray(2);
      dispatch(updateOrganizationMonitoringViewData(data));
    }
  }, [data]);

  useEffect(() => {
    setCascaderSelectData(cascaderIdSelected);
  }, [cascaderIdSelected]);

  useEffect(() => {
    setCascaderTypeLevel(filterType);
  }, [filterType]);
  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div className="generalStyles__flex">
      {/** ******************************************* */}
      {/** SELECT FILTER */}
      <div className="generalStyles__mlFix">
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Filter Type"
          style={{ width: "150px" }}
          allowClear={false}
          filterOption={(input: any, option: any) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          className="generalStyles__width100"
          onChange={onChangeSelect}
          value={filterType}
        >
          {[
            { name: "Organization", level: 1 },
            { name: "Server", level: 2 },
            { name: "Channel", level: 3 },
            { name: "Bank", level: 4 },
          ].map((item: any) => (
            <Option key={item.name} value={item.level}>
              {item.name}
            </Option>
          ))}
        </Select>
      </div>
      {/** ******************************************* */}
      {/** CASCADER */}
      <div className="generalStyles__mlFix">
        <Cascader
          style={{ width: "150px" }}
          options={cascaderData}
          onChange={onChangeCascader}
          placeholder="Please select"
          value={cascaderValue}
        />
      </div>
    </div>
  );
}

export default OrganizationCascaderSelect;
