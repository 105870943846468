import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// ANT DESIGN COMPONENTS
import { Breadcrumb } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateBreadcrumbTitle } from "../../redux/home/homeSlice";
// COMPONENTS
import OrganizationBreadcrumb from "../organizationManagement/organizations/OrganizationBreadcrumb";
import ServersBreadcrumb from "../edgeManagement/servers/ServersBreadcrumb";
import BanksBreadcrumb from "../edgeManagement/banks/BanksBreadcrumb";

function LayoutBreadcrumb() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const location = useLocation();
  const [t] = useTranslation("global");
  const { breadcrumbTitle } = useSelector((state: any) => state.home);
  const { offsetTypeName } = useSelector(
    (state: any) => state.configurationFile
  );
  const dispatch = useDispatch();

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleChangeHeaderTitle = (title: string) => {
    localStorage.setItem("headerTitle", title);
    dispatch(updateBreadcrumbTitle(title));
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (location && location.pathname) {
      if (location.pathname === "/edge-management/configuration-catalog") {
        handleChangeHeaderTitle("configurationCatalog");
      } else if (
        location.pathname ===
        "/edge-management/configuration-catalog/definitions"
      ) {
        handleChangeHeaderTitle("definitions");
      } else if (
        location.pathname === "/edge-management/configuration-catalog/offsets"
      ) {
        handleChangeHeaderTitle("offsets");
      }
    }
  }, [location]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Breadcrumb className="generalStyles__breadcrumb">
      {/** ************************************************* */}
      {/** SIMPLE BREADCRUMB */}
      {(localStorage.getItem("headerTitle") === "dashboard" ||
        breadcrumbTitle === "dashboard") && (
        <>
          <Breadcrumb.Item>{t("header.dashboard")} /</Breadcrumb.Item>
        </>
      )}
      {(localStorage.getItem("headerTitle") === "users" ||
        breadcrumbTitle === "users") && (
        <>
          <Breadcrumb.Item>{t("header.userManagement")}</Breadcrumb.Item>
          <Breadcrumb.Item className="generalStyles__breadcrumbItem">
            {t("header.users")}
          </Breadcrumb.Item>
        </>
      )}
      {(localStorage.getItem("headerTitle") === "roles" ||
        breadcrumbTitle === "roles") && (
        <>
          <Breadcrumb.Item>
            {t("header.organizationManagement")}
          </Breadcrumb.Item>
          <Breadcrumb.Item className="generalStyles__breadcrumbItem">
            {t("header.roles")}
          </Breadcrumb.Item>
        </>
      )}
      {(localStorage.getItem("headerTitle") === "rootPermissions" ||
        breadcrumbTitle === "rootPermissions") && (
        <>
          <Breadcrumb.Item>{t("header.tools")}</Breadcrumb.Item>
          <Breadcrumb.Item className="generalStyles__breadcrumbItem">
            {t("header.rootPermissions")}
          </Breadcrumb.Item>
        </>
      )}
      {(localStorage.getItem("headerTitle") === "offsetTypeConfiguration" ||
        breadcrumbTitle === "offsetTypeConfiguration") && (
        <>
          <Breadcrumb.Item>{t("header.tools")}</Breadcrumb.Item>
          <Breadcrumb.Item className="generalStyles__breadcrumbItem">
            {t("header.offsetTypeConfiguration")}
          </Breadcrumb.Item>
        </>
      )}
      {(localStorage.getItem("headerTitle") ===
        "specialSwitchesConfiguration" ||
        breadcrumbTitle === "specialSwitchesConfiguration") && (
        <>
          <Breadcrumb.Item>{t("header.tools")}</Breadcrumb.Item>
          <Breadcrumb.Item className="generalStyles__breadcrumbItem">
            {t("header.specialSwitchesConfiguration")}
          </Breadcrumb.Item>
        </>
      )}
      {(localStorage.getItem("headerTitle") ===
        "specialSwitchesCatalogConfiguration" ||
        breadcrumbTitle === "specialSwitchesCatalogConfiguration") && (
        <>
          <Breadcrumb.Item>{t("header.tools")}</Breadcrumb.Item>
          <Breadcrumb.Item className="generalStyles__breadcrumbItem">
            {t("header.specialSwitchesCatalogConfiguration")}
          </Breadcrumb.Item>
        </>
      )}
      {(localStorage.getItem("headerTitle") === "dataBlocksConfiguration" ||
        breadcrumbTitle === "dataBlocksConfiguration") && (
        <>
          <Breadcrumb.Item>{t("header.tools")}</Breadcrumb.Item>
          <Breadcrumb.Item className="generalStyles__breadcrumbItem">
            {t("header.dataBlocks")}
          </Breadcrumb.Item>
        </>
      )}
      {(localStorage.getItem("headerTitle") === "vendorsConfiguration" ||
        breadcrumbTitle === "vendorsConfiguration") && (
        <>
          <Breadcrumb.Item>{t("header.tools")}</Breadcrumb.Item>
          <Breadcrumb.Item className="generalStyles__breadcrumbItem">
            {t("header.vendors")}
          </Breadcrumb.Item>
        </>
      )}
      {(localStorage.getItem("headerTitle") === "lifts" ||
        breadcrumbTitle === "lifts") && (
        <>
          <Breadcrumb.Item>{t("header.edgeManagement")}</Breadcrumb.Item>
          <Breadcrumb.Item className="generalStyles__breadcrumbItem">
            {t("header.lifts")}
          </Breadcrumb.Item>
        </>
      )}
      {(localStorage.getItem("headerTitle") === "configurationCatalog" ||
        breadcrumbTitle === "configurationCatalog") && (
        <>
          <Breadcrumb.Item>{t("header.edgeManagement")}</Breadcrumb.Item>
          <Breadcrumb.Item className="generalStyles__breadcrumbItem">
            {t("header.configurationCatalog")}
          </Breadcrumb.Item>
        </>
      )}
      {(localStorage.getItem("headerTitle") === "definitions" ||
        breadcrumbTitle === "definitions") && (
        <>
          <Breadcrumb.Item>{t("header.edgeManagement")}</Breadcrumb.Item>
          <Breadcrumb.Item className="generalStyles__breadcrumbItem">
            <Link to="configuration-catalog">
              {t("header.configurationCatalog")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="generalStyles__breadcrumbItem">
            {t("header.definitions")} - {offsetTypeName}
          </Breadcrumb.Item>
        </>
      )}
      {(localStorage.getItem("headerTitle") === "offsets" ||
        breadcrumbTitle === "offsets") && (
        <>
          <Breadcrumb.Item>{t("header.edgeManagement")}</Breadcrumb.Item>
          <Breadcrumb.Item className="generalStyles__breadcrumbItem">
            <Link to="configuration-catalog">
              {t("header.configurationCatalog")}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="generalStyles__breadcrumbItem">
            {t("header.offsets")}
          </Breadcrumb.Item>
        </>
      )}
      {(localStorage.getItem("headerTitle") === "reportOffsets" ||
        breadcrumbTitle === "reportOffsets") && (
        <>
          <Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
          <Breadcrumb.Item className="generalStyles__breadcrumbItem">
            {t("header.offsets")}
          </Breadcrumb.Item>
        </>
      )}
      {/** ************************************************* */}
      {/** COMPONENT BREADCRUMB */}
      {(localStorage.getItem("headerTitle") === "organizations" ||
        breadcrumbTitle === "organizations") && <OrganizationBreadcrumb />}
      {(localStorage.getItem("headerTitle") === "servers" ||
        breadcrumbTitle === "servers") && <ServersBreadcrumb />}
      {(localStorage.getItem("headerTitle") === "banks" ||
        breadcrumbTitle === "banks") && <BanksBreadcrumb />}
    </Breadcrumb>
  );
}

export default LayoutBreadcrumb;
