/* eslint-disable */
import React, { useState, useEffect } from "react";
// GRID LAYOUT
import "react-grid-layout/css/styles.css";
import GridLayout from "react-grid-layout";
// ANT DESIGN COMPONENTS
import { Empty, Tooltip } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
  updateOpenDrawerBank,
  updateBank,
} from "../../../redux/banks/bankSlice";
// ICONS
import test1 from "../../../assets/icons/test1.gif";
import test2 from "../../../assets/icons/test2.gif";
import test3 from "../../../assets/icons/test3.png";
import test4 from "../../../assets/icons/test4.png";
import test5 from "../../../assets/icons/test5.png";

const MonitoringGrid = () => {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const liftsAllowedPerColumn = 5;
  const liftsAllowedPerRow = 2;
  const baseLayoutGrid = 130;
  const cardHeight = 60;
  const [rowsAllowed, setRowsAllowed] = useState(0);
  const [t] = useTranslation("global");
  const { theme } = useSelector((state: any) => state.home);
  const dispatch = useDispatch();

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [layout, setLayout] = useState<any[]>([]);
  const [mainContainerWidth, setMainContainerWidth] = useState(0);
  const [banks, setBanks] = useState<any>([]);
  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { organizationSelectMultipleData } = useSelector(
    (state: any) => state.organization
  );
  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const layoutChange = (newLayout: any) => {
    console.log("NEW LAYOUT TO SAVE");
    console.log({ newLayout });
  };

  const testingGetWidthAndHeight = (itemId: string) => {
    const elementId = document.getElementById(itemId);
    if (elementId) {
      const divWidth = elementId.clientWidth;
      const divHeight = elementId.clientHeight;
      return `W: ${divWidth} h: ${divHeight}`;
    }
    return "";
  };

  const generateInitialGrid = (widthContainer: number) => {
    let counterX = 0;
    let counterY = 0;
    const newArr: any = [];
    const banksCopy = structuredClone(banks);
    banksCopy.sort(
      (a: any, b: any) =>
        parseFloat(a.bank && a.bank.lifts ? a.bank.lifts.length : 0) -
        parseFloat(b.bank && b.bank.lifts ? b.bank.lifts.length : 0)
    );
    banksCopy.map((bank: any) => {
      const totalLifts = bank && bank.lifts ? bank.lifts.length : 0;
      const totalFloors = bank && bank.floor_count ? bank.floor_count : 0;
      newArr.push({
        ...bank,
        i: bank.id,
        x: counterX,
        y: counterY,
        w:
          totalLifts <= 3
            ? 2
            : Math.ceil(totalLifts / liftsAllowedPerColumn) + 2,
        h: totalLifts >= 1 ? Math.ceil(totalFloors / liftsAllowedPerRow) : 2,
        l: totalLifts,
        f: totalFloors,
        floor: 3,
        // w: item.w,
        // h: item.h,
      });
      console.log({ counterX, totalLifts, liftsAllowedPerColumn });
      counterX =
        counterX + totalLifts <= 3
          ? 2
          : Math.ceil(totalLifts / liftsAllowedPerColumn) + 2 + counterX;
      if (counterX > Math.floor(widthContainer / baseLayoutGrid)) {
        counterX = 0;
        counterY += 1;
      }
    });
    console.log({ newArr });
    setLayout(newArr);
  };

  const onClickOpenDrawerBank = (item: any) => {
    if (item) {
      dispatch(updateBank(item));
      dispatch(updateOpenDrawerBank(true));
    }
  };

  const generateRandom = () => {
    const copy = structuredClone(layout);
    for (let index = 0; index < copy.length; index += 1) {
      if (copy[index].lifts) {
        console.log("stop 1");
        for (let index2 = 0; index2 < copy[index].lifts.length; index2 += 1) {
          console.log("stop 2");
          copy[index].lifts[index2].floorTesting = Math.floor(
            Math.random() * 10
          );
          copy[index].lifts[index2].iconTesting = Math.floor(Math.random() * 4);
        }
      }
    }
    console.log({ copy });
    setLayout(copy);
  };
  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    const elementId = document.getElementById("mainCainer");
    if (elementId && banks && banks.length > 0) {
      setMainContainerWidth(elementId.clientWidth);
      generateInitialGrid(elementId.clientWidth);
      setRowsAllowed(Math.floor(elementId.clientWidth / baseLayoutGrid));
    }
  }, [banks]);

  useEffect(() => {
    if (
      organizationSelectMultipleData &&
      organizationSelectMultipleData.length > 0
    ) {
      setBanks(organizationSelectMultipleData);
    } else {
      setBanks([]);
      setLayout([]);
    }
  }, [organizationSelectMultipleData]);

  useEffect(() => {
    console.log({ layout });
  }, [layout]);
  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div id="mainCainer">
      {mainContainerWidth > 0 && layout && layout.length > 0 ? (
        <GridLayout
          className="layout"
          layout={layout}
          onLayoutChange={layoutChange}
          cols={mainContainerWidth / 102}
          rowHeight={60}
          autoSize
          width={mainContainerWidth}
          style={{ marginTop: "-40px" }}
          isResizable={false}
          margin={[40, 40]}
          containerPadding={[20, 20]}
        >
          {layout.map((item) => (
            <div id={item.i} key={item.i}>
              <div
                id={item.i}
                key={item.i}
                className={`monitoring__card__${theme}`}
              >
                {/** *********************************************************** */}
                {/** HEADER */}
                <div className="monitoring__card__header">
                  <Tooltip
                    title={
                      <>
                        <span> {testingGetWidthAndHeight(item.i)} px</span>
                        <br />
                        <span>Floors: {item.f}</span>
                        <br />
                        <span>Lifts: {item.l}</span>
                        <br />
                        <span>Columns: {item.w}</span>
                        <br />
                        <span>Rows: {item.h}</span>
                        <br />
                      </>
                    }
                  >
                    <div className="generalStyles__flex">
                      <div
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {}}
                        onClick={() => {
                          generateRandom();
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          className="generalStyles__success generalStyles__mrFix"
                          style={{ fontSize: "16px" }}
                        />
                      </div>
                      <div
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => {}}
                        onClick={() => {
                          onClickOpenDrawerBank(item);
                        }}
                      >
                        <h6>{item.name}</h6>
                      </div>
                    </div>
                  </Tooltip>
                </div>
                {/** *********************************************************** */}
                {/** BODY */}
                <div>
                  {item && item.l === 0 ? (
                    <>
                      <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        imageStyle={{ height: 40 }}
                        description={
                          <span>
                            {t("edgeManagement.lifts.liftsNotAsigned")}
                          </span>
                        }
                      />
                    </>
                  ) : (
                    <div>
                      {item && item.bank_floors && (
                        <div style={{ padding: "10px 20px 10px 5px" }}>
                          {item.bank_floors.map(
                            (floor: any, floorIndex: number) => (
                              <div className="generalStyles__flex ">
                                {/** *********************************************************** */}
                                {/** FLOORS */}
                                <div
                                  className={`monitoring__card__floor__${theme}`}
                                >
                                  {floor.floor_index}
                                </div>
                                {/** *********************************************************** */}
                                {/** DOTS AND ELEVATOR CONTAINER */}
                                <div
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                  }}
                                >
                                  {/** LIFTS */}
                                  {item && item.lifts && (
                                    <div
                                      className="generalStyles__flex"
                                      style={{ justifyContent: "space-evenly" }}
                                    >
                                      {item.lifts.map(
                                        (lift: any, liftIndex: number) => (
                                          <div>
                                            <div
                                              className={`monitoring__card__liftContainer__${theme} ${
                                                floorIndex === 0
                                                  ? "monitoring__card__liftContainerBorderTop"
                                                  : ""
                                              } ${
                                                floorIndex ===
                                                item.bank_floors.length - 1
                                                  ? "monitoring__card__liftContainerBorderBottom"
                                                  : ""
                                              }`}
                                            >
                                              {floorIndex ===
                                                lift.floorTesting && (
                                                <>
                                                  {lift.iconTesting === 0 && (
                                                    <img
                                                      src={test5}
                                                      alt=""
                                                      height={10}
                                                      width={10}
                                                    />
                                                  )}
                                                  {lift.iconTesting === 1 && (
                                                    <img
                                                      src={test3}
                                                      alt=""
                                                      height={10}
                                                      width={10}
                                                    />
                                                  )}
                                                  {lift.iconTesting === 2 && (
                                                    <img
                                                      src={test4}
                                                      alt=""
                                                      height={10}
                                                      width={10}
                                                    />
                                                  )}
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                                  {/** DOTS STYLE */}
                                  <div
                                    style={{
                                      position: "absolute",
                                      zIndex: "1",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      className={`monitoring__card__dotStyle__${theme}`}
                                    />
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                          {/** *********************************************************** */}
                          {/** ELEVATOR NUMBER AND DESCRIPTION */}
                          <div>
                            {item && item.lifts && (
                              <div className="generalStyles__flex">
                                <div
                                  className={`monitoring__card__floor__${theme}`}
                                />
                                <div
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    className="generalStyles__flex"
                                    style={{ justifyContent: "space-evenly" }}
                                  >
                                    {item.lifts.map((lift: any) => (
                                      <div
                                        className={`monitoring__card__elevatorContainer__${theme}`}
                                      >
                                        {lift && lift.position === 4 ? (
                                          <div>
                                            <FontAwesomeIcon
                                              icon={faCircleInfo}
                                              className="generalStyles__warning"
                                              style={{ fontSize: "16px" }}
                                            />
                                          </div>
                                        ) : (
                                          <div>
                                            E
                                            {lift &&
                                            lift.position &&
                                            lift.position < 10
                                              ? 0
                                              : ""}
                                            {lift && lift.position
                                              ? lift.position
                                              : 0}
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {/** *********************************************************** */}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </GridLayout>
      ) : (
        <div
          className={
            theme === "dark" ? " drawer__box__dark" : " drawer__box__light"
          }
        >
          <Empty
            description={<span>{t("edgeManagement.banks.noBanksFound")}</span>}
          />
        </div>
      )}
    </div>
  );
};

export default MonitoringGrid;
