/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Table, Alert, Menu, Dropdown, Space, Empty, Tag } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsis,
  faTrash,
  faPenToSquare,
  faBan,
  faElevator,
  faTable,
  faArrowUp19,
  faCheckCircle,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  useLazyGetBanksQuery,
  useLazyGetBanksByServerQuery,
} from "../../../redux/banks/bankAPI";
import {
  updateOpenModalDeleteBank,
  updateOpenModalEditBank,
  updateOpenDrawerBank,
  updateBank,
  updateBankTableParams,
  updateBankBreadcrumb,
  updateIdBankSelected,
  updateNameBankSelected,
  updateOpenDrawerBankFloorNames,
  updateOpenDrawerBankCheckOffsets,
  updateOpenDrawerLiftsPosition,
} from "../../../redux/banks/bankSlice";
import { updateServerBreadcrumb } from "../../../redux/servers/serverSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import validatePermission from "../../../utils/validatePermissions";
// UTILS
import { getTableRowClass } from "../../../utils/utils";

const BanksTable = ({ renderComponent }: any) => {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [banks, setBanks] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [idBankSelected, setIdBankSelected] = useState("");

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { permissions } = useSelector((state: any) => state.user);
  const { userLogged } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [triggerGetBanksTable, { data, isLoading, isError, isFetching }] =
    useLazyGetBanksQuery();
  const [
    triggerGetBanksByService,
    {
      data: dataGetBanksByOrganization,
      isLoading: isLoadingGetBanksByOrganization,
      isError: isErorGetBanksByOrganization,
      isFetching: isFetchingGetBanksByOrganization,
    },
  ] = useLazyGetBanksByServerQuery();
  const { bankTableParams, bankRefreshTable } = useSelector(
    (state: any) => state.bank
  );
  const { idServerSelected, serverBreadcrumb } = useSelector(
    (state: any) => state.server
  );

  // ************************************************ */
  // TABLE ACTIONS ************************************ */
  const { Column } = Table;

  const manageActions = () => {
    const BLANK = {
      label: (
        <div className="generalStyles__tableActionBox">
          <span>
            <FontAwesomeIcon
              className="generalStyles__error generalStyles__mrFix"
              icon={faBan}
            />
            {t("general.noActions")}
          </span>
        </div>
      ),
      key: "0",
    };

    const FLOOR_NAMES = validatePermission("bank-managerupdate", permissions)
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenDrawerFloorNames();
              }}
              onKeyDown={() => {
                handleOpenDrawerFloorNames();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faElevator}
                />
                {t("edgeManagement.banks.floorNames")}
              </span>
            </div>
          ),
          key: "1",
        }
      : null;

    const EDIT = validatePermission("bank-managerupdate", permissions)
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenModalEdit();
              }}
              onKeyDown={() => {
                handleOpenModalEdit();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faPenToSquare}
                />
                {t("organizationManagement.edit")}
              </span>
            </div>
          ),
          key: "2",
        }
      : null;

    const DELETE = validatePermission("bank-managerdelete", permissions)
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenModalDelete();
              }}
              onKeyDown={() => {
                handleOpenModalDelete();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faTrash}
                />
                {t("organizationManagement.delete")}
              </span>
            </div>
          ),
          key: "3",
        }
      : null;

    const CHECK_OFFSETS = validatePermission("bank-managerupdate", permissions)
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenDrawerCheckOffsets();
              }}
              onKeyDown={() => {
                handleOpenDrawerCheckOffsets();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faTable}
                />
                {t("edgeManagement.banks.checkOffsets")}
              </span>
            </div>
          ),
          key: "4",
        }
      : null;

    const LIFT_POSITION = validatePermission("bank-managerupdate", permissions)
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenDrawerLiftsPosition();
              }}
              onKeyDown={() => {
                handleOpenDrawerLiftsPosition();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faArrowUp19}
                />
                {t("edgeManagement.banks.liftPosition")}
              </span>
            </div>
          ),
          key: "6",
        }
      : null;

    // RETURN OBJECT WITH VALID ACTIONS
    if (EDIT || DELETE || FLOOR_NAMES || CHECK_OFFSETS || LIFT_POSITION) {
      return [EDIT, DELETE, FLOOR_NAMES, CHECK_OFFSETS, LIFT_POSITION];
    }
    return [BLANK];
  };

  const menu = <Menu items={manageActions()} />;

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleOpenModalEdit = () => {
    dispatch(updateBank(selectedItem));
    dispatch(updateOpenModalEditBank(true));
  };

  const handleOpenModalDelete = () => {
    dispatch(updateBank(selectedItem));
    dispatch(updateOpenModalDeleteBank(true));
  };

  const handleOpenDrawerFloorNames = () => {
    dispatch(updateBank(selectedItem));
    dispatch(updateOpenDrawerBankFloorNames(true));
  };

  const handleOpenDrawerCheckOffsets = () => {
    dispatch(updateBank(selectedItem));
    dispatch(updateOpenDrawerBankCheckOffsets(true));
  };

  const handleOpenDrawerLiftsPosition = () => {
    dispatch(updateBank(selectedItem));
    dispatch(updateOpenDrawerLiftsPosition(true));
  };

  const onCellNameClick = (record: any) => {
    if (record) {
      if (renderComponent === "serverPage") {
        dispatch(updateServerBreadcrumb([...serverBreadcrumb, record.name]));
        dispatch(updateIdBankSelected(record.id));
        dispatch(updateNameBankSelected(record.name));
        dispatch(updateBank(record));
      } else {
        dispatch(updateBankBreadcrumb([record.name]));
        dispatch(updateIdBankSelected(record.id));
        dispatch(updateNameBankSelected(record.name));
        dispatch(updateBank(record));
      }
    }
  };

  const onCellClick = (record: any) => {
    if (record) {
      dispatch(updateBank(record));
      dispatch(updateOpenDrawerBank(true));
    }
  };

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: any
  ) => {
    dispatch(
      updateBankTableParams({
        pagination,
        filters,
        ...sorter,
      })
    );
    //
    const token = await GETJwtToken();

    if (renderComponent === "serverPage") {
      triggerGetBanksByService({
        page: pagination.current,
        limit: pagination.pageSize,
        token,
        orgId: idServerSelected,
      });
    } else {
      triggerGetBanksTable({
        page: pagination.current,
        limit: pagination.pageSize,
        token,
        orgId: idBankSelected,
      });
    }
  };

  const refreshTable = async (orgId: string) => {
    dispatch(
      updateBankTableParams({
        pagination: {
          current: 1,
          pageSize: 10,
          total: bankTableParams.pagination.total,
        },
        filters: {},
        sorter: {},
      })
    );
    //
    const token = await GETJwtToken();

    if (renderComponent === "serverPage") {
      await triggerGetBanksByService({
        page: 1,
        limit: 10,
        token,
        orgId: idServerSelected,
      }).unwrap();
    } else {
      await triggerGetBanksTable({
        page: 1,
        limit: 10,
        token,
        orgId,
      }).unwrap();
    }
  };

  const getInitialData = async () => {
    const token = await GETJwtToken();
    if (renderComponent === "serverPage") {
      triggerGetBanksByService({
        token,
        orgId: idServerSelected,
        page: bankTableParams.pagination.current,
        limit: bankTableParams.pagination.pageSize,
      });
    } else {
      triggerGetBanksTable({
        page: bankTableParams.pagination.current,
        limit: bankTableParams.pagination.pageSize,
        token,
      });
    }
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (userLogged && userLogged.org) {
      setIdBankSelected(userLogged.org.id);
      getInitialData();
    }
  }, []);

  useEffect(() => {
    const newData = structuredClone(data || dataGetBanksByOrganization);
    if (newData && newData.data && newData.data.length > 0) {
      dispatch(
        updateBankTableParams({
          ...bankTableParams,
          pagination: {
            ...bankTableParams.pagination,
          },
        })
      );
      setBanks(newData.data);
    }
  }, [data, dataGetBanksByOrganization]);

  useEffect(() => {
    if (isError || isErorGetBanksByOrganization) {
      setBanks([]);
    }
  }, [isError, isErorGetBanksByOrganization]);

  useEffect(() => {
    if (bankRefreshTable) {
      refreshTable(idBankSelected);
    }
  }, [bankRefreshTable]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div className="mt-3">
      {validatePermission("bank-managerread", permissions) ? (
        <>
          {!isError || !isErorGetBanksByOrganization ? (
            <Table
              rowClassName={(record, index) => getTableRowClass(index, theme)}
              className="customTable"
              dataSource={banks}
              loading={
                isLoading ||
                isFetching ||
                isLoadingGetBanksByOrganization ||
                isFetchingGetBanksByOrganization
              }
              rowKey={(record) => record.id}
              size="small"
              pagination={bankTableParams.pagination}
              onChange={handleTableChange}
              scroll={{ x: 900 }}
            >
              {/** NAME */}
              <Column
                onCell={(record) => ({
                  onClick: () => {
                    onCellNameClick(record);
                  },
                })}
                title={t("edgeManagement.banks.bankName")}
                dataIndex="name"
                key="name"
                className="generalStyles__tableHeaderLink"
                filterSearch
                width="200px"
                render={(text) => (
                  <>
                    <div className="">{text}</div>
                  </>
                )}
              />
              {/** TYPE -- */}
              <Column
                onCell={(record) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                })}
                title={t("edgeManagement.lifts.type")}
                dataIndex="type"
                key="type"
                width="100px"
                className=""
                filterSearch
                render={(text) => (
                  <>
                    <div className="">
                      {text === "ELEVATOR" && (
                        <Tag color="blue" className="drawer__typeTag">
                          {text}
                        </Tag>
                      )}
                      {text === "ESCALATOR" && (
                        <Tag color="geekblue" className="drawer__typeTag">
                          {text}
                        </Tag>
                      )}
                      {text === "WALKINGBAND" && (
                        <Tag color="cyan" className="drawer__typeTag">
                          {text}
                        </Tag>
                      )}
                    </div>
                  </>
                )}
              />
              {/** SERVER NAME */}
              <Column
                onCell={(record) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                })}
                width="150px"
                title={t("edgeManagement.servers.server")}
                render={(text, record: any) => (
                  <>
                    <div className="">
                      {record &&
                      record.channel &&
                      record.channel.server &&
                      record.channel.server.name
                        ? record.channel.server.name
                        : ""}
                    </div>
                  </>
                )}
              />
              {/** CHANNEL NAME */}
              <Column
                onCell={(record) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                })}
                width="150px"
                title={t("edgeManagement.servers.channel")}
                render={(text, record: any) => (
                  <>
                    <div className="">
                      {record && record.channel && record.channel.name
                        ? record.channel.name
                        : "--"}
                    </div>
                  </>
                )}
              />
              {/** TOTAL LIFTS */}
              <Column
                onCell={(record) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                })}
                width="75px"
                title={t("edgeManagement.lifts.lifts")}
                dataIndex="total"
                key="total"
                render={(text, record: any) => (
                  <>
                    <div className="">
                      {record && record.lifts ? record.lifts.length : 0}
                    </div>
                  </>
                )}
              />
              {/** TOTAL STOPS */}
              <Column
                onCell={(record) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                })}
                width="75px"
                title={t("configuration.configurationFiles.stops")}
                dataIndex="stops"
                key="stops"
                render={(text, record: any) => (
                  <>
                    <div className="">
                      {record && record.floor_count ? record.floor_count : 0}
                    </div>
                  </>
                )}
              />
              {/** TOTAL STOPS BELOW LOBBY */}
              <Column
                onCell={(record) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                })}
                width="125px"
                title={t("configuration.configurationFiles.floorsBelowLobby")}
                dataIndex="stopsBelowLobby"
                key="stopsBelowLobby"
                render={(text, record: any) => (
                  <>
                    <div className="">
                      {record && record.floor_below_lobby
                        ? record.floor_below_lobby
                        : 0}
                    </div>
                  </>
                )}
              />
              {/** STATUS */}
              <Column
                onCell={(record) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                })}
                title={t("userManagement.status")}
                dataIndex="status"
                key="status"
                width="150px"
                className=""
                filterSearch
                render={(text, record: any) => (
                  <>
                    <div className="">
                      {record.active && (
                        <Tag
                          color="success"
                          icon={
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="generalStyles__mrFix"
                            />
                          }
                          className="drawer__tag"
                        >
                          {t("general.active")}
                        </Tag>
                      )}
                      {!record.active && (
                        <Tag
                          color="error"
                          icon={
                            <FontAwesomeIcon
                              icon={faLock}
                              className="generalStyles__mrFix"
                            />
                          }
                          className="drawer__tag"
                        >
                          {t("general.lock")}
                        </Tag>
                      )}
                    </div>
                  </>
                )}
              />
              {/** ACTIONS */}
              <Column
                title={t("edgeManagement.banks.actions")}
                key="action"
                width="150px"
                render={(_: any, record: any) => (
                  <Dropdown overlay={menu} trigger={["click"]} className="">
                    <Space
                      className="generalStyles__hoverCursor"
                      onClick={() => {
                        setSelectedItem(record);
                      }}
                    >
                      <FontAwesomeIcon icon={faEllipsis} />
                    </Space>
                  </Dropdown>
                )}
              />
            </Table>
          ) : (
            <Alert
              message={t("organizationManagement.errorDescription.error")}
              description={t("organizationManagement.errorDescription.text1")}
              type="error"
              showIcon
              className="mt-3"
            />
          )}
        </>
      ) : (
        <>
          <div className="generalStyles__noAccessToListTable">
            <Empty
              description={t("edgeManagement.banks.listNotAvailable")}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default BanksTable;
