import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Button, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenAlertDownloadReport } from "../../redux/reports/reportsSlice";

function ReportsAlertDownloadModal({ setDownloadReport }: any) {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [isLoading, setIsLoading] = useState(false);

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { openModalDownloadReport } = useSelector((state: any) => state.report);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleIsLoading = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const handleCloseModal = () => {
    dispatch(updateOpenAlertDownloadReport(false));
  };

  const onClickDownloadReport = async () => {
    handleIsLoading();
    setDownloadReport(true);
    dispatch(updateOpenAlertDownloadReport(false));
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {}, [openModalDownloadReport]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Modal
      onOk={handleCloseModal}
      closable={false}
      onCancel={handleCloseModal}
      visible={openModalDownloadReport}
      footer={null}
    >
      <Spin spinning={isLoading}>
        <div>
          <div className="text-center">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="generalStyles__warning generalStyles__deleteItemIcon"
            />
            <div className="mt-3 mb-3">
              Please note that reports download are audited. By continuing, you
              confirm that you are following your organizations best practices
              for exporting, using, and retaining data.{" "}
            </div>
          </div>
          <div className="generalStyles__flexEnd mt-4">
            <div>
              <Button onClick={handleCloseModal} className="buttonStyle__4">
                {t("general.close")}
              </Button>
              <Button
                htmlType="submit"
                className="buttonStyle__2 generalStyles__mlFix"
                onClick={onClickDownloadReport}
              >
                {t("general.download")}
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
}

export default ReportsAlertDownloadModal;
