import React from "react";
// ANT DESIGN COMPONENTS
import { Button } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faArrowsRotate,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  updateOpenModalCreateNewRole,
  updateRoleRefreshTable,
  updateCleanFilters,
} from "../../../redux/roles/roleSlice";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";

function RolesHeader() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const { permissions } = useSelector((state: any) => state.user);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleOpenModalCreateNewRole = () => {
    dispatch(updateOpenModalCreateNewRole(true));
  };

  const onClickReloadPage = () => {
    dispatch(updateRoleRefreshTable(true));
    setTimeout(() => {
      dispatch(updateRoleRefreshTable(false));
    }, 1000);
  };

  const onClickCleanFilters = () => {
    dispatch(updateCleanFilters(true));
    setTimeout(() => {
      dispatch(updateCleanFilters(false));
    }, 1000);
  };

  return (
    <div className="generalStyles__pageHeader">
      {validatePermission("roles-and-permissionscreate", permissions) && (
        <div className="generalStyles__mlFix mt-1">
          <Button
            type="primary"
            icon={
              <FontAwesomeIcon icon={faPlus} className="generalStyles__mrFix" />
            }
            onClick={handleOpenModalCreateNewRole}
          >
            {t("organizationManagement.createNewRole")}
          </Button>
        </div>
      )}
      {validatePermission("roles-and-permissionsread", permissions) && (
        <div className="generalStyles__mlFix mt-1">
          <Button
            type="primary"
            icon={
              <FontAwesomeIcon
                icon={faArrowsRotate}
                className="generalStyles__mrFix"
              />
            }
            onClick={() => {
              onClickReloadPage();
            }}
          >
            {t("organizationManagement.refreshTable")}
          </Button>
        </div>
      )}
      {validatePermission("roles-and-permissionsread", permissions) && (
        <div className="generalStyles__mlFix mt-1">
          <Button
            className="buttonStyle__2"
            icon={
              <FontAwesomeIcon
                icon={faTrash}
                className="generalStyles__mrFix"
              />
            }
            onClick={() => {
              onClickCleanFilters();
            }}
          >
            {t("general.clearFilters")}
          </Button>
        </div>
      )}
    </div>
  );
}

export default RolesHeader;
