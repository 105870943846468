/* eslint-disable */
import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import {
  Spin,
  Divider,
  Drawer,
  Input,
  InputNumber,
  Button,
  Tooltip,
  Select,
} from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faServer,
  faCircleInfo,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  updateOpenModalCreateNewServer,
  updateServerAlert,
} from "../../../redux/servers/serverSlice";
import { usePostServerMutation } from "../../../redux/servers/serverAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import OrganizationSelectOrganizationSubOrganization from "../../organizationManagement/organizations/OrganizationSelectOrganizationSubOrganization";

const { Option } = Select;

function ServersCreateForm() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [organizationsAndChildsIsLoading, setOrganizationsAndChildsIsLoading] =
    useState(false);
  const [name, setName] = useState("");
  const [serverIp, setServerIp] = useState("");
  const [serverIpIsValid, setServerIpIsValid] = useState(false);
  const [channelIpIsValid, setChannelIpIsValid] = useState(false);
  const [channelNameTCP, setChannelnameTCP] = useState("");
  const [channelNameSerial, setChannelnameSerial] = useState("/dev/ttyS");
  const [channelIndex, setChannelIndex] = useState("");
  const [channelBaudRate, setChannelBaudRate] = useState(1);
  const [channelType, setChannelType] = useState("SERIAL");
  const [channels, setChannels] = useState<any>([]);
  const [channelIp, setChannelIp] = useState("");
  const [channelNumber, setChannelNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { openModalCreateNewServer } = useSelector(
    (state: any) => state.server
  );
  const { theme } = useSelector((state: any) => state.home);
  const { organizationAndChilds } = useSelector(
    (state: any) => state.organization
  );
  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    postServer,
    {
      isSuccess: isSuccessPostServer,
      isLoading: isLoadingPostServer,
      isError: isErrorPostServer,
      error: errorPostServer,
      reset: resetPostServer,
    },
  ] = usePostServerMutation();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const showIsLoading = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const onChangeChannelNameTCP = (newValue: string) => {
    setChannelnameTCP(newValue);
  };

  const onChangeServerIp = (newValue: string) => {
    setServerIp(newValue);
    if (
      /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}(\/\d+)?$/.test(
        newValue
      )
    ) {
      setServerIpIsValid(true);
    } else {
      setServerIpIsValid(false);
    }
  };

  const onChangeChannelNameSerial = (newValue: string) => {
    setChannelnameSerial(newValue);
  };

  const onChangeChannelIndex = (newValue: string) => {
    setChannelIndex(newValue);
    setChannelnameSerial(`/dev/ttyS${newValue}`);
  };

  const onChangeChannelBaudRate = (newValue: number) => {
    setChannelBaudRate(newValue);
  };

  const onChangeChannelType = (newValue: string) => {
    setChannelType(newValue);
  };

  const onChangeChannelIp = (newValue: string) => {
    setChannelIp(newValue);
    if (
      /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}(\/\d+)?$/.test(
        newValue
      )
    ) {
      setChannelIpIsValid(true);
    } else {
      setChannelIpIsValid(false);
    }
  };

  const onChangeChannelNumber = (newValue: number) => {
    setChannelNumber(newValue);
  };

  const onClickClose = () => {
    dispatch(updateOpenModalCreateNewServer(false));
    setOrganizationsAndChildsIsLoading(false);
    setName("");
    setServerIp("");
    setServerIpIsValid(false);
    setChannelIpIsValid(false);
    setChannelnameTCP("");
    setChannelnameSerial("/dev/ttyS");
    setChannelIndex("");
    setChannelBaudRate(1);
    setChannelType("SERIAL");
    setChannels([]);
    setChannelIp("");
    setChannelNumber(0);
  };

  const onClickAddNewChannel = () => {
    showIsLoading();
    const clone = structuredClone(channels);
    clone.push({
      active: true,
      baud_rate: channelBaudRate,
      index: channelType === "TCP" ? "" : channelIndex,
      ip: channelType === "SERIAL" ? "0.0.0.0" : channelIp,
      port_number: channelType === "SERIAL" ? 0 : channelNumber,
      name: channelType === "SERIAL" ? channelNameSerial : channelNameTCP,
      type: channelType,
    });
    setChannels(clone);
    setChannelnameTCP("");
    setChannelnameSerial("/dev/ttyS");
    setChannelIndex("");
    setChannelBaudRate(1);
    setChannelType("SERIAL");
    setChannelIp("");
    setChannelNumber(0);
  };

  const onClickRemoveChannel = (removeIndex: number) => {
    showIsLoading();
    const newArr: any = [];
    for (let x = 0; x < channels.length; x += 1) {
      if (x !== removeIndex) {
        newArr.push(channels[x]);
      }
    }
    setChannels(newArr);
  };

  const onFinish = async () => {
    const channelsObj = structuredClone(channels);
    if (channelNameTCP !== "" || channelNameSerial !== "") {
      channelsObj.push({
        active: true,
        name: channelType === "SERIAL" ? channelNameSerial : channelNameTCP,
        index: channelType === "TCP" ? "" : channelIndex,
        baud_rate: channelBaudRate,
        type: channelType,
        ip: channelType === "SERIAL" ? "0.0.0.0" : channelIp,
        port_number: channelType === "SERIAL" ? 0 : channelNumber,
      });
    }
    const token = await GETJwtToken();
    const BODY = {
      token,
      body: {
        banks: [],
        ip: serverIp,
        name,
        channels: channelsObj,
        org_id: organizationAndChilds.data.idOrganizationSelected
          ? organizationAndChilds.data.idOrganizationSelected
          : "",
      },
    };
    postServer(BODY);
  };

  const onChangeName = (newValue: string) => {
    setName(newValue);
  };

  const disableCreateNewChannelButton = () => {
    if (channelType === "TCP") {
      if (channelNameTCP === "" || !channelIpIsValid) {
        return true;
      }
    } else if (channelType === "SERIAL" && channelNameSerial === "") {
      return true;
    }
    return false;
  };
  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (openModalCreateNewServer) {
      resetPostServer();
    }
  }, [openModalCreateNewServer]);

  useEffect(() => {
    if (isSuccessPostServer) {
      setTimeout(() => {
        dispatch(
          updateServerAlert({
            title: t("general.success"),
            description: t("edgeManagement.servers.serverCreated"),
            status: "success",
          })
        );
      }, 150);
      onClickClose();
    }
    if (isErrorPostServer) {
      setTimeout(() => {
        dispatch(
          updateServerAlert({
            title: t("general.error"),
            description: errorPostServer,
            status: "error",
          })
        );
      }, 150);
      onClickClose();
    }
  }, [isSuccessPostServer, isErrorPostServer]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClickClose}
      closable={false}
      visible={openModalCreateNewServer}
    >
      <Spin
        spinning={
          isLoadingPostServer || organizationsAndChildsIsLoading || isLoading
        }
        tip={t("general.loading")}
      >
        {/** ---------------------------------------------------- */}
        {/** SERVER INFORMATION  */}
        <div>
          <Divider orientation="left" className="generalStyles__drawerDivider">
            <h5>
              <FontAwesomeIcon
                icon={faServer}
                className="generalStyles__info generalStyles__mrFix"
              />
              {t("edgeManagement.servers.createNewServer")}
            </h5>
          </Divider>
        </div>
        <div
          className={
            theme === "dark"
              ? "row drawer__box__dark"
              : "row drawer__box__light"
          }
        >
          {/** NAME  */}
          <div className="col-md-12">
            <div className="generalStyles__flex">
              <div className="generalStyles__inputFlexName">
                <span className="generalStyles__inputFlexRequired">*</span>
                <span className="drawer__text">
                  {t("edgeManagement.servers.serverName")}
                </span>
              </div>
              <div className="generalStyles__width100">
                <Input
                  className="generalStyles__input"
                  onChange={(event: React.FormEvent<HTMLInputElement>) => {
                    onChangeName(event.currentTarget.value);
                  }}
                  value={name}
                  size="small"
                />
              </div>
              <div className="generalStyles__infoTooltipIconBlank" />
            </div>
          </div>
          {/** SERVER IP  */}
          <div className="col-md-12 mt-3">
            <div className="generalStyles__flex">
              <div className="generalStyles__inputFlexName">
                <span className="generalStyles__inputFlexRequired">*</span>
                <span className="drawer__text">
                  {t("edgeManagement.servers.ip")}
                </span>
              </div>
              <div className="generalStyles__width100">
                <Input
                  className="generalStyles__input"
                  onChange={(newValue: React.FormEvent<HTMLInputElement>) => {
                    onChangeServerIp(newValue.currentTarget.value);
                  }}
                  value={serverIp}
                  size="small"
                  status={!serverIpIsValid && serverIp !== "" ? "error" : ""}
                />
              </div>
              <div>
                <Tooltip title={t("tooltip.ipFormatValid")} placement="left">
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    className="generalStyles__infoTooltipIcon"
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        {/** ---------------------------------------------------- */}
        {/** GET ORGANIZATION AND CHILDS  */}
        <div className="container">
          <OrganizationSelectOrganizationSubOrganization
            setOrganizationsAndChildsIsLoading={
              setOrganizationsAndChildsIsLoading
            }
          />
        </div>
        <br />
        {/** ---------------------------------------------------- */}
        {/** CHANNEL INFORMATION  */}
        <div>
          <div>
            <div>
              <Divider
                orientation="left"
                className="generalStyles__drawerDivider"
              >
                <h5>
                  <FontAwesomeIcon
                    icon={faServer}
                    className="generalStyles__info generalStyles__mrFix"
                  />
                  {t("edgeManagement.servers.createChannel")}
                </h5>
              </Divider>
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className={
              theme === "dark"
                ? "row drawer__box__dark"
                : "row drawer__box__light"
            }
          >
            {channelType === "SERIAL" && (
              <>
                {/** CHANNEL INDEX  */}
                <div className="col-md-12 mt-3">
                  <div className="generalStyles__flex">
                    <div className="generalStyles__inputFlexName">
                      <span className="drawer__text">
                        {t("edgeManagement.servers.index")}
                      </span>
                    </div>
                    <div className="generalStyles__width100">
                      <Input
                        className="generalStyles__input"
                        size="small"
                        onChange={(
                          event: React.FormEvent<HTMLInputElement>
                        ) => {
                          onChangeChannelIndex(event.currentTarget.value);
                        }}
                        value={channelIndex}
                      />
                    </div>
                    <div className="generalStyles__infoTooltipIconBlank" />
                  </div>
                </div>
              </>
            )}
            {channelType === "TCP" && (
              <>
                {/** NAME TCP  */}
                <div className="col-md-12 mt-3">
                  <div className="generalStyles__flex">
                    <div className="generalStyles__inputFlexName">
                      <span className="drawer__text">{t("general.name")}</span>
                    </div>
                    <div className="generalStyles__width100">
                      <Input
                        className="generalStyles__input"
                        onChange={(
                          event: React.FormEvent<HTMLInputElement>
                        ) => {
                          onChangeChannelNameTCP(event.currentTarget.value);
                        }}
                        value={channelNameTCP}
                        size="small"
                      />
                    </div>
                    <div className="generalStyles__infoTooltipIconBlank" />
                  </div>
                </div>
                {/** CHANNEL  IP  */}
                <div className="col-md-12 mt-3">
                  <div className="generalStyles__flex">
                    <div className="generalStyles__inputFlexName">
                      <span className="drawer__text">
                        {t("edgeManagement.servers.channelIp")}
                      </span>
                    </div>
                    <div className="generalStyles__width100">
                      <Input
                        className="generalStyles__input"
                        onChange={(
                          newValue: React.FormEvent<HTMLInputElement>
                        ) => {
                          onChangeChannelIp(newValue.currentTarget.value);
                        }}
                        value={channelIp}
                        size="small"
                        status={
                          !channelIpIsValid && channelIp !== "" ? "error" : ""
                        }
                      />
                    </div>
                    <div>
                      <Tooltip
                        title={t("tooltip.ipFormatValid")}
                        placement="left"
                      >
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          className="generalStyles__infoTooltipIcon"
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
                {/** CHANNEL NUMBER  */}
                <div className="col-md-12 mt-3">
                  <div className="generalStyles__flex">
                    <div className="generalStyles__inputFlexName">
                      <span className="drawer__text">
                        {t("edgeManagement.servers.channelNumber")}
                      </span>
                    </div>
                    <div className="generalStyles__width100">
                      <InputNumber
                        className="generalStyles__input"
                        size="small"
                        onChange={(e: number) => {
                          onChangeChannelNumber(e);
                        }}
                        value={channelBaudRate}
                        style={{ width: "100%" }}
                        min={1}
                      />
                    </div>
                    <div className="generalStyles__infoTooltipIconBlank" />
                  </div>
                </div>
              </>
            )}
            {/** CHANNEL NAME SERIAL  */}
            {channelType === "SERIAL" && (
              <div className="col-md-12 mt-3">
                <div className="generalStyles__flex">
                  <div className="generalStyles__inputFlexName">
                    <span className="drawer__text">{t("general.name")}</span>
                  </div>
                  <div className="generalStyles__width100">
                    <Input
                      className="generalStyles__input"
                      onChange={(event: React.FormEvent<HTMLInputElement>) => {
                        onChangeChannelNameSerial(event.currentTarget.value);
                      }}
                      value={channelNameSerial}
                      size="small"
                    />
                  </div>
                  <div className="generalStyles__infoTooltipIconBlank" />
                </div>
              </div>
            )}
            {/** CHANNEL BAUD RATE  */}
            {channelType === "SERIAL" && (
              <div className="col-md-12 mt-3">
                <div className="generalStyles__flex">
                  <div className="generalStyles__inputFlexName">
                    <span className="drawer__text">
                      {t("edgeManagement.servers.baudRate")}
                    </span>
                  </div>
                  <div className="generalStyles__width100">
                    <InputNumber
                      className="generalStyles__input"
                      size="small"
                      onChange={(e: number) => {
                        onChangeChannelBaudRate(e);
                      }}
                      value={channelBaudRate}
                      style={{ width: "100%" }}
                      min={1}
                    />
                  </div>
                  <div className="generalStyles__infoTooltipIconBlank" />
                </div>
              </div>
            )}
            {/** CHANNEL TYPE  */}
            <div className="col-md-12 mt-3">
              <div className="generalStyles__flex">
                <div className="generalStyles__inputFlexName">
                  <span>{t("edgeManagement.lifts.type")}</span>
                </div>
                <div className="generalStyles__width100">
                  <Select
                    value={channelType}
                    onChange={onChangeChannelType}
                    className="generalStyles__width100"
                    size="small"
                  >
                    {["SERIAL", "TCP"].map((item: any) => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="generalStyles__infoTooltipIconBlank" />
              </div>
            </div>
            {/** ADD NEW CHANNEL  */}
            <div className="generalStyles__flexEnd">
              <div className="mt-3">
                <Tooltip
                  placement="left"
                  title={t("edgeManagement.servers.addChannel")}
                >
                  <Button
                    onClick={() => {
                      onClickAddNewChannel();
                    }}
                    icon={<FontAwesomeIcon icon={faPlus} />}
                    shape="circle"
                    disabled={disableCreateNewChannelButton()}
                    type="primary"
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        {channels && channels.length > 0 && (
          <>
            {channels.map((item: any, index2: number) => (
              <div className="container">
                <br />
                <div
                  className={
                    theme === "dark"
                      ? "row drawer__box__dark"
                      : "row drawer__box__light"
                  }
                >
                  {item.type === "SERIAL" && (
                    <>
                      {/** CHANNEL INDEX  */}
                      <div className="col-md-12">
                        <div className="generalStyles__flex">
                          <div className="generalStyles__inputFlexName">
                            <span className="drawer__text">
                              {t("edgeManagement.servers.index")}
                            </span>
                          </div>
                          <div className="generalStyles__width100">
                            <Input
                              className="generalStyles__input"
                              size="small"
                              value={item.index}
                              disabled
                            />
                          </div>
                          <div className="generalStyles__infoTooltipIconBlank" />
                        </div>
                      </div>
                    </>
                  )}
                  {/** CHANNEL NAME  */}
                  <div className="col-md-12 mt-3">
                    <div className="generalStyles__flex">
                      <div className="generalStyles__inputFlexName">
                        <span className="drawer__text">
                          {t("general.name")}
                        </span>
                      </div>
                      <div className="generalStyles__width100">
                        <Input
                          className="generalStyles__input"
                          value={item.name}
                          size="small"
                          disabled
                        />
                      </div>
                      <div className="generalStyles__infoTooltipIconBlank" />
                    </div>
                  </div>
                  {item.type === "SERIAL" && (
                    <>
                      {/** CHANNEL BAUD RATE  */}
                      <div className="col-md-12 mt-3">
                        <div className="generalStyles__flex">
                          <div className="generalStyles__inputFlexName">
                            <span className="drawer__text">
                              {t("edgeManagement.servers.baudRate")}
                            </span>
                          </div>
                          <div className="generalStyles__width100">
                            <Input
                              className="generalStyles__input"
                              value={item.baud_rate}
                              size="small"
                              disabled
                            />
                          </div>
                          <div className="generalStyles__infoTooltipIconBlank" />
                        </div>
                      </div>
                    </>
                  )}
                  {item.type === "TCP" && (
                    <>
                      {/** CHANNEL IP  */}
                      <div className="col-md-12 mt-3">
                        <div className="generalStyles__flex">
                          <div className="generalStyles__inputFlexName">
                            <span className="drawer__text">
                              {t("edgeManagement.servers.channelIp")}
                            </span>
                          </div>
                          <div className="generalStyles__width100">
                            <Input
                              className="generalStyles__input"
                              value={item.ip}
                              size="small"
                              disabled
                            />
                          </div>
                          <div className="generalStyles__infoTooltipIconBlank" />
                        </div>
                      </div>
                      {/** CHANNEL NUMBER  */}
                      <div className="col-md-12 mt-3">
                        <div className="generalStyles__flex">
                          <div className="generalStyles__inputFlexName">
                            <span className="drawer__text">
                              {t("edgeManagement.servers.channelNumber")}
                            </span>
                          </div>
                          <div className="generalStyles__width100">
                            <Input
                              className="generalStyles__input"
                              value={item.port_number}
                              size="small"
                              disabled
                            />
                          </div>
                          <div className="generalStyles__infoTooltipIconBlank" />
                        </div>
                      </div>
                    </>
                  )}
                  {/** CHANNEL TYPE  */}
                  <div className="col-md-12 mt-3">
                    <div className="generalStyles__flex">
                      <div className="generalStyles__inputFlexName">
                        <span>{t("edgeManagement.lifts.type")}</span>
                      </div>
                      <div className="generalStyles__width100">
                        <Input
                          className="generalStyles__input"
                          size="small"
                          value={item.type}
                          disabled
                        />
                      </div>
                      <div className="generalStyles__infoTooltipIconBlank" />
                    </div>
                  </div>
                  {/** REMOVE CHANNEL  */}
                  <div className="mt-3 generalStyles__flexEnd">
                    <div>
                      <Tooltip
                        placement="left"
                        title={`${t(
                          "edgeManagement.servers.removeChannel"
                        )} - ${item.name}`}
                      >
                        <Button
                          onClick={() => {
                            onClickRemoveChannel(index2);
                          }}
                          style={{ borderStyle: "none" }}
                          icon={<FontAwesomeIcon icon={faTrash} />}
                          className="buttonStyle__2"
                          shape="circle"
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
        {/** ---------------------------------------------------- */}
        <div className="mt-4">
          <div>
            <Button
              type="default"
              onClick={onClickClose}
              className="buttonStyle__3"
            >
              {t("edgeManagement.close")}
            </Button>
            <Button
              onClick={onFinish}
              disabled={
                isLoadingPostServer ||
                name === "" ||
                serverIp === "" ||
                !serverIpIsValid
              }
              className="buttonStyle__3"
              type="primary"
            >
              {t("general.save")}
            </Button>
          </div>
        </div>
      </Spin>
    </Drawer>
  );
}

export default ServersCreateForm;
