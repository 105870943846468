import React from 'react'
// ANT DESIGN COMPONENTS
import { Alert, Button } from 'antd'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser } from '@fortawesome/free-solid-svg-icons'
// STYLES

library.add(faUser)

function NoInternetConnection() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  // eslint-disable-next-line no-unused-vars
  const [t] = useTranslation('global')

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const routeChange = () => {
    localStorage.setItem('headerTitle', 'dashboard')
    window.location.replace('http://dev.agilegoods.com/')
  }
  // ************************************************ */
  // FUNCTIONS ************************************** */

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      <div className="generalStyles__flexCenter mb-5 mt-5">
        <Alert
          message="No Internet"
          description="This page require internet connectivity, Please check the internet connection and tray again."
          type="error"
          showIcon
        />
      </div>
      <div className="generalStyles__flexCenter mb-3">
        <Button onClick={routeChange} type="primary">
          {t('general.backToDashboard')}
        </Button>
      </div>
    </div>
  )
}

export default NoInternetConnection
