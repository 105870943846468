import React, { useEffect } from "react";
// REACT DETECT OFFLINE
import { Offline, Online } from "react-detect-offline";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
  updateServerBreadcrumb,
  updateIdServerSelected,
  updateNameServerSelected,
} from "../../../redux/servers/serverSlice";
import {
  updateIdBankSelected,
  updateNameBankSelected,
  updateBankBreadcrumb,
} from "../../../redux/banks/bankSlice";
// COMPONENTS
import ServersTable from "./ServersTable";
import ServersHeader from "./ServersHeader";
import ServersCreateForm from "./ServersCreateForm";
import ServersDrawer from "./ServersDrawer";
import ServersDeleteMOdal from "./ServersDeleteModal";
import ServersEditForm from "./ServersEditForm";
import BanksTable from "../banks/BanksTable";
import BanksCreateForm from "../banks/BanksCreateForm";
import BanksHeader from "../banks/BanksHeader";
import LiftsTable from "../lifts/LiftsTable";
import LiftsHeader from "../lifts/LiftsHeader";
import LiftsCreateForm from "../lifts/LiftsCreateForm";
import BanksDrawer from "../banks/BanksDrawer";
import LiftsDrawer from "../lifts/LiftsDrawer";
import ServerCreateFormChannels from "./ServerCreateFormChannels";
import BanksDeleteModal from "../banks/BanksDeleteModal";
import BanksEditForm from "../banks/BanksEditForm";
import BanksFloorNames from "../banks/BanksFloorNames";
import BanksCheckOffsets from "../banks/BanksCheckOffsets";
import BanksDrawerLiftsPosition from "../banks/BanksDrawerLiftsPosition";
import LiftsDeleteModal from "../lifts/LiftsDeleteModal";
import LiftsEditForm from "../lifts/LiftsEditForm";
import LiftsActivateDeactivate from "../lifts/LiftsActivateDeactivate";
import LiftsConfiguration from "../lifts/LifstConfiguration";
// NO INTERNET CONNECTION
import NoInternetConnection from "../../unauthorized/NoInternetConnection";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

const ServersPage = () => {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { permissions } = useSelector((state: any) => state.user);
  const { theme } = useSelector((state: any) => state.home);
  const { serverBreadcrumb } = useSelector((state: any) => state.server);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    dispatch(updateServerBreadcrumb([]));
    dispatch(updateBankBreadcrumb([]));
    dispatch(updateIdServerSelected(""));
    dispatch(updateIdBankSelected(""));
    dispatch(updateNameBankSelected(""));
    dispatch(updateNameServerSelected(""));
  }, []);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      {validatePermission("server-manager", permissions) ? (
        <div>
          <div
            className={
              theme === "light"
                ? "layout__contentPage__light"
                : "layout__contentPage__dark"
            }
          >
            <Online>
              {serverBreadcrumb.length === 0 && (
                <>
                  <ServersHeader />
                  <ServersTable />
                </>
              )}
              {serverBreadcrumb.length === 1 && (
                <>
                  <BanksHeader />
                  <BanksTable renderComponent="serverPage" />
                </>
              )}
              {serverBreadcrumb.length === 2 && (
                <>
                  <LiftsHeader />
                  <LiftsTable renderComponent="bankPage" />
                </>
              )}
              <ServersCreateForm />
              <ServersDrawer />
              <BanksDrawer />
              <ServersDeleteMOdal />
              <ServersEditForm />
              <ServerCreateFormChannels />
              <BanksCreateForm />
              <BanksDeleteModal />
              <BanksEditForm />
              <BanksFloorNames />
              <BanksCheckOffsets />
              <BanksDrawerLiftsPosition />
              <LiftsDrawer />
              <LiftsCreateForm />
              <LiftsDeleteModal />
              <LiftsEditForm />
              <LiftsActivateDeactivate />
              <LiftsConfiguration />
            </Online>
            <Offline>
              <NoInternetConnection />
            </Offline>
          </div>
        </div>
      ) : (
        <UnauthorizedPage />
      )}
    </div>
  );
};

export default ServersPage;
