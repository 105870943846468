import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Button, Input, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useDeleteUserMutation } from "../../../redux/users/userAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import {
  updateOpenModalDeleteUser,
  updateUserAlert,
} from "../../../redux/users/userSlice";
// UTILS
import { styleDeleteButton } from "../../../utils/utils";

library.add(faTrash, faTriangleExclamation);

function UsersDeleteUserModal() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch();
  const { user, openModalDeleteUser } = useSelector((state: any) => state.user);
  const [disableButtonConfirmDelete, setDisableButtonConfirmDelete] =
    useState(true);
  const [confirmUserName, setConfirmUserName] = useState("");
  const [t] = useTranslation("global");
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    triggerDeleteUser,
    { isLoading, isSuccess, isError, error, reset: resetDeleteUser },
  ] = useDeleteUserMutation();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleOk = () => {
    dispatch(updateOpenModalDeleteUser(false));
  };

  const handleCancel = () => {
    dispatch(updateOpenModalDeleteUser(false));
  };

  const handleCloseModal = () => {
    dispatch(updateOpenModalDeleteUser(false));
  };

  const handleDeleteItem = async () => {
    const token = await GETJwtToken();
    triggerDeleteUser({ id: user.id, token });
  };

  const handleSubmitDeleteItem = async (event: any) => {
    if (event.key === "Enter" && !disableButtonConfirmDelete) {
      const token = await GETJwtToken();
      triggerDeleteUser({ id: user.id, token });
    }
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (openModalDeleteUser) {
      setDisableButtonConfirmDelete(true);
      setConfirmUserName("");
      resetDeleteUser();
    }
  }, [openModalDeleteUser]);

  useEffect(() => {
    setDisableButtonConfirmDelete(true);
    let firstName = "";
    let middleName = "";
    let lastName = "";
    if (user.first_name && user.first_name !== "") {
      firstName = user.first_name;
    }
    if (user.middle_name && user.middle_name !== "") {
      middleName = ` ${user.middle_name}`;
    }
    if (user.last_name && user.middle_name !== "") {
      lastName = ` ${user.last_name}`;
    }
    const userName = firstName + middleName + lastName;
    //
    if (
      confirmUserName &&
      confirmUserName.toLowerCase() === userName.toLowerCase()
    ) {
      setDisableButtonConfirmDelete(false);
    } else {
      setDisableButtonConfirmDelete(true);
    }
  }, [confirmUserName]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        dispatch(
          updateUserAlert({
            title: t("general.success"),
            description: t("userManagement.userDeleted"),
            status: "success",
          })
        );
      }, 150);
      dispatch(updateOpenModalDeleteUser(false));
    }
    if (isError) {
      setTimeout(() => {
        dispatch(
          updateUserAlert({
            title: t("general.error"),
            description: error,
            status: "error",
          })
        );
      }, 150);
      dispatch(updateOpenModalDeleteUser(false));
    }
  }, [isSuccess, isError]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Modal
      onOk={handleOk}
      closable={false}
      onCancel={handleCancel}
      visible={openModalDeleteUser}
      footer={null}
    >
      <Spin spinning={isLoading}>
        <div>
          <div className="text-center">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="generalStyles__warning generalStyles__deleteItemIcon"
            />
            <p className="mt-3 drawer__text">
              {t("userManagement.deleteDescription.text1")}
              <b className="generalStyles__warning">
                {t("userManagement.deleteDescription.text2")}{" "}
              </b>
              {t("userManagement.deleteDescription.text3")}
              <b className="generalStyles__warning">
                {user.first_name} {user.middle_name} {user.last_name}{" "}
              </b>
              {t("userManagement.deleteDescription.text4")}
            </p>
            <Input
              placeholder={t("userManagement.userName")}
              onChange={(e) => {
                setConfirmUserName(e.target.value);
              }}
              value={confirmUserName}
              onKeyDown={handleSubmitDeleteItem}
              status="warning"
            />
          </div>
          <div className="generalStyles__flexEnd mt-4">
            <div>
              <Button
                htmlType="submit"
                className={styleDeleteButton(disableButtonConfirmDelete, theme)}
                onClick={handleDeleteItem}
                disabled={disableButtonConfirmDelete}
              >
                {t("userManagement.delete")}
              </Button>
              <Button onClick={handleCloseModal} className="buttonStyle__4">
                {t("userManagement.close")}
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
}

export default UsersDeleteUserModal;
