import React from 'react'
// ANT DESIGN COMPONENTS
import { Button } from 'antd'
// FONT AWESOME LIBRYARY AND ICONS
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
// I18N TRANSLATION
import { useTranslation } from 'react-i18next'
// REDUX
import { useDispatch, useSelector } from 'react-redux'
import {
  updateOpenModalCreateNewServer,
  updateServerRefreshTable,
} from '../../../redux/servers/serverSlice'
// AUTHORIZATION
import validatePermission from '../../../utils/validatePermissions'

library.add(faPlus, faArrowsRotate)

function ServersHeader() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch()
  const [t] = useTranslation('global')

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { permissions } = useSelector((state: any) => state.user)

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleOpenModalCreateNewServer = () => {
    dispatch(updateOpenModalCreateNewServer(true))
  }

  const handleReloadPage = () => {
    dispatch(updateServerRefreshTable(true))
    setTimeout(() => {
      dispatch(updateServerRefreshTable(false))
    }, 1000)
  }

  return (
    <div className="generalStyles__pageHeader">
      {validatePermission('server-managercreate', permissions) && (
        <div className="generalStyles__mlFix mt-1">
          <Button
            type="primary"
            icon={
              <FontAwesomeIcon icon={faPlus} className="generalStyles__mrFix" />
            }
            onClick={handleOpenModalCreateNewServer}
          >
            {t('edgeManagement.servers.createNewServer')}
          </Button>
        </div>
      )}
      {validatePermission('server-managerread', permissions) && (
        <div className="generalStyles__mlFix mt-1">
          <Button
            type="primary"
            icon={
              <FontAwesomeIcon
                icon={faArrowsRotate}
                className="generalStyles__mrFix"
              />
            }
            onClick={() => {
              handleReloadPage()
            }}
          >
            {t('edgeManagement.servers.refreshTable')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default ServersHeader
