import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import {
  Drawer,
  Button,
  Input,
  Spin,
  Divider,
  Select,
  Switch,
  Tag,
} from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  updateOpenFormOffsetType,
  updateToolAlert,
} from "../../../redux/tools/toolsSlice";
import {
  usePostOffsetTypeMutation,
  usePutOffsetTypeMutation,
} from "../../../redux/configurationFiles/configurationFilesAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

const { Option } = Select;

function OffsetTypeForm() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const classDinamic = "col-md-6 mt-3";
  const values: any = [
    { name: "Byte", action: "byte", disabled: false },
    { name: "Bit", action: "bit", disabled: false },
    { name: "Short Name", action: "shortName", disabled: false },
    { name: "Long Name", action: "longName", disabled: false },
    { name: "Special Switches", action: "specialSwitch", disabled: false },
    { name: "Active Position", action: "activePosition", disabled: false },
    { name: "Priority", action: "priority", disabled: false },
    { name: "Severity", action: "severity", disabled: false },
    { name: "Alert", action: "alert", disabled: false },
    { name: "Log", action: "log", disabled: false },
    { name: "Confirm Seconds", action: "confirmSeconds", disabled: false },
    { name: "Help ID", action: "helpId", disabled: false },
    { name: "Group", action: "group", disabled: false },
    { name: "Paging Scheme", action: "pagingScheme", disabled: false },
    {
      name: "3rd Party Position",
      action: "thirdPartyPosition",
      disabled: false,
    },
  ];
  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [offset, setOffset] = useState<any>({
    name: "",
    color: "magenta",
    attributes: {
      byte: true,
      bit: true,
      shortName: true,
      longName: true,
      specialSwitch: false,
      activePosition: false,
      priority: false,
      severity: false,
      alert: false,
      log: false,
      confirmSeconds: false,
      helpId: false,
      group: false,
      pagingScheme: false,
      thirdPartyPosition: false,
    },
  });
  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { offsetType, openFormOffsetType, openFormOffsetTypeAction } =
    useSelector((state: any) => state.tool);
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    postOffset,
    {
      isSuccess: isSuccessPost,
      isLoading: isLoadingPost,
      isError: isErrorPost,
      error: errorPost,
      reset: resetPost,
    },
  ] = usePostOffsetTypeMutation();
  const [
    putOffset,
    {
      isSuccess: isSuccessPut,
      isLoading: isLoadingPut,
      isError: isErrorPut,
      error: errorPut,
      reset: resetPut,
    },
  ] = usePutOffsetTypeMutation();
  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onFill = () => {
    const clone = structuredClone(offsetType.attributes);
    setOffset({
      name: offsetType.name,
      color: offsetType.color,
      attributes: clone,
    });
  };

  const onClickClose = () => {
    dispatch(updateOpenFormOffsetType(false));
    setOffset({
      name: "",
      color: "magenta",
      attributes: {
        byte: true,
        bit: true,
        shortName: true,
        longName: true,
        specialSwitch: false,
        activePosition: false,
        priority: false,
        severity: false,
        alert: false,
        log: false,
        confirmSeconds: false,
        helpId: false,
        group: false,
        pagingScheme: false,
        thirdPartyPosition: false,
      },
    });
  };

  const onClickSave = async () => {
    const token = await GETJwtToken();
    if (openFormOffsetTypeAction === "create") {
      const BODY = {
        token,
        body: {
          name: offset.name,
          color: offset.color,
          attributes: JSON.stringify(offset.attributes),
        },
      };
      postOffset(BODY);
    } else if (openFormOffsetTypeAction === "update") {
      const BODY = {
        token,
        id: offsetType.id,
        body: {
          name: offset.name,
          color: offset.color,
          attributes: JSON.stringify(offset.attributes),
        },
      };
      putOffset(BODY);
    }
  };

  const onChangeSwitch = (newValue: boolean, action: string) => {
    const newArr: any = { ...offset };
    newArr.attributes[action] = newValue;
    setOffset(newArr);
  };

  const onChangeName = (newValue: string) => {
    const newArr: any = { ...offset };
    newArr.name = newValue;
    setOffset(newArr);
  };

  const onChangeLabelColor = (newValue: string) => {
    const newArr: any = { ...offset };
    newArr.color = newValue;
    setOffset(newArr);
  };

  // ************************************************* */
  // USE EFFECT ************************************** */

  useEffect(() => {
    if (openFormOffsetType) {
      resetPost();
      resetPut();
      if (openFormOffsetTypeAction === "update") {
        onFill();
      }
    }
  }, [openFormOffsetType]);

  useEffect(() => {
    if (isSuccessPost) {
      setTimeout(() => {
        dispatch(
          updateToolAlert({
            title: t("general.success"),
            description: t(
              "configuration.offsetTypeConfiguration.offsetCreated"
            ),
            status: "success",
          })
        );
      }, 150);
      onClickClose();
    }
    if (isErrorPost) {
      setTimeout(() => {
        dispatch(
          updateToolAlert({
            title: t("general.error"),
            description: errorPost,
            status: "error",
          })
        );
      }, 150);
      onClickClose();
    }
  }, [isSuccessPost, isErrorPost]);

  useEffect(() => {
    if (isSuccessPut) {
      setTimeout(() => {
        dispatch(
          updateToolAlert({
            title: t("general.success"),
            description: t(
              "configuration.offsetTypeConfiguration.offsetUpdated"
            ),
            status: "success",
          })
        );
      }, 150);
      onClickClose();
    }
    if (isErrorPut) {
      setTimeout(() => {
        dispatch(
          updateToolAlert({
            title: t("general.error"),
            description: errorPut,
            status: "error",
          })
        );
      }, 150);
      onClickClose();
    }
  }, [isSuccessPut, isErrorPut]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="45%"
      placement="right"
      onClose={onClickClose}
      closable={false}
      visible={openFormOffsetType}
    >
      <Spin spinning={isLoadingPost || isLoadingPut} tip={t("general.loading")}>
        {/** ---------------------------------------------------- */}
        {/** OFFSET INFORMATION */}
        <div>
          <Divider orientation="left" className="generalStyles__drawerDivider">
            <h5>
              <FontAwesomeIcon
                icon={faGear}
                className="generalStyles__info generalStyles__mrFix"
              />
              {t(
                "configuration.offsetTypeConfiguration.offsetTypeConfiguration"
              )}
            </h5>
          </Divider>
        </div>
        <div className="container">
          <div
            className={
              theme === "dark"
                ? "row drawer__box__dark"
                : "row drawer__box__light"
            }
          >
            {/** NAME */}
            <div className="col-md-12">
              <div className="generalStyles__flex">
                <div className="generalStyles__inputFlexName">
                  <span className="generalStyles__inputFlexRequired">*</span>
                  <span className="drawer__text">{t("general.name")}</span>
                </div>
                <div className="generalStyles__width100">
                  <Input
                    className=""
                    size="small"
                    value={offset.name}
                    onChange={(e) => {
                      onChangeName(e.currentTarget.value);
                    }}
                  />
                </div>
                <div className="generalStyles__infoTooltipIconBlank" />
              </div>
            </div>
            {/** OFFSET TAG COLOR */}
            <div className="col-md-12 mt-3">
              <div className="generalStyles__flex">
                <div className="generalStyles__inputFlexName">
                  <span>
                    {t("configuration.offsetTypeConfiguration.labelColor")}
                  </span>
                </div>
                <div className="generalStyles__width100">
                  <Select
                    size="small"
                    className="generalStyles__width100"
                    bordered={false}
                    value={offset.color}
                    onChange={onChangeLabelColor}
                    style={{ marginLeft: "-7px", marginBottom: "5px" }}
                  >
                    {[
                      "magenta",
                      "red",
                      "volcano",
                      "orange",
                      "gold",
                      "lime",
                      "green",
                      "cyan",
                      "blue",
                      "geekblue",
                      "purple",
                    ].map((item: any, index: number) => (
                      <Option key={index} value={item}>
                        <Tag
                          color={item}
                          style={{
                            textAlign: "center",
                            width: "100%",
                            marginBottom: "5px",
                            paddingBottom: "5px",
                          }}
                        >
                          {item}
                        </Tag>
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="generalStyles__infoTooltipIconBlank" />
              </div>
            </div>
            {values.map((item: any, index: number) => (
              <div className={classDinamic} key={index}>
                <div className="generalStyles__flex">
                  <div className="generalStyles__inputFlexNameLarge">
                    <span>{item.name}</span>
                  </div>
                  <div className="generalStyles__width100 generalStyles__mlFix25">
                    <Switch
                      onChange={(e: boolean) => {
                        onChangeSwitch(e, item.action);
                      }}
                      checked={offset.attributes[item.action]}
                      disabled={item.disabled}
                    />
                  </div>
                  <div className="generalStyles__infoTooltipIconBlank" />
                </div>
              </div>
            ))}
          </div>
          <br />
        </div>
        {/** ---------------------------------------------------- */}
        <div className="mt-4">
          <div>
            <Button onClick={onClickClose} className="buttonStyle__3">
              {t("organizationManagement.close")}
            </Button>
            <Button
              onClick={onClickSave}
              className="buttonStyle__3"
              type="primary"
            >
              {t("general.save")}
            </Button>
          </div>
        </div>
      </Spin>
    </Drawer>
  );
}

export default OffsetTypeForm;
