import React from "react";
import { Route, Routes } from "react-router-dom";
// COMPONENTS
import LoginPage from "../pages/login/LoginPage";
import NotFoundPage from "../pages/unauthorized/NotFoundPage";
import Layout from "../pages/home/Layout";
// PRIVATE ROUTE
import PrivateRoute from "./PrivateRoute";

export default function AppRouter() {
  return (
    <>
      <Routes>
        {/* *************** PRIVATE ROUTES *************** */}
        <Route path="/" element={<PrivateRoute Component={Layout} />} />
        <Route
          path="/monitoring/*"
          element={<PrivateRoute Component={Layout} />}
        />
        <Route
          path="/organization-management/*"
          element={<PrivateRoute Component={Layout} />}
        />
        <Route
          path="/users-management/*"
          element={<PrivateRoute Component={Layout} />}
        />
        <Route
          path="/reports/*"
          element={<PrivateRoute Component={Layout} />}
        />
        <Route path="/tools/*" element={<PrivateRoute Component={Layout} />} />
        <Route
          path="/edge-management/*"
          element={<PrivateRoute Component={Layout} />}
        />
        {/* *************** PUBLIC ROUTES *************** */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}
