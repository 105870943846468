import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Button, Input, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useDeleteChannelMutation } from "../../../redux/servers/serverAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import {
  updateOpenModalDeleteChannel,
  updateOpenDrawerEditChannel,
  updateServerAlert,
} from "../../../redux/servers/serverSlice";
// UTILS
import { styleDeleteButton } from "../../../utils/utils";

library.add(faTrash, faTriangleExclamation);

function ServersDeleteChannelModal() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [disableButtonConfirmDelete, setDisableButtonConfirmDelete] =
    useState(true);
  const [confirmServerName, setConfirmServerName] = useState("");

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { channel, openModalDeleteChannel } = useSelector(
    (state: any) => state.server
  );
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    triggerDeleteServer,
    { isLoading, isSuccess, error, isError, reset: resetDeleteServer },
  ] = useDeleteChannelMutation();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onClickClose = () => {
    dispatch(updateOpenModalDeleteChannel(false));
  };

  const handleDeleteItem = async () => {
    const token = await GETJwtToken();
    triggerDeleteServer({ id: channel.id, token });
  };

  const handleSubmitDeleteItem = async (event: any) => {
    if (event.key === "Enter" && !disableButtonConfirmDelete) {
      const token = await GETJwtToken();
      triggerDeleteServer({ id: channel.id, token });
    }
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (openModalDeleteChannel) {
      setDisableButtonConfirmDelete(true);
      setConfirmServerName("");
      resetDeleteServer();
    }
  }, [openModalDeleteChannel]);

  useEffect(() => {
    setDisableButtonConfirmDelete(true);
    if (
      confirmServerName &&
      channel &&
      channel.name &&
      confirmServerName.toLowerCase() === channel.name.toLowerCase()
    ) {
      setDisableButtonConfirmDelete(false);
    } else {
      setDisableButtonConfirmDelete(true);
    }
  }, [confirmServerName]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        dispatch(
          updateServerAlert({
            title: t("general.success"),
            description: t("edgeManagement.servers.channelDeleted"),
            status: "success",
          })
        );
      }, 150);
      onClickClose();
      dispatch(updateOpenDrawerEditChannel(false));
    }
    if (isError) {
      setTimeout(() => {
        dispatch(
          updateServerAlert({
            title: t("general.error"),
            description: error,
            status: "error",
          })
        );
      }, 150);
      onClickClose();
      dispatch(updateOpenDrawerEditChannel(false));
    }
  }, [isSuccess, isError]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Modal
      onOk={onClickClose}
      closable={false}
      onCancel={onClickClose}
      visible={openModalDeleteChannel}
      footer={null}
    >
      <Spin spinning={isLoading}>
        <div>
          <div className="text-center">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="generalStyles__warning generalStyles__deleteItemIcon"
            />
            <p className="mt-3 drawer__text">
              {t("organizationManagement.deleteDescription.text1")}
              <b className="generalStyles__warning">
                {t("organizationManagement.deleteDescription.text2")}{" "}
              </b>
              {t("edgeManagement.servers.theChannel")}{" "}
              <b className="generalStyles__warning">
                {channel && channel.name ? channel.name : ""}{" "}
              </b>
              {t("edgeManagement.servers.confirmChannelName")}
            </p>
            <Input
              placeholder={t("edgeManagement.servers.serverName")}
              onChange={(e) => {
                setConfirmServerName(e.target.value);
              }}
              value={confirmServerName}
              onKeyDown={handleSubmitDeleteItem}
              status="warning"
            />
          </div>
          <div className="generalStyles__flexEnd mt-4">
            <div>
              <Button
                htmlType="submit"
                className={styleDeleteButton(disableButtonConfirmDelete, theme)}
                onClick={handleDeleteItem}
                disabled={disableButtonConfirmDelete}
              >
                {t("organizationManagement.delete")}
              </Button>
              <Button onClick={onClickClose} className="buttonStyle__4">
                {t("organizationManagement.close")}
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
}

export default ServersDeleteChannelModal;
