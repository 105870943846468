import React, { useState } from "react";
// ANT DESIGN COMPONENTS
import { Dropdown, Select, Menu, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDesktop,
  faGear,
  faArrowLeft,
  faRefresh,
  faMinimize,
  faExpand,
  faWifi,
  faExclamationTriangle,
  faCircleCheck,
  faThumbTack,
  faSave,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
  updateGridStyle,
  updateShowFullView,
} from "../../../redux/monitoring/monitoringSlice";
import { udpateOpenModalTotpToken } from "../../../redux/authentication/authenticationSlice";
// COMPONENTS
import OrganizationCascaderSelectMultiple from "../../organizationManagement/organizations/OrganizationCascaderSelectMultiple";
import AuthenticationTOTP from "../../authentication/AuthenticationTOTP";

function MonitoringHeader2() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [totpTokenIsValid, setTotpTokenIsValid] = useState(false);

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */

  const { showFullView, gridStyle } = useSelector(
    (state: any) => state.monitoring
  );

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // DROPDOWN MENU ITEMS **************************** */
  const gridStyleMenu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <div
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              onClick={() => {
                dispatch(updateGridStyle("grid"));
              }}
            >
              <span>
                {gridStyle === "grid" && (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="generalStyles__success generalStyles__mrFix"
                  />
                )}
              </span>
              <span
                className={gridStyle === "grid" ? "" : "generalStyles__mlFix25"}
              >
                Grid View
              </span>
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              onClick={() => {
                dispatch(updateGridStyle("topView"));
              }}
            >
              <span>
                {gridStyle === "topView" && (
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="generalStyles__success generalStyles__mrFix"
                  />
                )}
              </span>
              <span
                className={
                  gridStyle === "topView" ? "" : "generalStyles__mlFix25"
                }
              >
                Top View
              </span>
            </div>
          ),
        },
      ]}
    />
  );

  const toolsMenu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <div>
              <FontAwesomeIcon
                icon={faSave}
                className="generalStyles__info generalStyles__mrFix"
              />
              <span>Save Selected View</span>
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div>
              <FontAwesomeIcon
                icon={faThumbTack}
                className="generalStyles__info generalStyles__mrFix"
              />
              <span>Pin Banks</span>
            </div>
          ),
          children: [
            {
              key: "2-1",
              disabled: true,
              label: (
                <Select
                  mode="multiple"
                  size="small"
                  allowClear
                  className="banksView__barSelectClose"
                  placeholder="Banks"
                />
              ),
            },
          ],
        },
      ]}
    />
  );
  // ************************************************ */
  // FUNCTIONS ************************************** */

  const onClickConnect = () => {
    dispatch(udpateOpenModalTotpToken(true));
  };

  const onClickFullView = () => {
    dispatch(updateShowFullView(!showFullView));
  };
  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div className="generalStyles__flexCenter mb-5">
      <div className="generalStyles__flex">
        {/** ******************************************* */}
        {/** CASCADER SELECT */}
        <div>
          <OrganizationCascaderSelectMultiple />
        </div>
        {/** ************************************************** */}
        {/** MAXIMIZE ICON */}
        <div>
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            className="monitoring__barIcon"
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
        </div>
        {/** ************************************************** */}
        {/** LAYOUT ICON */}
        <div className="">
          <div className="monitoring__barIcon">
            <Dropdown overlay={gridStyleMenu} placement="bottomLeft">
              <FontAwesomeIcon icon={faDesktop} />
            </Dropdown>
          </div>
        </div>
        {/** ************************************************** */}
        {/** GEAR ICON */}
        <div>
          <div className="monitoring__barIcon">
            <Dropdown overlay={toolsMenu} placement="bottomLeft">
              <FontAwesomeIcon icon={faGear} />
            </Dropdown>
          </div>
        </div>
        {/** ************************************************** */}
        {/** REFRESH ICON */}
        <div>
          <div
            className="monitoring__barIcon"
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
          >
            <Tooltip title={t("general.refreshPage")}>
              <FontAwesomeIcon icon={faRefresh} />
            </Tooltip>
          </div>
        </div>
        {/** ************************************************** */}
        {/** CONNECT DISCONNECT ICON ICON */}
        <div>
          <div
            className="monitoring__barIcon"
            role="button"
            tabIndex={0}
            onClick={onClickConnect}
            onKeyDown={() => {}}
          >
            <Tooltip title={t("monitoring.cloudConnection")}>
              <FontAwesomeIcon
                className={
                  totpTokenIsValid
                    ? "generalStyles__success"
                    : "generalStyles__warning"
                }
                icon={totpTokenIsValid ? faWifi : faExclamationTriangle}
              />
            </Tooltip>
          </div>
        </div>
        {/** ************************************************** */}
        {/** EXPAND ICON */}
        <div>
          <div
            role="button"
            tabIndex={0}
            onClick={onClickFullView}
            onKeyDown={() => {}}
            className="monitoring__barIcon"
          >
            <FontAwesomeIcon icon={showFullView ? faMinimize : faExpand} />
          </div>
        </div>
        {/** MONITORING MODAL VALIDATE TOTP */}
        <AuthenticationTOTP setTotpTokenIsValid={setTotpTokenIsValid} />
      </div>
    </div>
  );
}

export default MonitoringHeader2;
