import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Breadcrumb } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
  updateOrganizationUpdateBreadcrumb,
  updateOrganizationBreadcrumb,
} from "../../../redux/organizations/organizationSlice";
// INTERFACE
import { organizationBreadcrumbInterface } from "../../../interfaces/Organizations.interface";
import { RootState } from "../../../app/store";

function OrganizationBreadcrumb() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [items, setItems] = useState<organizationBreadcrumbInterface[]>([]);

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { organizationBreadcrumb } = useSelector(
    (state: RootState) => state.organization
  );

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const updateBreadCrumb = (data: any) => {
    // find breadcrumb index
    const index = organizationBreadcrumb.findIndex(
      (x: organizationBreadcrumbInterface) => x.name === data.target.innerText
    );
    // remove item from breadcrumb
    const newObject = [];
    for (let x = 0; x <= index; x += 1) {
      newObject.push(organizationBreadcrumb[x]);
    }
    dispatch(updateOrganizationBreadcrumb(newObject));
    // call organizations and childs again
    dispatch(
      updateOrganizationUpdateBreadcrumb({
        update: true,
        id: newObject[newObject.length - 1].orgId,
        name: newObject[newObject.length - 1].name,
      })
    );
    // clean breadcrumb update object
    setTimeout(() => {
      dispatch(
        updateOrganizationUpdateBreadcrumb({
          update: false,
          id: "",
          name: "",
        })
      );
    }, 1000);
  };
  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (organizationBreadcrumb) {
      setItems(organizationBreadcrumb);
    }
  }, [organizationBreadcrumb]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Breadcrumb className="">
      <Breadcrumb.Item>{t("header.organizationManagement")}</Breadcrumb.Item>
      <Breadcrumb.Item>{t("header.organizations")}</Breadcrumb.Item>
      {items.map((item: organizationBreadcrumbInterface, index: number) => (
        <Breadcrumb.Item
          onClick={updateBreadCrumb}
          key={item.orgId}
          className={
            index === items.length - 1
              ? "generalStyles__breadcrumbItem"
              : "generalStyles__breadcrumbItemInactive"
          }
        >
          {item.name}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

export default OrganizationBreadcrumb;
