import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Button, Input, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useDeleteOrganizationMutation } from "../../../redux/organizations/organizationAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import {
  updateOpenModalDeleteOrganization,
  updateOrganizationAlert,
} from "../../../redux/organizations/organizationSlice";

library.add(faTrash, faTriangleExclamation);

function OrganizationsDeleteOrganizationModal() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [disableButtonConfirmDelete, setDisableButtonConfirmDelete] =
    useState(true);
  const [confirmOrganizationName, setConfirmOrganizationName] = useState("");

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const organizationState = useSelector((state: any) => state.organization);
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    deleteOrganization,
    { isLoading, isSuccess, isError, error, reset: resetDeleteOrganization },
  ] = useDeleteOrganizationMutation();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleOk = () => {
    dispatch(updateOpenModalDeleteOrganization(false));
  };

  const handleCancel = () => {
    dispatch(updateOpenModalDeleteOrganization(false));
  };

  const handleCloseModal = () => {
    dispatch(updateOpenModalDeleteOrganization(false));
  };

  const handleDeleteItem = async () => {
    const token = await GETJwtToken();
    deleteOrganization({ id: organizationState.organization.id, token });
  };

  const handleSubmitDeleteItem = async (event: any) => {
    if (event.key === "Enter" && !disableButtonConfirmDelete) {
      const token = await GETJwtToken();
      deleteOrganization({ id: organizationState.organization.id, token });
    }
  };

  const styleDeleteButton = () => {
    if (disableButtonConfirmDelete) {
      if (theme === "dark") {
        return "buttonStyle__inactive__1__dark generalStyles__mrFix";
      }
      return "buttonStyle__inactive__1 generalStyles__mrFix";
    }
    return "buttonStyle__2 generalStyles__mrFix";
  };
  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    setDisableButtonConfirmDelete(true);
    setConfirmOrganizationName("");
    resetDeleteOrganization();
  }, [organizationState.openModalDeleteOrganization]);

  useEffect(() => {
    setDisableButtonConfirmDelete(true);
    if (
      confirmOrganizationName &&
      confirmOrganizationName.toLowerCase() ===
        organizationState.organization.name.toLowerCase()
    ) {
      setDisableButtonConfirmDelete(false);
    } else {
      setDisableButtonConfirmDelete(true);
    }
  }, [confirmOrganizationName]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        dispatch(
          updateOrganizationAlert({
            title: t("genera.success"),
            description: t("organizationManagement.organizationDeleted"),
            status: "success",
          })
        );
      }, 150);
      dispatch(updateOpenModalDeleteOrganization(false));
    }
    if (isError) {
      setTimeout(() => {
        dispatch(
          updateOrganizationAlert({
            title: t("general.error"),
            description: error,
            status: "error",
          })
        );
      }, 150);
      dispatch(updateOpenModalDeleteOrganization(false));
    }
  }, [isSuccess, isError]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Modal
      onOk={handleOk}
      closable={false}
      onCancel={handleCancel}
      visible={organizationState.openModalDeleteOrganization}
      footer={null}
    >
      <Spin spinning={isLoading}>
        <div>
          <div className="text-center">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="generalStyles__warning generalStyles__deleteItemIcon"
            />
            <p className="mt-3 drawer__text">
              {t("organizationManagement.deleteDescription.text1")}
              <b className="generalStyles__warning">
                {t("organizationManagement.deleteDescription.text2")}{" "}
              </b>
              {t("organizationManagement.deleteDescription.text3")}
              <b className="generalStyles__warning">
                {organizationState.organization.name}{" "}
              </b>
              {t("organizationManagement.deleteDescription.text4")}
            </p>
            <Input
              placeholder={t("organizationManagement.organizationName")}
              onChange={(e) => {
                setConfirmOrganizationName(e.target.value);
              }}
              value={confirmOrganizationName}
              onKeyDown={handleSubmitDeleteItem}
              status="warning"
            />
          </div>
          <div className="generalStyles__flexEnd mt-4">
            <div>
              <Button
                htmlType="submit"
                className={styleDeleteButton()}
                onClick={handleDeleteItem}
                disabled={disableButtonConfirmDelete}
              >
                {t("organizationManagement.delete")}
              </Button>
              <Button onClick={handleCloseModal} className="buttonStyle__4">
                {t("organizationManagement.close")}
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
}

export default OrganizationsDeleteOrganizationModal;
