/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ServerState {
  server: Object;
  channel: Object;
  idServerSelected: String;
  nameServerSelected: String;
  openModalCreateNewServer: boolean;
  openModalEditServer: boolean;
  openModalDeleteServer: boolean;
  openDrawerServer: boolean;
  openDrawerCreateChannel: boolean;
  openDrawerEditChannel: boolean;
  openModalDeleteChannel: boolean;
  serverAlert: Object;
  serverTableParams: Object;
  serverRefreshTable: boolean;
  serverBanksRefreshTable: boolean;
  serverList: Array<any>;
  serverBreadcrumb: Array<String>;
  serverUpdateBreadcrumb: Object;
  openModalSecretClient: boolean;
}

const initialState: ServerState = {
  server: {},
  channel: {},
  idServerSelected: '',
  nameServerSelected: '',
  openModalCreateNewServer: false,
  openModalEditServer: false,
  openModalDeleteServer: false,
  openDrawerServer: false,
  openModalDeleteChannel: false,
  openDrawerCreateChannel: false,
  openDrawerEditChannel: false,
  serverAlert: {},
  serverTableParams: {
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ["10", "20", "50", "100"],
    },
  },
  serverRefreshTable: false,
  serverBanksRefreshTable: false,
  serverList: [],
  serverBreadcrumb: [],
  serverUpdateBreadcrumb: {
    update: false,
    id: '',
    name: '',
  },
  openModalSecretClient: false
};

export const serverSlice = createSlice({
  name: 'server',
  initialState,
  reducers: {
    updateServer: (state, action: PayloadAction<Object>) => {
      state.server = action.payload;
    },
    updateChannel: (state, action: PayloadAction<Object>) => {
      state.channel = action.payload;
    },
    updateIdServerSelected: (state, action: PayloadAction<String>) => {
      state.idServerSelected = action.payload;
    },
    updateNameServerSelected: (state, action: PayloadAction<String>) => {
      state.nameServerSelected = action.payload;
    },
    updateOpenModalCreateNewServer: (state, action: PayloadAction<boolean>) => {
      state.openModalCreateNewServer = action.payload;
    },
    updateOpenModalEditServer: (state, action: PayloadAction<boolean>) => {
      state.openModalEditServer = action.payload;
    },
    updateOpenModalDeleteServer: (state, action: PayloadAction<boolean>) => {
      state.openModalDeleteServer = action.payload;
    },
    updateOpenDrawerServer: (state, action: PayloadAction<boolean>) => {
      state.openDrawerServer = action.payload;
    },
    updateOpenDrawerCreateChannel: (state, action: PayloadAction<boolean>) => {
      state.openDrawerCreateChannel = action.payload;
    },
    updateOpenDrawerEditChannel: (state, action: PayloadAction<boolean>) => {
      state.openDrawerEditChannel = action.payload;
    },
    updateOpenModalDeleteChannel: (state, action: PayloadAction<boolean>) => {
      state.openModalDeleteChannel = action.payload;
    },
    updateServerAlert: (state, action: PayloadAction<Object>) => {
      state.serverAlert = action.payload;
    },
    updateServerTableParams: (state, action: PayloadAction<Object>) => {
      state.serverTableParams = action.payload;
    },
    updateServerRefreshTable: (state, action: PayloadAction<boolean>) => {
      state.serverRefreshTable = action.payload;
    },
    updateServerBanksRefreshTable: (state, action: PayloadAction<boolean>) => {
      state.serverBanksRefreshTable = action.payload;
    },
    updateServerList: (state, action: PayloadAction<any>) => {
      state.serverList = action.payload;
    },
    updateServerBreadcrumb: (state, action: PayloadAction<any>) => {
      state.serverBreadcrumb = action.payload;
    },
    updateServerUpdateBreadcrumb: (state, action: PayloadAction<Object>) => {
      state.serverUpdateBreadcrumb = action.payload;
    },
    updateOpenModalSecretClient: (state, action: PayloadAction<boolean>) => {
      state.openModalSecretClient = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateChannel,
  updateServer,
  updateIdServerSelected,
  updateNameServerSelected,
  updateOpenModalCreateNewServer,
  updateOpenModalEditServer,
  updateOpenModalDeleteServer,
  updateOpenDrawerServer,
  updateOpenDrawerCreateChannel,
  updateOpenModalDeleteChannel,
  updateServerAlert,
  updateServerTableParams,
  updateServerRefreshTable,
  updateServerBanksRefreshTable,
  updateServerList,
  updateServerBreadcrumb,
  updateServerUpdateBreadcrumb,
  updateOpenDrawerEditChannel, 
  updateOpenModalSecretClient
} = serverSlice.actions;

export default serverSlice.reducer;
