import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { InputNumber, Divider, Modal, Switch, Spin, Alert } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faGear, faUser } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenModalOffsetsTable } from "../../../redux/configurationFiles/configurationFilesSlice";

library.add(faUser);

function ConfigurationFilesModalOffsetsTable({
  mode,
  data,
  setData,
  name,
  selectedItemsObj,
  setOffsetsTableIsValid,
  showOffsetsAlert,
}: any) {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [totalColumns, setTotalColumns] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [fullTable, setFullTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { openModalOffsetsTable } = useSelector(
    (state: any) => state.configurationFile
  );

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const showIsLoading = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const onClickValidateOffsets = (data_: any) => {
    showIsLoading();
    const newObj = [];
    let offsetsTableIsValid = true;
    for (let x = 0; x < totalColumns; x += 1) {
      const obj1: any = {
        liftName: data_[x].liftName,
        liftNumber: data_[x].liftNumber,
        description: "",
        startOffset: 0,
        endOffset: 0,
        statusArr: [],
        items: [],
      };
      for (let y = 0; y < totalRows; y += 1) {
        let offsetValid = true;
        // Check if offset already exist in table
        for (let a = 0; a < totalColumns; a += 1) {
          for (let b = 0; b < totalRows; b += 1) {
            if (
              data_[x].statusArr[y].offset === data_[a].statusArr[b].offset &&
              data_[x].statusArr[y].offset !== 0
            ) {
              if (x === a && y === b) {
                //
              } else {
                offsetValid = false;
                offsetsTableIsValid = false;
              }
            }
          }
        }
        // Check if offset is equal 0
        if (data_[x].statusArr[y].offset === 0) {
          offsetValid = false;
          offsetsTableIsValid = false;
        }
        obj1.statusArr.push({
          startOffset: data_[x].statusArr[y].startOffset,
          endOffset: data_[x].statusArr[y].endOffset,
          key: data_[x].statusArr[y].key,
          keyIndex: data_[x].statusArr[y].keyIndex,
          isValid: offsetValid,
          name: data_[x].statusArr[y].name,
          offset: data_[x].statusArr[y].offset,
          offsetType: data_[x].statusArr[y].offsetType,
          totalItems: data_[x].statusArr[y].totalItems,
        });
      }
      newObj.push(obj1);
    }
    setData(newObj);
    setOffsetsTableIsValid(offsetsTableIsValid);
    if (!offsetsTableIsValid) {
      setShowAlert(true);
      showOffsetsAlert(true);
    } else {
      setShowAlert(false);
      showOffsetsAlert(false);
    }
  };

  const onChangeOffsetFirstValue = (
    newValue: number,
    carIndex: number,
    valueIndex: number
  ) => {
    const newObj = [];
    for (let x = 0; x < totalColumns; x += 1) {
      const obj1: any = {
        key: `${data[x].liftName}-${x}`,
        liftName: data[x].liftName,
        liftNumber: data[x].liftNumber,
        description: "",
        startOffset: 0,
        endOffset: 0,
        statusArr: [],
        items: [],
      };
      for (let y = 0; y < totalRows; y += 1) {
        let newOffset = data[x].statusArr[y].offset;
        if (x === carIndex && y === valueIndex) {
          newOffset = newValue;
        }
        obj1.statusArr.push({
          startOffset: data[x].statusArr[y].startOffset,
          endOffset: data[x].statusArr[y].endOffset,
          key: data[x].statusArr[y].key,
          keyIndex: data[x].statusArr[y].keyIndex,
          isValid: data[x].statusArr[y].isValid,
          name: data[x].statusArr[y].name,
          offset: newOffset,
          offsetType: data[x].statusArr[y].offsetType,
          totalItems: data[x].statusArr[y].totalItems,
        });
      }
      newObj.push(obj1);
    }
    setData(newObj);
  };

  const onClickAutoFillOffsets = () => {
    showIsLoading();
    const newObj = [];
    for (let x = 0; x < totalColumns; x += 1) {
      const obj1: any = {
        liftName: data[x].liftName,
        liftNumber: data[x].liftNumber,
        description: "",
        startOffset: 0,
        endOffset: 0,
        statusArr: [],
        items: [],
      };
      let counter = 0;
      for (let y = 0; y < totalRows; y += 1) {
        if (data[x].statusArr[y].keyIndex === 0) {
          counter = data[x].statusArr[y].offset;
        } else if (data[x].statusArr[y].keyIndex !== 0) {
          if (counter > 0) counter += 1;
        }
        const newOffset = counter === 0 ? 0 : counter;
        obj1.statusArr.push({
          startOffset: data[x].statusArr[y].startOffset,
          endOffset: data[x].statusArr[y].endOffset,
          key: data[x].statusArr[y].key,
          keyIndex: data[x].statusArr[y].keyIndex,
          isValid: data[x].statusArr[y].isValid,
          name: data[x].statusArr[y].name,
          offset: newOffset,
          offsetType: data[x].statusArr[y].offsetType,
          totalItems: data[x].statusArr[y].totalItems,
        });
      }
      newObj.push(obj1);
    }
    setData(newObj);
    onClickValidateOffsets(newObj);
  };

  const onChangeSwitchFullTable = (checked: boolean) => {
    if (mode === "table") {
      onClickAutoFillOffsets();
    }
    setFullTable(checked);
    showIsLoading();
  };

  const handleCancel = () => {
    dispatch(updateOpenModalOffsetsTable(false));
  };

  const onClickSave = () => {
    dispatch(updateOpenModalOffsetsTable(false));
    if (mode === "table") {
      onClickAutoFillOffsets();
    }
  };

  const onChangeOffsetInputNumber = (
    newValue: number,
    carIndex: number,
    valueIndex: number
  ) => {
    const newObj = [];
    for (let x = 0; x < totalColumns; x += 1) {
      const obj1: any = {
        liftName: data[x].liftName,
        liftNumber: data[x].liftNumber,
        description: "",
        startOffset: 0,
        endOffset: 0,
        statusArr: [],
        items: [],
      };
      for (let y = 0; y < totalRows; y += 1) {
        let newOffset = data[x].statusArr[y].offset;
        if (x === carIndex && y === valueIndex) {
          newOffset = newValue;
        }
        obj1.statusArr.push({
          startOffset: data[x].statusArr[y].startOffset,
          endOffset: data[x].statusArr[y].endOffset,
          key: data[x].statusArr[y].key,
          keyIndex: data[x].statusArr[y].keyIndex,
          name: data[x].statusArr[y].name,
          offset: newOffset,
          offsetType: data[x].statusArr[y].offsetType,
          totalItems: data[x].statusArr[y].totalItems,
          isValid: data[x].statusArr[y].isValid,
        });
      }
      newObj.push(obj1);
    }
    // ****************
    setData(newObj);
  };

  const createRowClass = (index: number, addPadding: boolean) => {
    if (theme === "light") {
      if (index % 2 === 0) {
        return `edgeManagement__bankTableRow1 ${
          addPadding ? "edgeManagement__bankTableRowPadding" : ""
        }`;
      }
      return `edgeManagement__bankTableRowLight ${
        addPadding ? "edgeManagement__bankTableRowPadding" : ""
      }`;
    }
    if (index % 2 === 0) {
      return `edgeManagement__bankTableRow1 ${
        addPadding ? "edgeManagement__bankTableRowPadding" : ""
      }`;
    }
    return `edgeManagement__bankTableRowDark ${
      addPadding ? "edgeManagement__bankTableRowPadding" : ""
    }`;
  };
  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (data && data[0]) {
      setTotalColumns(data.length);
      setTotalRows(data[0].statusArr.length);
    }
  }, [data]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Modal
      visible={openModalOffsetsTable}
      width="86%"
      onCancel={handleCancel}
      onOk={onClickSave}
      maskClosable={false}
    >
      <Spin spinning={isLoading}>
        <Divider orientation="left" className="generalStyles__drawerDivider">
          <h5>
            <FontAwesomeIcon
              icon={faGear}
              className="generalStyles__info generalStyles__mrFix"
            />
            {t(
              "configuration.configurationFiles.offsetsTableConfigurationFile"
            )}{" "}
            - {name}
          </h5>
        </Divider>
        <div className="generalStyles__flexEnd">
          <div>
            <Switch
              checked={fullTable}
              onChange={onChangeSwitchFullTable}
              checkedChildren="Range View"
              unCheckedChildren="Table View"
            />
          </div>
        </div>
        {showAlert && (
          <Alert
            className="mt-3"
            message="Please check and fill all invalid offsets!"
            type="warning"
            showIcon
            closable
          />
        )}
        <br />
        {/** ************************************************* */}
        {/** TABLE FULL VALUES */}
        <div className="configuration__maxModalOffsetsTable mt-3">
          {fullTable && (
            <div
              className="generalStyles__flex"
              style={{ overflowX: "auto", overflowY: "auto" }}
            >
              <div>
                <div className="edgeManagement__bankTableColumn">
                  <span>-</span>
                </div>
                {data && data[0] && data[0].statusArr && (
                  <>
                    {data[0].statusArr.map((item: any, index: number) => (
                      <div key={index}>
                        <div
                          key={index}
                          className={createRowClass(index, true)}
                        >
                          <span>{item.name || ""}</span>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
              {data && data.length > 0 && (
                <>
                  {/** OFFSETS TABLE INPUTS */}
                  {data.map((item: any, index: number) => (
                    <div key={index}>
                      <div
                        key={index}
                        className="edgeManagement__bankTableColumn"
                      >
                        <span>{item.liftName || "--"}</span>
                      </div>
                      {item.statusArr.map((item2: any, index2: number) => (
                        <div key={index2}>
                          <div
                            key={index}
                            className={createRowClass(index2, false)}
                          >
                            {mode === "table" && (
                              <InputNumber
                                placeholder={item2.key}
                                onChange={(e: number) => {
                                  onChangeOffsetInputNumber(e, index, index2);
                                }}
                                value={item2.offset}
                                className="edgeManagement__bankTableInput"
                                status={item2.isValid ? "" : "warning"}
                              />
                            )}
                            {mode === "list" && (
                              <div style={{ padding: "5px 0px 0px 10px" }}>
                                <p>{item2.offset}</p>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
          {/** ************************************************* */}
          {/** TABLE INITIAL VALUES */}
          {!fullTable && (
            <div style={{ overflowX: "auto", overflowY: "auto" }}>
              {/** HEADERS */}
              <div className="generalStyles__fitContent">
                <div className="generalStyles__flex">
                  <div className="edgeManagement__bankTableColumn">
                    <span>--</span>
                  </div>
                  <div className="" />
                  {selectedItemsObj.map((item: any, index: number) => (
                    <div
                      key={index}
                      className="edgeManagement__bankTableColumn"
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              </div>
              {/** INPUTS AND DATA */}
              <div className="generalStyles__fitContent">
                {data.map((item: any, index: number) => (
                  <div className="generalStyles__flex" key={index}>
                    <div key={index} className={createRowClass(index, true)}>
                      <span>{item.liftName}</span>
                    </div>
                    {item.statusArr.map((item2: any, index2: number) => (
                      <div key={index2}>
                        {item2.keyIndex === 0 && (
                          <div className={createRowClass(index, false)}>
                            {mode === "table" && (
                              <InputNumber
                                placeholder={item2.key}
                                onChange={(e: number) => {
                                  onChangeOffsetFirstValue(e, index, index2);
                                }}
                                value={item2.offset}
                                className="edgeManagement__bankTableInput"
                                status={item2.isValid ? "" : "warning"}
                              />
                            )}
                            {mode === "list" && (
                              <div style={{ padding: "5px 0px 0px 10px" }}>
                                <p>{item2.offset} </p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <br />
      </Spin>
    </Modal>
  );
}

export default ConfigurationFilesModalOffsetsTable;
