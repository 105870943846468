import React from "react";
// ANT DESIGN COMPONENTS
import { Divider, Tag } from "antd";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faElevator } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";

function DrawerLiftInformation({ data }: any) {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const { theme } = useSelector((state: any) => state.home);
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      <br />
      {/** ************************************************* */}
      {/** LIFT INFORMATION */}
      <div>
        <Divider orientation="left" className="generalStyles__drawerDivider">
          <h5>
            <FontAwesomeIcon
              icon={faElevator}
              className="generalStyles__info generalStyles__mrFix"
            />
            {t("edgeManagement.lifts.liftInformation")}
          </h5>
        </Divider>
      </div>
      <div
        className={
          theme === "dark" ? " drawer__box__dark" : " drawer__box__light"
        }
      >
        {/* ID */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              ID:
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">{data && data.id ? data.id : "--"}</p>
          </div>
        </div>
        {/* NAME */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("edgeManagement.lifts.position")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {data && data.position ? data.position : "--"}
            </p>
          </div>
        </div>
        {/* NAME */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("general.name")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {data && data.name ? data.name : "--"}
            </p>
          </div>
        </div>
        {/* TYPE */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("edgeManagement.lifts.type")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <div className="drawer__text">
              {data && data.type ? (
                <>
                  <div className="">
                    {data.type === "ELEVATOR" && (
                      <Tag color="blue" className="drawer__typeTag">
                        {data.type}
                      </Tag>
                    )}
                    {data.type === "ESCALATOR" && (
                      <Tag color="geekblue" className="drawer__typeTag">
                        {data.type}
                      </Tag>
                    )}
                    {data.type === "WALKINGBAND" && (
                      <Tag color="cyan" className="drawer__typeTag">
                        {data.type}
                      </Tag>
                    )}{" "}
                  </div>
                </>
              ) : (
                "--"
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DrawerLiftInformation;
