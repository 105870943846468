/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ConfigurationFileState {
  configurationFile: Object;
  offsetType: Object;
  offsetTypeName: string;
  offsetTypeTagColor: string;
  openModalCreateNewConfigurationFile: boolean;
  expandDrawerCreateConfigurationFile: boolean;
  openModalEditConfigurationFile: boolean;
  openModalDeleteConfigurationFile: boolean;
  openDrawerBankFiles: boolean;
  showSpecialSwitches: boolean;
  openDrawerConfigurationFile: boolean;
  openModalOffsetsTable: boolean;
  configurationFileAlert: Object;
  configurationFileTableParams: Object;
  configurationFileRefreshTable: boolean;
  configurationFileList: Array<any>;
  configurationFileBreadcrumb: Array<any>;
  configurationFileUpdateBreadcrumb: Object;
  configurationFileForm: Object;
  openModalUnsavedChangesDefinition: boolean;
  configurationDefaultOffsets: boolean;
  dataBlocksData: Array<any>;
  openDrawerCreateDataBlock: boolean;
}

const initialState: ConfigurationFileState = {
  configurationFile: {},
  offsetType: {},
  offsetTypeName: "",
  offsetTypeTagColor: "",
  openModalCreateNewConfigurationFile: false,
  expandDrawerCreateConfigurationFile: false, 
  openModalEditConfigurationFile: false,
  openModalDeleteConfigurationFile: false,
  openDrawerBankFiles: false,
  showSpecialSwitches: false,
  openDrawerConfigurationFile: false,
  openModalOffsetsTable: false,
  configurationFileAlert: {},
  configurationFileTableParams: {
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ["10", "20", "50", "100"],
    },
  },
  configurationFileRefreshTable: false,
  configurationFileList: [],
  configurationFileBreadcrumb: [],
  configurationFileUpdateBreadcrumb: {
    update: false,
    id: '',
    name: '',
  },
  configurationFileForm: {
    step1: {},
    step2: {},
    step3: {},
    step4: {},
    step5: { carOffsets: [] },
  },
  openModalUnsavedChangesDefinition: false,
  configurationDefaultOffsets: true,
  dataBlocksData: [],
  openDrawerCreateDataBlock: false
};

export const configurationFileSlice = createSlice({
  name: 'configurationFile',
  initialState,
  reducers: {
    updateConfigurationFile: (state, action: PayloadAction<Object>) => {
      state.configurationFile = action.payload;
    },
    updateOffsetType: (state, action: PayloadAction<Object>) => {
      state.offsetType = action.payload;
    },
    updateOffsetTypeName: (state, action: PayloadAction<string>) => {
      state.offsetTypeName = action.payload;
    },
    updateOffsetTypeTagColor: (state, action: PayloadAction<string>) => {
      state.offsetTypeTagColor = action.payload;
    },
    updateOpenModalCreateNewConfigurationFile: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.openModalCreateNewConfigurationFile = action.payload;
    },
    updateExpandDrawerCreateNewConfigurationFile: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.expandDrawerCreateConfigurationFile = action.payload;
    },
    updateOpenModalEditConfigurationFile: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.openModalEditConfigurationFile = action.payload;
    },
    updateOpenModalDeleteConfigurationFile: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.openModalDeleteConfigurationFile = action.payload;
    },
    updateOpenDrawerConfigurationFile: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.openDrawerConfigurationFile = action.payload;
    },
    updateShowSpecialSwitches: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.showSpecialSwitches = action.payload;
    },
    updateOpenModalOffsetsTable: (state, action: PayloadAction<boolean>) => {
      state.openModalOffsetsTable = action.payload;
    },
    updateOpenDrawerBankFiles: (state, action: PayloadAction<boolean>) => {
      state.openDrawerBankFiles = action.payload;
    },
    updateConfigurationFileAlert: (state, action: PayloadAction<Object>) => {
      state.configurationFileAlert = action.payload;
    },
    updateConfigurationFileTableParams: (
      state,
      action: PayloadAction<Object>
    ) => {
      state.configurationFileTableParams = action.payload;
    },
    updateConfigurationFileRefreshTable: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.configurationFileRefreshTable = action.payload;
    },
    updateConfigurationFileList: (state, action: PayloadAction<any>) => {
      state.configurationFileList = action.payload;
    },
    updateConfigurationFileBreadcrumb: (state, action: PayloadAction<any>) => {
      state.configurationFileBreadcrumb = action.payload;
    },
    updateConfigurationFileUpdateBreadcrumb: (
      state,
      action: PayloadAction<Object>
    ) => {
      state.configurationFileUpdateBreadcrumb = action.payload;
    },
    updateConfigurationFileForm: (state, action: PayloadAction<Object>) => {
      state.configurationFileForm = action.payload;
    },
    updateOpenModalUnsavedChangesDefinition: (state, action: PayloadAction<boolean>) => {
      state.openModalUnsavedChangesDefinition = action.payload;
    },
    updateConfigurationDefaultOffsets: (state, action: PayloadAction<boolean>) => {
      state.configurationDefaultOffsets = action.payload;
    },
    updateDataBlockData: (state, action: PayloadAction<any>) => {
      state.dataBlocksData = action.payload;
    },
    updateOpenDrawerCreateDataBlock: (state, action: PayloadAction<boolean>) => {
      state.openDrawerCreateDataBlock = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateExpandDrawerCreateNewConfigurationFile,
  updateConfigurationFile,
  updateOffsetType,
  updateOffsetTypeName,
  updateOffsetTypeTagColor,
  updateOpenModalCreateNewConfigurationFile,
  updateOpenModalEditConfigurationFile,
  updateOpenModalDeleteConfigurationFile,
  updateOpenDrawerBankFiles,
  updateShowSpecialSwitches,
  updateOpenDrawerConfigurationFile,
  updateOpenModalOffsetsTable,
  updateConfigurationFileAlert,
  updateConfigurationFileTableParams,
  updateConfigurationFileRefreshTable,
  updateConfigurationFileList,
  updateConfigurationFileBreadcrumb,
  updateConfigurationFileUpdateBreadcrumb,
  updateConfigurationFileForm,
  updateOpenModalUnsavedChangesDefinition,
  updateConfigurationDefaultOffsets,
  updateDataBlockData,
  updateOpenDrawerCreateDataBlock
} = configurationFileSlice.actions;

export default configurationFileSlice.reducer;
