/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserState {
  user: Object;
  openModalCreateNewUser: boolean;
  openModalEditUser: boolean;
  openModalDeleteUser: boolean;
  openModalResetUserPassword: boolean;
  openModalBlockUnBlockUser: boolean;
  openDrawerUser: boolean;
  userAlert: Object;
  userTableParams: Object;
  userRefreshTable: boolean;
  userList: Array<any>;
  permissions: Object;
  userBreadcrumb: Array<any>;
}

const initialState: UserState = {
  user: {},
  openModalCreateNewUser: false,
  openModalEditUser: false,
  openModalDeleteUser: false,
  openModalResetUserPassword: false,
  openModalBlockUnBlockUser: false,
  openDrawerUser: false,
  userAlert: {},
  userTableParams: {
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ["10", "20", "50", "100"],
    },
  },
  userRefreshTable: false,
  userList: [],
  permissions: {},
  userBreadcrumb: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<Object>) => {
      state.user = action.payload;
    },
    updateOpenModalCreateNewUser: (state, action: PayloadAction<boolean>) => {
      state.openModalCreateNewUser = action.payload;
    },
    updateOpenModalEditUser: (state, action: PayloadAction<boolean>) => {
      state.openModalEditUser = action.payload;
    },
    updateOpenModalDeleteUser: (state, action: PayloadAction<boolean>) => {
      state.openModalDeleteUser = action.payload;
    },
    updateOpenModalResetUserPassword: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.openModalResetUserPassword = action.payload;
    },
    updateOpenModalBlockUnBlockUser: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.openModalBlockUnBlockUser = action.payload;
    },
    updateOpenDrawerUser: (state, action: PayloadAction<boolean>) => {
      state.openDrawerUser = action.payload;
    },
    updateUserAlert: (state, action: PayloadAction<Object>) => {
      state.userAlert = action.payload;
    },
    updateUserTableParams: (state, action: PayloadAction<Object>) => {
      state.userTableParams = action.payload;
    },
    updateUserRefreshTable: (state, action: PayloadAction<boolean>) => {
      state.userRefreshTable = action.payload;
    },
    updateUserList: (state, action: PayloadAction<any>) => {
      state.userList = action.payload;
    },
    updatePermissions: (state, action: PayloadAction<Object>) => {
      state.permissions = action.payload;
    },
    updateUserBreadcrumb: (state, action: PayloadAction<Array<any>>) => {
      state.userBreadcrumb = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateUser,
  updateOpenModalCreateNewUser,
  updateOpenModalEditUser,
  updateOpenModalDeleteUser,
  updateOpenModalResetUserPassword,
  updateOpenModalBlockUnBlockUser,
  updateOpenDrawerUser,
  updateUserAlert,
  updateUserRefreshTable,
  updateUserTableParams,
  updateUserList,
  updatePermissions,
  updateUserBreadcrumb,
} = userSlice.actions;

export default userSlice.reducer;
