import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Button, Input, Spin, Divider, Tooltip } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faServer } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { usePutServerMutation } from "../../../redux/servers/serverAPI";
import {
  updateOpenModalEditServer,
  updateServerAlert,
} from "../../../redux/servers/serverSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

function ServersEditForm() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [name, setName] = useState("");
  const [serverIp, setServerIp] = useState("");
  const [ipIsValid, setIpIsValid] = useState(true);
  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { server, openModalEditServer } = useSelector(
    (state: any) => state.server
  );
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    putServer,
    {
      isSuccess: isSuccessPutServer,
      isError: isErrorPutServer,
      error: errorPutServer,
      isLoading: isLoadingPutServer,
      reset: resetPutServer,
    },
  ] = usePutServerMutation();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onClickClose = () => {
    dispatch(updateOpenModalEditServer(false));
  };

  const onClickSave = async () => {
    const token = await GETJwtToken();
    //
    const BODY = {
      id: server.id,
      body: {
        banks: [],
        ip: serverIp,
        name,
        org_id: server.org_id,
      },
      token,
    };
    putServer(BODY);
  };

  const onFill = () => {
    if (server) {
      setName(server.name);
      setServerIp(server.ip);
    }
  };

  const onChangeName = (newValue: string) => {
    setName(newValue);
  };

  const onChangeIp = (newValue: string) => {
    setServerIp(newValue);
    if (
      /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}(\/\d+)?$/.test(
        newValue
      )
    ) {
      setIpIsValid(true);
    } else {
      setIpIsValid(false);
    }
  };

  // ************************************************* */
  // USE EFFECT ************************************** */

  useEffect(() => {
    if (openModalEditServer) {
      onFill();
      resetPutServer();
    }
  }, [openModalEditServer]);

  useEffect(() => {
    if (isSuccessPutServer) {
      setTimeout(() => {
        dispatch(
          updateServerAlert({
            title: t("general.success"),
            description: t("edgeManagement.servers.serverEdited"),
            status: "success",
          })
        );
      }, 150);
      dispatch(updateOpenModalEditServer(false));
    }
    if (isErrorPutServer) {
      setTimeout(() => {
        dispatch(
          updateServerAlert({
            title: t("general.error"),
            description: errorPutServer,
            status: "error",
          })
        );
      }, 150);
      dispatch(updateOpenModalEditServer(false));
    }
  }, [isSuccessPutServer, isErrorPutServer]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClickClose}
      closable={false}
      visible={openModalEditServer}
    >
      <Spin spinning={isLoadingPutServer} tip={t("general.loading")}>
        <div>
          <Divider orientation="left" className="generalStyles__drawerDivider">
            <h5>
              <FontAwesomeIcon
                icon={faServer}
                className="generalStyles__info generalStyles__mrFix"
              />
              {t("edgeManagement.servers.editServer")}
            </h5>
          </Divider>
        </div>
        <div className="container">
          <div
            className={
              theme === "dark"
                ? "row drawer__box__dark"
                : "row drawer__box__light"
            }
          >
            {/** NAME */}
            <div className="col-md-12">
              <div className="generalStyles__flex">
                <div className="generalStyles__inputFlexName">
                  <span className="generalStyles__inputFlexRequired">*</span>
                  <span className="drawer__text">
                    {t("edgeManagement.servers.serverName")}
                  </span>
                </div>
                <div className="generalStyles__width100">
                  <Input
                    className=""
                    size="small"
                    onChange={(value) => {
                      onChangeName(value.target.value);
                    }}
                    value={name}
                  />
                </div>
                <div className="generalStyles__infoTooltipIconBlank" />
              </div>
            </div>
            {/** IP */}
            <div className="col-md-12 mt-3">
              <div className="generalStyles__flex">
                <div className="generalStyles__inputFlexName">
                  <span className="generalStyles__inputFlexRequired">*</span>
                  <span className="drawer__text">
                    {t("edgeManagement.servers.ip")}
                  </span>
                </div>
                <div className="generalStyles__width100">
                  <Input
                    className=""
                    size="small"
                    onChange={(value) => {
                      onChangeIp(value.target.value);
                    }}
                    value={serverIp}
                    status={!ipIsValid && serverIp !== "" ? "error" : ""}
                  />
                </div>
                <div>
                  <Tooltip title={t("tooltip.ipFormatValid")} placement="top">
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="generalStyles__infoTooltipIcon"
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/** ---------------------------------------------------- */}
        <div className="mt-4">
          <div>
            <Button onClick={onClickClose} className="buttonStyle__3">
              {t("general.close")}
            </Button>
            <Button
              onClick={onClickSave}
              disabled={isLoadingPutServer || !ipIsValid}
              className="buttonStyle__3"
              type="primary"
            >
              {t("general.save")}
            </Button>
          </div>
        </div>
      </Spin>
    </Drawer>
  );
}

export default ServersEditForm;
