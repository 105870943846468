/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import {
  Table,
  Alert,
  Menu,
  Dropdown,
  Space,
  Empty,
  Input,
  Button,
} from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsis,
  faTrash,
  faPenToSquare,
  faBan,
  faBuildingCircleArrowRight,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetOrganizationAndChildsQuery } from "../../../redux/organizations/organizationAPI";
import {
  updateOpenModalDeleteOrganization,
  updateOpenModalEditOrganization,
  updateOpenModalCreateSubOrganization,
  updateOpenDrawerOrganization,
  updateOrganization,
  updateOrganizationTableParams,
  updateOrganizationBreadcrumb,
  updateOrganizationAlert,
} from "../../../redux/organizations/organizationSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import validatePermission from "../../../utils/validatePermissions";
// UTILS
import { getTableRowClass } from "../../../utils/utils";

library.add(faEllipsis, faTrash, faPenToSquare);

const OrganizationTable = () => {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const { Column } = Table;
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [idOrganizationSelected, setIdOrganizationSelected] = useState("");
  const [searchName, setSearchName] = useState("");
  /* const [searchAddress, setSearchAddress] = useState("")
  const [searchCountry, setSearchCountry] = useState("")
  const [filterType, setFilterType] = useState([]) */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { permissions } = useSelector((state: any) => state.user);
  const { userLogged } = useSelector((state: any) => state.home);
  const [
    triggerGetOrganizationsTable,
    { data, isLoading, isError, isFetching },
  ] = useLazyGetOrganizationAndChildsQuery();
  const {
    organizationTableParams,
    organizationRefreshTable,
    organizationBreadcrumb,
    organizationUpdateBreadcrumb,
  } = useSelector((state: any) => state.organization);

  // ************************************************ */
  // TABLE ACTIONS ********************************** */
  const manageActions = () => {
    const BLANK = {
      label: (
        <div className="generalStyles__tableActionBox">
          <span>
            <FontAwesomeIcon
              className="generalStyles__error generalStyles__mrFix"
              icon={faBan}
            />
            {t("general.noActions")}
          </span>
        </div>
      ),
      key: "0",
    };

    const CREATE_SUB_ORGANIZATION = validatePermission(
      "organizationscreate",
      permissions
    )
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenModalCreateSubOrganization();
              }}
              onKeyDown={() => {
                handleOpenModalCreateSubOrganization();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faBuildingCircleArrowRight}
                />
                {t("organizationManagement.createSubOrganization")}
              </span>
            </div>
          ),
          key: "1",
        }
      : null;

    const EDIT = validatePermission("organizationsupdate", permissions)
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenModalEdit();
              }}
              onKeyDown={() => {
                handleOpenModalEdit();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faPenToSquare}
                />
                {t("organizationManagement.edit")}
              </span>
            </div>
          ),
          key: "2",
        }
      : null;

    const DELETE = validatePermission("organizationsdelete", permissions)
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenModalDelete();
              }}
              onKeyDown={() => {
                handleOpenModalDelete();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faTrash}
                />
                {t("organizationManagement.delete")}
              </span>
            </div>
          ),
          key: "3",
        }
      : null;

    // return object with valid actions
    if (
      selectedItem &&
      selectedItem.type &&
      selectedItem.type !== process.env.REACT_APP_ORGANIZATION_MASTER_TYPE
    ) {
      if (EDIT || DELETE || CREATE_SUB_ORGANIZATION) {
        return [CREATE_SUB_ORGANIZATION, EDIT, DELETE];
      }
    }
    return [BLANK];
  };

  const menu = <Menu items={manageActions()} />;

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleOpenModalCreateSubOrganization = () => {
    dispatch(updateOrganization(selectedItem));
    dispatch(updateOpenModalCreateSubOrganization(true));
  };

  const handleOpenModalEdit = () => {
    dispatch(updateOrganization(selectedItem));
    dispatch(updateOpenModalEditOrganization(true));
  };

  const handleOpenModalDelete = () => {
    dispatch(updateOrganization(selectedItem));
    dispatch(updateOpenModalDeleteOrganization(true));
  };

  const onCellClick = (record: any) => {
    if (record) {
      dispatch(updateOrganization(record));
      dispatch(updateOpenDrawerOrganization(true));
    }
  };

  const navigateThrowOrganization = (
    record: any,
    updateBreadCrumb: boolean
  ) => {
    refreshTable(record.id, updateBreadCrumb, record.name);
    setIdOrganizationSelected(record.id);
  };

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: any
  ) => {
    dispatch(
      updateOrganizationTableParams({
        pagination,
        filters,
        ...sorter,
      })
    );
    //
    const token = await GETJwtToken();
    triggerGetOrganizationsTable({
      page: pagination.current,
      limit: pagination.pageSize,
      token,
      orgId: idOrganizationSelected,
    });
  };

  const getSearchAndFilters = () => {
    const searchAndFilterString = "/?";
    if (searchName !== "") {
      searchAndFilterString.concat("filter_target=name&filter=", searchName);
    }
    if (searchAndFilterString.length > 3) {
      return searchAndFilterString;
    }
    return "";
  };

  const refreshTable = async (
    orgId: string,
    updateBreadcrumb: boolean,
    orgName: string
  ) => {
    const searchAndFilterString = getSearchAndFilters();
    dispatch(
      updateOrganizationTableParams({
        pagination: {
          current: 1,
          pageSize: 10,
          total: organizationTableParams.pagination.total,
        },
        filters: {},
        sorter: {},
      })
    );
    //
    const token = await GETJwtToken();
    const response = await triggerGetOrganizationsTable({
      page: 1,
      limit: 10,
      token,
      orgId,
      searchAndFilterString,
    }).unwrap();
    if (
      updateBreadcrumb &&
      response &&
      response.data &&
      response.data.children
    ) {
      dispatch(
        updateOrganizationBreadcrumb([
          ...organizationBreadcrumb,
          { orgId, name: orgName },
        ])
      );
    }
    if (response && response.data && !response.data.children) {
      dispatch(
        updateOrganizationAlert({
          title: orgName,
          description:
            "This organization does not have sub organizations asigned",
          status: "info",
        })
      );
    }
  };

  const getInitialData = async (orgId: string) => {
    const token = await GETJwtToken();
    triggerGetOrganizationsTable({
      page: organizationTableParams.pagination.current,
      limit: organizationTableParams.pagination.pageSize,
      token,
      orgId,
    });
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    // clean breadcrumb
    dispatch(updateOrganizationBreadcrumb([]));
    if (userLogged && userLogged.org) {
      setIdOrganizationSelected(userLogged.org.id);
      getInitialData(userLogged.org.id);
      dispatch(
        updateOrganizationBreadcrumb([
          { orgId: userLogged.org.id, name: userLogged.org.name },
        ])
      );
    }
  }, []);

  useEffect(() => {
    if (
      data &&
      data.data &&
      data.data.children &&
      data.data.children.length > 0
    ) {
      dispatch(
        updateOrganizationTableParams({
          ...organizationTableParams,
          pagination: {
            ...organizationTableParams.pagination,
            total: data.total,
          },
        })
      );
      setOrganizations(data.data.children);
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      setOrganizations([]);
    }
  }, [isError]);

  useEffect(() => {
    if (organizationRefreshTable) {
      refreshTable(idOrganizationSelected, false, "");
    }
  }, [organizationRefreshTable]);

  useEffect(() => {
    // update breadcrumb from organization breadcrumb
    // remove items from breadcrumb and load again
    if (organizationUpdateBreadcrumb && organizationUpdateBreadcrumb.update) {
      navigateThrowOrganization(organizationUpdateBreadcrumb, false);
    }
  }, [organizationUpdateBreadcrumb]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div className="mt-3">
      {validatePermission("organizationsread", permissions) ? (
        <>
          {!isError ? (
            <Table
              rowClassName={(record, index) => getTableRowClass(index, theme)}
              className="customTable"
              dataSource={organizations}
              loading={isLoading || isFetching}
              rowKey={(record) => record.id}
              size="small"
              pagination={organizationTableParams.pagination}
              onChange={handleTableChange}
              scroll={{ x: 900 }}
            >
              {/** COLUMN - NAME */}
              <Column
                onCell={(record) => ({
                  onClick: () => {
                    navigateThrowOrganization(record, true);
                  },
                })}
                render={(text) => (
                  <>
                    <div className="">{text}</div>
                  </>
                )}
                title={t("organizationManagement.organizationName")}
                dataIndex="name"
                key="name"
                className="generalStyles__tableHeaderLink"
                filterIcon={<FontAwesomeIcon icon={faSearch} />}
                filterDropdown={() => (
                  <div className="generalStyles__searchDropdown">
                    <Input
                      style={{ marginBottom: 8, display: "block" }}
                      placeholder={t("organizationManagement.organizationName")}
                      onChange={(e) => {
                        setSearchName(e.target.value);
                      }}
                      value={searchName}
                    />
                    <div className="generalStyles__spaceBetween">
                      <div />
                      <div>
                        <Button
                          type="primary"
                          size="small"
                          style={{ width: 90 }}
                          onClick={() => {
                            refreshTable(idOrganizationSelected, false, "");
                          }}
                        >
                          Search
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              />
              {/** COLUMN - TYPE */}
              <Column
                onCell={(record) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                })}
                title={t("organizationManagement.type")}
                dataIndex="type"
                key="type"
                filters={[
                  {
                    text: "Master",
                    value: "MASTER",
                  },
                  {
                    text: "Organization",
                    value: "ORG",
                  },
                  {
                    text: "Sub Organization",
                    value: "SUBORG",
                  },
                ]}
                render={(text) => {
                  if (text === "SUBORG") {
                    return (
                      <>
                        <div className="">
                          <span>
                            {t("organizationManagement.subOrganization")}
                          </span>
                        </div>
                      </>
                    );
                  }
                  if (text === "MASTER") {
                    return (
                      <>
                        <div className="">
                          <span>{t("organizationManagement.master")}</span>
                        </div>
                      </>
                    );
                  }
                  if (text === "ORG") {
                    return (
                      <>
                        <div className="generalStyles__ellipsis__200px">
                          <span>
                            {t("organizationManagement.organization")}
                          </span>
                        </div>
                      </>
                    );
                  }
                  return true;
                }}
              />
              {/** COLUMN - ADDRESS */}
              <Column
                onCell={(record) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                })}
                title={t("organizationManagement.address")}
                dataIndex="address"
                key="address"
                render={(text) => (
                  <>
                    <div className="">{text}</div>
                  </>
                )}
              />
              {/** COLUMN - COUNTRY */}
              <Column
                onCell={(record) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                })}
                title={t("organizationManagement.country")}
                dataIndex="country"
                key="country"
                render={(text) => (
                  <>
                    <div className="">{text}</div>
                  </>
                )}
              />
              {/** COLUMN - ACTIONS */}
              <Column
                title={t("organizationManagement.actions")}
                key="action"
                render={(_: any, record: any) => (
                  <Dropdown overlay={menu} trigger={["click"]} className="">
                    <Space
                      className="generalStyles__hoverCursor"
                      onClick={() => {
                        setSelectedItem(record);
                      }}
                    >
                      <FontAwesomeIcon icon={faEllipsis} />
                    </Space>
                  </Dropdown>
                )}
              />
            </Table>
          ) : (
            <Alert
              message={t("organizationManagement.errorDescription.error")}
              description={t("organizationManagement.errorDescription.text1")}
              type="error"
              showIcon
              className="mt-3"
            />
          )}
        </>
      ) : (
        <>
          <div className="generalStyles__noAccessToListTable">
            <Empty
              description={t(
                "organizationManagement.listNotAvailableOrganizations"
              )}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default OrganizationTable;
