import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

const maxRetries = 2;

const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE,
  }),
  { maxRetries }
);

export const reportAPI = createApi({
  reducerPath: 'reportAPI',
  baseQuery,
  keepUnusedDataFor: 0,
  tagTypes: ['tagGetReports'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    //* *********************************************************** */
    //* GET ALL REPORTS */
    getOffsetReport: builder.query({
      query: (body: any) => ({
        url: `/reports/offsets/?&page=${body.page}&limit=${body.limit}${body.searchAndFilterString || ""}`,
        headers: { authorization: body.token },
      }),
      providesTags: ['tagGetReports'],
    }),
    //* *********************************************************** */
  }),
});

export const {
  useLazyGetOffsetReportQuery,
} = reportAPI;
