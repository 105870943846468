import React, { useState } from "react";
import { Auth } from "aws-amplify";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// ANT DESIGN COMPONENT
import { Drawer, Avatar, Button, Tag } from "antd";
// REACT DETECT OFFLINE
import { Offline, Online } from "react-detect-offline";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCircleCheck,
  faCircleExclamation,
  faBriefcase,
  faScrewdriverWrench,
  faEdit,
  faRightFromBracket,
  faSun,
  faMoon,
  faBuilding,
  faCheckCircle,
  faUserPlus,
  faBan,
  faWifi,
  faUserAltSlash,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import Appearance from "./Appearance";
import Internationalization from "./Internationalization";
import DrawerOrganizationInformation from "../components/DrawerOrganizationInformation";
import DrawerRoleInformation from "../components/DrawerRoleInformation";
import DrawerUserInformation from "../components/DrawerUserInformation";
// STYLES
import usaFlag from "../../assets/i18n/unitedStatesFlag.png";
import spainFlag from "../../assets/i18n/spainFlag.png";

library.add(
  faUser,
  faCircleCheck,
  faCircleExclamation,
  faBriefcase,
  faScrewdriverWrench,
  faEdit,
  faRightFromBracket,
  faSun,
  faMoon,
  faBuilding,
  faCheckCircle,
  faUserPlus,
  faBan
);

const YourProfileDrawer = () => {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const [visible, setVisible] = useState(false);
  const [t] = useTranslation("global");
  const { userLogged } = useSelector((state: any) => state.home);
  const { language } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************* */
  // FUNCTIONS *************************************** */
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const signOut = async () => {
    await Auth.signOut();
    window.location.href = "/login";
  };

  // *********************************************** */
  // USE EFFECT ************************************ */

  // ************************************************* */
  // COMPONENT ************************************** */
  return (
    <div>
      <div
        className={
          isMobile
            ? "generalStyles__flex layout__userAvatarMobile "
            : "generalStyles__flex"
        }
        role="button"
        tabIndex={0}
        onClick={() => {
          showDrawer();
        }}
        onKeyDown={() => {
          showDrawer();
        }}
      >
        {/** ************************************************* */}
        {/** HEADER AND TAGS */}
        {!isMobile && (
          <div>
            <Tag color="blue">
              {userLogged && userLogged.org && userLogged.org.name
                ? userLogged.org.name
                : ""}
            </Tag>
            <Tag color="cyan">
              {userLogged && userLogged.role && userLogged.role.name
                ? userLogged.role.name
                : ""}
            </Tag>
            <Online>
              <Tag color="green">
                <FontAwesomeIcon
                  icon={faWifi}
                  className="generalStyles__success generalStyles__userLoggedIcon"
                />
                {"  "}Online
              </Tag>
            </Online>
            <Offline>
              <Tag color="red">
                <FontAwesomeIcon
                  icon={faUserAltSlash}
                  className="generalStyles__error generalStyles__userLoggedIcon"
                />
                {"  "}Offline
              </Tag>
            </Offline>
          </div>
        )}
        {/** ************************************************* */}
        {/** USER AVATAR */}
        <div className="generalStyles__mlFix25">
          <Avatar className="button__button1">
            {userLogged && userLogged.first_name
              ? userLogged.first_name.substring(0, 1)
              : ""}
            {userLogged && userLogged.last_name
              ? userLogged.last_name.substring(0, 1)
              : ""}
          </Avatar>
        </div>
        {/** ************************************************* */}
        {/** CURRENT LANGUAGE ICON */}
        {!isMobile && (
          <div>
            <img
              src={language === "en" ? usaFlag : spainFlag}
              alt=""
              width="25"
              height="15"
              className="generalStyles__mlFix"
            />
          </div>
        )}
      </div>
      <Drawer
        width={isMobile ? "99%" : "35%"}
        placement="right"
        onClose={onClose}
        closable={false}
        visible={visible}
      >
        <div className="generalStyles__spaceBetween mb-3">
          <div className="generalStyles__flex">
            {/** ************************************************* */}
            {/** USER AVATAR */}
            <div>
              {!isMobile && (
                <Avatar
                  size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                >
                  {userLogged && userLogged.first_name
                    ? userLogged.first_name.substring(0, 1)
                    : ""}
                  {userLogged && userLogged.last_name
                    ? userLogged.last_name.substring(0, 1)
                    : ""}
                </Avatar>
              )}
            </div>
            {/** ************************************************* */}
            {/** USER EMAIL AND ROLE DATA */}
            <div className="generalStyles__mlFix25 mt-3">
              <p className="drawer__userNameDrawer">
                {userLogged && userLogged.first_name
                  ? userLogged.first_name
                  : ""}{" "}
                {userLogged && userLogged.middle_name
                  ? userLogged.middle_name
                  : ""}{" "}
                {userLogged && userLogged.last_name ? userLogged.last_name : ""}
              </p>
              <p className="generalStyles__userLoggedEmail">
                {userLogged && userLogged.email ? userLogged.email : "--"}
              </p>
              <p className="generalStyles__userLoggedEmail">
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="generalStyles__success generalStyles__userLoggedIcon"
                />
                <span className="generalStyles__mlFix">
                  {" "}
                  {userLogged && userLogged.role && userLogged.role.name
                    ? userLogged.role.name
                    : "--"}
                </span>
              </p>
            </div>
          </div>
          {/** ************************************************* */}
          {/** SIGN OUT BUTTON */}
          <div>
            <Button
              className="drawer__logoutButton"
              shape="round"
              icon={
                <FontAwesomeIcon
                  icon={faRightFromBracket}
                  className="generalStyles__mrFix"
                />
              }
              onClick={signOut}
            >
              {t("header.signOut")}
            </Button>
          </div>
        </div>
        {/** ************************************************* */}
        {userLogged && (
          <div>
            {/** ************************************************* */}
            {/** USER INFORMATION */}
            <DrawerUserInformation data={userLogged || {}} />
            {/** ************************************************* */}
            {/** ROLE INFORMATION */}
            <DrawerRoleInformation data={userLogged.role || {}} />
            {/** ************************************************* */}
            {/** ORGANIZATION INFORMATION */}
            <DrawerOrganizationInformation data={userLogged.org || {}} />
          </div>
        )}
        <br />
        {/** ************************************************* */}
        {/** INTERNATIONALIZATION */}
        <Internationalization />
        {/** ************************************************* */}
        {/** APPEARANCE */}
        <Appearance />
        {/** ************************************************* */}
        {/** CLOSE AND SUBMIT BUTTONS */}
        <div className="mt-4 generalStyles__width100">
          <div>
            <Button type="default" onClick={onClose} className="buttonStyle__5">
              {t("organizationManagement.close")}
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default YourProfileDrawer;
