import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { notification } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateGlobalAlert } from "../../redux/home/homeSlice";
import { updateOrganizationAlert } from "../../redux/organizations/organizationSlice";
import { updateUserAlert } from "../../redux/users/userSlice";
import { updateRoleAlert } from "../../redux/roles/roleSlice";
import { updateConfigurationAlert } from "../../redux/configuration/configurationSlice";
import { updateServerAlert } from "../../redux/servers/serverSlice";
import { updateBankAlert } from "../../redux/banks/bankSlice";
import { updateLiftAlert } from "../../redux/lifts/liftSlice";
import { updateConfigurationFileAlert } from "../../redux/configurationFiles/configurationFilesSlice";
import { updateToolAlert } from "../../redux/tools/toolsSlice";
// STYLES

function GlobalAlert() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const { globalAlert } = useSelector((state: any) => state.home);
  const { organizationAlert } = useSelector((state: any) => state.organization);
  const { userAlert } = useSelector((state: any) => state.user);
  const { roleAlert } = useSelector((state: any) => state.role);
  const { configurationAlert } = useSelector(
    (state: any) => state.configuration
  );
  const { serverAlert } = useSelector((state: any) => state.server);
  const { bankAlert } = useSelector((state: any) => state.bank);
  const { liftAlert } = useSelector((state: any) => state.lift);
  const { configurationFileAlert } = useSelector(
    (state: any) => state.configurationFile
  );
  const { toolAlert } = useSelector((state: any) => state.tool);
  type NotificationType = "success" | "info" | "warning" | "error";

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const launchNotification = (
    title: string,
    text: any,
    status: NotificationType
  ) => {
    let customClass = "";
    if (status === "success") {
      customClass = "generalStyles__notificationSuccess";
    } else if (status === "info") {
      customClass = "generalStyles__notificationInfo";
    } else if (status === "warning") {
      customClass = "generalStyles__notificationWarning";
    } else if (status === "error") {
      customClass = "generalStyles__notificationError";
    }
    let description = text;
    if (title === "Error") {
      if (text && text.data && text.data.message) {
        description = text.data.message;
      } else {
        description = t("sideBar.users");
      }
    }
    notification[status]({
      message: title,
      description,
      placement: "top",
      className: customClass,
    });
    // clean alert
    dispatch(
      updateGlobalAlert({
        title: "",
        description: "",
        status: "",
      })
    );
    // clean alert
    dispatch(
      updateOrganizationAlert({
        title: "",
        description: "",
        status: "",
      })
    );
    dispatch(
      updateUserAlert({
        title: "",
        description: "",
        status: "",
      })
    );
    dispatch(
      updateRoleAlert({
        title: "",
        description: "",
        status: "",
      })
    );
    dispatch(
      updateConfigurationAlert({
        title: "",
        description: "",
        status: "",
      })
    );
    dispatch(updateServerAlert({ title: "", description: "", status: "" }));
    dispatch(updateBankAlert({ title: "", description: "", status: "" }));
    dispatch(updateLiftAlert({ title: "", description: "", status: "" }));
    dispatch(
      updateConfigurationFileAlert({ title: "", description: "", status: "" })
    );
    dispatch(updateToolAlert({ title: "", description: "", status: "" }));
  };
  // ************************************************* */
  // USE EFFECT ************************************** */

  // ORGANIZATIONS
  useEffect(() => {
    if (
      globalAlert &&
      globalAlert.title &&
      globalAlert.description &&
      globalAlert.status
    ) {
      launchNotification(
        globalAlert.title,
        globalAlert.description,
        globalAlert.status
      );
    }
  }, [globalAlert]);

  // ORGANIZATIONS
  useEffect(() => {
    if (
      organizationAlert &&
      organizationAlert.title &&
      organizationAlert.description &&
      organizationAlert.status
    ) {
      launchNotification(
        organizationAlert.title,
        organizationAlert.description,
        organizationAlert.status
      );
    }
  }, [organizationAlert]);

  // USERS
  useEffect(() => {
    if (
      userAlert &&
      userAlert.title &&
      userAlert.description &&
      userAlert.status
    ) {
      launchNotification(
        userAlert.title,
        userAlert.description,
        userAlert.status
      );
    }
  }, [userAlert]);

  // ROLES
  useEffect(() => {
    if (
      roleAlert &&
      roleAlert.title &&
      roleAlert.description &&
      roleAlert.status
    ) {
      launchNotification(
        roleAlert.title,
        roleAlert.description,
        roleAlert.status
      );
    }
  }, [roleAlert]);

  // CONFIGURATION
  useEffect(() => {
    if (
      configurationAlert &&
      configurationAlert.title &&
      configurationAlert.description &&
      configurationAlert.status
    ) {
      launchNotification(
        configurationAlert.title,
        configurationAlert.description,
        configurationAlert.status
      );
    }
  }, [configurationAlert]);

  // CONFIGURATION FILE
  useEffect(() => {
    if (
      configurationFileAlert &&
      configurationFileAlert.title &&
      configurationFileAlert.description &&
      configurationFileAlert.status
    ) {
      launchNotification(
        configurationFileAlert.title,
        configurationFileAlert.description,
        configurationFileAlert.status
      );
    }
  }, [configurationFileAlert]);

  // SERVERS
  useEffect(() => {
    if (
      serverAlert &&
      serverAlert.title &&
      serverAlert.description &&
      serverAlert.status
    ) {
      launchNotification(
        serverAlert.title,
        serverAlert.description,
        serverAlert.status
      );
    }
  }, [serverAlert]);

  // BANKS
  useEffect(() => {
    if (
      bankAlert &&
      bankAlert.title &&
      bankAlert.description &&
      bankAlert.status
    ) {
      launchNotification(
        bankAlert.title,
        bankAlert.description,
        bankAlert.status
      );
    }
  }, [bankAlert]);

  // LIFTS
  useEffect(() => {
    if (
      liftAlert &&
      liftAlert.title &&
      liftAlert.description &&
      liftAlert.status
    ) {
      launchNotification(
        liftAlert.title,
        liftAlert.description,
        liftAlert.status
      );
    }
  }, [liftAlert]);

  // TOOL
  useEffect(() => {
    if (
      toolAlert &&
      toolAlert.title &&
      toolAlert.description &&
      toolAlert.status
    ) {
      launchNotification(
        toolAlert.title,
        toolAlert.description,
        toolAlert.status
      );
    }
  }, [toolAlert]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      <div id="global-alert" />
    </div>
  );
}

export default GlobalAlert;
