import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Cascader } from "antd";
// REDUX
import { useDispatch } from "react-redux";
import { useLazyGetOrganizationsCascaderQuery } from "../../../redux/monitoring/monitoringAPI";
import { updateOrganizationSelectMultipleData } from "../../../redux/organizations/organizationSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import "antd/dist/antd.min.css";

function OrganizationCascaderSelectMultiple() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const { SHOW_CHILD } = Cascader;
  const dispatch = useDispatch();

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [cascaderData, setCascaderData] = useState([]);
  const [cascaderValue, setCascaderValue] = useState<string[]>();

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [triggerGetOrganizationsCascader, { data }] =
    useLazyGetOrganizationsCascaderQuery();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const initData = async () => {
    const token = await GETJwtToken();
    triggerGetOrganizationsCascader({ token });
  };

  const buildCascaderArray = () => {
    if (data && data.organizations && data.organizations.length > 0) {
      const newArr: any = [];
      // ***************************************
      // BUILD ORGANIZATIONS CASCADER
      data.organizations.map((org: any) => {
        const orgCascader: any = {
          value: org.id,
          label: org.name,
          children: [],
          type: "organization",
        };
        // ***************************************
        // BUILD SERVER CASCADER
        if (org && org.servers && org.servers.length > 0) {
          org.servers.map((serv: any) => {
            const servCascader: any = {
              value: serv.id,
              label: serv.name,
              children: [],
              type: "server",
            };
            // ***************************************
            // BUILD CHANNELS CASCADER
            if (serv && serv.channels && serv.channels.length > 0) {
              serv.channels.map((channel: any) => {
                const channelCascader: any = {
                  value: channel.id,
                  label: channel.name,
                  children: [],
                  type: "channel",
                };
                // ***************************************
                // BUILD BANKS CASCADER
                if (channel && channel.banks && channel.banks.length > 0) {
                  channel.banks.map((bank: any) => {
                    const bankCascader: any = {
                      value: bank.id,
                      label: bank.name,
                      children: [],
                      type: "bank",
                      idOrganization: org.id,
                      idServer: serv.id,
                      idChannel: channel.id,
                      idBank: bank.id,
                      nameOrganization: org.name,
                      nameServer: serv.name,
                      nameChannel: channel.name,
                      ...bank,
                    };
                    return channelCascader.children.push(bankCascader);
                  });
                }
                return servCascader.children.push(channelCascader);
              });
            }
            return orgCascader.children.push(servCascader);
          });
        }
        return newArr.push(orgCascader);
      });
      setCascaderData(newArr);
    }
  };

  const onChangeCascader = (_: any, selectedOptions: any) => {
    const newArrSelectedOptions: any = [];
    const newArrSelectedBanks: any = [];
    if (selectedOptions) {
      selectedOptions.map((item: any) => {
        // Build cascader value array
        const subArr: any = [];
        item.map((item2: any) => subArr.push(item2.value));
        newArrSelectedOptions.push(subArr);
        // Get all banks selected
        if (item && item.length === 4) {
          newArrSelectedBanks.push(item[3]);
        }
        return true;
      });
    }
    dispatch(updateOrganizationSelectMultipleData(newArrSelectedBanks));
    setCascaderValue(newArrSelectedOptions);
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (data) {
      buildCascaderArray();
    }
  }, [data]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div className="generalStyles__flex">
      {/** ******************************************* */}
      {/** CASCADER */}
      <div className="generalStyles__mlFix">
        <Cascader
          maxTagCount="responsive"
          options={cascaderData}
          onChange={onChangeCascader}
          placeholder="Please select"
          multiple
          value={cascaderValue}
          showCheckedStrategy={SHOW_CHILD}
        />
      </div>
    </div>
  );
}

export default OrganizationCascaderSelectMultiple;
