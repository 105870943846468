import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// ANT DESIGN COMPONENTS
import {
  Input,
  Switch,
  Button,
  Tooltip,
  Table,
  InputNumber,
  Select,
  Badge,
} from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleExclamation,
  faSave,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateBreadcrumbTitle } from "../../../redux/home/homeSlice";
import {
  updateConfigurationFileAlert,
  updateConfigurationFileBreadcrumb,
  updateShowSpecialSwitches,
  updateOpenModalUnsavedChangesDefinition,
} from "../../../redux/configurationFiles/configurationFilesSlice";
import {
  usePostDefinitionMutation,
  usePutDefinitionMutation,
} from "../../../redux/configurationFiles/configurationFilesAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACE
import {
  DefinitionByteInterface,
  DefinitionsInterface,
  DefinitionsInterfacePostPut,
  DefinitionBitInterface,
  SpecialSwitchInterface,
} from "../../../interfaces/Configurations.interface";
// COMPONENTS
import SpecialSwitchesTable from "../specialSwitches/SpecialSwitchesTable";
import DefinitionsModalUnsaveChanges from "./DefinitionsModalUnsaveChanges";
// UTILS
import {
  getOffsetsCountByConfiguration,
  getOffsetsAttributesByConfiguration,
  onChangeTextAreaCopyAndPasteFromExcelColumn,
  addSpecialSwitchesToDefinitions,
  validateIfStringIsExcelData,
  getOffsetIdByName,
  getTableRowOffsetClass,
  getDefinitionsArrayByOffsetType,
} from "../../../utils/utils";

function DefinitionsTable() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const { theme } = useSelector((state: any) => state.home);
  const { Column } = Table;
  const { Option } = Select;
  const { TextArea } = Input;
  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [definitionBytes, setDefinitionBytes] = useState<
    DefinitionByteInterface[]
  >([]);
  const [byteCount, setByteCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [editionMode, setEditionMode] = useState(false);
  const [specialSwitches, setSpecialSwitches] = useState<
    SpecialSwitchInterface[]
  >([]);
  const [dataTable, setDataTable] = useState<DefinitionsInterface[]>([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [attributes, setAttributes] = useState<any>({});
  const [idOffsetType, setIdOffsetType] = useState("");
  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { configurationFile, offsetTypeName, offsetTypeTagColor } = useSelector(
    (state: any) => state.configurationFile
  );
  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    postDefinition,
    {
      isSuccess: isSuccessPost,
      isError: isErrorPost,
      error: errorPost,
      isLoading: isLoadingPost,
      reset: resetPost,
    },
  ] = usePostDefinitionMutation();
  const [
    putDefinition,
    {
      isSuccess: isSuccessPut,
      isError: isErrorPut,
      error: errorPut,
      isLoading: isLoadingPut,
      reset: resetPut,
    },
  ] = usePutDefinitionMutation();
  // ************************************************ */
  // FUNCTIONS ************************************** */
  const showLoading = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const onClickClose = () => {
    localStorage.setItem("headerTitle", "configurationCatalog");
    dispatch(updateBreadcrumbTitle("configurationCatalog"));
    setDefinitionBytes([]);
    setByteCount(0);
    dispatch(updateShowSpecialSwitches(false));
    setSpecialSwitches([]);
    dispatch(updateConfigurationFileBreadcrumb([]));
    setUnsavedChanges(false);
    resetPost();
    resetPut();
    navigate("/edge-management/configuration-catalog");
  };

  const onClickShowSpecialSwitches = () => {
    dispatch(updateShowSpecialSwitches(true));
  };

  const onChangeInputTextArea = (
    newValue: string,
    byte: number,
    bit: number,
    columnName: string
  ) => {
    setUnsavedChanges(true);
    if (validateIfStringIsExcelData(newValue, byteCount)) {
      setDefinitionBytes(
        onChangeTextAreaCopyAndPasteFromExcelColumn(
          newValue,
          byte - 1,
          bit,
          byteCount,
          definitionBytes,
          columnName
        )
      );
    } else {
      const copy: any = structuredClone(definitionBytes);
      copy[byte - 1].bits[bit][columnName] = newValue;
      setDefinitionBytes(copy);
    }
  };

  const onChangeInputPriority = (
    newValue: number,
    index: number,
    index2: number
  ) => {
    showLoading();
    setUnsavedChanges(true);
    const newObj = [...definitionBytes];
    newObj[index - 1].bits[index2].priority = newValue;
    setDefinitionBytes(newObj);
  };

  const onChangeActivePosition = (
    newValue: boolean,
    index: number,
    index2: number
  ) => {
    showLoading();
    setUnsavedChanges(true);
    const newObj = [...definitionBytes];
    newObj[index - 1].bits[index2].activePosition = newValue;
    setDefinitionBytes(newObj);
  };

  const onChangeSelectSeverity = (
    newValue: string,
    index: number,
    index2: number
  ) => {
    showLoading();
    const newObj = [...definitionBytes];
    newObj[index - 1].bits[index2].severity = newValue;
    setDefinitionBytes(newObj);
  };

  const onChangeInputConfirmSeconds = (
    newValue: number,
    index: number,
    index2: number
  ) => {
    showLoading();
    const newObj = [...definitionBytes];
    newObj[index - 1].bits[index2].confirmSeconds = newValue;
    setDefinitionBytes(newObj);
  };

  const onChangeInputHelpID = (
    newValue: number,
    index: number,
    index2: number
  ) => {
    showLoading();
    const newObj = [...definitionBytes];
    newObj[index - 1].bits[index2].helpId = newValue;
    setDefinitionBytes(newObj);
  };

  const onChangeSelectGroup = (
    newValue: string,
    index: number,
    index2: number
  ) => {
    showLoading();
    const newObj = [...definitionBytes];
    newObj[index - 1].bits[index2].group = newValue;
    setDefinitionBytes(newObj);
  };

  const onChangeInputPagingScheme = (
    newValue: number,
    index: number,
    index2: number
  ) => {
    showLoading();
    const newObj = [...definitionBytes];
    newObj[index - 1].bits[index2].pagingScheme = newValue;
    setDefinitionBytes(newObj);
  };

  const onChangeInputThirdPartyPosition = (
    newValue: number,
    index: number,
    index2: number
  ) => {
    showLoading();
    const newObj = [...definitionBytes];
    newObj[index - 1].bits[index2].thirdPartyPosition = newValue;
    setDefinitionBytes(newObj);
  };

  const onChangeSwitchAlert = (
    newValue: boolean,
    index: number,
    index2: number
  ) => {
    showLoading();
    const newObj = [...definitionBytes];
    newObj[index - 1].bits[index2].alert = newValue;
    setDefinitionBytes(newObj);
  };

  const onChangeSwitchLog = (
    newValue: boolean,
    index: number,
    index2: number
  ) => {
    showLoading();
    const newObj = [...definitionBytes];
    newObj[index - 1].bits[index2].log = newValue;
    setDefinitionBytes(newObj);
  };

  const buildDefinitionsByteArray = (totalBytes: number) => {
    const newObject = [];
    let totalBits = 7;
    if (totalBytes === 1) {
      totalBits = 255;
    }
    for (let item = 0; item < totalBytes; item += 1) {
      const subObj: DefinitionByteInterface = {
        name: `${offsetTypeName} byte ${item + 1}`,
        isValid: false,
        bits: [],
      };
      for (let index2 = 0; index2 <= totalBits; index2 += 1) {
        subObj.bits.push({
          byte: item + 1,
          bit: index2,
          shortName: "",
          longName: "",
          activePosition: false,
          priority: 0,
          specialSwitchName: "",
          specialSwitchId: "",
          alert: false,
          log: false,
          severity: "",
          confirmSeconds: 0,
          helpId: 0,
          group: "",
          pagingScheme: 0,
          thirdPartyPosition: 0,
          code: "",
          faultSpect: 0,
          idDefinition: "",
        });
      }
      newObject.push(subObj);
    }
    setDefinitionBytes(newObject);
    return newObject;
  };

  const buildDefinitions = () => {
    const newObj: DefinitionsInterfacePostPut[] = [];
    if (definitionBytes && definitionBytes.length > 0) {
      definitionBytes.map((item: DefinitionByteInterface) => {
        if (item.bits) {
          item.bits.map((item2: DefinitionBitInterface) => {
            if (item2.shortName !== "" || item2.longName !== "") {
              const prevData: DefinitionsInterfacePostPut = {
                active_position: item2.activePosition,
                alert: item2.alert,
                bit: item2.bit,
                byte: item2.byte,
                confirm_seconds: item2.confirmSeconds,
                group: item2.group,
                help_id: item2.helpId ? item2.helpId.toString() : "",
                id: item2.idDefinition,
                log: item2.log,
                message: item2.longName,
                offset_type_id: idOffsetType,
                paging_scheme: item2.pagingScheme,
                priority: item2.priority,
                severity: item2.severity,
                short_message: item2.shortName,
                special_switch_id: item2.specialSwitchId,
                special_switch_name: item2.specialSwitchName,
                third_party_position: item2.thirdPartyPosition
                  ? item2.thirdPartyPosition.toString()
                  : "",
                fault_spect: item2.faultSpect,
              };
              // Remove items if not exists in attributes
              if (attributes) {
                if (!attributes.activePosition) {
                  delete prevData.active_position;
                }
                if (!attributes.alert) {
                  delete prevData.alert;
                }
                if (!attributes.confirmSeconds) {
                  delete prevData.confirm_seconds;
                }
                if (!attributes.group) {
                  delete prevData.group;
                }
                if (!attributes.helpId) {
                  delete prevData.help_id;
                }
                if (!attributes.log) {
                  delete prevData.log;
                }
                if (!attributes.pagingScheme) {
                  delete prevData.paging_scheme;
                }
                if (!attributes.priority) {
                  delete prevData.priority;
                }
                if (!attributes.severity) {
                  delete prevData.severity;
                }
                if (!attributes.specialSwitch) {
                  delete prevData.special_switch_name;
                }
                if (!attributes.specialSwitch) {
                  delete prevData.special_switch_id;
                }
                if (!attributes.thirdPartyPosition) {
                  delete prevData.third_party_position;
                }
              }
              newObj.push(prevData);
            }
            return true;
          });
        }
        return true;
      });
    }
    // Separate data to create POST and PUT definitions
    const bodyPost: any = [];
    const bodyPut: any = [];
    newObj.map((item: any) => {
      if (item && item.id && item.id !== "") {
        bodyPut.push(item);
      } else {
        bodyPost.push(item);
      }
      return true;
    });
    return [bodyPost, bodyPut];
  };

  const onClickSave = async () => {
    setUnsavedChanges(false);
    const [bodyPost, bodyPut] = buildDefinitions();
    const token = await GETJwtToken();
    if (bodyPost && bodyPost.length > 0) {
      const BODY = {
        id: configurationFile.data_block.id,
        body: { definitions: bodyPost },
        token,
      };
      postDefinition(BODY);
    }
    if (bodyPut && bodyPut.length > 0) {
      const BODY = {
        id: configurationFile.data_block.id,
        body: { definitions: bodyPut },
        token,
      };
      putDefinition(BODY);
    }
  };

  const buildDataTable = (definitionBytes_: DefinitionByteInterface[]) => {
    const newArr: DefinitionsInterface[] = [];
    definitionBytes_.map((item: DefinitionByteInterface) => {
      item.bits.map((item2: DefinitionBitInterface) => {
        newArr.push({
          id: `${item2.byte}${item2.bit}`,
          byte: item2.byte,
          bit: item2.bit,
          longName: item2.longName,
          shortName: item2.shortName,
          offsets: [],
          type: "",
          specialSwitchId: item2.specialSwitchId,
          specialSwitchName: item2.specialSwitchName,
          activePosition: item2.activePosition,
          priority: item2.priority,
          log: item2.log,
          offset_id: "",
          pagingScheme: item2.pagingScheme,
          faultSpect: item2.faultSpect,
          alert: item2.alert,
          severity: item2.severity,
          confirmSeconds: item2.confirmSeconds,
          helpId: item2.helpId,
          group: item2.group,
          thirdPartyPosition: item2.thirdPartyPosition,
        });
        return true;
      });
      return true;
    });
    setDataTable(newArr);
  };

  const onChangeEditionMode = (newValue: boolean) => {
    showLoading();
    if (newValue) {
      setEditionMode(newValue);
    }
    if (unsavedChanges) {
      dispatch(updateOpenModalUnsavedChangesDefinition(true));
    } else {
      setEditionMode(newValue);
    }
  };

  const buildPreviousDefinitions = (
    definitions: DefinitionsInterfacePostPut[],
    definitionBytes_: any
  ) => {
    showLoading();
    const newObj = [...definitionBytes_];
    definitions.map((item: DefinitionsInterfacePostPut) => {
      newObj[item.byte - 1].bits[item.bit].idDefinition = item.id;
      newObj[item.byte - 1].bits[item.bit].shortName = item.short_message;
      newObj[item.byte - 1].bits[item.bit].longName = item.message;
      newObj[item.byte - 1].bits[item.bit].activePosition =
        item.active_position;
      newObj[item.byte - 1].bits[item.bit].priority = item.priority;
      newObj[item.byte - 1].bits[item.bit].specialSwitchName =
        item.special_switch_name;
      newObj[item.byte - 1].bits[item.bit].special_switch_id =
        item.special_switch_id;
      newObj[item.byte - 1].bits[item.bit].alert = item.alert;
      newObj[item.byte - 1].bits[item.bit].log = item.log;
      newObj[item.byte - 1].bits[item.bit].severity = item.severity;
      newObj[item.byte - 1].bits[item.bit].confirmSeconds =
        item.confirm_seconds;
      newObj[item.byte - 1].bits[item.bit].helpId = item.help_id;
      newObj[item.byte - 1].bits[item.bit].group = item.group;
      newObj[item.byte - 1].bits[item.bit].pagingScheme = item.paging_scheme;
      newObj[item.byte - 1].bits[item.bit].thirdPartyPosition =
        item.third_party_position;
      return true;
    });
    setDefinitionBytes(newObj);
  };

  const initData = () => {
    showLoading();
    // Get total bytes per offset type
    const totalBytes = getOffsetsCountByConfiguration(
      offsetTypeName,
      configurationFile.data_block.offsets
    );
    // Get list of attributes to build data table
    setAttributes(
      getOffsetsAttributesByConfiguration(
        offsetTypeName,
        configurationFile.data_block.offsets
      )
    );
    // Get offset type id to create definitions
    setIdOffsetType(
      getOffsetIdByName(offsetTypeName, configurationFile.data_block.offsets)
    );
    // Number of bytes per offset type
    setByteCount(totalBytes);
    const definitionObj = buildDefinitionsByteArray(totalBytes);
    // Get previous definitions already created and fill data table
    const definitions = getDefinitionsArrayByOffsetType(
      offsetTypeName,
      configurationFile.data_block.offsets
    );
    if (definitions && definitions.length > 0) {
      buildPreviousDefinitions(definitions, definitionObj);
    }
  };

  const onClickDiscart = () => {
    if (
      configurationFile &&
      configurationFile.data_block &&
      offsetTypeName &&
      offsetTypeTagColor
    ) {
      initData();
    }
    setUnsavedChanges(false);
    setEditionMode(false);
  };

  // ************************************************* */
  // USE EFFECT ************************************** */

  useEffect(() => {
    if (isSuccessPost || isSuccessPut) {
      setTimeout(() => {
        dispatch(
          updateConfigurationFileAlert({
            title: t("general.success"),
            description: t(
              "configuration.configurationFiles.definitionsCreated"
            ),
            status: "success",
          })
        );
      }, 150);
      onClickClose();
    }
    if (isErrorPost || isErrorPut) {
      setTimeout(() => {
        dispatch(
          updateConfigurationFileAlert({
            title: t("general.error"),
            description: errorPost || errorPut,
            status: "error",
          })
        );
      }, 150);
      onClickClose();
    }
  }, [isSuccessPost, isSuccessPut, isErrorPost, isErrorPut]);

  useEffect(() => {
    if (
      configurationFile &&
      configurationFile.data_block &&
      offsetTypeName &&
      offsetTypeTagColor
    ) {
      initData();
    }
  }, []);

  useEffect(() => {
    if (specialSwitches.length > 0) {
      setUnsavedChanges(true);
      addSpecialSwitchesToDefinitions(definitionBytes, specialSwitches);
    }
  }, [specialSwitches]);

  useEffect(() => {
    buildDataTable(definitionBytes);
  }, [definitionBytes, specialSwitches]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      {/** CLOSE AND SUBMIT BUTTONS */}
      <div
        className="generalStyles__pageHeader"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ display: "flex" }}>
          {dataTable && dataTable.length > 0 && (
            <>
              {attributes.specialSwitch && (
                <div className="generalStyles__mlFix mt-1">
                  <Button
                    type="primary"
                    onClick={() => {
                      onClickShowSpecialSwitches();
                    }}
                    disabled={byteCount === 0 || !editionMode}
                    icon={
                      <FontAwesomeIcon
                        icon={faTable}
                        className="generalStyles__mrFix"
                      />
                    }
                  >
                    {t("configuration.configurationFiles.specialSwitches")}
                  </Button>
                </div>
              )}
              <div className="generalStyles__mlFix mt-1">
                <Button
                  onClick={onClickSave}
                  type="primary"
                  disabled={byteCount === 0 || !editionMode}
                  icon={
                    <FontAwesomeIcon
                      icon={faSave}
                      className="generalStyles__mrFix"
                    />
                  }
                >
                  {t("general.save")}
                </Button>
              </div>
            </>
          )}
        </div>
        <div>
          {dataTable && dataTable.length > 0 && (
            <Switch
              checked={editionMode}
              onChange={(e) => {
                onChangeEditionMode(e);
              }}
              checkedChildren="Block Table"
              unCheckedChildren="Edit Mode"
            />
          )}
        </div>
      </div>
      <div className="mt-3">
        <div>
          {/** ---------------------------------------------------- */}
          {/** TABLE */}
          <Table
            rowClassName={(record, index) =>
              getTableRowOffsetClass(index, theme, record.byte)
            }
            dataSource={dataTable}
            pagination={{
              defaultPageSize: 8,
              showSizeChanger: true,
              pageSizeOptions: ["8", "20", "50", "100"],
            }}
            loading={isLoading || isLoadingPost || isLoadingPut}
            scroll={{ x: 1300 }}
            onChange={showLoading}
            size="small"
            rowKey={(record) => record.id}
          >
            {/** COLUMN STATUS */}
            <Column
              title="Status"
              dataIndex="status"
              key="status"
              className="generalStyles__tableHeaderLink"
              width={15}
              render={(text, record: DefinitionsInterface) => (
                <div>
                  {record.bit === 0 ? (
                    <Badge.Ribbon
                      color={offsetTypeTagColor}
                      text={`Byte ${record.byte}`}
                      style={{ marginTop: "-18px" }}
                      placement="start"
                    >
                      <div style={{ marginLeft: "75px" }}>
                        {record.shortName !== "" || record.longName !== "" ? (
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            className="generalStyles__success"
                          />
                        ) : (
                          <Tooltip title={t("tooltip.definitionNotUsed")}>
                            <FontAwesomeIcon
                              icon={faCircleExclamation}
                              className="generalStyles__icon"
                            />
                          </Tooltip>
                        )}
                      </div>
                    </Badge.Ribbon>
                  ) : (
                    <div style={{ marginLeft: "75px" }}>
                      {record.shortName !== "" || record.longName !== "" ? (
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="generalStyles__success"
                        />
                      ) : (
                        <Tooltip title={t("tooltip.definitionNotUsed")}>
                          <FontAwesomeIcon
                            icon={faCircleExclamation}
                            className="generalStyles__icon"
                          />
                        </Tooltip>
                      )}
                    </div>
                  )}
                </div>
              )}
            />
            {/** NO */}
            <Column
              title="No"
              className="generalStyles__tableHeaderLink"
              render={(text, record: any, index: number) => (
                <>
                  <div className="">{index + 1}</div>
                </>
              )}
            />
            {/** BYTE */}
            {attributes.byte && (
              <Column
                title="Byte"
                dataIndex="byte"
                key="byte"
                className="generalStyles__tableHeaderLink"
                render={(text) => (
                  <>
                    <div className="">
                      {text} / {byteCount}
                    </div>
                  </>
                )}
              />
            )}
            {/** BIT */}
            {attributes.bit && (
              <Column
                title="Bit"
                dataIndex="bit"
                key="bit"
                className="generalStyles__tableHeaderLink"
                render={(text) => (
                  <>
                    <div className="">{text}</div>
                  </>
                )}
              />
            )}
            {/** SPECIAL SWITCH */}
            {attributes.specialSwitch && (
              <Column
                title="Special Switch"
                dataIndex="specialSwitchName"
                key="specialSwitchName"
                className="generalStyles__tableHeaderLink"
                render={(text) => (
                  <div className="generalStyles__textEllipsis">
                    <span
                      className="generalStyles__info "
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        if (editionMode) {
                          onClickShowSpecialSwitches();
                        }
                      }}
                      onKeyDown={() => {
                        if (editionMode) {
                          onClickShowSpecialSwitches();
                        }
                      }}
                    >
                      {text || "--"}
                    </span>
                  </div>
                )}
              />
            )}
            {/** SHORT NAME */}
            {attributes.shortName && (
              <Column
                title="Short Name"
                dataIndex="shortName"
                key="shortName"
                className="generalStyles__tableHeaderLink"
                render={(text, record: DefinitionsInterface) => (
                  <TextArea
                    bordered={false}
                    placeholder={`${t(
                      "configuration.configurationFiles.shortName"
                    )} ${record.bit}`}
                    value={text}
                    onChange={(e: any) => {
                      onChangeInputTextArea(
                        e.currentTarget.value,
                        record.byte,
                        record.bit,
                        "shortName"
                      );
                    }}
                    disabled={!editionMode}
                    style={{ height: 40, resize: "none" }}
                  />
                )}
              />
            )}
            {/** LONG NAME */}
            {attributes.longName && (
              <Column
                title="Long Name"
                dataIndex="longName"
                key="longName"
                className="generalStyles__tableHeaderLink"
                render={(text, record: DefinitionsInterface) => (
                  <TextArea
                    bordered={false}
                    placeholder={`${t(
                      "configuration.configurationFiles.longName"
                    )} ${record.bit}`}
                    value={text}
                    onChange={(e: any) => {
                      onChangeInputTextArea(
                        e.currentTarget.value,
                        record.byte,
                        record.bit,
                        "longName"
                      );
                    }}
                    disabled={!editionMode}
                    style={{ height: 40, resize: "none" }}
                  />
                )}
              />
            )}
            {/** ACTIVE POSITION */}
            {attributes.activePosition && (
              <Column
                title="Active Position"
                dataIndex="activePosition"
                key="activePosition"
                className="generalStyles__tableHeaderLink"
                render={(text, record: DefinitionsInterface) => (
                  <Switch
                    checkedChildren="High"
                    unCheckedChildren="Low"
                    checked={text}
                    onChange={(e) => {
                      onChangeActivePosition(e, record.byte, record.bit);
                    }}
                    disabled={!editionMode}
                  />
                )}
              />
            )}
            {/** PRIORITY */}
            {attributes.priority && (
              <Column
                title="Priority"
                dataIndex="priority"
                key="priority"
                render={(text, record: DefinitionsInterface) => (
                  <InputNumber
                    bordered={false}
                    style={{ width: "100%" }}
                    value={text}
                    onChange={(e: number) => {
                      onChangeInputPriority(e, record.byte, record.bit);
                    }}
                    min={0}
                    max={99}
                    disabled={!editionMode}
                  />
                )}
              />
            )}
            {/** SEVERITY */}
            {attributes.severity && (
              <Column
                title="Severity"
                dataIndex="severity"
                key="severity"
                render={(text, record: DefinitionsInterface) => (
                  <Select
                    className="configuration__inputSizeL"
                    size="small"
                    bordered={false}
                    value={text}
                    disabled={!editionMode}
                    onChange={(e) => {
                      onChangeSelectSeverity(e, record.byte, record.bit);
                    }}
                  >
                    {[
                      "None",
                      "Trouble",
                      "Warning",
                      "Ready",
                      "Maint",
                      "Log",
                      "Ignore",
                    ].map((severity: string, index: number) => (
                      <Option key={index}>{severity}</Option>
                    ))}
                  </Select>
                )}
              />
            )}
            {/** ALERT */}
            {attributes.alert && (
              <Column
                title="Alert"
                dataIndex="alert"
                key="alert"
                render={(text, record: DefinitionsInterface) => (
                  <Switch
                    checkedChildren="Alert"
                    unCheckedChildren="No"
                    checked={text}
                    onChange={(e) => {
                      onChangeSwitchAlert(e, record.byte, record.bit);
                    }}
                    disabled={!editionMode}
                  />
                )}
              />
            )}
            {/** LOG */}
            {attributes.log && (
              <Column
                title="Log"
                dataIndex="log"
                key="log"
                render={(text, record: DefinitionsInterface) => (
                  <Switch
                    checkedChildren="Log"
                    unCheckedChildren="No"
                    checked={text}
                    onChange={(e) => {
                      onChangeSwitchLog(e, record.byte, record.bit);
                    }}
                    disabled={!editionMode}
                  />
                )}
              />
            )}
            {/** CONFIRM SECONDS */}
            {attributes.confirmSeconds && (
              <Column
                title="Confirm Seconds"
                dataIndex="confirmSeconds"
                key="confirmSeconds"
                render={(text, record: DefinitionsInterface) => (
                  <InputNumber
                    bordered={false}
                    className="configuration__inputSizeS"
                    min={0}
                    max={999}
                    disabled={!editionMode}
                    precision={0}
                    value={text}
                    onChange={(e) => {
                      onChangeInputConfirmSeconds(e, record.byte, record.bit);
                    }}
                  />
                )}
              />
            )}
            {/** HELP ID */}
            {attributes.helpId && (
              <Column
                title="Help ID"
                dataIndex="helpId"
                key="helpId"
                width="100px"
                render={(text, record: DefinitionsInterface) => (
                  <InputNumber
                    bordered={false}
                    className="configuration__inputSizeXXS"
                    min={0}
                    max={999}
                    disabled={!editionMode}
                    precision={0}
                    value={text}
                    onChange={(e) => {
                      onChangeInputHelpID(e, record.byte, record.bit);
                    }}
                  />
                )}
              />
            )}
            {/** GROUP */}
            {attributes.group && (
              <Column
                title="Group"
                dataIndex="group"
                key="group"
                render={(text, record: DefinitionsInterface) => (
                  <Select
                    className=" configuration__inputSizeL"
                    bordered={false}
                    size="small"
                    value={text}
                    disabled={!editionMode}
                    onChange={(e) => {
                      onChangeSelectGroup(e, record.byte, record.bit);
                    }}
                  >
                    {[
                      "General Fault",
                      "Door Fault",
                      "Safety Fault",
                      "Drive Fault",
                      "Warning",
                    ].map((group: string, index: number) => (
                      <Option key={index}>{group}</Option>
                    ))}
                  </Select>
                )}
              />
            )}
            {/** PAGING SCHEME */}
            {attributes.pagingScheme && (
              <Column
                title="Paging Scheme"
                dataIndex="pagingScheme"
                key="pagingScheme"
                render={(text, record: DefinitionsInterface) => (
                  <InputNumber
                    bordered={false}
                    className="configuration__inputSizeS"
                    min={0}
                    max={999}
                    disabled={!editionMode}
                    precision={0}
                    value={text}
                    onChange={(e) => {
                      onChangeInputPagingScheme(e, record.byte, record.bit);
                    }}
                  />
                )}
              />
            )}
            {/** THIRD PARTY POSITION */}
            {attributes.thirdPartyPosition && (
              <Column
                title="3rd Party Position"
                dataIndex="thirdPartyPosition"
                key="thirdPartyPosition"
                render={(text, record: DefinitionsInterface) => (
                  <InputNumber
                    bordered={false}
                    className="configuration__inputSizeS"
                    min={0}
                    max={999}
                    disabled={!editionMode}
                    precision={0}
                    value={text}
                    onChange={(e) => {
                      onChangeInputThirdPartyPosition(
                        e,
                        record.byte,
                        record.bit
                      );
                    }}
                  />
                )}
              />
            )}
          </Table>
        </div>
      </div>
      {/** ---------------------------------------------------- */}
      {/** SPECIAL SWITCHES COMPONENT */}
      <SpecialSwitchesTable
        showLoading={showLoading}
        byteCount={byteCount}
        setSpecialSwitches={setSpecialSwitches}
        definitionBytes={definitionBytes}
      />
      {/** ---------------------------------------------------- */}
      {/** MODAL UNSAVED CHANGES */}
      <DefinitionsModalUnsaveChanges
        onClickSave={onClickSave}
        onClickDiscart={onClickDiscart}
      />
    </div>
  );
}

export default DefinitionsTable;
