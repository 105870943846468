import React from "react";
// ANT DESIGN COMPONENTS
import { Button } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  updateOpenFormOffsetType,
  updateOffsetTypeRefreshTable,
  updateOpenFormOffsetTypeAction,
  updateShowOffsetTypeForm,
} from "../../../redux/tools/toolsSlice";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";

library.add(faPlus, faArrowsRotate);

function OffsetTypeHeader() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { permissions } = useSelector((state: any) => state.user);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleOpenModalCreateNewOffsetType = () => {
    dispatch(updateOpenFormOffsetType(true));
    dispatch(updateOpenFormOffsetTypeAction("create"));
  };

  const handleReloadPage = () => {
    dispatch(updateOffsetTypeRefreshTable(true));
    setTimeout(() => {
      dispatch(updateOffsetTypeRefreshTable(false));
    }, 1000);
  };

  return (
    <div className="generalStyles__pageHeader">
      <div className="generalStyles__mlFix mt-1">
        <Button
          onClick={() => {
            dispatch(updateShowOffsetTypeForm(false));
          }}
        >
          {t("configuration.cancel")}
        </Button>
      </div>
      {validatePermission("permission-not-defined", permissions) && (
        <div className="generalStyles__mlFix mt-1">
          <Button
            type="primary"
            icon={
              <FontAwesomeIcon icon={faPlus} className="generalStyles__mrFix" />
            }
            onClick={handleOpenModalCreateNewOffsetType}
          >
            {t("configuration.configurationFiles.createNewOffsetType")}
          </Button>
        </div>
      )}
      {validatePermission("permission-not-defined", permissions) && (
        <div className="generalStyles__mlFix mt-1">
          <Button
            type="primary"
            icon={
              <FontAwesomeIcon
                icon={faArrowsRotate}
                className="generalStyles__mrFix"
              />
            }
            onClick={() => {
              handleReloadPage();
            }}
          >
            {t("edgeManagement.banks.refreshTable")}
          </Button>
        </div>
      )}
    </div>
  );
}

export default OffsetTypeHeader;
