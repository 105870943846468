import React from "react";
// ANT DESIGN COMPONENTS
import { Divider } from "antd";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingCircleCheck } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";

function DrawerOrganizationInformation({ data }: any) {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const { theme } = useSelector((state: any) => state.home);
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      <br />
      {/** ************************************************* */}
      {/** ORGANIZATION INFORMATION */}
      <div>
        <Divider orientation="left" className="generalStyles__drawerDivider">
          <h5>
            <FontAwesomeIcon
              icon={faBuildingCircleCheck}
              className="generalStyles__info generalStyles__mrFix"
            />
            {t("organizationManagement.organizationInformation")}
          </h5>
        </Divider>
      </div>
      <div
        className={
          theme === "dark" ? " drawer__box__dark" : " drawer__box__light"
        }
      >
        {/* ORGANIZATION ID */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("organizationManagement.organizationId")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">ID</p>
          </div>
        </div>
        {/* NAME */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("organizationManagement.name")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {data && data.name ? data.name : "--"}
            </p>
          </div>
        </div>
        {/* TYPE */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("organizationManagement.type")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {data && data.type ? (
                <>{data.type === "ORG" ? "ORGANIZATION" : "SUB ORGANIZATION"}</>
              ) : (
                "--"
              )}
            </p>
          </div>
        </div>
        {/* ADDRESS */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("organizationManagement.address")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {data && data.address ? data.address : "--"}
            </p>
          </div>
        </div>
        {/* COUNTRY */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("organizationManagement.country")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {data && data.country ? data.country : "--"}
            </p>
          </div>
        </div>
        {/* STATE */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("organizationManagement.state")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {data && data.state ? data.state : "--"}
            </p>
          </div>
        </div>
        {/* CITY */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("organizationManagement.city")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {data && data.city ? data.city : "--"}
            </p>
          </div>
        </div>
        {/* PHONE NUMBER */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("organizationManagement.phoneNumber")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {data && data.phone ? data.phone : "--"}
            </p>
          </div>
        </div>
        {/* DIAL CODE */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("organizationManagement.dialCode")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {data && data.phone_code ? data.phone_code : "--"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DrawerOrganizationInformation;
