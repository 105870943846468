import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Button, Input, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { usePutRoleMutation } from "../../../redux/roles/roleAPI";
// AUTHORIZATION
import {
  updateOpenModalEditPermissionsRoot,
  updateConfigurationAlert,
} from "../../../redux/configuration/configurationSlice";
// UTILS
import { styleDeleteButton } from "../../../utils/utils";

library.add(faTrash, faTriangleExclamation);

function RootPermissionsEditModal() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const [t] = useTranslation("global");
  const dispatch = useDispatch();
  const { theme } = useSelector((state: any) => state.home);
  const { rootPermissions, openModalEditPermissionsRoot } = useSelector(
    (state: any) => state.configuration
  );
  const [disableButtonConfirmDelete, setDisableButtonConfirmDelete] =
    useState(true);
  const [confirmRoleName, setConfirmRoleName] = useState("");

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    putRole,
    { isSuccess, isError, error, isLoading, reset: resetPutRole },
  ] = usePutRoleMutation();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleOk = () => {
    dispatch(updateOpenModalEditPermissionsRoot(false));
  };

  const handleCancel = () => {
    dispatch(updateOpenModalEditPermissionsRoot(false));
  };

  const handleCloseModal = () => {
    dispatch(updateOpenModalEditPermissionsRoot(false));
  };

  const handleEditPermissions = async () => {
    putRole(rootPermissions);
  };

  const handleSubmitDeleteItem = async (event: any) => {
    if (event.key === "Enter" && !disableButtonConfirmDelete) {
      handleEditPermissions();
    }
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (openModalEditPermissionsRoot) {
      setDisableButtonConfirmDelete(true);
      setConfirmRoleName("");
      resetPutRole();
    }
  }, [openModalEditPermissionsRoot]);

  useEffect(() => {
    setDisableButtonConfirmDelete(true);
    if (confirmRoleName && confirmRoleName.toLowerCase() === "master admin") {
      setDisableButtonConfirmDelete(false);
    } else {
      setDisableButtonConfirmDelete(true);
    }
  }, [confirmRoleName]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        dispatch(
          updateConfigurationAlert({
            title: t("general.success"),
            description: t("organizationManagement.masterPermissionUpdate"),
            status: "success",
          })
        );
      }, 150);
      dispatch(updateOpenModalEditPermissionsRoot(false));
    }
    if (isError) {
      setTimeout(() => {
        dispatch(
          updateConfigurationAlert({
            title: t("general.error"),
            description: error,
            status: "error",
          })
        );
      }, 150);
      dispatch(updateOpenModalEditPermissionsRoot(false));
    }
  }, [isSuccess, isError]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Modal
      onOk={handleOk}
      closable={false}
      onCancel={handleCancel}
      visible={openModalEditPermissionsRoot}
      footer={null}
    >
      <Spin spinning={isLoading}>
        <div>
          <div className="text-center">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="generalStyles__warning generalStyles__deleteItemIcon"
            />
            <p className="mt-3 drawer__text">
              {t("organizationManagement.editRoleDescription.text1")}{" "}
              <b className="generalStyles__warning">
                {t("organizationManagement.editRoleDescription.text2")}{" "}
              </b>
              {t("organizationManagement.editRoleDescription.text3")}
              <b className="generalStyles__warning">
                {" "}
                {t("organizationManagement.editRoleDescription.text4")}{" "}
              </b>
              {t("organizationManagement.editRoleDescription.text5")}{" "}
            </p>
            <Input
              placeholder={t("organizationManagement.roleName")}
              onChange={(e) => {
                setConfirmRoleName(e.target.value);
              }}
              value={confirmRoleName}
              onKeyDown={handleSubmitDeleteItem}
              status="warning"
            />
          </div>
          <div className="generalStyles__flexEnd mt-4">
            <div>
              <Button
                htmlType="submit"
                className={styleDeleteButton(disableButtonConfirmDelete, theme)}
                onClick={handleEditPermissions}
                disabled={disableButtonConfirmDelete}
              >
                {t("organizationManagement.save")}
              </Button>
              <Button onClick={handleCloseModal} type="default">
                {t("organizationManagement.close")}
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
}

export default RootPermissionsEditModal;
