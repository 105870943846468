import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Form, Spin, Divider, Drawer, Input, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserShield,
  faBuildingCircleCheck,
  faUserLock,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  updateOpenModalCreateNewRole,
  updateRoleAlert,
} from "../../../redux/roles/roleSlice";
import { usePostRoleMutation } from "../../../redux/roles/roleAPI";
import { useLazyGetOrganizationAndChildsQuery } from "../../../redux/organizations/organizationAPI";
import { updateOrganizationAndChilds } from "../../../redux/organizations/organizationSlice";
// COMPONENTS
import RolesDinamicRolesTree from "./RolesDinamicRolesTree";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// COMPONENTS
import OrganizationSelectOrganizationSubOrganization from "../organizations/OrganizationSelectOrganizationSubOrganization";

library.add(faUserShield, faBuildingCircleCheck, faUserLock);

function RolesCreateRoleForm() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch();
  const { openModalCreateNewRole } = useSelector((state: any) => state.role);
  const { organizationAndChilds } = useSelector(
    (state: any) => state.organization
  );
  const { userLogged } = useSelector((state: any) => state.home);
  const [form] = Form.useForm();
  const [organizationsAndChildsIsLoading, setOrganizationsAndChildsIsLoading] =
    useState(false);
  const [masterID, setMasterID] = useState("");
  const [menu, setMenu] = useState<any>({});
  const [t] = useTranslation("global");
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    postRole,
    {
      isSuccess: isSuccessPostRole,
      isLoading: isLoadingPostRole,
      isError: isErrorPostRole,
      error: errorPostRole,
      reset: resetPostRole,
    },
  ] = usePostRoleMutation();
  const [
    triggerGetOrganizationAndChilds,
    {
      isError: isErrorGetOrganizationAndChilds,
      error: errorGetOrganizationAndChilds,
    },
  ] = useLazyGetOrganizationAndChildsQuery();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const cleanOrganizationsList = () => {
    dispatch(
      updateOrganizationAndChilds({
        childs: [],
      })
    );
  };

  const onClose = () => {
    cleanOrganizationsList();
    dispatch(updateOpenModalCreateNewRole(false));
  };

  const onFinish = async (values: any) => {
    const token = await GETJwtToken();
    let roleMenu: any = {
      active: true,
      offline: true,
      name: values.name,
      pages: [],
      actions: [],
    };
    if (menu && menu.active) {
      roleMenu = menu;
      if (roleMenu.name === "") {
        roleMenu.name = values.name;
      }
    }
    const BODY = {
      token,
      body: {
        active: true,
        offline: true,
        menu: JSON.stringify(roleMenu),
        name: values.name,
        org_id: organizationAndChilds.data.idOrganizationSelected
          ? organizationAndChilds.data.idOrganizationSelected
          : "",
      },
    };
    postRole(BODY);
  };

  const handleCloseModal = () => {
    cleanOrganizationsList();
    dispatch(updateOpenModalCreateNewRole(false));
  };

  const getOrganizationAndChilds = async (orgId: string) => {
    const token = await GETJwtToken();
    const data = await triggerGetOrganizationAndChilds({
      orgId,
      token,
    }).unwrap();
    if (
      data &&
      data.data &&
      data.data.children &&
      data.data.children.length > 0
    ) {
      const BODY = {
        childs: [
          ...organizationAndChilds.childs,
          {
            level: organizationAndChilds.childs.length,
            data: data.data.children,
            orgSelected: orgId,
          },
        ],
      };
      dispatch(updateOrganizationAndChilds(BODY));
    }
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (openModalCreateNewRole) {
      resetPostRole();
      form.resetFields();
      cleanOrganizationsList();
      getOrganizationAndChilds(masterID);
    }
  }, [openModalCreateNewRole]);

  useEffect(() => {
    if (isSuccessPostRole) {
      setTimeout(() => {
        dispatch(
          updateRoleAlert({
            title: t("general.success"),
            description: t("organizationManagement.roles.roleCreated"),
            status: "success",
          })
        );
      }, 150);
      cleanOrganizationsList();
      dispatch(updateOpenModalCreateNewRole(false));
    }
    if (isErrorPostRole) {
      setTimeout(() => {
        dispatch(
          updateRoleAlert({
            title: t("general.error"),
            description: errorPostRole,
            status: "error",
          })
        );
      }, 150);
      dispatch(updateOpenModalCreateNewRole(false));
    }
  }, [isSuccessPostRole, isErrorPostRole]);

  useEffect(() => {
    if (isErrorGetOrganizationAndChilds) {
      setTimeout(() => {
        dispatch(
          updateRoleAlert({
            title: "Error",
            description: errorGetOrganizationAndChilds,
            status: "error",
          })
        );
      }, 150);
      cleanOrganizationsList();
      dispatch(updateOpenModalCreateNewRole(false));
    }
  }, [isErrorGetOrganizationAndChilds]);

  useEffect(() => {
    if (userLogged && userLogged.org && userLogged.org.id) {
      setMasterID(userLogged.org.id);
    }
  }, [userLogged]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClose}
      closable={false}
      visible={openModalCreateNewRole}
    >
      <Spin
        spinning={isLoadingPostRole || organizationsAndChildsIsLoading}
        tip={t("general.loading")}
      >
        <div>
          <Divider orientation="left" className="generalStyles__drawerDivider">
            <h5>
              <FontAwesomeIcon
                icon={faUserShield}
                className="generalStyles__info generalStyles__mrFix"
              />
              {t("organizationManagement.createNewRole")}
            </h5>
          </Divider>
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <div className="container">
            <div
              className={
                theme === "dark"
                  ? "row drawer__box__dark"
                  : "row drawer__box__light"
              }
            >
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** NAME */}
                <div className="col-md-12 mt-3">
                  <div className="generalStyles__flex">
                    <div className="generalStyles__inputFlexName">
                      <span className="generalStyles__inputFlexRequired">
                        *
                      </span>
                      <span> {t("organizationManagement.roleName")}</span>
                    </div>
                    <div className="generalStyles__width100">
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: t(
                              "organizationManagement.formRequiredMessage.roleName"
                            ),
                          },
                          {
                            max: 50,
                            message: t(
                              "organizationManagement.formRequiredMessage.roleNameMax"
                            ),
                          },
                        ]}
                      >
                        <Input className="generalStyles__input" size="small" />
                      </Form.Item>
                    </div>
                    <div>
                      <div className="generalStyles__infoTooltipIconBlank" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/** ---------------------------------------------------- */}
            {/** GET ORGANIZATION AND CHILDS ----------- */}
            <OrganizationSelectOrganizationSubOrganization
              setOrganizationsAndChildsIsLoading={
                setOrganizationsAndChildsIsLoading
              }
            />
            <br />
            <Divider
              orientation="left"
              className="generalStyles__drawerDivider"
            >
              <h5>
                <FontAwesomeIcon
                  icon={faUserLock}
                  className="generalStyles__info generalStyles__mrFix"
                />
                {t("organizationManagement.permissions")}
              </h5>
            </Divider>
            <div
              className={
                theme === "dark"
                  ? "row drawer__box__dark"
                  : "row drawer__box__light"
              }
            >
              <RolesDinamicRolesTree setMenu={setMenu} />
            </div>
          </div>
          {/** ---------------------------------------------------- */}
          <div className="mt-4">
            <div>
              <Button
                type="default"
                onClick={handleCloseModal}
                className="buttonStyle__3"
              >
                {t("general.close")}
              </Button>
              <Button
                htmlType="submit"
                disabled={isLoadingPostRole}
                className="buttonStyle__3"
                type="primary"
              >
                {t("general.save")}
              </Button>
            </div>
          </div>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default RolesCreateRoleForm;
