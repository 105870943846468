/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Table, Alert, Menu, Dropdown, Space, Empty, Tag } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsis,
  faTrash,
  faPenToSquare,
  faBan,
  faCheckCircle,
  faLock,
  faPlug,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  useLazyGetServersQuery,
  usePostSecretClientTokenMutation,
} from "../../../redux/servers/serverAPI";
import {
  updateOpenModalDeleteServer,
  updateOpenModalEditServer,
  updateOpenDrawerServer,
  updateServer,
  updateServerTableParams,
  updateServerBreadcrumb,
  updateIdServerSelected,
  updateNameServerSelected,
  updateOpenDrawerEditChannel,
  updateOpenModalSecretClient,
} from "../../../redux/servers/serverSlice";
import { udpateOpenModalTotpToken } from "../../../redux/authentication/authenticationSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import validatePermission from "../../../utils/validatePermissions";
// COMPONENTS
import AuthenticationTOTP from "../../authentication/AuthenticationTOTP";
import ServerModalSecretClient from "./ServerModalSecretClient";
// UTILS
import { getTableRowClass } from "../../../utils/utils";

library.add(faEllipsis, faTrash, faPenToSquare);

const ServersTable = () => {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [servers, setServers] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [idServerSelected, setIdServerSelected] = useState("");
  const [totpTokenIsValid, setTotpTokenIsValid] = useState(false);
  const [secretClientToken, setSecretClientToken] = useState("");
  const [totp, setTotp] = useState("");
  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { permissions } = useSelector((state: any) => state.user);
  const { userLogged } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [triggerGetServersTable, { data, isLoading, isError, isFetching }] =
    useLazyGetServersQuery();
  const [
    triggerGetSecretClientMutation,
    { data: dataSecretClientToken, isLoading: isLoadingSecretClientToken },
  ] = usePostSecretClientTokenMutation();
  const { serverTableParams, serverRefreshTable } = useSelector(
    (state: any) => state.server
  );

  // ************************************************ */
  // TABLE ACTIONS ************************************ */

  const { Column } = Table;

  const manageActions = () => {
    const BLANK = {
      label: (
        <div className="generalStyles__tableActionBox">
          <span>
            <FontAwesomeIcon
              className="generalStyles__error generalStyles__mrFix"
              icon={faBan}
            />
            {t("general.noActions")}
          </span>
        </div>
      ),
      key: "0",
    };

    const CHANNELS = validatePermission("permission-not-defined", permissions)
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenDrawerEditChannels();
              }}
              onKeyDown={() => {
                handleOpenDrawerEditChannels();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faPlug}
                />
                {t("edgeManagement.servers.channels")}
              </span>
            </div>
          ),
          key: "1",
        }
      : null;

    const SECRET_CLIENT = validatePermission(
      "permission-not-defined",
      permissions
    )
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenModalTotpToken();
              }}
              onKeyDown={() => {
                handleOpenModalTotpToken();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faKey}
                />
                {t("edgeManagement.servers.secretClient")}
              </span>
            </div>
          ),
          key: "2",
        }
      : null;

    const EDIT = validatePermission("server-managerupdate", permissions)
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenModalEdit();
              }}
              onKeyDown={() => {
                handleOpenModalEdit();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faPenToSquare}
                />
                {t("organizationManagement.edit")}
              </span>
            </div>
          ),
          key: "3",
        }
      : null;

    const DELETE = validatePermission("server-managerdelete", permissions)
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenModalDelete();
              }}
              onKeyDown={() => {
                handleOpenModalDelete();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faTrash}
                />
                {t("organizationManagement.delete")}
              </span>
            </div>
          ),
          key: "4",
        }
      : null;

    // RETURN OBJECT WITH VALID ACTIONS
    if (EDIT || DELETE || CHANNELS || SECRET_CLIENT) {
      return [CHANNELS, SECRET_CLIENT, EDIT, DELETE];
    }
    return [BLANK];
  };

  const menu = <Menu items={manageActions()} />;

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleOpenModalTotpToken = () => {
    dispatch(udpateOpenModalTotpToken(true));
  };

  const handleOpenDrawerEditChannels = () => {
    dispatch(updateServer(selectedItem));
    dispatch(updateOpenDrawerEditChannel(true));
  };

  const handleOpenModalEdit = () => {
    dispatch(updateServer(selectedItem));
    dispatch(updateOpenModalEditServer(true));
  };

  const handleOpenModalDelete = () => {
    dispatch(updateServer(selectedItem));
    dispatch(updateOpenModalDeleteServer(true));
  };

  const onCellNameClick = (record: any) => {
    if (record) {
      dispatch(updateServerBreadcrumb([record.name]));
      dispatch(updateIdServerSelected(record.id));
      dispatch(updateNameServerSelected(record.name));
      dispatch(updateServer(record));
    }
  };

  const onCellClick = (record: any) => {
    if (record) {
      dispatch(updateServer(record));
      dispatch(updateOpenDrawerServer(true));
    }
  };

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: any
  ) => {
    dispatch(
      updateServerTableParams({
        pagination,
        filters,
        ...sorter,
      })
    );
    //
    const token = await GETJwtToken();
    triggerGetServersTable({
      page: pagination.current,
      limit: pagination.pageSize,
      token,
      orgId: idServerSelected,
    });
  };

  const refreshTable = async (orgId: string) => {
    dispatch(
      updateServerTableParams({
        pagination: {
          current: 1,
          pageSize: 10,
          total: serverTableParams.pagination.total,
        },
        filters: {},
        sorter: {},
      })
    );
    //
    const token = await GETJwtToken();
    await triggerGetServersTable({
      page: 1,
      limit: 10,
      token,
      orgId,
    }).unwrap();
  };

  const getInitialData = async () => {
    const token = await GETJwtToken();
    triggerGetServersTable({
      page: serverTableParams.pagination.current,
      limit: serverTableParams.pagination.pageSize,
      token,
    });
  };

  const TotpTokenIsValid = async () => {
    if (totpTokenIsValid) {
      const token = await GETJwtToken();
      triggerGetSecretClientMutation({
        token,
        body: {
          server_id: selectedItem.id,
          totp,
        },
      });
    }
  };
  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (userLogged && userLogged.org) {
      setIdServerSelected(userLogged.org.id);
      getInitialData();
    }
  }, []);

  useEffect(() => {
    if (data && data.data && data.data.length > 0) {
      dispatch(
        updateServerTableParams({
          ...serverTableParams,
          pagination: {
            ...serverTableParams.pagination,
            total: data.total,
          },
        })
      );
      setServers(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      setServers([]);
    }
  }, [isError]);

  useEffect(() => {
    if (serverRefreshTable) {
      refreshTable(idServerSelected);
    }
  }, [serverRefreshTable]);

  useEffect(() => {
    if (totpTokenIsValid) {
      TotpTokenIsValid();
      setTimeout(() => {
        setTotpTokenIsValid(false);
      }, 1000);
    }
  }, [totpTokenIsValid]);

  useEffect(() => {
    if (
      dataSecretClientToken &&
      dataSecretClientToken.data &&
      dataSecretClientToken.data.Secret
    ) {
      setSecretClientToken(dataSecretClientToken.data.Secret);
      dispatch(updateOpenModalSecretClient(true));
    }
  }, [dataSecretClientToken]);
  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div className="mt-3">
      {validatePermission("server-managerread", permissions) ? (
        <>
          {!isError ? (
            <Table
              rowClassName={(record, index) => getTableRowClass(index, theme)}
              className="customTable"
              dataSource={servers}
              loading={isLoading || isFetching || isLoadingSecretClientToken}
              rowKey={(record) => record.id}
              size="small"
              pagination={serverTableParams.pagination}
              onChange={handleTableChange}
              scroll={{ x: 900 }}
            >
              {/** NAME */}
              <Column
                onCell={(record) => ({
                  onClick: () => {
                    onCellNameClick(record);
                  },
                })}
                title={t("edgeManagement.servers.serverName")}
                dataIndex="name"
                key="name"
                className="generalStyles__tableHeaderLink"
                filterSearch
                render={(text) => (
                  <>
                    <div className="">{text}</div>
                  </>
                )}
              />
              {/** IP */}
              <Column
                onCell={(record) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                })}
                title={t("edgeManagement.servers.ip")}
                dataIndex="ip"
                key="ip"
                render={(text) => (
                  <>
                    <div className="">{text}</div>
                  </>
                )}
              />
              {/** TOTAL CHANNELS ASIGNED */}
              <Column
                onCell={(record) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                })}
                width="150px"
                title={t("edgeManagement.servers.channels")}
                dataIndex="organizacion"
                key="organization"
                render={(text, record: any) => (
                  <>
                    <div className="">
                      {record && record.channels ? record.channels.length : 0}
                    </div>
                  </>
                )}
              />
              {/** TOTAL BANKS ASIGNED */}
              <Column
                onCell={(record) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                })}
                width="150px"
                title={t("edgeManagement.banks.banks")}
                dataIndex="organizacion"
                key="organization"
                render={(text, record: any) => (
                  <>
                    <div className="">
                      {record && record.banks ? record.banks.length : 0}
                    </div>
                  </>
                )}
              />
              {/** ORGANIZATION */}
              <Column
                onCell={(record) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                })}
                title={t("edgeManagement.servers.organization")}
                dataIndex="organizacion"
                key="organization"
                render={(text, record: any) => (
                  <>
                    <div className="">
                      {record && record.org && record.org.name
                        ? record.org.name
                        : "--"}
                    </div>
                  </>
                )}
              />
              {/** STATUS */}
              <Column
                onCell={(record) => ({
                  onClick: () => {
                    onCellClick(record);
                  },
                })}
                title={t("userManagement.status")}
                dataIndex="status"
                key="status"
                className=""
                width="150px"
                filterSearch
                render={() => (
                  <>
                    <div className="">
                      {true && (
                        <Tag
                          color="success"
                          icon={
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="generalStyles__mrFix"
                            />
                          }
                          className="drawer__tag"
                        >
                          {t("general.active")}
                        </Tag>
                      )}
                      {false && (
                        <Tag
                          color="error"
                          icon={
                            <FontAwesomeIcon
                              icon={faLock}
                              className="generalStyles__mrFix"
                            />
                          }
                          className="drawer__tag"
                        >
                          {t("general.lock")}
                        </Tag>
                      )}
                    </div>
                  </>
                )}
              />
              {/** ACTIONS */}
              <Column
                title={t("edgeManagement.servers.actions")}
                key="action"
                width="150px"
                render={(_: any, record: any) => (
                  <Dropdown overlay={menu} trigger={["click"]} className="">
                    <Space
                      className="generalStyles__hoverCursor"
                      onClick={() => {
                        setSelectedItem(record);
                      }}
                    >
                      <FontAwesomeIcon icon={faEllipsis} />
                    </Space>
                  </Dropdown>
                )}
              />
            </Table>
          ) : (
            <Alert
              message={t("organizationManagement.errorDescription.error")}
              description={t("organizationManagement.errorDescription.text1")}
              type="error"
              showIcon
              className="mt-3"
            />
          )}
        </>
      ) : (
        <>
          <div className="generalStyles__noAccessToListTable">
            <Empty
              description={t("edgeManagement.servers.listNotAvailable")}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        </>
      )}
      {/** ********************************************* */}
      {/** AUTHENTICATION TOPTP TOKEN */}
      <AuthenticationTOTP
        setTotpTokenIsValid={setTotpTokenIsValid}
        setTotp={setTotp}
      />
      {/** ********************************************* */}
      {/** SECRET CLIENT */}
      <ServerModalSecretClient secretClientToken={secretClientToken} />
    </div>
  );
};

export default ServersTable;
