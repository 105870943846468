import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserShield,
  faBuildingCircleCheck,
  faUserLock,
  faCheckCircle,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerBank } from "../../../redux/banks/bankSlice";
// COMPONENTS
import DrawerBankInformation from "../../components/DrawerBankInformation";
import DrawerPortInformation from "../../components/DrawerPortInformation";
import DrawerServerInformation from "../../components/DrawerServerInformation";
import DrawerLiftsAssigned from "../../components/DrawerLiftsAssigned";

library.add(
  faUserShield,
  faBuildingCircleCheck,
  faUserLock,
  faCheckCircle,
  faBan
);

function BanksDrawer() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [data, setData] = useState<any>({});

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { bank, openDrawerBank } = useSelector((state: any) => state.bank);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onClickClose = () => {
    dispatch(updateOpenDrawerBank(false));
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (openDrawerBank) {
      if (bank) {
        setData(bank);
      }
    }
  }, [openDrawerBank]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClickClose}
      closable={false}
      visible={openDrawerBank}
    >
      {data && (
        <div>
          {/** ************************************************* */}
          {/** BANK INFORMATION */}
          <DrawerBankInformation data={bank || {}} />
          {/** ************************************************* */}
          {/** PORT INFORMATION */}
          <DrawerPortInformation data={bank && bank.port ? bank.port : []} />
          {/** ************************************************* */}
          {/** SERVER INFORMATION */}
          <DrawerServerInformation
            data={bank && bank.servers ? bank.servers : {}}
          />
          {/** ************************************************* */}
          {/** LIFTS ASSIGNED */}
          <DrawerLiftsAssigned data={bank && bank.lifts ? bank.lifts : []} />
        </div>
      )}
      {/** ************************************************* */}
      {/** CLOSE AND SUBMIT BUTTONS */}
      <div className="mt-4 generalStyles__width100">
        <div>
          <Button
            type="default"
            onClick={onClickClose}
            className="buttonStyle__5"
          >
            {t("organizationManagement.close")}
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

export default BanksDrawer;
