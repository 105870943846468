/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MonitoringState {
  gridStyle: string;
  openMonitorSelectBankDrawer: boolean;
  showElevatorDetail: boolean;
  showElevatorPanel: boolean;
  showTerminal: boolean;
  showFullView: boolean;
  monitoringHeaderData: {};
  generateFakeData: boolean;
  cleanMonitoringData: boolean;
  openDrawerLiftActions: boolean;
  liftSelected: {};
  bankSelected: {};
  openModalSettings: boolean;
  serverSelected: Object
}

const initialState: MonitoringState = {
  gridStyle: "topView",
  openMonitorSelectBankDrawer: false,
  monitoringHeaderData: {
    state: "ORGANIZATION",
    organization: {},
    organizations:[],
    server: {},
    servers: [],
    port: {},
    ports: [],
    bank: {},
    banks: [],
    banksPined: [],
    lift: {},
    lifts:[],
  },
  showElevatorDetail: false,
  showElevatorPanel: false,
  showTerminal: false,
  showFullView: false,
  generateFakeData: false,
  cleanMonitoringData: false,
  openDrawerLiftActions: false,
  liftSelected: {},
  bankSelected: {},
  openModalSettings: false,
  serverSelected: []
};

export const monitoringSlice = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {
    updateOpenMonitorSelectBankDrawer: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.openMonitorSelectBankDrawer = action.payload;
    },
    updateGridStyle: (state, action: PayloadAction<string>) => {
      state.gridStyle = action.payload;
    },
    updateMonitoringHeaderData: (state, action: PayloadAction<any>) => {
      state.monitoringHeaderData = action.payload;
    },
    updateShowElevatorDetail: (state, action: PayloadAction<boolean>) => {
      state.showElevatorDetail = action.payload;
    },
    updateShowElevatorPanel: (state, action: PayloadAction<boolean>) => {
      state.showElevatorPanel = action.payload;
    },
    updateShowTerminal: (state, action: PayloadAction<boolean>) => {
      state.showTerminal = action.payload;
    },
    updateShowFullView: (state, action: PayloadAction<boolean>) => {
      state.showFullView = action.payload;
    },
    updateGenerateFakeData: (state, action: PayloadAction<boolean>) => {
      state.generateFakeData = action.payload;
    },
    updateCleanMonitoringData: (state, action: PayloadAction<boolean>) => {
      state.cleanMonitoringData = action.payload;
    },
    updateOpenDrawerLiftActions: (state, action: PayloadAction<boolean>) => {
      state.openDrawerLiftActions = action.payload;
    },
    updateLiftSelected: (state, action: PayloadAction<any>) => {
      state.liftSelected = action.payload;
    },
    updateBankSelected: (state, action: PayloadAction<any>) => {
      state.bankSelected = action.payload;
    },
    updateOpenModalSettings: (state, action: PayloadAction<boolean>) => {
      state.openModalSettings = action.payload;
    },
    updateServerSelected: (state, action: PayloadAction<Object>) => {
      state.serverSelected = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateGridStyle,
  updateOpenMonitorSelectBankDrawer,
  updateMonitoringHeaderData,
  updateShowElevatorDetail,
  updateShowElevatorPanel,
  updateShowFullView, 
  updateShowTerminal,
  updateGenerateFakeData,
  updateCleanMonitoringData,
  updateOpenDrawerLiftActions,
  updateLiftSelected,
  updateBankSelected,
  updateOpenModalSettings,
  updateServerSelected
} = monitoringSlice.actions;

export default monitoringSlice.reducer;
