import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Divider, Drawer, Collapse, Empty, Button } from "antd";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserShield,
  faBuildingCircleCheck,
  faUserLock,
  faCheckCircle,
  faBan,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  updateOpenDrawerConfigurationFile,
  updateOpenModalOffsetsTable,
} from "../../../redux/configurationFiles/configurationFilesSlice";
import { useLazyGetAllOffsetsTypesQuery } from "../../../redux/configurationFiles/configurationFilesAPI";
// COMPONENTS
import ConfigurationFilesModalOffsetsTable from "./ConfigurationFilesModalOffsetsTable";
import DrawerConfigurationInformation from "../../components/DrawerConfigurationInformation";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import {
  buildSelectedItemsOffsetsTable,
  buildDataOffsetsTable,
} from "../../../utils/utils";
// INTERFACE
import { SelectedItemsOffsetsTableInterface } from "../../../interfaces/Configurations.interface";

library.add(
  faUserShield,
  faBuildingCircleCheck,
  faUserLock,
  faCheckCircle,
  faBan
);
const { Panel } = Collapse;

function ConfigurationFilesDrawer() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch();
  const { configurationFile, openDrawerConfigurationFile } = useSelector(
    (state: any) => state.configurationFile
  );
  const [t] = useTranslation("global");
  const { theme } = useSelector((state: any) => state.home);
  const [offsets, setOffsets] = useState([]);
  const [definitions, setDefinitions] = useState([]);
  const [selectedItemsObj, setSelectedItemsObj] = useState<
    SelectedItemsOffsetsTableInterface[]
  >([]);
  const [offsetsTableData, setOffsetsTableData] = useState<any>([]);
  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [triggerGetOffsetsType, { data: liftOffsetsType }] =
    useLazyGetAllOffsetsTypesQuery();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onClickClose = () => {
    dispatch(updateOpenDrawerConfigurationFile(false));
  };

  const getOffsetsList = async () => {
    const token = await GETJwtToken();
    triggerGetOffsetsType({ token });
  };

  const buildOffsetsObj = (offsets_: any, options_: any) => {
    if (offsets_ && options_) {
      const newObj: any = [];
      options_.map((item: any) => {
        const offsetObj: any = {
          name: item.name,
          id: item.id,
          offsets: [],
        };
        offsets_.map((item2: any) => {
          if (item2.offset_type_id === item.id) {
            offsetObj.offsets.push(item2);
          }
          return true;
        });
        if (offsetObj.offsets.length > 0) {
          newObj.push(offsetObj);
        }
        return true;
      });
      setOffsets(newObj);
    }
  };

  const buildDefinitionsObj = (definitions_: any) => {
    if (definitions_ && liftOffsetsType.data) {
      const newObj: any = [];
      liftOffsetsType.data.map((item: any) => {
        const definitionObj: any = {
          name: item.name,
          id: item.id,
          definitions: [],
        };
        definitions_.map((item2: any) => {
          if (item2.offset_type_id === item.id) {
            definitionObj.definitions.push(item2);
          }
          return true;
        });
        if (definitionObj.definitions.length > 0) {
          newObj.push(definitionObj);
        }
        return true;
      });
      setDefinitions(newObj);
    }
  };

  const getOffsetTypeNameById = (id_: string, item: any) => {
    let name = "--";
    if (liftOffsetsType.data) {
      const index = liftOffsetsType.data.findIndex(
        (object: any) => object.id === id_
      );
      name = liftOffsetsType.data[index].name;
    }
    let byteCounter = 0;
    if (item) {
      byteCounter = item.offsets[0].byte_count;
    }
    if (byteCounter > 0) {
      // return `${name} - Total Bytes ${totalByte}`
      return (
        <>
          <span className="generalStyles__info generalStyles__mrFix">
            {name}
          </span>
          - Byte Count{" "}
          <span
            className="generalStyles__info generalStyles__mlFix"
            style={{ fontWeight: "bolder" }}
          >
            {byteCounter}
          </span>
        </>
      );
    }
    return (
      <>
        <span className="generalStyles__info generalStyles__mrFix">{name}</span>
      </>
    );
  };

  const onClickShowOffsetsTable = () => {
    // Build selected items to create offsets table
    const selectedItems = buildSelectedItemsOffsetsTable(
      configurationFile.offsets
    );
    setSelectedItemsObj(selectedItems);
    // Build data to create offsets table
    const data = buildDataOffsetsTable(configurationFile.offsets);
    setOffsetsTableData(data);
    // Open offsets table
    dispatch(updateOpenModalOffsetsTable(true));
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    getOffsetsList();
  }, []);

  useEffect(() => {
    // Get offset type list
    if (openDrawerConfigurationFile) {
      if (configurationFile) {
        // Build and set offsets object
        buildOffsetsObj(configurationFile.offsets, liftOffsetsType.data);
        // If definitions, build and set definitions object
        if (
          configurationFile.offsets &&
          configurationFile.offsets[0] &&
          configurationFile.offsets[0].definitions &&
          configurationFile.offsets[0].definitions.length > 0
        ) {
          buildDefinitionsObj(configurationFile.offsets[0].definitions);
        }
      }
    }
  }, [openDrawerConfigurationFile]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClickClose}
      closable={false}
      visible={openDrawerConfigurationFile}
    >
      {configurationFile && (
        <div>
          {/** ************************************************* */}
          {/** CONFIGURATION DATA */}
          <DrawerConfigurationInformation data={configurationFile} />
          <br />
          {/** ************************************************* */}
          {/** OFFSETS */}
          <div>
            <Divider
              orientation="left"
              className="generalStyles__drawerDivider"
            >
              <h5>
                <FontAwesomeIcon
                  icon={faBuildingCircleCheck}
                  className="generalStyles__info generalStyles__mrFix"
                />
                {t("configuration.configurationFiles.offsets")}
              </h5>
            </Divider>
          </div>
          <Collapse>
            {offsets.map((item: any, index: number) => (
              <Panel header={getOffsetTypeNameById(item.id, item)} key={index}>
                {configurationFile &&
                  configurationFile.offsets &&
                  configurationFile.offsets.length > 0 && (
                    <>
                      {configurationFile.offsets.map(
                        (offset: any, index2: number) => (
                          <div key={index2}>
                            {item.id === offset.offset_type_id && (
                              <div
                                className={
                                  theme === "dark"
                                    ? " drawer__box__dark2 mb-2"
                                    : " drawer__box__light2 mb-2"
                                }
                              >
                                {/* TYPE */}
                                <div className="generalStyles__flex">
                                  <div>
                                    <p
                                      className={
                                        isMobile
                                          ? "drawer__titleMobile"
                                          : "drawer__title"
                                      }
                                    >
                                      {t(
                                        "configuration.configurationFiles.type"
                                      )}
                                    </p>
                                  </div>
                                  <div className="drawer__textContainer">
                                    <p className="drawer__text">
                                      {offset.offset_type_id
                                        ? getOffsetTypeNameById(
                                            offset.offset_type_id,
                                            null
                                          )
                                        : "--"}
                                    </p>
                                  </div>
                                </div>
                                {/* NAME */}
                                <div className="generalStyles__flex">
                                  <div>
                                    <p
                                      className={
                                        isMobile
                                          ? "drawer__titleMobile"
                                          : "drawer__title"
                                      }
                                    >
                                      {t(
                                        "configuration.configurationFiles.name"
                                      )}
                                    </p>
                                  </div>
                                  <div className="drawer__textContainer">
                                    <p className="drawer__text">
                                      {offset.name ? offset.name : "--"}
                                    </p>
                                  </div>
                                </div>
                                {/* BANK LOCATION */}
                                <div className="generalStyles__flex">
                                  <div>
                                    <p
                                      className={
                                        isMobile
                                          ? "drawer__titleMobile"
                                          : "drawer__title"
                                      }
                                    >
                                      Bank Location
                                    </p>
                                  </div>
                                  <div className="drawer__textContainer">
                                    <p className="drawer__text">
                                      {offset.bank_location
                                        ? offset.bank_location
                                        : "--"}{" "}
                                      /{" "}
                                      {offset.byte_count
                                        ? offset.byte_count
                                        : "--"}
                                    </p>
                                  </div>
                                </div>
                                {/* INITIAL VALUE */}
                                <div className="generalStyles__flex">
                                  <div>
                                    <p
                                      className={
                                        isMobile
                                          ? "drawer__titleMobile"
                                          : "drawer__title"
                                      }
                                    >
                                      Initial Value
                                    </p>
                                  </div>
                                  <div className="drawer__textContainer">
                                    <p className="drawer__text">
                                      {offset.initial_value
                                        ? offset.initial_value
                                        : "--"}
                                    </p>
                                  </div>
                                </div>
                                {/* LAST VALUE */}
                                <div className="generalStyles__flex">
                                  <div>
                                    <p
                                      className={
                                        isMobile
                                          ? "drawer__titleMobile"
                                          : "drawer__title"
                                      }
                                    >
                                      Last Value
                                    </p>
                                  </div>
                                  <div className="drawer__textContainer">
                                    <p className="drawer__text">
                                      {offset.last_value
                                        ? offset.last_value
                                        : "--"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </>
                  )}
              </Panel>
            ))}
          </Collapse>
          <div className="mt-4 generalStyles__flexEnd">
            <div>
              <Button type="primary" onClick={onClickShowOffsetsTable}>
                <FontAwesomeIcon
                  icon={faTable}
                  className="generalStyles__mrFix"
                />
                Offsets Table
              </Button>
            </div>
          </div>
          <br />
          {/** ************************************************* */}
          {/** DEFINITIONS */}
          <div>
            <Divider
              orientation="left"
              className="generalStyles__drawerDivider"
            >
              <h5>
                <FontAwesomeIcon
                  icon={faBuildingCircleCheck}
                  className="generalStyles__info generalStyles__mrFix"
                />
                {t("configuration.configurationFiles.definitions")}
              </h5>
            </Divider>
          </div>
          {definitions && definitions.length > 0 ? (
            <>
              {true && (
                <Collapse>
                  {definitions.map((item: any, index: number) => (
                    <Panel
                      header={getOffsetTypeNameById(item.id, null)}
                      key={index}
                    >
                      {item && item.definitions && (
                        <>
                          {item.definitions.map(
                            (item2: any, index2: number) => (
                              <div
                                className={
                                  theme === "dark"
                                    ? " drawer__box__dark2 mb-2"
                                    : " drawer__box__light2 mb-2"
                                }
                                key={index2}
                              >
                                {/* TYPE */}
                                <div className="generalStyles__flex">
                                  <div>
                                    <p
                                      className={
                                        isMobile
                                          ? "drawer__titleMobile"
                                          : "drawer__title"
                                      }
                                    >
                                      Type
                                    </p>
                                  </div>
                                  <div className="drawer__textContainer">
                                    <p className="drawer__text">
                                      {getOffsetTypeNameById(item.id, null)}
                                    </p>
                                  </div>
                                </div>
                                {/* BIT */}
                                <div className="generalStyles__flex">
                                  <div>
                                    <p
                                      className={
                                        isMobile
                                          ? "drawer__titleMobile"
                                          : "drawer__title"
                                      }
                                    >
                                      Bit
                                    </p>
                                  </div>
                                  <div className="drawer__textContainer">
                                    <p className="drawer__text">
                                      {item2.bit ? item2.bit : "0"}
                                    </p>
                                  </div>
                                </div>
                                {/* BYTE */}
                                <div className="generalStyles__flex">
                                  <div>
                                    <p
                                      className={
                                        isMobile
                                          ? "drawer__titleMobile"
                                          : "drawer__title"
                                      }
                                    >
                                      Byte
                                    </p>
                                  </div>
                                  <div className="drawer__textContainer">
                                    <p className="drawer__text">
                                      {item2.byte ? item2.byte : "0"}
                                    </p>
                                  </div>
                                </div>
                                {/* SHORT MESSAGE */}
                                <div className="generalStyles__flex">
                                  <div>
                                    <p
                                      className={
                                        isMobile
                                          ? "drawer__titleMobile"
                                          : "drawer__title"
                                      }
                                    >
                                      Short Message
                                    </p>
                                  </div>
                                  <div className="drawer__textContainer">
                                    <p className="drawer__text">
                                      {item2.showt_message
                                        ? item2.short_message
                                        : "--"}
                                    </p>
                                  </div>
                                </div>
                                {/* MESSAGE */}
                                <div className="generalStyles__flex">
                                  <div>
                                    <p
                                      className={
                                        isMobile
                                          ? "drawer__titleMobile"
                                          : "drawer__title"
                                      }
                                    >
                                      Message
                                    </p>
                                  </div>
                                  <div className="drawer__textContainer">
                                    <p className="drawer__text">
                                      {item2.message ? item2.message : "--"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </>
                      )}
                    </Panel>
                  ))}
                </Collapse>
              )}
            </>
          ) : (
            <div
              className={
                theme === "dark"
                  ? " drawer__box__dark mb-2"
                  : " drawer__box__light mb-2"
              }
            >
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No Definitions assigned"
              />
            </div>
          )}
        </div>
      )}
      <br />
      {/** CLOSE AND SUBMIT BUTTONS */}
      <div className="mt-4 generalStyles__width100">
        <div>
          <Button
            type="default"
            onClick={onClickClose}
            className="buttonStyle__5"
          >
            {t("organizationManagement.close")}
          </Button>
        </div>
      </div>
      {/** ---------------------------------------------------- */}
      {/** COMPONENT - CONFIGURATION OFFSETS TABLE */}
      <ConfigurationFilesModalOffsetsTable
        mode="list"
        data={offsetsTableData}
        setData={null}
        name={configurationFile.name || ""}
        selectedItemsObj={selectedItemsObj}
        fullTable
      />
    </Drawer>
  );
}

export default ConfigurationFilesDrawer;
