import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Button, Input, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useDeleteDataBlockTypesMutation } from "../../../redux/configurationFiles/configurationFilesAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import {
  updateOpenModalDeleteDataBlock,
  updateToolAlert,
} from "../../../redux/tools/toolsSlice";
// UTILS
import { styleDeleteButton } from "../../../utils/utils";

library.add(faTrash, faTriangleExclamation);

function DataBlocksDeleteModal() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [disableButtonConfirmDelete, setDisableButtonConfirmDelete] =
    useState(true);
  const [confirmDataBlockName, setConfirmDataBlockName] = useState("");

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { dataBlock, openModalDeleteDataBlock } = useSelector(
    (state: any) => state.tool
  );
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    triggerDeleteDataBlock,
    { isLoading, isSuccess, error, isError, reset: resetDeleteDataBlock },
  ] = useDeleteDataBlockTypesMutation();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onClickClose = () => {
    dispatch(updateOpenModalDeleteDataBlock(false));
  };

  const handleDeleteItem = async () => {
    const token = await GETJwtToken();
    triggerDeleteDataBlock({ id: dataBlock.id, token });
  };

  const handleSubmitDeleteItem = async (event: any) => {
    if (event.key === "Enter" && !disableButtonConfirmDelete) {
      const token = await GETJwtToken();
      triggerDeleteDataBlock({ id: dataBlock.id, token });
    }
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (openModalDeleteDataBlock) {
      setDisableButtonConfirmDelete(true);
      setConfirmDataBlockName("");
      resetDeleteDataBlock();
    }
  }, [openModalDeleteDataBlock]);

  useEffect(() => {
    setDisableButtonConfirmDelete(true);
    if (
      confirmDataBlockName &&
      dataBlock &&
      dataBlock.name &&
      confirmDataBlockName.toLowerCase() === dataBlock.name.toLowerCase()
    ) {
      setDisableButtonConfirmDelete(false);
    } else {
      setDisableButtonConfirmDelete(true);
    }
  }, [confirmDataBlockName]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        dispatch(
          updateToolAlert({
            title: t("general.success"),
            description: t("configuration.dataBlock.dataBlockDeleted"),
            status: "success",
          })
        );
      }, 150);
      onClickClose();
    }
    if (isError) {
      setTimeout(() => {
        dispatch(
          updateToolAlert({
            title: t("general.error"),
            description: error,
            status: "error",
          })
        );
      }, 150);
      onClickClose();
    }
  }, [isSuccess, isError]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Modal
      onOk={onClickClose}
      closable={false}
      onCancel={onClickClose}
      visible={openModalDeleteDataBlock}
      footer={null}
    >
      <Spin spinning={isLoading}>
        <div>
          <div className="text-center">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="generalStyles__warning generalStyles__deleteItemIcon"
            />
            <p className="mt-3 drawer__text">
              {t("organizationManagement.deleteDescription.text1")}
              <b className="generalStyles__warning">
                {t("organizationManagement.deleteDescription.text2")}{" "}
              </b>
              {t("configuration.dataBlock.theDataBlock")}{" "}
              <b className="generalStyles__warning">
                {dataBlock && dataBlock.name ? dataBlock.name : ""}{" "}
              </b>
              {t("configuration.dataBlock.confirmDataBlockName")}{" "}
            </p>
            <Input
              placeholder={t("edgeManagement.banks.bankName")}
              onChange={(e) => {
                setConfirmDataBlockName(e.target.value);
              }}
              value={confirmDataBlockName}
              onKeyDown={handleSubmitDeleteItem}
              status="warning"
            />
          </div>
          <div className="generalStyles__flexEnd mt-4">
            <div>
              <Button
                htmlType="submit"
                className={styleDeleteButton(disableButtonConfirmDelete, theme)}
                onClick={handleDeleteItem}
                disabled={disableButtonConfirmDelete}
              >
                {t("organizationManagement.delete")}
              </Button>
              <Button onClick={onClickClose} className="buttonStyle__4">
                {t("organizationManagement.close")}
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
}

export default DataBlocksDeleteModal;
