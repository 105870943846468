import React from "react";
// ANT DESIGN COMPONENTS
import { Divider, Alert, Tag } from "antd";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faElevator } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";

function DrawerLiftsAssigned({ data }: any) {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const { theme } = useSelector((state: any) => state.home);
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      <br />
      {/** ************************************************* */}
      {/** LIFTS ASSIGNED  */}
      <div>
        <Divider orientation="left" className="generalStyles__drawerDivider">
          <h5>
            <FontAwesomeIcon
              icon={faElevator}
              className="generalStyles__info generalStyles__mrFix"
            />
            {t("edgeManagement.lifts.liftsAssigned")}
          </h5>
        </Divider>
      </div>
      <div>
        {data && data.length > 0 ? (
          <>
            {data.map((lift: any, index: number) => (
              <div key={index}>
                <div
                  className={
                    theme === "dark"
                      ? " drawer__box__dark mt-3"
                      : " drawer__box__light mt-3"
                  }
                >
                  {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
                  <div className="generalStyles__flex">
                    <div>
                      <p
                        className={
                          isMobile ? "drawer__titleMobile" : "drawer__title"
                        }
                      >
                        ID:
                      </p>
                    </div>
                    <div className="drawer__textContainer">
                      <p className="drawer__text">
                        {lift && lift.id ? lift.id : "--"}
                      </p>
                    </div>
                  </div>
                  {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
                  <div className="generalStyles__flex">
                    <div>
                      <p
                        className={
                          isMobile ? "drawer__titleMobile" : "drawer__title"
                        }
                      >
                        {t("edgeManagement.lifts.board")}
                      </p>
                    </div>
                    <div className="drawer__textContainer">
                      <p className="drawer__text">
                        {lift && lift.board ? lift.board : "--"}
                      </p>
                    </div>
                  </div>
                  {/* -*-*-*-*-*-*-*-*-*-*-*-*-*-*- */}
                  <div className="generalStyles__flex">
                    <div>
                      <p
                        className={
                          isMobile ? "drawer__titleMobile" : "drawer__title"
                        }
                      >
                        {t("edgeManagement.lifts.type")}
                      </p>
                    </div>
                    <div className="drawer__textContainer">
                      {lift && lift.type ? (
                        <>
                          <div className="">
                            {lift.type === "ELEVATOR" && (
                              <Tag color="blue" className="drawer__typeTag">
                                {lift.type}
                              </Tag>
                            )}
                            {lift.type === "ESCALATOR" && (
                              <Tag color="geekblue" className="drawer__typeTag">
                                {lift.type}
                              </Tag>
                            )}
                            {lift.type === "WALKINGBAND" && (
                              <Tag color="cyan" className="drawer__typeTag">
                                {lift.type}
                              </Tag>
                            )}{" "}
                          </div>
                        </>
                      ) : (
                        "--"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div
            className={
              theme === "dark" ? " drawer__box__dark" : " drawer__box__light"
            }
          >
            <Alert
              message={t("edgeManagement.lifts.noLiftsFound")}
              type="warning"
              showIcon
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default DrawerLiftsAssigned;
