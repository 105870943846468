import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

const maxRetries = 2;

const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE,
  }),
  { maxRetries }
);

export const bankAPI = createApi({
  reducerPath: 'bankAPI',
  baseQuery,
  keepUnusedDataFor: 0,
  tagTypes: ['tagGetBanks', 'tagPostBank', 'tagPutBank', 'tagDeleteBank'],
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    //* *********************************************************** */
    //* GET ALL BANKS */
    getBanks: builder.query({
      query: (body: any) => ({
        url: `/banks/?page=${body.page}&limit=${body.limit}`,
        headers: { authorization: body.token },
      }),
      providesTags: ['tagGetBanks'],
    }),
    //* *********************************************************** */
    //* GET BANKS BY SERVER */
    getBanksByServer: builder.query({
      query: (body: any) => ({
        url: `/banks/byServer/${body.orgId}?page=${body.page}&limit=${body.limit}`,
        headers: { authorization: body.token },
      }),
      providesTags: ['tagGetBanks'],
    }),
    //* *********************************************************** */
    //* CREATE BANK */
    postBank: builder.mutation({
      query: (body: any) => ({
        url: '/banks/',
        method: 'post',
        body: body.body,
        headers: { authorization: body.token },
      }),
      invalidatesTags: ['tagGetBanks'],
    }),
    //* *********************************************************** */
    //* EDIT BANK */
    putBank: builder.mutation({
      query: (body: any) => ({
        url: `/banks/${body.id}`,
        method: 'put',
        body: body.body,
        headers: { authorization: body.token },
      }),
      invalidatesTags: ['tagGetBanks'],
    }),
    //* *********************************************************** */
    //* DELETE BANK */
    deleteBank: builder.mutation({
      query: (body: any) => ({
        url: `/banks/${body.id}`,
        method: 'delete',
        headers: { authorization: body.token },
      }),
      invalidatesTags: ['tagGetBanks'],
    }),
    //* *********************************************************** */
  }),
});

export const {
  useLazyGetBanksQuery,
  useLazyGetBanksByServerQuery,
  usePostBankMutation,
  usePutBankMutation,
  useDeleteBankMutation,
} = bankAPI;
