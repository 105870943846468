import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Button, Input, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { usePutRoleMutation } from "../../../redux/roles/roleAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import {
  updateOpenModalBlockUnlockRole,
  updateRoleAlert,
} from "../../../redux/roles/roleSlice";
// UTILS
import { styleDeleteButton } from "../../../utils/utils";

library.add(faTrash, faTriangleExclamation);

function RolesBlockUnlockRoleModal() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch();
  const { role, openModalBlockUnlockRole } = useSelector(
    (state: any) => state.role
  );
  const [
    disableButtonConfirmBlockUnlockRole,
    setDisableButtonConfirmBlockUnlockRole,
  ] = useState(true);
  const [confirmRoleName, setConfirmRoleName] = useState("");

  const { theme } = useSelector((state: any) => state.home);
  const [t] = useTranslation("global");

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    putRole,
    {
      isSuccess: isSuccessPutRole,
      isError: isErrorPutRole,
      error: errorPutRole,
      isLoading: isLoadingPutRole,
      reset: resetPutRole,
    },
  ] = usePutRoleMutation();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const handleOk = () => {
    dispatch(updateOpenModalBlockUnlockRole(false));
  };

  const handleCancel = () => {
    dispatch(updateOpenModalBlockUnlockRole(false));
  };

  const handleCloseModal = () => {
    dispatch(updateOpenModalBlockUnlockRole(false));
  };

  const handleDeleteItem = async () => {
    const token = await GETJwtToken();
    if (role && role.active) {
      putRole({
        id: role.id,
        body: {
          active: false,
          menu: JSON.stringify(role.menu),
          name: role.name,
        },
        token,
      });
    } else {
      putRole({
        id: role.id,
        body: {
          active: true,
          menu: JSON.stringify(role.menu),
          name: role.name,
        },
        token,
      });
    }
  };

  const handleSubmitDeleteItem = async (event: any) => {
    if (event.key === "Enter" && !disableButtonConfirmBlockUnlockRole) {
      handleDeleteItem();
    }
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    if (openModalBlockUnlockRole) {
      setDisableButtonConfirmBlockUnlockRole(true);
      setConfirmRoleName("");
      resetPutRole();
    }
  }, [openModalBlockUnlockRole]);

  useEffect(() => {
    setDisableButtonConfirmBlockUnlockRole(true);
    if (
      role &&
      role.name &&
      role.name.toLowerCase() === confirmRoleName.toLowerCase()
    ) {
      setDisableButtonConfirmBlockUnlockRole(false);
    } else {
      setDisableButtonConfirmBlockUnlockRole(true);
    }
  }, [confirmRoleName]);

  useEffect(() => {
    if (isSuccessPutRole) {
      setTimeout(() => {
        dispatch(
          updateRoleAlert({
            title: t("general.success"),
            description:
              role && role.active
                ? t("organizationManagement.roles.roleLocked")
                : t("organizationManagement.roles.roleUnlocked"),
            status: "success",
          })
        );
      }, 150);
      dispatch(updateOpenModalBlockUnlockRole(false));
    }
    if (isErrorPutRole) {
      setTimeout(() => {
        dispatch(
          updateRoleAlert({
            title: t("general.error"),
            description: errorPutRole,
            status: "error",
          })
        );
      }, 150);
      dispatch(updateOpenModalBlockUnlockRole(false));
    }
  }, [isSuccessPutRole, isErrorPutRole]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Modal
      onOk={handleOk}
      closable={false}
      onCancel={handleCancel}
      visible={openModalBlockUnlockRole}
      footer={null}
    >
      <Spin spinning={isLoadingPutRole}>
        <div>
          <div className="text-center">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="generalStyles__warning generalStyles__deleteItemIcon"
            />
            <p className="mt-3 drawer__text">
              {t("organizationManagement.lockUnlockDescription.text1")}
              <b className="generalStyles__warning">
                {role && role.active ? (
                  <>
                    {" "}
                    {t("organizationManagement.lockUnlockDescription.text2")}
                  </>
                ) : (
                  <>{t("organizationManagement.lockUnlockDescription.text3")}</>
                )}
              </b>{" "}
              {t("organizationManagement.lockUnlockDescription.text4")}
              <b className="generalStyles__warning">{role.name} </b>
              {t("organizationManagement.lockUnlockDescription.text5")}
            </p>
            <Input
              placeholder={t("organizationManagement.roleName")}
              onChange={(e) => {
                setConfirmRoleName(e.target.value);
              }}
              value={confirmRoleName}
              onKeyDown={handleSubmitDeleteItem}
              status="warning"
            />
          </div>
          <div className="generalStyles__flexEnd mt-4">
            <div>
              <Button
                htmlType="submit"
                className={styleDeleteButton(
                  disableButtonConfirmBlockUnlockRole,
                  theme
                )}
                onClick={handleDeleteItem}
                disabled={disableButtonConfirmBlockUnlockRole}
              >
                {role && role.active ? (
                  <>{t("organizationManagement.lockUnlockDescription.text2")}</>
                ) : (
                  <>{t("organizationManagement.lockUnlockDescription.text3")}</>
                )}
              </Button>
              <Button onClick={handleCloseModal} className="buttonStyle__4">
                {t("organizationManagement.close")}
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
}

export default RolesBlockUnlockRoleModal;
