import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Divider, Input, Select } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingCircleCheck } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetOrganizationAndChildsQuery } from "../../../redux/organizations/organizationAPI";
import {
  updateOrganizationAndChilds,
  updateOpenModalCreateNewOrganization,
} from "../../../redux/organizations/organizationSlice";
import {
  updateOpenModalCreateNewServer,
  updateServerAlert,
} from "../../../redux/servers/serverSlice";
import { updateOpenModalCreateNewLift } from "../../../redux/lifts/liftSlice";
import { updateOpenModalCreateNewBank } from "../../../redux/banks/bankSlice";
import { updateOpenMonitorSelectBankDrawer } from "../../../redux/monitoring/monitoringSlice";
import { updateOpenModalCreateNewRole } from "../../../redux/roles/roleSlice";
import { updateOpenModalCreateNewUser } from "../../../redux/users/userSlice";

// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// STYLES

const { Option } = Select;

function OrganizationSelectOrganizationSubOrganization({
  setOrganizationsAndChildsIsLoading,
}: any) {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [listOrganizations, setListOrganizations] = useState<any[]>([]);
  const [nameOrganizationSelected, setNameOrganizationSelected] = useState("");
  const [nameStaticOrganization, setNameStaticOrganization] = useState("");
  const [idOrganizationSelected, setIdOrganizationSelected] = useState("");

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { userLogged } = useSelector((state: any) => state.home);
  const { openModalCreateNewServer } = useSelector(
    (state: any) => state.server
  );
  const { openModalCreateNewLift } = useSelector((state: any) => state.lift);
  const { openModalCreateNewBank } = useSelector((state: any) => state.bank);
  const { openModalCreateNewRole } = useSelector((state: any) => state.role);
  const { openModalCreateNewUser } = useSelector((state: any) => state.user);
  const { openModalCreateNewOrganization } = useSelector(
    (state: any) => state.organization
  );
  const { openMonitorSelectBankDrawer } = useSelector(
    (state: any) => state.monitoring
  );
  const { organizationAndChilds } = useSelector(
    (state: any) => state.organization
  );
  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    triggerGetOrganizationAndChilds,
    {
      isError: isErrorGetOrganizationAndChilds,
      error: errorGetOrganizationAndChilds,
      isLoading: isLoadingGetOrganizationsAndChilds,
      isFetching: isFetchingGetOrganizationsAndChilds,
    },
  ] = useLazyGetOrganizationAndChildsQuery();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const cleanOrganizationsList = () => {
    dispatch(
      updateOrganizationAndChilds({
        childs: [],
        data: {},
      })
    );
  };

  const getOrganizationAndChilds = async (orgId: string) => {
    const token = await GETJwtToken();
    const data = await triggerGetOrganizationAndChilds({
      orgId,
      token,
    }).unwrap();
    if (
      data &&
      data.data &&
      data.data.children &&
      data.data.children.length > 0
    ) {
      const BODY = {
        data: { idOrganizationSelected: orgId },
        childs: [
          ...organizationAndChilds.childs,
          {
            level: organizationAndChilds.childs.length + 1,
            data: data.data.children,
            orgSelected: orgId,
          },
        ],
      };
      dispatch(updateOrganizationAndChilds(BODY));
    }
    return true;
  };

  const handleChangeOrganization = (value: string, level: number) => {
    // if we select a new value
    const totalChilds = organizationAndChilds.childs.length;
    if (value) {
      if (totalChilds === level) {
        setIdOrganizationSelected(value);
        getOrganizationAndChilds(value);
      } else if (totalChilds > level) {
        const newArray = [];
        setIdOrganizationSelected(value);
        for (let index = 0; index < level; index += 1) {
          newArray.push(organizationAndChilds.childs[index]);
        }
        const BODY = {
          data: { idOrganizationSelected },
          childs: newArray,
        };
        dispatch(updateOrganizationAndChilds(BODY));
      }
    }
    // if the user clear an input and the value is null
    else {
      const newArray = [];
      setIdOrganizationSelected(
        organizationAndChilds.childs[level - 1].orgSelected
      );
      for (let index = 0; index < level; index += 1) {
        newArray.push(organizationAndChilds.childs[index]);
      }
      const BODY = {
        data: { idOrganizationSelected },
        childs: newArray,
      };
      dispatch(updateOrganizationAndChilds(BODY));
    }
  };

  const getOrganizationsAndChildsWhenOpenModal = () => {
    if (userLogged && userLogged.org && userLogged.org.id) {
      // save id of the organization that the user logged belongs
      setIdOrganizationSelected(userLogged.org.id);
      setNameStaticOrganization(userLogged.org.name);
      // call for the first time organizations and childs
      getOrganizationAndChilds(userLogged.org.id);
    }
  };

  const cleanComponentData = () => {
    cleanOrganizationsList();
    setListOrganizations([]);
    setNameOrganizationSelected("");
    setNameStaticOrganization("");
    setIdOrganizationSelected("");
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    cleanComponentData();
    if (
      openModalCreateNewServer ||
      openModalCreateNewLift ||
      openModalCreateNewBank ||
      openMonitorSelectBankDrawer ||
      openModalCreateNewOrganization ||
      openModalCreateNewRole ||
      openModalCreateNewUser
    ) {
      getOrganizationsAndChildsWhenOpenModal();
    }
  }, [
    openModalCreateNewServer,
    openModalCreateNewLift,
    openModalCreateNewBank,
    openMonitorSelectBankDrawer,
    openModalCreateNewOrganization,
    openModalCreateNewRole,
    openModalCreateNewUser,
  ]);

  useEffect(() => {
    if (isErrorGetOrganizationAndChilds) {
      setTimeout(() => {
        dispatch(
          updateServerAlert({
            title: "Error",
            description: errorGetOrganizationAndChilds,
            status: "error",
          })
        );
      }, 150);
      cleanComponentData();
      dispatch(updateOpenModalCreateNewServer(false));
      dispatch(updateOpenModalCreateNewLift(false));
      dispatch(updateOpenModalCreateNewBank(false));
      dispatch(updateOpenMonitorSelectBankDrawer(false));
      dispatch(updateOpenModalCreateNewOrganization(false));
      dispatch(updateOpenModalCreateNewRole(false));
      dispatch(updateOpenModalCreateNewUser(false));
    }
  }, [isErrorGetOrganizationAndChilds]);

  useEffect(() => {
    if (
      organizationAndChilds &&
      organizationAndChilds.childs &&
      organizationAndChilds.childs.length > 0
    ) {
      setListOrganizations(organizationAndChilds.childs);
    }
  }, [organizationAndChilds]);

  useEffect(() => {
    const BODY = {
      data: { idOrganizationSelected, nameOrganizationSelected },
      childs: [...organizationAndChilds.childs],
    };
    dispatch(updateOrganizationAndChilds(BODY));
  }, [idOrganizationSelected]);

  useEffect(() => {
    if (
      isFetchingGetOrganizationsAndChilds ||
      isFetchingGetOrganizationsAndChilds
    ) {
      setOrganizationsAndChildsIsLoading(true);
    } else {
      setOrganizationsAndChildsIsLoading(false);
    }
  }, [isFetchingGetOrganizationsAndChilds, isLoadingGetOrganizationsAndChilds]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      <br />
      {/** ************************************************** */}
      {/** SELECT ORGANIZATION AND SUB ORGANIZATION FORM  */}
      <Divider orientation="left" className="generalStyles__drawerDivider">
        <h5>
          <FontAwesomeIcon
            icon={faBuildingCircleCheck}
            className="generalStyles__info generalStyles__mrFix"
          />
          {t("organizationManagement.assignToOrganization")}
        </h5>
      </Divider>
      <div
        className={
          theme === "dark" ? "row drawer__box__dark" : "row drawer__box__light"
        }
      >
        {/** ESTATIC ORGANIZATION / USER LOGGED ORGANIZATION */}
        <div className="col-md-12">
          <div className="generalStyles__flex">
            <div className="generalStyles__inputFlexName">
              <span>{t("organizationManagement.organization")}</span>
            </div>
            <div className="generalStyles__width100">
              <Input
                className="generalStyles__input mt-1"
                value={nameStaticOrganization}
                disabled
                size="small"
              />
            </div>
            <div className="generalStyles__infoTooltipIconBlank" />
          </div>
        </div>
        {/** ASSIGN TO ORGANIZATION */}
        <div className="col-12">
          {listOrganizations.map((organization: any) => (
            <div key={organization.level} className="mt-3 generalStyles__flex">
              <div className="generalStyles__inputFlexName">
                <span>{t("userManagement.subOrganization")}</span>
              </div>
              <div className="generalStyles__width100">
                <Select
                  showSearch
                  optionFilterProp="children"
                  size="small"
                  onChange={(value) => {
                    handleChangeOrganization(value, organization.level);
                  }}
                  allowClear
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  className="generalStyles__width100"
                >
                  {organization.data.map((item: any) => (
                    <Option
                      key={item.id}
                      value={item.id}
                      onChange={(e: any) => {
                        setNameOrganizationSelected(e.target.value);
                      }}
                    >
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="generalStyles__infoTooltipIconBlank" />
            </div>
          ))}
          <br />
        </div>
      </div>
    </div>
  );
}

export default OrganizationSelectOrganizationSubOrganization;
