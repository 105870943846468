import React, { useEffect } from "react";
// REACT DETECT OFFLINE
import { Offline, Online } from "react-detect-offline";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
  updateConfigurationFileBreadcrumb,
  updateShowSpecialSwitches,
} from "../../../redux/configurationFiles/configurationFilesSlice";
// COMPONENTS
import DefinitionsTable from "./DefinitionsTable";
// NO INTERNET CONNECTION
import NoInternetConnection from "../../unauthorized/NoInternetConnection";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

const DefinitionsPage = () => {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch();
  const { permissions } = useSelector((state: any) => state.user);
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    dispatch(updateShowSpecialSwitches(false));
    dispatch(updateConfigurationFileBreadcrumb([]));
  }, []);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      {validatePermission("configuration-manager", permissions) ? (
        <div>
          {/** HEADER AND STEPER */}
          <div
            className={
              theme === "light"
                ? "layout__contentPage__light"
                : "layout__contentPage__dark"
            }
          >
            <Online>
              <DefinitionsTable />
            </Online>
            <Offline>
              <NoInternetConnection />
            </Offline>
          </div>
        </div>
      ) : (
        <UnauthorizedPage />
      )}
    </div>
  );
};

export default DefinitionsPage;
