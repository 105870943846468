import React from "react";
// ANT DESIGN COMPONENTS
import { Divider, Tag } from "antd";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faCircleCheck,
  faPlug,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";

function DrawerPortInformation({ data }: any) {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const { theme } = useSelector((state: any) => state.home);
  const [t] = useTranslation("global");

  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      <br />
      {/** ************************************************* */}
      {/** PORT INFORMATION */}
      <div>
        <Divider orientation="left" className="generalStyles__drawerDivider">
          <h5>
            <FontAwesomeIcon
              icon={faPlug}
              className="generalStyles__info generalStyles__mrFix"
            />
            {t("edgeManagement.servers.portInformation")}
          </h5>
        </Divider>
      </div>
      <div
        className={
          theme === "dark"
            ? " drawer__box__dark mt-2"
            : " drawer__box__light mt-2"
        }
      >
        {/* ID */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              ID
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">{data && data.id ? data.id : "--"}</p>
          </div>
        </div>
        {/* STATUS */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("general.status")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {data && data.active ? (
                <Tag
                  color="success"
                  icon={
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="generalStyles__mrFix"
                    />
                  }
                  className="drawer__tag"
                >
                  {t("userManagement.active")}
                </Tag>
              ) : (
                <Tag
                  color="error"
                  icon={
                    <FontAwesomeIcon
                      icon={faBan}
                      className="generalStyles__mrFix"
                    />
                  }
                  className="drawer__tag"
                >
                  {t("userManagement.lock")}
                </Tag>
              )}
            </p>
          </div>
        </div>
        {/* NAME */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("general.name")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {data && data.name ? data.name : "--"}
            </p>
          </div>
        </div>
        {/* INDEX */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("edgeManagement.servers.index")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {data && data.index ? data.index : "--"}
            </p>
          </div>
        </div>
        {/* TYPE */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("edgeManagement.servers.type")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {data && data.type ? data.type : "--"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DrawerPortInformation;
