import React from "react";
// ANT DESIGN COMPONENTS
import { Divider, Drawer, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerSpecialSwitch } from "../../../redux/tools/toolsSlice";

function SpecialSwitchesDrawer() {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const [t] = useTranslation("global");
  // ************************************************ */
  // USE STATE VARIABLES **************************** */

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { specialSwitch, openDrawerSpecialSwitch } = useSelector(
    (state: any) => state.tool
  );
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onClickClose = () => {
    dispatch(updateOpenDrawerSpecialSwitch(false));
  };

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClickClose}
      closable={false}
      visible={openDrawerSpecialSwitch}
    >
      {/** ************************************************* */}
      {/** SPECIAL SWITCH INFORMATION */}
      <div>
        <Divider orientation="left" className="generalStyles__drawerDivider">
          <h5>
            <FontAwesomeIcon
              icon={faGear}
              className="generalStyles__info generalStyles__mrFix"
            />
            {t(
              "configuration.specialSwitchesConfiguration.specialSwitchesInformation"
            )}
          </h5>
        </Divider>
      </div>
      <div
        className={
          theme === "dark" ? " drawer__box__dark" : " drawer__box__light"
        }
      >
        {/* ID */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              ID
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {specialSwitch.id ? specialSwitch.id : "--"}
            </p>
          </div>
        </div>
        {/* NAME */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("configuration.configurationFiles.name")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {specialSwitch.message ? specialSwitch.message : "--"}
            </p>
          </div>
        </div>
        {/* SHORT NAME */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              {t("general.shortName")}
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {specialSwitch.short_message ? specialSwitch.short_message : "--"}
            </p>
          </div>
        </div>
        {/* CATALOG ID */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              Catalog ID
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {specialSwitch &&
              specialSwitch.special_catalog &&
              specialSwitch.special_catalog.id
                ? specialSwitch.special_catalog.id
                : "--"}
            </p>
          </div>
        </div>
        {/* CATALOG */}
        <div className="generalStyles__flex">
          <div>
            <p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
              Catalog
            </p>
          </div>
          <div className="drawer__textContainer">
            <p className="drawer__text">
              {specialSwitch &&
              specialSwitch.special_catalog &&
              specialSwitch.special_catalog.name
                ? specialSwitch.special_catalog.name
                : "--"}
            </p>
          </div>
        </div>
      </div>
      {/** ************************************************* */}
      {/** CLOSE AND SUBMIT BUTTONS */}
      <div className="mt-4 generalStyles__width100">
        <div>
          <Button
            type="default"
            onClick={onClickClose}
            className="buttonStyle__5"
          >
            {t("organizationManagement.close")}
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

export default SpecialSwitchesDrawer;
