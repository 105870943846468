import React from 'react'
// REDUX
import { useSelector } from 'react-redux'
// REACT DETECT OFFLINE
import { Offline, Online } from 'react-detect-offline'
// COMPONENTS
import RolesTable from './RolesTable'
import RolesHeader from './RolesHeader'
import RolesDrawer from './RolesDrawer'
import RolesCreateRoleForm from './RolesCreateRoleForm'
import RolesDeleteRoleModal from './RolesDeleteRoleModal'
import RolesEditRoleModal from './RolesEditRoleForm'
import RolesBlockUnlockRolesModal from './RolesBlockUnlockRoleModal'
// NO INTERNET CONNECTION
import NoInternetConnection from '../../unauthorized/NoInternetConnection'
// AUTHORIZATION
import validatePermission from '../../../utils/validatePermissions'
import UnauthorizedPage from '../../unauthorized/UnauthorizedPage'

const RolesPage = () => {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const { permissions } = useSelector((state: any) => state.user)
  const { theme } = useSelector((state: any) => state.home)

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      {validatePermission('roles-and-permissions', permissions) ? (
        <div
          className={
            theme === 'light'
              ? 'layout__contentPage__light'
              : 'layout__contentPage__dark'
          }
        >
          <Online>
            <RolesHeader />
            <RolesTable />
            <RolesDrawer />
            <RolesCreateRoleForm />
            <RolesDeleteRoleModal />
            <RolesEditRoleModal />
            <RolesBlockUnlockRolesModal />
          </Online>
          <Offline>
            <NoInternetConnection />
          </Offline>
        </div>
      ) : (
        <UnauthorizedPage />
      )}
    </div>
  )
}

export default RolesPage
