/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Table, Alert, Menu, Dropdown, Space, Tag, Empty } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsis,
  faTrash,
  faPenToSquare,
  faKey,
  faLock,
  faUnlock,
  faCheckCircle,
  faCircleExclamation,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetUsersQuery } from "../../../redux/users/userAPI";
import {
  updateOpenModalDeleteUser,
  updateOpenModalEditUser,
  updateOpenModalResetUserPassword,
  updateOpenModalBlockUnBlockUser,
  updateOpenDrawerUser,
  updateUser,
  updateUserTableParams,
  updateUserList,
} from "../../../redux/users/userSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
import validatePermission from "../../../utils/validatePermissions";
// UTILS
import { getTableRowClass } from "../../../utils/utils";

library.add(
  faEllipsis,
  faTrash,
  faPenToSquare,
  faKey,
  faLock,
  faUnlock,
  faCheckCircle,
  faCircleExclamation,
  faBan
);

const UsersTable = () => {
  // ************************************************ */
  // GLOBAL VARIABLES ******************************* */
  const dispatch = useDispatch();
  const { theme } = useSelector((state: any) => state.home);
  const [t] = useTranslation("global");
  const { Column } = Table;

  // ************************************************ */
  // USE STATE VARIABLES **************************** */
  const [users, setUsers] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>({});

  // ************************************************ */
  // REDUX SLICE VARIABLES ************************** */
  const { userTableParams, userRefreshTable, permissions } = useSelector(
    (state: any) => state.user
  );
  const { userLogged } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [triggerGetUsersTable, { data, isLoading, isError, isFetching }] =
    useLazyGetUsersQuery();

  // ************************************************ */
  // ACTIONS **************************************** */

  const manageActions = () => {
    const BLANK = {
      label: (
        <div className="generalStyles__tableActionBox">
          <span>
            <FontAwesomeIcon
              className="generalStyles__error generalStyles__mrFix"
              icon={faBan}
            />
            {t("general.noActions")}
          </span>
        </div>
      ),
      key: "0",
    };

    const EDIT = validatePermission("usersupdate", permissions)
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenModalEdit();
              }}
              onKeyDown={() => {
                handleOpenModalEdit();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faPenToSquare}
                />
                {t("userManagement.edit")}
              </span>
            </div>
          ),
          key: "1",
        }
      : null;

    const BLOCK_USER = validatePermission("userslock-block", permissions)
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenModalLockUnlock();
              }}
              onKeyDown={() => {
                handleOpenModalLockUnlock();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faLock}
                />
                {t("general.block")}
              </span>
            </div>
          ),
          key: "2",
        }
      : null;

    const UNLOCK_USER = validatePermission("userslock-unblock", permissions)
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenModalLockUnlock();
              }}
              onKeyDown={() => {
                handleOpenModalLockUnlock();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faUnlock}
                />
                {t("general.unlock")}
              </span>
            </div>
          ),
          key: "3",
        }
      : null;

    let BLOCK_UNLOCK_USER = null;
    if (selectedItem.status === "ACTIVE") {
      BLOCK_UNLOCK_USER = BLOCK_USER;
    } else {
      BLOCK_UNLOCK_USER = UNLOCK_USER;
    }

    const RESET_PASSWORD = validatePermission(
      "usersreset-password",
      permissions
    )
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenModalResetUserPassword();
              }}
              onKeyDown={() => {
                handleOpenModalResetUserPassword();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faKey}
                />
                {t("userManagement.resetPassword")}
              </span>
            </div>
          ),
          key: "4",
        }
      : null;

    const DELETE = validatePermission("usersdelete", permissions)
      ? {
          label: (
            <div
              className="generalStyles__tableActionBox"
              role="button"
              tabIndex={0}
              onClick={() => {
                handleOpenModalDelete();
              }}
              onKeyDown={() => {
                handleOpenModalDelete();
              }}
            >
              <span>
                <FontAwesomeIcon
                  className="generalStyles__info generalStyles__mrFix"
                  icon={faTrash}
                />
                {t("userManagement.delete")}
              </span>
            </div>
          ),
          key: "5",
        }
      : null;
    // RETURN OBJECT WITH VALID ACTIONS
    if (selectedItem.status === "ACTIVE") {
      if (EDIT || BLOCK_UNLOCK_USER || RESET_PASSWORD || DELETE) {
        return [EDIT, RESET_PASSWORD, DELETE, BLOCK_UNLOCK_USER];
      }
    }
    if (selectedItem.status === "NEW") {
      if (EDIT || DELETE) {
        return [EDIT, DELETE];
      }
    }
    if (selectedItem.status === "BLOCKED") {
      if (BLOCK_UNLOCK_USER || DELETE) {
        return [DELETE, BLOCK_UNLOCK_USER];
      }
    }
    return [BLANK];
  };

  const menu = <Menu items={manageActions()} />;

  // ************************************************ */
  // FUNCTIONS ************************************** */

  const handleOpenModalEdit = () => {
    dispatch(updateUser(selectedItem));
    dispatch(updateOpenModalEditUser(true));
  };

  const handleOpenModalDelete = () => {
    dispatch(updateUser(selectedItem));
    dispatch(updateOpenModalDeleteUser(true));
  };

  const handleOpenModalResetUserPassword = () => {
    dispatch(updateUser(selectedItem));
    dispatch(updateOpenModalResetUserPassword(true));
  };

  const handleOpenModalLockUnlock = () => {
    dispatch(updateUser(selectedItem));
    dispatch(updateOpenModalBlockUnBlockUser(true));
  };

  const onCellClick = (record: any) => {
    if (record) {
      dispatch(updateUser(record));
      dispatch(updateOpenDrawerUser(true));
    }
  };

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: any
  ) => {
    dispatch(
      updateUserTableParams({
        pagination,
        filters,
        ...sorter,
      })
    );
    //
    const token = await GETJwtToken();
    triggerGetUsersTable({
      page: pagination.current,
      limit: pagination.pageSize,
      token,
    });
  };

  const refreshTable = async () => {
    dispatch(
      updateUserTableParams({
        pagination: {
          current: 1,
          pageSize: 10,
          total: userTableParams.pagination.total,
        },
        filters: {},
        sorter: {},
      })
    );
    //
    const token = await GETJwtToken();
    triggerGetUsersTable({
      page: 1,
      limit: 10,
      token,
      orgId: userLogged.org.id,
    });
  };

  const getInitialData = async () => {
    const token = await GETJwtToken();
    triggerGetUsersTable({
      page: userTableParams.pagination.current,
      limit: userTableParams.pagination.pageSize,
      token,
    });
  };

  // ************************************************* */
  // USE EFFECT ************************************** */
  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (data && data.data && data.data.length > 0) {
      dispatch(
        updateUserTableParams({
          ...userTableParams,
          pagination: {
            ...userTableParams.pagination,
            total: data.total,
          },
        })
      );
      setUsers(data.data);
      dispatch(updateUserList(data.data));
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      setUsers([]);
    }
  }, [isError]);

  useEffect(() => {
    if (userRefreshTable) {
      refreshTable();
    }
  }, [userRefreshTable]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div className="mt-3">
      <>
        {validatePermission("usersread", permissions) ? (
          <>
            {!isError ? (
              <Table
                rowClassName={(record, index) => getTableRowClass(index, theme)}
                className="customTable"
                dataSource={users}
                loading={isLoading || isFetching}
                rowKey={(record) => record.id}
                size="small"
                pagination={userTableParams.pagination}
                onChange={handleTableChange}
                scroll={{ x: 900 }}
              >
                {/** NAME */}
                <Column
                  onCell={(record) => ({
                    onClick: () => {
                      onCellClick(record);
                    },
                  })}
                  title={t("userManagement.name")}
                  dataIndex="first_name"
                  key="first_name"
                  width="200px"
                  filterSearch
                  render={(text, record: any) => (
                    <>
                      <div className="generalStyles__ellipsis__200px">
                        {record.first_name} {record.middle_name}{" "}
                        {record.last_name}
                      </div>
                    </>
                  )}
                />
                {/** EMAIL */}
                <Column
                  onCell={(record) => ({
                    onClick: () => {
                      onCellClick(record);
                    },
                  })}
                  title={t("userManagement.email")}
                  dataIndex="email"
                  key="email"
                  filterSearch
                  render={(text) => (
                    <>
                      <div className="">{text}</div>
                    </>
                  )}
                />
                {/** ORGANIZATION */}
                <Column
                  onCell={(record) => ({
                    onClick: () => {
                      onCellClick(record);
                    },
                  })}
                  title={t("organizationManagement.organization")}
                  dataIndex="organization"
                  key="organization"
                  filterSearch
                  render={(text, record: any) => (
                    <>
                      <div className="">
                        {record && record.org && record.org.name
                          ? record.org.name
                          : ""}
                      </div>{" "}
                    </>
                  )}
                />
                {/** ROLE */}
                <Column
                  onCell={(record) => ({
                    onClick: () => {
                      onCellClick(record);
                    },
                  })}
                  title={t("userManagement.role")}
                  dataIndex="role"
                  key="role"
                  filterSearch
                  render={(text) => (
                    <>
                      <div className="">{text.name || "--"}</div>
                    </>
                  )}
                />
                {/** STATUS */}
                <Column
                  onCell={(record) => ({
                    onClick: () => {
                      onCellClick(record);
                    },
                  })}
                  title={t("userManagement.status")}
                  dataIndex="status"
                  key="status"
                  className=""
                  filterSearch
                  render={(text) => (
                    <>
                      <div className="">
                        {text === "ACTIVE" && (
                          <Tag
                            color="success"
                            icon={
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                className="generalStyles__mrFix"
                              />
                            }
                            className="drawer__tag"
                          >
                            {t("userManagement.active")}
                          </Tag>
                        )}
                        {text === "NEW" && (
                          <Tag
                            color="warning"
                            icon={
                              <FontAwesomeIcon
                                icon={faCircleExclamation}
                                className="generalStyles__mrFix"
                              />
                            }
                            className="drawer__tag"
                          >
                            {t("userManagement.newUser")}
                          </Tag>
                        )}
                        {text === "BLOCKED" && (
                          <Tag
                            color="error"
                            icon={
                              <FontAwesomeIcon
                                icon={faBan}
                                className="generalStyles__mrFix"
                              />
                            }
                            className="drawer__tag"
                          >
                            {t("userManagement.lock")}
                          </Tag>
                        )}{" "}
                      </div>
                    </>
                  )}
                />
                {/** ACTIONS */}
                <Column
                  title={t("userManagement.actions")}
                  key="action"
                  width="100px"
                  render={(_: any, record: any) => (
                    <Dropdown overlay={menu} trigger={["click"]}>
                      <Space
                        className="generalStyles__hoverCursor"
                        onClick={() => {
                          setSelectedItem(record);
                        }}
                      >
                        <FontAwesomeIcon icon={faEllipsis} />
                      </Space>
                    </Dropdown>
                  )}
                />
              </Table>
            ) : (
              <Alert
                message={t("userManagement.errorDescription.error")}
                description={t("userManagement.errorDescription.text1")}
                type="error"
                showIcon
                className="mt-3"
              />
            )}
          </>
        ) : (
          <>
            <div className="generalStyles__noAccessToListTable">
              <Empty
                description={t("userManagement.listNotAvailable")}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default UsersTable;
