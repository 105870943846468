import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import {
  Drawer,
  Button,
  Form,
  Input,
  Select,
  Spin,
  Divider,
  InputNumber,
} from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuildingCircleExclamation,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { usePutUserMutation } from "../../../redux/users/userAPI";
import { useLazyGetRolesQuery } from "../../../redux/roles/roleAPI";
import {
  updateOpenModalEditUser,
  updateUserAlert,
} from "../../../redux/users/userSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

const { Option } = Select;
library.add(faBuildingCircleExclamation);

function UsersEditUserForm() {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const dispatch = useDispatch();
  const { user, openModalEditUser } = useSelector((state: any) => state.user);
  const [t] = useTranslation("global");
  const [listRoles, setListRoles] = useState<any[]>([]);
  const [triggerGetRoles, { data: dataGetRoles }] = useLazyGetRolesQuery();
  const { theme } = useSelector((state: any) => state.home);
  const [form] = Form.useForm();

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */
  const [
    putUser,
    {
      isSuccess: isSuccessPutUser,
      isError: isErrorPutUser,
      error: errorPutUser,
      isLoading: isLoadingPutUser,
      reset: resetPutUser,
    },
  ] = usePutUserMutation();

  // ************************************************ */
  // FUNCTIONS ************************************** */
  const onReset = () => {
    form.resetFields();
  };

  const handleCloseModal = () => {
    dispatch(updateOpenModalEditUser(false));
  };

  const onFinish = async (values: any) => {
    const token = await GETJwtToken();
    const BODY = {
      id: user.id,
      body: {
        contact_info: [
          {
            address: values.address,
            phone: values.phone.toString(),
            phone_code: values.dialCode.toString(),
            priority: 0,
          },
        ],
        email: values.email,
        first_name: values.firstName,
        middle_name: values.middleName,
        last_name: values.lastName,
        org_id: user.org_id,
        role_id: values.role,
        user_org_server: "",
      },
      token,
    };
    putUser(BODY);
  };

  const onClose = () => {
    onReset();
    dispatch(updateOpenModalEditUser(false));
  };

  const onFill = () => {
    if (user) {
      form.setFieldsValue({
        firstName: user.first_name ? user.first_name : "",
        middleName: user.middle_name ? user.middle_name : "",
        lastName: user.last_name ? user.last_name : "",
        email: user.email ? user.email : "",
        phone:
          user.contact_info && user.contact_inf[0] && user.contact_inf[0].phone
            ? parseInt(user.contact_info[0].phone, 10)
            : "",
        dialCode:
          user.contact_inf &&
          user.contact_inf[0] &&
          user.contact_inf[0].phone_code
            ? parseInt(user.contact_info[0].phone_code, 10)
            : "",
        role: user.role_id ? user.role_id : "",
        organization: user.org.name ? user.org.name : "",
        address:
          user.contact_info &&
          user.contact_info[0] &&
          user.contact_info[0].address &&
          user.contact_info[0].address
            ? user.contact_info[0].address
            : "",
      });
    }
  };

  const launchGetRoles = async () => {
    const token = await GETJwtToken();
    triggerGetRoles({ token });
  };

  // ************************************************* */
  // USE EFFECT ************************************** */

  useEffect(() => {
    if (openModalEditUser) {
      onFill();
      resetPutUser();
      launchGetRoles();
    }
  }, [openModalEditUser]);

  useEffect(() => {
    if (isSuccessPutUser) {
      setTimeout(() => {
        dispatch(
          updateUserAlert({
            title: t("general.success"),
            description: t("userManagement.userUpdated"),
            status: "success",
          })
        );
      }, 150);
      dispatch(updateOpenModalEditUser(false));
    }
    if (isErrorPutUser) {
      setTimeout(() => {
        dispatch(
          updateUserAlert({
            title: t("general.error"),
            description: errorPutUser,
            status: "error",
          })
        );
      }, 150);
      dispatch(updateOpenModalEditUser(false));
    }
  }, [isSuccessPutUser, isErrorPutUser]);

  useEffect(() => {
    if (dataGetRoles && dataGetRoles.data) {
      setListRoles(dataGetRoles.data);
    }
  }, [dataGetRoles]);

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <Drawer
      width="35%"
      placement="right"
      onClose={onClose}
      closable={false}
      visible={openModalEditUser}
    >
      <Spin spinning={isLoadingPutUser} tip={t("general.loading")}>
        <div>
          <Divider orientation="left" className="generalStyles__drawerDivider">
            <h5>
              <FontAwesomeIcon
                icon={faBuildingCircleExclamation}
                className="generalStyles__info generalStyles__mrFix"
              />
              {t("userManagement.editUser")}
            </h5>
          </Divider>
        </div>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <div className="container">
            <div
              className={
                theme === "dark"
                  ? " row drawer__box__dark"
                  : " row drawer__box__light"
              }
            >
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** EMAIL */}
                <Form.Item
                  name="email"
                  label={
                    <>
                      <span className="drawer__text">
                        {t("userManagement.email")}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t("userManagement.formRequiredMessage.email"),
                    },
                  ]}
                >
                  <Input className="generalStyles__input" disabled />
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** FIRTS NAME */}
                <Form.Item
                  name="firstName"
                  label={
                    <>
                      <span className="drawer__text">
                        {t("userManagement.firstName")}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t(
                        "userManagement.formRequiredMessage.firstName"
                      ),
                    },
                    {
                      max: 25,
                      message: t(
                        "userManagement.formRequiredMessage.firstNameMax"
                      ),
                    },
                  ]}
                >
                  <Input className="generalStyles__input" />
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** MIDDLE NAME */}
                <Form.Item
                  name="middleName"
                  label={
                    <>
                      <span className="drawer__text">
                        {t("userManagement.middleName")}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      max: 25,
                      message: t(
                        "userManagement.formRequiredMessage.middleNameMax"
                      ),
                    },
                  ]}
                >
                  <Input className="generalStyles__input" />
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** LAST NAME */}
                <Form.Item
                  name="lastName"
                  label={
                    <>
                      <span className="drawer__text">
                        {t("userManagement.lastName")}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t("userManagement.formRequiredMessage.lastName"),
                    },
                    {
                      max: 25,
                      message: t(
                        "userManagement.formRequiredMessage.lastNameMax"
                      ),
                    },
                  ]}
                >
                  <Input className="generalStyles__input" />
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** ADDRESS */}
                <Form.Item
                  name="address"
                  label={
                    <>
                      <span className="drawer__text">
                        {t("userManagement.address")}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t("userManagement.formRequiredMessage.address"),
                    },
                    {
                      max: 100,
                      message: t(
                        "userManagement.formRequiredMessage.addressMax"
                      ),
                    },
                  ]}
                >
                  <Input className="generalStyles__input" />
                </Form.Item>
              </div>
              <div className="col-12">
                {/** ---------------------------------------------------- */}
                {/** PHONE */}
                <Form.Item
                  name="phone"
                  label={
                    <>
                      <span className="drawer__text">
                        {t("organizationManagement.phoneNumber")}
                      </span>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: t(
                        "organizationManagement.formRequiredMessage.phone"
                      ),
                      type: "number",
                    },
                    {
                      type: "number",
                      max: 999999999,
                      message: t("userManagement.formRequiredMessage.phoneMax"),
                    },
                  ]}
                >
                  <InputNumber
                    addonBefore={
                      <Form.Item
                        name="dialCode"
                        rules={[
                          {
                            required: true,
                            message: t(
                              "userManagement.formRequiredMessage.dialCode"
                            ),
                          },
                          {
                            type: "number",
                            max: 99999,
                            message: t(
                              "userManagement.formRequiredMessage.dialCodeMax"
                            ),
                          },
                        ]}
                        noStyle
                      >
                        <InputNumber
                          prefix="+"
                          className="generalStyles__inputNumber"
                        />
                      </Form.Item>
                    }
                    className="generalStyles__width100"
                  />
                </Form.Item>
              </div>
            </div>
            <br />
            <Divider
              orientation="left"
              className="generalStyles__drawerDivider"
            >
              <h5>
                <FontAwesomeIcon
                  icon={faCircle}
                  className="generalStyles__info generalStyles__mrFix"
                />
                {t("organizationManagement.assignToOrganization")}
              </h5>
            </Divider>
            <div
              className={
                theme === "dark"
                  ? "row drawer__box__dark"
                  : "row drawer__box__light"
              }
            >
              {/** ---------------------------------------------------- */}
              {/** ORGANIZATION */}
              <Form.Item
                name="organization"
                label={
                  <>
                    <span className="drawer__text">
                      {t("userManagement.organization")}
                    </span>
                  </>
                }
              >
                <Input disabled className="generalStyles__input" />
              </Form.Item>
              {/** ---------------------------------------------------- */}
              {/** ROLE */}
              <Form.Item
                name="role"
                label={
                  <>
                    <span className="drawer__text">
                      {t("userManagement.role")}
                    </span>
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: t("userManagement.formRequiredMessage.role"),
                  },
                ]}
              >
                <Select
                  disabled={listRoles.length === 0}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {listRoles.map((st) => (
                    <Option key={st.id} value={st.id}>
                      {st.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          {/** ---------------------------------------------------- */}
          <div className="mt-4">
            <div>
              <Button onClick={handleCloseModal} className="buttonStyle__3">
                {t("general.close")}
              </Button>
              <Button
                htmlType="submit"
                disabled={isLoadingPutUser}
                className="buttonStyle__3"
                type="primary"
              >
                {t("general.save")}
              </Button>
            </div>
          </div>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default UsersEditUserForm;
