import React from "react";
// REACT DETECT OFFLINE
import { Offline, Online } from "react-detect-offline";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import OffsetTypeConfigurationTable from "./OffsetTypeTable";
import OffsetTypeForm from "./OffsetTypeForm";
import OffsetTypeDrawer from "./OffsetTypeDrawer";
import OffsetTypeDeleteModal from "./OffsetTypeDeleteModal";
// NO INTERNET CONNECTION
import NoInternetConnection from "../../unauthorized/NoInternetConnection";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

// eslint-disable-next-line arrow-body-style
const OffsetTypePage = () => {
  // ************************************************ */
  // LOCAL STORAGE AND VARIABLES ******************** */
  const { permissions } = useSelector((state: any) => state.user);
  const { theme } = useSelector((state: any) => state.home);

  // ************************************************ */
  // SERVICES AND API CALLS ************************* */

  // ************************************************* */
  // USE EFFECT ************************************** */

  // ************************************************ */
  // COMPONENT ************************************** */
  return (
    <div>
      {validatePermission("permission-not-defined", permissions) ? (
        <div
          className={
            theme === "light"
              ? "layout__contentPage__light"
              : "layout__contentPage__dark"
          }
        >
          <Online>
            <OffsetTypeConfigurationTable />
            <OffsetTypeForm />
            <OffsetTypeDrawer />
            <OffsetTypeDeleteModal />
          </Online>
          <Offline>
            <NoInternetConnection />
          </Offline>
        </div>
      ) : (
        <UnauthorizedPage />
      )}
    </div>
  );
};

export default OffsetTypePage;
